import Switch from 'react-switch';
import PropTypes from 'prop-types';

import styles from './toggle.module.css';

export function Toggle(props) {
  return (
    <label className={styles.toggle}>
      <span className={styles['switch-part']}>
        <Switch
          offColor={'#5f0000'}
          offHandleColor={'#efefef'}
          onColor={'#5f955f'}
          onHandleColor={'#efefef'}
          uncheckedHandleIcon={undefined}
          checkedHandleIcon={undefined}
          borderRadius={undefined}
          boxShadow={undefined}
          activeBoxShadow={'0 0 2px 3px #7f7f7f'}
          width={46}
          height={23}
          handleDiameter={19}
          checked={props.on}
          onChange={props.onChange !== undefined ? props.onChange : () => {}}
          disabled={props.disabled} />
      </span>
      <span className={styles['label-part']}>{props.children}</span>
    </label>
  );
}

Toggle.propTypes = {
  on: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
