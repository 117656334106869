/* eslint-disable no-magic-numbers, max-len */

import { STANDARD_OPENING_ASSISTANCE } from '../features/play/playerTypes.js';

export const RULES_TUTORIAL = {
  title: 'Tutorial: Rules',
  gameIdentifier: 'boost-9-9-2-8-2-demo',
  successMessage: <p>Correct!  Tap the "next" button to continue.</p>,
  failureMessage: <p>Oops!  That is not correct.  Tap the "previous" button to go back and try again.</p>,
  pages: [
    {
      caption: <p>This tutorial introduces the rules of the game.  Use the "previous" and "next" buttons at the bottom of the screen to navigate.</p>,
      treeName: 'full setup',
      rootPosition: '1180592888463981806592;495/0/0,2337571409020474380779520/0/0',
    },
    {
      caption: <h2>1. Overview</h2>,
      treeName: 'full setup',
    },
    {
      caption: <p>Boost is a turn-based abstract strategy board game like checkers, chess, Xiangqi, or Shōgi.</p>,
      treeName: 'full setup',
    },
    {
      caption: <h2>2. The Board</h2>,
      treeName: 'blank',
      rootPosition: '0;0/0/0,0/0/0',
    },
    {
      caption: <p>Boost is played on a 9×9 grid.</p>,
      treeName: 'blank',
    },
    {
      caption: <p>The vertical gridlines are called <strong>files</strong> and lettered "a" to "i" from west to east.</p>,
      treeName: 'blank',
      highlightFiles: () => true,
      hideRanks: () => true,
      hidePoints: () => true,
    },
    {
      caption: <p>The horizontal gridlines are called <strong>ranks</strong> and numbered "1" to "9" from south to north.</p>,
      treeName: 'blank',
      hideFiles: () => true,
      highlightRanks: () => true,
      hidePoints: () => true,
    },
    {
      caption: <p>Pieces are always placed on the gridlines' intersections, which are called <strong>points</strong>.</p>,
      treeName: 'blank',
      hideFiles: () => true,
      hideRanks: () => true,
      highlightPoints: () => true,
    },
    {
      caption: <p>Points are named by file and rank.  For example, the point in the southwest corner is called "a1" because it is where the "a" file and the first rank intersect.</p>,
      treeName: 'blank',
      target: 'a1',
      highlightFiles: (x) => x === 0,
      highlightRanks: (y) => y === 0,
      highlightPoints: (x, y) => x === 0 && y === 0,
    },
    {
      caption: <h2>3. The Pieces</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>There are three types of Boost pieces: …</p>,
      treeName: 'blank',
    },
    {
      caption: <p>There are three types of Boost pieces: <strong>pawns</strong>, …</p>,
      treeName: 'introduce pawns',
      rootPosition: '0;1048576/0/0,0/0/0',
    },
    {
      caption: <p>There are three types of Boost pieces: <strong>pawns</strong>, <strong>knights</strong>, …</p>,
      treeName: 'introduce knights',
      rootPosition: '0;1048576/4194304/0,0/0/0',
    },
    {
      caption: <p>There are three types of Boost pieces: <strong>pawns</strong>, <strong>knights</strong>, and <strong>towers</strong>.</p>,
      treeName: 'introduce towers',
      rootPosition: '0;1048576/4194304/16777216,0/0/0',
    },
    {
      caption: <p>Each player has their own color of pieces.  You can right-click or long press the board to change the piece colors.</p>,
      treeName: 'introduce colors',
      rootPosition: '0;1048576/4194304/16777216,72057594037927936/288230376151711744/1152921504606846976',
    },
    {
      caption: <p>There are also <strong>dragons</strong>, pawns that do not belongs to any player.  Dragons are always colored gray.</p>,
      treeName: 'introduce dragons',
      rootPosition: '1099511627776;1048576/4194304/16777216,72057594037927936/288230376151711744/1152921504606846976',
    },
    {
      caption: <p>The goal of the game is to build a tower and surround it with dragons while still having at least one pawn or knight on the board.</p>,
      treeName: 'dragon circle',
      rootPosition: '565700879974400;16777216/0/1099511627776,0/0/0',
    },
    {
      caption: <h2>4. Setup</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>In a two-player game, each side begins with eight pawns.  The first player's pawns go on the first rank, and the second player's pawns go on the ninth rank.  Pawns go on every file except for the "e" file.</p>,
      treeName: 'open setup',
      rootPosition: '0;495/0/0,2337571409020474380779520/0/0',
    },
    {
      caption: <p>Then, seven dragons are added in a random symmetric pattern.</p>,
      treeName: 'full setup',
    },
    {
      caption: <p>And the center dragon is usually adjusted to compensate the second player for their opponent's first-turn advantage.</p>,
      treeName: 'compensation',
      rootPosition: '1180592888463981806592;495/0/0,2337571409020474380779520/0/0',
      compensated: true,
    },
    {
      caption: <h2>5. Movement</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>Only one piece may move each turn.</p>,
      treeName: 'lone pawn',
      rootPosition: '0;1099511627776/0/0,0/0/0',
    },
    {
      caption: <p>Boost pieces move by taking <strong>steps</strong> along the gridlines.  Each step moves the piece one point east, north, west, or south.</p>,
      treeName: 'lone pawn movement',
      rootPosition: '0;1099511627776/0/0,0/0/0',
      singlePlayer: true,
      moves: [
        ['e5f5', 'f5e5', 'e5e6', 'e6e5', 'e5d5', 'd5e5', 'e5e4', 'e4e5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 8,
    },
    {
      caption: <p>Tap on the pawn to select it and then tap on one of the highlighted points to move it.</p>,
      treeName: 'one-step exercise',
      rootPosition: '0;1073741824/0/0,0/0/0',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>Using several turns, move the pawn to the target at f5.</p>,
      treeName: 'many-step exercise',
      rootPosition: '0;1073741824/0/0,0/0/0',
      singlePlayer: true,
      target: 'f5',
      done: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(5, 4)[1];
        return pieceType === game.pawn;
      },
    },
    {
      caption: <p>The number of steps a piece takes on a turn depends on its type and its neighbors.</p>,
      treeName: 'blank',
    },
    {
      caption: <p>Towers always have zero steps, so they cannot move.</p>,
      treeName: 'lone tower',
      rootPosition: '0;0/0/1099511627776,0/0/0',
    },
    {
      caption: <p>But pawns and knights get one step for themselves …</p>,
      treeName: 'unboosted pawn',
      rootPosition: '0;1099511627776/0/0,0/0/0',
      singlePlayer: true,
      moves: [
        ['e5f5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>… plus one additional step for each piece they are next to, …</p>,
      treeName: 'singly boosted pawn',
      rootPosition: '0;1099511627776/549755813888/0,0/0/0',
      singlePlayer: true,
      moves: [
        ['e5g5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>… even if that piece belongs to an opponent.</p>,
      treeName: 'doubly boosted pawn',
      rootPosition: '0;1099511627776/549755813888/0,0/562949953421312/0',
      singlePlayer: true,
      moves: [
        ['e5h5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>The additional steps are called <strong>boosts</strong>.</p>,
      treeName: 'pawn after boosts',
      rootPosition: '0;8796093022208/549755813888/0,0/562949953421312/0',
    },
    {
      caption: <p>Move the pawn to the target at g6 in two turns.</p>,
      treeName: 'rapid movement exercise',
      rootPosition: '0;1073741824/0/0,0/0/555745280',
      singlePlayer: true,
      target: 'g6',
      done: (game, encoding) => encoding.ply >= 2 * 2,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(6, 5)[1];
        return pieceType === game.pawn;
      },
    },
    {
      caption: <p>When a piece moves, it must always take all of its steps.</p>,
      treeName: 'overshooting exercise preview',
      rootPosition: '0;1099511627776/0/0,0/0/549755813888',
    },
    {
      caption: <p>Move the pawn to the target at f5.</p>,
      treeName: 'overshooting exercise',
      rootPosition: '0;1099511627776/0/0,0/0/549755813888',
      singlePlayer: true,
      target: 'f5',
      done: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(5, 4)[1];
        return pieceType === game.pawn;
      },
    },
    {
      caption: <p>A piece can change direction between steps.  For example, this pawn can move to d6 by stepping west and then north.</p>,
      treeName: 'mixed movement',
      rootPosition: '0;1099511627776/0/0,0/0/567628246548480',
      singlePlayer: true,
      moves: [
        ['e5d6'],
      ],
      target: 'd6',
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>But a piece may not step onto a point occupied by another piece.  Here, the e6 tower blocks the same pawn from moving to e7 with two steps north.</p>,
      treeName: 'forbidden movement',
      rootPosition: '0;1099511627776/0/0,0/0/567628246548480',
      target: 'e7',
    },
    {
      caption: <p>Also, a piece may not occupy the same point twice in one turn.  So if this pawn steps south, it cannot use its second step to return north.</p>,
      treeName: 'forbidden movement',
      target: 'e5',
    },
    {
      caption: <p>Move the pawn to the target at f5.  (Hint: The pawn can only reach the target from one place.)</p>,
      treeName: 'constrained movement exercise',
      rootPosition: '0;1099511627776/0/0,0/0/567628246548480',
      singlePlayer: true,
      target: 'f5',
      done: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(5, 4)[1];
        return pieceType === game.pawn;
      },
    },
    {
      caption: <h2>6. Construction</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>If an empty point is surrounded by a player's pieces, and that player has fewer than two towers, then that player can spend their turn to place a tower on the empty point.</p>,
      treeName: 'construction site preview',
      rootPosition: '0;565700879974400/0/0,0/0/0',
    },
    {
      caption: <p>This is called <strong>building</strong> the tower.</p>,
      treeName: 'construction site',
      rootPosition: '0;565700879974400/0/0,0/0/0',
      singlePlayer: true,
      moves: [
        ['e5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>Tap twice on the empty point in the middle to build a tower.</p>,
      treeName: 'construction exercise',
      rootPosition: '0;565700879974400/0/0,0/0/0',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 4)[1];
        return pieceType === game.tower;
      },
    },
    {
      caption: <h2>7. Promotion</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>If a player's pawn is next to one of their towers, and that player has fewer knights than towers, then that player can spend their turn to replace the pawn with a knight.</p>,
      treeName: 'knighting ceremony preview',
      rootPosition: '0;2199023255552/0/1099511627776,0/0/0',
    },
    {
      caption: <p>This is called <strong>promoting</strong> or <strong>knighting</strong> the pawn.</p>,
      treeName: 'knighting ceremony',
      rootPosition: '0;2199023255552/0/1099511627776,0/0/0',
      singlePlayer: true,
      moves: [
        ['f5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>Tap twice on a pawn to knight it.</p>,
      treeName: 'knighting exercise',
      rootPosition: '0;565700879974400/0/1099511627776,0/0/0',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const firstPieceType = position.getColorAndPieceType(3, 4)[1];
        const secondPieceType = position.getColorAndPieceType(4, 3)[1];
        const thirdPieceType = position.getColorAndPieceType(4, 5)[1];
        const fourthPieceType = position.getColorAndPieceType(5, 4)[1];
        return firstPieceType === game.knight || secondPieceType === game.knight || thirdPieceType === game.knight || fourthPieceType === game.knight;
      },
    },
    {
      caption: <p>Because a player can never have more than two towers, they can never have more than two knights.</p>,
      treeName: 'promotion limit',
      rootPosition: '0;2097153/151115727454027670093824/549755817984,281474976710656/1099511627776/295147905179352825856',
    },
    {
      caption: <p>But even though towers can be captured (as explained next), knights are never demoted to pawns.  So a player can end up with more knights than towers.</p>,
      treeName: 'non-demotion',
      rootPosition: '0;2097153/151115727454027670093824/549755817984,281474976710656/1099511627776/295147905179352825856',
      moves: [
        ['f5g6'],
        ['d6c5'],
      ],
    },
    {
      caption: <h2>8. Capturing with Knights</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>When a player moves a knight, they may remove an opponent's piece that blocks the knight's final step.</p>,
      treeName: 'knight capture preview',
      rootPosition: '0;549755813888/1099511627776/0,4398046511104/0/0',
    },
    {
      caption: <p>This is called <strong>capturing</strong> the opponent's piece with the knight.</p>,
      treeName: 'knight capture',
      rootPosition: '0;549755813888/1099511627776/0,4398046511104/0/0',
      singlePlayer: true,
      moves: [
        ['e5g5'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>Capture the opposing tower in one turn.</p>,
      treeName: 'knight capture exercise',
      rootPosition: '0;2147483648/1099511627776/0,0/0/562949953421312',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 5)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <p>Dragons may not be captured.</p>,
      treeName: 'knight capture with decoys exercise preview',
      rootPosition: '2147483648;0/1099511627776/0,0/0/562949953421312',
    },
    {
      caption: <p>Capture any piece in one turn.</p>,
      treeName: 'knight capture with decoys exercise',
      rootPosition: '2147483648;0/1099511627776/0,0/0/562949953421312',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 5)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <h2>9. Capturing with Pawns</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>After a player finishes moving a pawn, if an opponent's piece is adjacent to that pawn on one side and adjacent to another of the player's pieces on the opposite side, it is removed from the board.</p>,
      treeName: 'pawn capture preview',
      rootPosition: '0;288230376151711744/2147483648/0,0/0/1099511627776',
    },
    {
      caption: <p>This is called <strong>capturing</strong> or <strong>flanking</strong> the opponent's piece with the pawn.</p>,
      treeName: 'pawn capture',
      rootPosition: '0;288230376151711744/2147483648/0,0/0/1099511627776',
      singlePlayer: true,
      moves: [
        ['e7e6'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
    },
    {
      caption: <p>Capture the opposing tower in one turn.</p>,
      treeName: 'pawn capture exercise',
      rootPosition: '0;562949953421312/549755813888/0,0/0/1099511627776',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 4)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <p>A pawn can capture multiple pieces in the same turn.</p>,
      treeName: 'multiple pawn capture exercise preview',
      rootPosition: '0;562949953421312/4672928612352/0,0/0/2750926553088',
    },
    {
      caption: <p>Capture all of the opposing towers in one turn.</p>,
      treeName: 'multiple pawn capture exercise',
      rootPosition: '0;562949953421312/4672928612352/0,0/0/2750926553088',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const firstPieceType = position.getColorAndPieceType(3, 4)[1];
        const secondPieceType = position.getColorAndPieceType(4, 3)[1];
        const thirdPieceType = position.getColorAndPieceType(5, 4)[1];
        return firstPieceType !== game.tower && secondPieceType !== game.tower && thirdPieceType !== game.tower;
      },
    },
    {
      caption: <p>Pawns only capture after they move.  They cannot capture at other times.</p>,
      treeName: 'movement through pawns exercise preview',
      rootPosition: '0;0/2147483648/0,2748779069440/0/0',
    },
    {
      caption: <p>Move the knight to the target in two turns without it being captured.</p>,
      treeName: 'movement through pawns exercise',
      rootPosition: '0;0/2147483648/0,2748779069440/0/0',
      singlePlayer: true,
      target: 'e8',
      done: (game, encoding) => encoding.ply >= 2 * 2,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 7)[1];
        return pieceType === game.knight;
      },
    },
    {
      caption: <p>When a player is moving a pawn, dragons also count as that player's pieces and can be used for flanking.</p>,
      treeName: 'pawn-with-dragon capture exercise preview',
      rootPosition: '2147483648;288230376151711744/0/0,0/0/1099511627776',
    },
    {
      caption: <p>Capture the opposing tower in one turn.</p>,
      treeName: 'pawn-with-dragon capture exercise',
      rootPosition: '2147483648;288230376151711744/0/0,0/0/1099511627776',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 4)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <p>Dragons may not be captured.</p>,
      treeName: 'pawn capture with decoys exercise preview',
      rootPosition: '551903297536;562949953421312/4672928612352/0,0/0/2199023255552',
    },
    {
      caption: <p>Capture any piece in one turn.</p>,
      treeName: 'pawn capture with decoys exercise',
      rootPosition: '551903297536;562949953421312/4672928612352/0,0/0/2199023255552',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(5, 4)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <h2>10. Moving Dragons</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>A player can move any dragon that is next to one of their pieces.  They cannot move other dragons.</p>,
      treeName: 'dragon movement exercise preview',
      rootPosition: '2323866345583673408;4611687118476935168/0/8192,0/0/0',
    },
    {
      caption: <p>Move a dragon in one turn.</p>,
      treeName: 'dragon movement exercise',
      rootPosition: '2323866345583673408;4611687118476935168/0/8192,0/0/0',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(7, 6)[1];
        return pieceType !== game.dragon;
      },
    },
    {
      caption: <h2>11. Capturing with Dragons</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>Dragons capture like pawns.</p>,
      treeName: 'dragon-with-pawn capture exercise preview',
      rootPosition: '2147483648;550833750016/0/0,0/0/1099511627776',
    },
    {
      caption: <p>Capture the opposing tower in one turn.</p>,
      treeName: 'dragon-with-pawn capture exercise',
      rootPosition: '2147483648;550833750016/0/0,0/0/1099511627776',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 4)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <p>When a player is moving a dragon, dragons also count as that player's pieces and can be used for flanking.</p>,
      treeName: 'dragon-with-dragon capture exercise preview',
      rootPosition: '551903297536;1077936128/0/0,0/0/1099511627776',
    },
    {
      caption: <p>Capture the opposing tower in one turn.</p>,
      treeName: 'dragon-with-dragon capture exercise',
      rootPosition: '551903297536;1077936128/0/0,0/0/1099511627776',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(4, 4)[1];
        return pieceType !== game.tower;
      },
    },
    {
      caption: <h2>12. Passing</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>A player is <strong>immobilized</strong> if they cannot move, build, or promote any piece.  An immobilized player must skip their turn.  This is called <strong>passing</strong>.</p>,
      treeName: 'immobilization exercise preview',
      rootPosition: '0;524800/0/1024,1/0/268435456',
    },
    {
      caption: <p>Force the opponent to pass in one turn.</p>,
      treeName: 'immobilization exercise',
      rootPosition: '0;524800/0/1024,1/0/268435456',
      singlePlayer: true,
      moves: [
        undefined,
        [['a1a2', 'a1c1', '--']],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        const pieceType = position.getColorAndPieceType(0, 0)[1];
        return pieceType === game.pawn;
      },
    },
    {
      caption: <p>A player who is not immobilized may not skip their turn.</p>,
      treeName: 'blank',
    },
    {
      caption: <h2>13. Repetition</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>A <strong>position</strong> is an arrangement of the pieces on the board.</p>,
      treeName: 'repetition preview',
      rootPosition: '1227302366714306560;1180591620717411827712/145135534866432/20480,302379317086623121801216/281474976710656/0',
    },
    {
      caption: <p>If players could keep moving to the same positions, then the game could last forever.</p>,
      treeName: 'repetition',
      rootPosition: '1227302366714306560;1180591620717411827712/145135534866432/20480,302379317086623121801216/281474976710656/0',
      moves: [
        ['c7d8', 'c7d8', 'c7d8'],
        ['d8c7', 'd8c7', 'd8c7'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 3,
    },
    {
      caption: <p>So a player may not repeat a position from earlier in the game unless they have no other choice.  They must play a different move, even if that move is bad for them.</p>,
      treeName: 'prevented repetition',
      rootPosition: '1227302366714306560;1180591620717411827712/145135534866432/20480,302379317086623121801216/281474976710656/0',
      moves: [
        ['c7d8', 'g6f8'],
        ['e7c7'],
      ],
      done: (game, encoding) => encoding.ply >= 3,
    },
    {
      caption: <h2>14. Winning</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>A player wins when they have a tower with four adjacent dragons and at least one pawn or knight.</p>,
      treeName: 'dragon circle',
    },
    {
      caption: <p>Win the game in one turn.</p>,
      treeName: 'win-by-dragon-circle exercise',
      rootPosition: '151706024388087263537160;8388608/0/4096,0/1064960/295147905179352825856',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        return !position.live;
      },
    },
    {
      caption: <p>When a player does not have enough pieces to win, the other player is declared the winner immediately.</p>,
      treeName: 'win-by-defeat exercise preview',
      rootPosition: '1226254532137197568;2252899325313024/0/4096,549755846656/4503599627370496/2199023255552',
    },
    {
      caption: <p>Win the game in one turn by making it impossible for the opponent to win.  (Hint: Winning requires a tower, and it takes four pieces to rebuild a tower.)</p>,
      treeName: 'win-by-defeat exercise',
      rootPosition: '1226254532137197568;2252899325313024/0/4096,549755846656/4503599627370496/2199023255552',
      singlePlayer: true,
      done: (game, encoding) => encoding.ply >= 2 * 1,
      successful: (game, encoding) => {
        const position = game.deserializePosition(encoding.serialization);
        return !position.live;
      },
    },
    {
      caption: <p>Congratulations!  You now know how to play Boost.  To wrap things up, here are a couple of features to help you get the most out of your first few games:</p>,
      treeName: 'blank',
    },
    {
      caption: <h2>14. App Features for New Players</h2>,
      treeName: 'blank',
    },
    {
      caption: <p>One big challenge as a new player is figuring out how to build towers from the starting position.  To help with this, you can turn on <strong>opening assistance</strong> in the player selection screen.</p>,
      treeName: 'opening with suggestions',
      rootPosition: '75560174073622945071120;16859335/0/0,2144046689000781512704000/0/0',
      suggestions: ['h1i3', 'g2'],
    },
    {
      caption: <p>For the first {STANDARD_OPENING_ASSISTANCE} turns, opening assistance will suggest moves using blue arrows and circles.</p>,
      treeName: 'opening with suggestions',
    },
    {
      caption: <p>Here the opening assistance has two suggestions: either build a tower on g2 or move the h1 pawn to i3 (to get ready for a tower on h3).</p>,
      treeName: 'opening with suggestions',
    },
    {
      caption: <p>The AI may change its mind as it thinks about the position.  A blinking blue dot in the lower right corner lets you know when it is still thinking.</p>,
      treeName: 'opening with suggestions',
    },
    {
      caption: <p>Another big challenge as a new player is learning to defend against your opponents' attacks.  There is a special <strong>teaching-game bot</strong> you can play against to practice this skill.</p>,
      treeName: 'teaching game preview',
      rootPosition: '149303335956844118016;36710984/0/4096,1511470887252306784419840/73859033888876134400/36929516944438067200',
    },
    {
      caption: <p>Because it always makes its attacks too daring, it is a good choice for your first opponent.  As long as you are paying attention to defense, it should not be too hard to turn the tables.</p>,
      treeName: 'teaching game',
      rootPosition: '149303335956844118016;36710984/0/4096,1511470887252306784419840/73859033888876134400/36929516944438067200',
      moves: [
        ['d1b1', 'd3c5', 'c5b4', 'b4c5'],
        ['c7d5', 'd8c6', 'c6c4'],
      ],
      done: (game, encoding) => encoding.ply >= 2 * 1 + 1,
    },
    {
      caption: <p>With that advice in mind, you are ready to play.  Tap the two-shields icon at the top of the screen to start a game or press the reset button in the lower left to restart the tutorial.</p>,
      treeName: 'full setup',
    },
  ],
};
