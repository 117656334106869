// WARNING: DO NOT EDIT THIS CODE.
// This code is autogenerated, and any changes you make here will be overwritten.
// Modify `src/generator.js` in `@unlsoft/boost-game` instead.

/* eslint-disable no-magic-numbers, no-extra-parens, max-len */

// Work around React not using the es2020 env or newer in ESLint:
// eslint-disable-next-line no-redeclare
/* global BigInt */

const BITS_TO_COORDINATES = new Map([
  [0n, [undefined, undefined]],
  [1n, [0, 0]],
  [2n, [1, 0]],
  [4n, [2, 0]],
  [8n, [3, 0]],
  [16n, [4, 0]],
  [32n, [5, 0]],
  [64n, [6, 0]],
  [128n, [7, 0]],
  [256n, [8, 0]],
  [512n, [0, 1]],
  [1024n, [1, 1]],
  [2048n, [2, 1]],
  [4096n, [3, 1]],
  [8192n, [4, 1]],
  [16384n, [5, 1]],
  [32768n, [6, 1]],
  [65536n, [7, 1]],
  [131072n, [8, 1]],
  [262144n, [0, 2]],
  [524288n, [1, 2]],
  [1048576n, [2, 2]],
  [2097152n, [3, 2]],
  [4194304n, [4, 2]],
  [8388608n, [5, 2]],
  [16777216n, [6, 2]],
  [33554432n, [7, 2]],
  [67108864n, [8, 2]],
  [134217728n, [0, 3]],
  [268435456n, [1, 3]],
  [536870912n, [2, 3]],
  [1073741824n, [3, 3]],
  [2147483648n, [4, 3]],
  [4294967296n, [5, 3]],
  [8589934592n, [6, 3]],
  [17179869184n, [7, 3]],
  [34359738368n, [8, 3]],
  [68719476736n, [0, 4]],
  [137438953472n, [1, 4]],
  [274877906944n, [2, 4]],
  [549755813888n, [3, 4]],
  [1099511627776n, [4, 4]],
  [2199023255552n, [5, 4]],
  [4398046511104n, [6, 4]],
  [8796093022208n, [7, 4]],
  [17592186044416n, [8, 4]],
  [35184372088832n, [0, 5]],
  [70368744177664n, [1, 5]],
  [140737488355328n, [2, 5]],
  [281474976710656n, [3, 5]],
  [562949953421312n, [4, 5]],
  [1125899906842624n, [5, 5]],
  [2251799813685248n, [6, 5]],
  [4503599627370496n, [7, 5]],
  [9007199254740992n, [8, 5]],
  [18014398509481984n, [0, 6]],
  [36028797018963968n, [1, 6]],
  [72057594037927936n, [2, 6]],
  [144115188075855872n, [3, 6]],
  [288230376151711744n, [4, 6]],
  [576460752303423488n, [5, 6]],
  [1152921504606846976n, [6, 6]],
  [2305843009213693952n, [7, 6]],
  [4611686018427387904n, [8, 6]],
  [9223372036854775808n, [0, 7]],
  [18446744073709551616n, [1, 7]],
  [36893488147419103232n, [2, 7]],
  [73786976294838206464n, [3, 7]],
  [147573952589676412928n, [4, 7]],
  [295147905179352825856n, [5, 7]],
  [590295810358705651712n, [6, 7]],
  [1180591620717411303424n, [7, 7]],
  [2361183241434822606848n, [8, 7]],
  [4722366482869645213696n, [0, 8]],
  [9444732965739290427392n, [1, 8]],
  [18889465931478580854784n, [2, 8]],
  [37778931862957161709568n, [3, 8]],
  [75557863725914323419136n, [4, 8]],
  [151115727451828646838272n, [5, 8]],
  [302231454903657293676544n, [6, 8]],
  [604462909807314587353088n, [7, 8]],
  [1208925819614629174706176n, [8, 8]],
]);

const BITS_TO_DIAMONDS = new Map([
  [1n, 69123972639n],
  [2n, 138382425663n],
  [4n, 276765113983n],
  [8n, 553530228479n],
  [16n, 1107060456959n],
  [32n, 2214120913406n],
  [64n, 4428241564156n],
  [128n, 8856348647928n],
  [256n, 17643843338736n],
  [512n, 35391473991183n],
  [1024n, 70851801939487n],
  [2048n, 141703738359359n],
  [4096n, 283407476981375n],
  [8192n, 566814953963262n],
  [16384n, 1133629907664380n],
  [32768n, 2267259680848376n],
  [65536n, 4534450507739632n],
  [131072n, 9033647789433312n],
  [262144n, 18120434683485703n],
  [524288n, 36276122593017359n],
  [1048576n, 72552314039991839n],
  [2097152n, 145104628214464062n],
  [4194304n, 290209256429190268n],
  [8388608n, 580418512724162808n],
  [16777216n, 1160836956594369008n],
  [33554432n, 2321638659962692064n],
  [67108864n, 4625227668189856192n],
  [134217728n, 9277662557944679939n],
  [268435456n, 18573374767624887815n],
  [536870912n, 37146784788475821582n],
  [1073741824n, 74293569645805599772n],
  [2147483648n, 148587139291745417272n],
  [4294967296n, 297174278514771357808n],
  [8589934592n, 594348521776316932320n],
  [17179869184n, 1188678993900898337216n],
  [34359738368n, 2368116566113206370688n],
  [68719476736n, 4750163229667676128769n],
  [137438953472n, 9509567881023942561282n],
  [274877906944n, 19019153811699620649988n],
  [549755813888n, 38038307658652467083272n],
  [1099511627776n, 76076615317373653643280n],
  [2199023255552n, 152153230599562935197728n],
  [4398046511104n, 304306443149474269347904n],
  [8796093022208n, 608603644877259948654720n],
  [17592186044416n, 1212475681849961661792512n],
  [35184372088832n, 14231934360591828517376n],
  [70368744177664n, 33195476625741892551680n],
  [140737488355328n, 66400194673172375144448n],
  [281474976710656n, 132800407395996351336448n],
  [562949953421312n, 265600814827177074761728n],
  [1125899906842624n, 531201611639955640041472n],
  [2251799813685248n, 1062393981858291543736320n],
  [4503599627370496n, 2120056355812024986435584n],
  [9007199254740992n, 1817529464490233388204032n],
  [18014398509481984n, 33195474935241152659456n],
  [36028797018963968n, 71122557775040540573696n],
  [72057594037927936n, 146976723454639182184448n],
  [144115188075855872n, 293962688330898100715520n],
  [288230376151711744n, 587925394676194710913024n],
  [576460752303423488n, 1175841565980352567050240n],
  [1152921504606846976n, 2346951524091400124628992n],
  [2305843009213693952n, 2271319801049052518285312n],
  [4611686018427387904n, 2120056354964288586121216n],
  [9223372036854775808n, 71121692238661715755008n],
  [18446744073709551616n, 146974992381881532547072n],
  [36893488147419103232n, 298681592668252446654464n],
  [73786976294838206464n, 602094793205809902780416n],
  [147573952589676412928n, 1204198809783656660336640n],
  [295147905179352825856n, 2403675253084443675459584n],
  [590295810358705651712n, 2384767277084722501517312n],
  [1180591620717411303424n, 2346951307070881644150784n],
  [2361183241434822606848n, 2271319367008015557328896n],
  [4722366482869645213696n, 146531837755923225378816n],
  [9444732965739290427392n, 297795283416335832317952n],
  [18889465931478580854784n, 600322174701976674107392n],
  [37778931862957161709568n, 1205375939258859848204288n],
  [75557863725914323419136n, 2415474245000589341622272n],
  [151115727451828646838272n, 2413096850771920333832192n],
  [302231454903657293676544n, 2403619695831712673038336n],
  [604462909807314587353088n, 2384656162579260496674816n],
  [1208925819614629174706176n, 2346729078059957634465792n],
]);

const BIT_PAIRS_TO_DISTANCES = new Map([
  [1n, 0],
  [3n, 1],
  [5n, 2],
  [9n, 3],
  [17n, 4],
  [33n, 5],
  [65n, 6],
  [129n, 7],
  [257n, 8],
  [513n, 1],
  [1025n, 2],
  [2049n, 3],
  [4097n, 4],
  [8193n, 5],
  [16385n, 6],
  [32769n, 7],
  [65537n, 8],
  [131073n, 9],
  [262145n, 2],
  [524289n, 3],
  [1048577n, 4],
  [2097153n, 5],
  [4194305n, 6],
  [8388609n, 7],
  [16777217n, 8],
  [33554433n, 9],
  [67108865n, 10],
  [134217729n, 3],
  [268435457n, 4],
  [536870913n, 5],
  [1073741825n, 6],
  [2147483649n, 7],
  [4294967297n, 8],
  [8589934593n, 9],
  [17179869185n, 10],
  [34359738369n, 11],
  [68719476737n, 4],
  [137438953473n, 5],
  [274877906945n, 6],
  [549755813889n, 7],
  [1099511627777n, 8],
  [2199023255553n, 9],
  [4398046511105n, 10],
  [8796093022209n, 11],
  [17592186044417n, 12],
  [35184372088833n, 5],
  [70368744177665n, 6],
  [140737488355329n, 7],
  [281474976710657n, 8],
  [562949953421313n, 9],
  [1125899906842625n, 10],
  [2251799813685249n, 11],
  [4503599627370497n, 12],
  [9007199254740993n, 13],
  [18014398509481985n, 6],
  [36028797018963969n, 7],
  [72057594037927937n, 8],
  [144115188075855873n, 9],
  [288230376151711745n, 10],
  [576460752303423489n, 11],
  [1152921504606846977n, 12],
  [2305843009213693953n, 13],
  [4611686018427387905n, 14],
  [9223372036854775809n, 7],
  [18446744073709551617n, 8],
  [36893488147419103233n, 9],
  [73786976294838206465n, 10],
  [147573952589676412929n, 11],
  [295147905179352825857n, 12],
  [590295810358705651713n, 13],
  [1180591620717411303425n, 14],
  [2361183241434822606849n, 15],
  [4722366482869645213697n, 8],
  [9444732965739290427393n, 9],
  [18889465931478580854785n, 10],
  [37778931862957161709569n, 11],
  [75557863725914323419137n, 12],
  [151115727451828646838273n, 13],
  [302231454903657293676545n, 14],
  [604462909807314587353089n, 15],
  [1208925819614629174706177n, 16],
  [3n, 1],
  [2n, 0],
  [6n, 1],
  [10n, 2],
  [18n, 3],
  [34n, 4],
  [66n, 5],
  [130n, 6],
  [258n, 7],
  [514n, 2],
  [1026n, 1],
  [2050n, 2],
  [4098n, 3],
  [8194n, 4],
  [16386n, 5],
  [32770n, 6],
  [65538n, 7],
  [131074n, 8],
  [262146n, 3],
  [524290n, 2],
  [1048578n, 3],
  [2097154n, 4],
  [4194306n, 5],
  [8388610n, 6],
  [16777218n, 7],
  [33554434n, 8],
  [67108866n, 9],
  [134217730n, 4],
  [268435458n, 3],
  [536870914n, 4],
  [1073741826n, 5],
  [2147483650n, 6],
  [4294967298n, 7],
  [8589934594n, 8],
  [17179869186n, 9],
  [34359738370n, 10],
  [68719476738n, 5],
  [137438953474n, 4],
  [274877906946n, 5],
  [549755813890n, 6],
  [1099511627778n, 7],
  [2199023255554n, 8],
  [4398046511106n, 9],
  [8796093022210n, 10],
  [17592186044418n, 11],
  [35184372088834n, 6],
  [70368744177666n, 5],
  [140737488355330n, 6],
  [281474976710658n, 7],
  [562949953421314n, 8],
  [1125899906842626n, 9],
  [2251799813685250n, 10],
  [4503599627370498n, 11],
  [9007199254740994n, 12],
  [18014398509481986n, 7],
  [36028797018963970n, 6],
  [72057594037927938n, 7],
  [144115188075855874n, 8],
  [288230376151711746n, 9],
  [576460752303423490n, 10],
  [1152921504606846978n, 11],
  [2305843009213693954n, 12],
  [4611686018427387906n, 13],
  [9223372036854775810n, 8],
  [18446744073709551618n, 7],
  [36893488147419103234n, 8],
  [73786976294838206466n, 9],
  [147573952589676412930n, 10],
  [295147905179352825858n, 11],
  [590295810358705651714n, 12],
  [1180591620717411303426n, 13],
  [2361183241434822606850n, 14],
  [4722366482869645213698n, 9],
  [9444732965739290427394n, 8],
  [18889465931478580854786n, 9],
  [37778931862957161709570n, 10],
  [75557863725914323419138n, 11],
  [151115727451828646838274n, 12],
  [302231454903657293676546n, 13],
  [604462909807314587353090n, 14],
  [1208925819614629174706178n, 15],
  [5n, 2],
  [6n, 1],
  [4n, 0],
  [12n, 1],
  [20n, 2],
  [36n, 3],
  [68n, 4],
  [132n, 5],
  [260n, 6],
  [516n, 3],
  [1028n, 2],
  [2052n, 1],
  [4100n, 2],
  [8196n, 3],
  [16388n, 4],
  [32772n, 5],
  [65540n, 6],
  [131076n, 7],
  [262148n, 4],
  [524292n, 3],
  [1048580n, 2],
  [2097156n, 3],
  [4194308n, 4],
  [8388612n, 5],
  [16777220n, 6],
  [33554436n, 7],
  [67108868n, 8],
  [134217732n, 5],
  [268435460n, 4],
  [536870916n, 3],
  [1073741828n, 4],
  [2147483652n, 5],
  [4294967300n, 6],
  [8589934596n, 7],
  [17179869188n, 8],
  [34359738372n, 9],
  [68719476740n, 6],
  [137438953476n, 5],
  [274877906948n, 4],
  [549755813892n, 5],
  [1099511627780n, 6],
  [2199023255556n, 7],
  [4398046511108n, 8],
  [8796093022212n, 9],
  [17592186044420n, 10],
  [35184372088836n, 7],
  [70368744177668n, 6],
  [140737488355332n, 5],
  [281474976710660n, 6],
  [562949953421316n, 7],
  [1125899906842628n, 8],
  [2251799813685252n, 9],
  [4503599627370500n, 10],
  [9007199254740996n, 11],
  [18014398509481988n, 8],
  [36028797018963972n, 7],
  [72057594037927940n, 6],
  [144115188075855876n, 7],
  [288230376151711748n, 8],
  [576460752303423492n, 9],
  [1152921504606846980n, 10],
  [2305843009213693956n, 11],
  [4611686018427387908n, 12],
  [9223372036854775812n, 9],
  [18446744073709551620n, 8],
  [36893488147419103236n, 7],
  [73786976294838206468n, 8],
  [147573952589676412932n, 9],
  [295147905179352825860n, 10],
  [590295810358705651716n, 11],
  [1180591620717411303428n, 12],
  [2361183241434822606852n, 13],
  [4722366482869645213700n, 10],
  [9444732965739290427396n, 9],
  [18889465931478580854788n, 8],
  [37778931862957161709572n, 9],
  [75557863725914323419140n, 10],
  [151115727451828646838276n, 11],
  [302231454903657293676548n, 12],
  [604462909807314587353092n, 13],
  [1208925819614629174706180n, 14],
  [9n, 3],
  [10n, 2],
  [12n, 1],
  [8n, 0],
  [24n, 1],
  [40n, 2],
  [72n, 3],
  [136n, 4],
  [264n, 5],
  [520n, 4],
  [1032n, 3],
  [2056n, 2],
  [4104n, 1],
  [8200n, 2],
  [16392n, 3],
  [32776n, 4],
  [65544n, 5],
  [131080n, 6],
  [262152n, 5],
  [524296n, 4],
  [1048584n, 3],
  [2097160n, 2],
  [4194312n, 3],
  [8388616n, 4],
  [16777224n, 5],
  [33554440n, 6],
  [67108872n, 7],
  [134217736n, 6],
  [268435464n, 5],
  [536870920n, 4],
  [1073741832n, 3],
  [2147483656n, 4],
  [4294967304n, 5],
  [8589934600n, 6],
  [17179869192n, 7],
  [34359738376n, 8],
  [68719476744n, 7],
  [137438953480n, 6],
  [274877906952n, 5],
  [549755813896n, 4],
  [1099511627784n, 5],
  [2199023255560n, 6],
  [4398046511112n, 7],
  [8796093022216n, 8],
  [17592186044424n, 9],
  [35184372088840n, 8],
  [70368744177672n, 7],
  [140737488355336n, 6],
  [281474976710664n, 5],
  [562949953421320n, 6],
  [1125899906842632n, 7],
  [2251799813685256n, 8],
  [4503599627370504n, 9],
  [9007199254741000n, 10],
  [18014398509481992n, 9],
  [36028797018963976n, 8],
  [72057594037927944n, 7],
  [144115188075855880n, 6],
  [288230376151711752n, 7],
  [576460752303423496n, 8],
  [1152921504606846984n, 9],
  [2305843009213693960n, 10],
  [4611686018427387912n, 11],
  [9223372036854775816n, 10],
  [18446744073709551624n, 9],
  [36893488147419103240n, 8],
  [73786976294838206472n, 7],
  [147573952589676412936n, 8],
  [295147905179352825864n, 9],
  [590295810358705651720n, 10],
  [1180591620717411303432n, 11],
  [2361183241434822606856n, 12],
  [4722366482869645213704n, 11],
  [9444732965739290427400n, 10],
  [18889465931478580854792n, 9],
  [37778931862957161709576n, 8],
  [75557863725914323419144n, 9],
  [151115727451828646838280n, 10],
  [302231454903657293676552n, 11],
  [604462909807314587353096n, 12],
  [1208925819614629174706184n, 13],
  [17n, 4],
  [18n, 3],
  [20n, 2],
  [24n, 1],
  [16n, 0],
  [48n, 1],
  [80n, 2],
  [144n, 3],
  [272n, 4],
  [528n, 5],
  [1040n, 4],
  [2064n, 3],
  [4112n, 2],
  [8208n, 1],
  [16400n, 2],
  [32784n, 3],
  [65552n, 4],
  [131088n, 5],
  [262160n, 6],
  [524304n, 5],
  [1048592n, 4],
  [2097168n, 3],
  [4194320n, 2],
  [8388624n, 3],
  [16777232n, 4],
  [33554448n, 5],
  [67108880n, 6],
  [134217744n, 7],
  [268435472n, 6],
  [536870928n, 5],
  [1073741840n, 4],
  [2147483664n, 3],
  [4294967312n, 4],
  [8589934608n, 5],
  [17179869200n, 6],
  [34359738384n, 7],
  [68719476752n, 8],
  [137438953488n, 7],
  [274877906960n, 6],
  [549755813904n, 5],
  [1099511627792n, 4],
  [2199023255568n, 5],
  [4398046511120n, 6],
  [8796093022224n, 7],
  [17592186044432n, 8],
  [35184372088848n, 9],
  [70368744177680n, 8],
  [140737488355344n, 7],
  [281474976710672n, 6],
  [562949953421328n, 5],
  [1125899906842640n, 6],
  [2251799813685264n, 7],
  [4503599627370512n, 8],
  [9007199254741008n, 9],
  [18014398509482000n, 10],
  [36028797018963984n, 9],
  [72057594037927952n, 8],
  [144115188075855888n, 7],
  [288230376151711760n, 6],
  [576460752303423504n, 7],
  [1152921504606846992n, 8],
  [2305843009213693968n, 9],
  [4611686018427387920n, 10],
  [9223372036854775824n, 11],
  [18446744073709551632n, 10],
  [36893488147419103248n, 9],
  [73786976294838206480n, 8],
  [147573952589676412944n, 7],
  [295147905179352825872n, 8],
  [590295810358705651728n, 9],
  [1180591620717411303440n, 10],
  [2361183241434822606864n, 11],
  [4722366482869645213712n, 12],
  [9444732965739290427408n, 11],
  [18889465931478580854800n, 10],
  [37778931862957161709584n, 9],
  [75557863725914323419152n, 8],
  [151115727451828646838288n, 9],
  [302231454903657293676560n, 10],
  [604462909807314587353104n, 11],
  [1208925819614629174706192n, 12],
  [33n, 5],
  [34n, 4],
  [36n, 3],
  [40n, 2],
  [48n, 1],
  [32n, 0],
  [96n, 1],
  [160n, 2],
  [288n, 3],
  [544n, 6],
  [1056n, 5],
  [2080n, 4],
  [4128n, 3],
  [8224n, 2],
  [16416n, 1],
  [32800n, 2],
  [65568n, 3],
  [131104n, 4],
  [262176n, 7],
  [524320n, 6],
  [1048608n, 5],
  [2097184n, 4],
  [4194336n, 3],
  [8388640n, 2],
  [16777248n, 3],
  [33554464n, 4],
  [67108896n, 5],
  [134217760n, 8],
  [268435488n, 7],
  [536870944n, 6],
  [1073741856n, 5],
  [2147483680n, 4],
  [4294967328n, 3],
  [8589934624n, 4],
  [17179869216n, 5],
  [34359738400n, 6],
  [68719476768n, 9],
  [137438953504n, 8],
  [274877906976n, 7],
  [549755813920n, 6],
  [1099511627808n, 5],
  [2199023255584n, 4],
  [4398046511136n, 5],
  [8796093022240n, 6],
  [17592186044448n, 7],
  [35184372088864n, 10],
  [70368744177696n, 9],
  [140737488355360n, 8],
  [281474976710688n, 7],
  [562949953421344n, 6],
  [1125899906842656n, 5],
  [2251799813685280n, 6],
  [4503599627370528n, 7],
  [9007199254741024n, 8],
  [18014398509482016n, 11],
  [36028797018964000n, 10],
  [72057594037927968n, 9],
  [144115188075855904n, 8],
  [288230376151711776n, 7],
  [576460752303423520n, 6],
  [1152921504606847008n, 7],
  [2305843009213693984n, 8],
  [4611686018427387936n, 9],
  [9223372036854775840n, 12],
  [18446744073709551648n, 11],
  [36893488147419103264n, 10],
  [73786976294838206496n, 9],
  [147573952589676412960n, 8],
  [295147905179352825888n, 7],
  [590295810358705651744n, 8],
  [1180591620717411303456n, 9],
  [2361183241434822606880n, 10],
  [4722366482869645213728n, 13],
  [9444732965739290427424n, 12],
  [18889465931478580854816n, 11],
  [37778931862957161709600n, 10],
  [75557863725914323419168n, 9],
  [151115727451828646838304n, 8],
  [302231454903657293676576n, 9],
  [604462909807314587353120n, 10],
  [1208925819614629174706208n, 11],
  [65n, 6],
  [66n, 5],
  [68n, 4],
  [72n, 3],
  [80n, 2],
  [96n, 1],
  [64n, 0],
  [192n, 1],
  [320n, 2],
  [576n, 7],
  [1088n, 6],
  [2112n, 5],
  [4160n, 4],
  [8256n, 3],
  [16448n, 2],
  [32832n, 1],
  [65600n, 2],
  [131136n, 3],
  [262208n, 8],
  [524352n, 7],
  [1048640n, 6],
  [2097216n, 5],
  [4194368n, 4],
  [8388672n, 3],
  [16777280n, 2],
  [33554496n, 3],
  [67108928n, 4],
  [134217792n, 9],
  [268435520n, 8],
  [536870976n, 7],
  [1073741888n, 6],
  [2147483712n, 5],
  [4294967360n, 4],
  [8589934656n, 3],
  [17179869248n, 4],
  [34359738432n, 5],
  [68719476800n, 10],
  [137438953536n, 9],
  [274877907008n, 8],
  [549755813952n, 7],
  [1099511627840n, 6],
  [2199023255616n, 5],
  [4398046511168n, 4],
  [8796093022272n, 5],
  [17592186044480n, 6],
  [35184372088896n, 11],
  [70368744177728n, 10],
  [140737488355392n, 9],
  [281474976710720n, 8],
  [562949953421376n, 7],
  [1125899906842688n, 6],
  [2251799813685312n, 5],
  [4503599627370560n, 6],
  [9007199254741056n, 7],
  [18014398509482048n, 12],
  [36028797018964032n, 11],
  [72057594037928000n, 10],
  [144115188075855936n, 9],
  [288230376151711808n, 8],
  [576460752303423552n, 7],
  [1152921504606847040n, 6],
  [2305843009213694016n, 7],
  [4611686018427387968n, 8],
  [9223372036854775872n, 13],
  [18446744073709551680n, 12],
  [36893488147419103296n, 11],
  [73786976294838206528n, 10],
  [147573952589676412992n, 9],
  [295147905179352825920n, 8],
  [590295810358705651776n, 7],
  [1180591620717411303488n, 8],
  [2361183241434822606912n, 9],
  [4722366482869645213760n, 14],
  [9444732965739290427456n, 13],
  [18889465931478580854848n, 12],
  [37778931862957161709632n, 11],
  [75557863725914323419200n, 10],
  [151115727451828646838336n, 9],
  [302231454903657293676608n, 8],
  [604462909807314587353152n, 9],
  [1208925819614629174706240n, 10],
  [129n, 7],
  [130n, 6],
  [132n, 5],
  [136n, 4],
  [144n, 3],
  [160n, 2],
  [192n, 1],
  [128n, 0],
  [384n, 1],
  [640n, 8],
  [1152n, 7],
  [2176n, 6],
  [4224n, 5],
  [8320n, 4],
  [16512n, 3],
  [32896n, 2],
  [65664n, 1],
  [131200n, 2],
  [262272n, 9],
  [524416n, 8],
  [1048704n, 7],
  [2097280n, 6],
  [4194432n, 5],
  [8388736n, 4],
  [16777344n, 3],
  [33554560n, 2],
  [67108992n, 3],
  [134217856n, 10],
  [268435584n, 9],
  [536871040n, 8],
  [1073741952n, 7],
  [2147483776n, 6],
  [4294967424n, 5],
  [8589934720n, 4],
  [17179869312n, 3],
  [34359738496n, 4],
  [68719476864n, 11],
  [137438953600n, 10],
  [274877907072n, 9],
  [549755814016n, 8],
  [1099511627904n, 7],
  [2199023255680n, 6],
  [4398046511232n, 5],
  [8796093022336n, 4],
  [17592186044544n, 5],
  [35184372088960n, 12],
  [70368744177792n, 11],
  [140737488355456n, 10],
  [281474976710784n, 9],
  [562949953421440n, 8],
  [1125899906842752n, 7],
  [2251799813685376n, 6],
  [4503599627370624n, 5],
  [9007199254741120n, 6],
  [18014398509482112n, 13],
  [36028797018964096n, 12],
  [72057594037928064n, 11],
  [144115188075856000n, 10],
  [288230376151711872n, 9],
  [576460752303423616n, 8],
  [1152921504606847104n, 7],
  [2305843009213694080n, 6],
  [4611686018427388032n, 7],
  [9223372036854775936n, 14],
  [18446744073709551744n, 13],
  [36893488147419103360n, 12],
  [73786976294838206592n, 11],
  [147573952589676413056n, 10],
  [295147905179352825984n, 9],
  [590295810358705651840n, 8],
  [1180591620717411303552n, 7],
  [2361183241434822606976n, 8],
  [4722366482869645213824n, 15],
  [9444732965739290427520n, 14],
  [18889465931478580854912n, 13],
  [37778931862957161709696n, 12],
  [75557863725914323419264n, 11],
  [151115727451828646838400n, 10],
  [302231454903657293676672n, 9],
  [604462909807314587353216n, 8],
  [1208925819614629174706304n, 9],
  [257n, 8],
  [258n, 7],
  [260n, 6],
  [264n, 5],
  [272n, 4],
  [288n, 3],
  [320n, 2],
  [384n, 1],
  [256n, 0],
  [768n, 9],
  [1280n, 8],
  [2304n, 7],
  [4352n, 6],
  [8448n, 5],
  [16640n, 4],
  [33024n, 3],
  [65792n, 2],
  [131328n, 1],
  [262400n, 10],
  [524544n, 9],
  [1048832n, 8],
  [2097408n, 7],
  [4194560n, 6],
  [8388864n, 5],
  [16777472n, 4],
  [33554688n, 3],
  [67109120n, 2],
  [134217984n, 11],
  [268435712n, 10],
  [536871168n, 9],
  [1073742080n, 8],
  [2147483904n, 7],
  [4294967552n, 6],
  [8589934848n, 5],
  [17179869440n, 4],
  [34359738624n, 3],
  [68719476992n, 12],
  [137438953728n, 11],
  [274877907200n, 10],
  [549755814144n, 9],
  [1099511628032n, 8],
  [2199023255808n, 7],
  [4398046511360n, 6],
  [8796093022464n, 5],
  [17592186044672n, 4],
  [35184372089088n, 13],
  [70368744177920n, 12],
  [140737488355584n, 11],
  [281474976710912n, 10],
  [562949953421568n, 9],
  [1125899906842880n, 8],
  [2251799813685504n, 7],
  [4503599627370752n, 6],
  [9007199254741248n, 5],
  [18014398509482240n, 14],
  [36028797018964224n, 13],
  [72057594037928192n, 12],
  [144115188075856128n, 11],
  [288230376151712000n, 10],
  [576460752303423744n, 9],
  [1152921504606847232n, 8],
  [2305843009213694208n, 7],
  [4611686018427388160n, 6],
  [9223372036854776064n, 15],
  [18446744073709551872n, 14],
  [36893488147419103488n, 13],
  [73786976294838206720n, 12],
  [147573952589676413184n, 11],
  [295147905179352826112n, 10],
  [590295810358705651968n, 9],
  [1180591620717411303680n, 8],
  [2361183241434822607104n, 7],
  [4722366482869645213952n, 16],
  [9444732965739290427648n, 15],
  [18889465931478580855040n, 14],
  [37778931862957161709824n, 13],
  [75557863725914323419392n, 12],
  [151115727451828646838528n, 11],
  [302231454903657293676800n, 10],
  [604462909807314587353344n, 9],
  [1208925819614629174706432n, 8],
  [513n, 1],
  [514n, 2],
  [516n, 3],
  [520n, 4],
  [528n, 5],
  [544n, 6],
  [576n, 7],
  [640n, 8],
  [768n, 9],
  [512n, 0],
  [1536n, 1],
  [2560n, 2],
  [4608n, 3],
  [8704n, 4],
  [16896n, 5],
  [33280n, 6],
  [66048n, 7],
  [131584n, 8],
  [262656n, 1],
  [524800n, 2],
  [1049088n, 3],
  [2097664n, 4],
  [4194816n, 5],
  [8389120n, 6],
  [16777728n, 7],
  [33554944n, 8],
  [67109376n, 9],
  [134218240n, 2],
  [268435968n, 3],
  [536871424n, 4],
  [1073742336n, 5],
  [2147484160n, 6],
  [4294967808n, 7],
  [8589935104n, 8],
  [17179869696n, 9],
  [34359738880n, 10],
  [68719477248n, 3],
  [137438953984n, 4],
  [274877907456n, 5],
  [549755814400n, 6],
  [1099511628288n, 7],
  [2199023256064n, 8],
  [4398046511616n, 9],
  [8796093022720n, 10],
  [17592186044928n, 11],
  [35184372089344n, 4],
  [70368744178176n, 5],
  [140737488355840n, 6],
  [281474976711168n, 7],
  [562949953421824n, 8],
  [1125899906843136n, 9],
  [2251799813685760n, 10],
  [4503599627371008n, 11],
  [9007199254741504n, 12],
  [18014398509482496n, 5],
  [36028797018964480n, 6],
  [72057594037928448n, 7],
  [144115188075856384n, 8],
  [288230376151712256n, 9],
  [576460752303424000n, 10],
  [1152921504606847488n, 11],
  [2305843009213694464n, 12],
  [4611686018427388416n, 13],
  [9223372036854776320n, 6],
  [18446744073709552128n, 7],
  [36893488147419103744n, 8],
  [73786976294838206976n, 9],
  [147573952589676413440n, 10],
  [295147905179352826368n, 11],
  [590295810358705652224n, 12],
  [1180591620717411303936n, 13],
  [2361183241434822607360n, 14],
  [4722366482869645214208n, 7],
  [9444732965739290427904n, 8],
  [18889465931478580855296n, 9],
  [37778931862957161710080n, 10],
  [75557863725914323419648n, 11],
  [151115727451828646838784n, 12],
  [302231454903657293677056n, 13],
  [604462909807314587353600n, 14],
  [1208925819614629174706688n, 15],
  [1025n, 2],
  [1026n, 1],
  [1028n, 2],
  [1032n, 3],
  [1040n, 4],
  [1056n, 5],
  [1088n, 6],
  [1152n, 7],
  [1280n, 8],
  [1536n, 1],
  [1024n, 0],
  [3072n, 1],
  [5120n, 2],
  [9216n, 3],
  [17408n, 4],
  [33792n, 5],
  [66560n, 6],
  [132096n, 7],
  [263168n, 2],
  [525312n, 1],
  [1049600n, 2],
  [2098176n, 3],
  [4195328n, 4],
  [8389632n, 5],
  [16778240n, 6],
  [33555456n, 7],
  [67109888n, 8],
  [134218752n, 3],
  [268436480n, 2],
  [536871936n, 3],
  [1073742848n, 4],
  [2147484672n, 5],
  [4294968320n, 6],
  [8589935616n, 7],
  [17179870208n, 8],
  [34359739392n, 9],
  [68719477760n, 4],
  [137438954496n, 3],
  [274877907968n, 4],
  [549755814912n, 5],
  [1099511628800n, 6],
  [2199023256576n, 7],
  [4398046512128n, 8],
  [8796093023232n, 9],
  [17592186045440n, 10],
  [35184372089856n, 5],
  [70368744178688n, 4],
  [140737488356352n, 5],
  [281474976711680n, 6],
  [562949953422336n, 7],
  [1125899906843648n, 8],
  [2251799813686272n, 9],
  [4503599627371520n, 10],
  [9007199254742016n, 11],
  [18014398509483008n, 6],
  [36028797018964992n, 5],
  [72057594037928960n, 6],
  [144115188075856896n, 7],
  [288230376151712768n, 8],
  [576460752303424512n, 9],
  [1152921504606848000n, 10],
  [2305843009213694976n, 11],
  [4611686018427388928n, 12],
  [9223372036854776832n, 7],
  [18446744073709552640n, 6],
  [36893488147419104256n, 7],
  [73786976294838207488n, 8],
  [147573952589676413952n, 9],
  [295147905179352826880n, 10],
  [590295810358705652736n, 11],
  [1180591620717411304448n, 12],
  [2361183241434822607872n, 13],
  [4722366482869645214720n, 8],
  [9444732965739290428416n, 7],
  [18889465931478580855808n, 8],
  [37778931862957161710592n, 9],
  [75557863725914323420160n, 10],
  [151115727451828646839296n, 11],
  [302231454903657293677568n, 12],
  [604462909807314587354112n, 13],
  [1208925819614629174707200n, 14],
  [2049n, 3],
  [2050n, 2],
  [2052n, 1],
  [2056n, 2],
  [2064n, 3],
  [2080n, 4],
  [2112n, 5],
  [2176n, 6],
  [2304n, 7],
  [2560n, 2],
  [3072n, 1],
  [2048n, 0],
  [6144n, 1],
  [10240n, 2],
  [18432n, 3],
  [34816n, 4],
  [67584n, 5],
  [133120n, 6],
  [264192n, 3],
  [526336n, 2],
  [1050624n, 1],
  [2099200n, 2],
  [4196352n, 3],
  [8390656n, 4],
  [16779264n, 5],
  [33556480n, 6],
  [67110912n, 7],
  [134219776n, 4],
  [268437504n, 3],
  [536872960n, 2],
  [1073743872n, 3],
  [2147485696n, 4],
  [4294969344n, 5],
  [8589936640n, 6],
  [17179871232n, 7],
  [34359740416n, 8],
  [68719478784n, 5],
  [137438955520n, 4],
  [274877908992n, 3],
  [549755815936n, 4],
  [1099511629824n, 5],
  [2199023257600n, 6],
  [4398046513152n, 7],
  [8796093024256n, 8],
  [17592186046464n, 9],
  [35184372090880n, 6],
  [70368744179712n, 5],
  [140737488357376n, 4],
  [281474976712704n, 5],
  [562949953423360n, 6],
  [1125899906844672n, 7],
  [2251799813687296n, 8],
  [4503599627372544n, 9],
  [9007199254743040n, 10],
  [18014398509484032n, 7],
  [36028797018966016n, 6],
  [72057594037929984n, 5],
  [144115188075857920n, 6],
  [288230376151713792n, 7],
  [576460752303425536n, 8],
  [1152921504606849024n, 9],
  [2305843009213696000n, 10],
  [4611686018427389952n, 11],
  [9223372036854777856n, 8],
  [18446744073709553664n, 7],
  [36893488147419105280n, 6],
  [73786976294838208512n, 7],
  [147573952589676414976n, 8],
  [295147905179352827904n, 9],
  [590295810358705653760n, 10],
  [1180591620717411305472n, 11],
  [2361183241434822608896n, 12],
  [4722366482869645215744n, 9],
  [9444732965739290429440n, 8],
  [18889465931478580856832n, 7],
  [37778931862957161711616n, 8],
  [75557863725914323421184n, 9],
  [151115727451828646840320n, 10],
  [302231454903657293678592n, 11],
  [604462909807314587355136n, 12],
  [1208925819614629174708224n, 13],
  [4097n, 4],
  [4098n, 3],
  [4100n, 2],
  [4104n, 1],
  [4112n, 2],
  [4128n, 3],
  [4160n, 4],
  [4224n, 5],
  [4352n, 6],
  [4608n, 3],
  [5120n, 2],
  [6144n, 1],
  [4096n, 0],
  [12288n, 1],
  [20480n, 2],
  [36864n, 3],
  [69632n, 4],
  [135168n, 5],
  [266240n, 4],
  [528384n, 3],
  [1052672n, 2],
  [2101248n, 1],
  [4198400n, 2],
  [8392704n, 3],
  [16781312n, 4],
  [33558528n, 5],
  [67112960n, 6],
  [134221824n, 5],
  [268439552n, 4],
  [536875008n, 3],
  [1073745920n, 2],
  [2147487744n, 3],
  [4294971392n, 4],
  [8589938688n, 5],
  [17179873280n, 6],
  [34359742464n, 7],
  [68719480832n, 6],
  [137438957568n, 5],
  [274877911040n, 4],
  [549755817984n, 3],
  [1099511631872n, 4],
  [2199023259648n, 5],
  [4398046515200n, 6],
  [8796093026304n, 7],
  [17592186048512n, 8],
  [35184372092928n, 7],
  [70368744181760n, 6],
  [140737488359424n, 5],
  [281474976714752n, 4],
  [562949953425408n, 5],
  [1125899906846720n, 6],
  [2251799813689344n, 7],
  [4503599627374592n, 8],
  [9007199254745088n, 9],
  [18014398509486080n, 8],
  [36028797018968064n, 7],
  [72057594037932032n, 6],
  [144115188075859968n, 5],
  [288230376151715840n, 6],
  [576460752303427584n, 7],
  [1152921504606851072n, 8],
  [2305843009213698048n, 9],
  [4611686018427392000n, 10],
  [9223372036854779904n, 9],
  [18446744073709555712n, 8],
  [36893488147419107328n, 7],
  [73786976294838210560n, 6],
  [147573952589676417024n, 7],
  [295147905179352829952n, 8],
  [590295810358705655808n, 9],
  [1180591620717411307520n, 10],
  [2361183241434822610944n, 11],
  [4722366482869645217792n, 10],
  [9444732965739290431488n, 9],
  [18889465931478580858880n, 8],
  [37778931862957161713664n, 7],
  [75557863725914323423232n, 8],
  [151115727451828646842368n, 9],
  [302231454903657293680640n, 10],
  [604462909807314587357184n, 11],
  [1208925819614629174710272n, 12],
  [8193n, 5],
  [8194n, 4],
  [8196n, 3],
  [8200n, 2],
  [8208n, 1],
  [8224n, 2],
  [8256n, 3],
  [8320n, 4],
  [8448n, 5],
  [8704n, 4],
  [9216n, 3],
  [10240n, 2],
  [12288n, 1],
  [8192n, 0],
  [24576n, 1],
  [40960n, 2],
  [73728n, 3],
  [139264n, 4],
  [270336n, 5],
  [532480n, 4],
  [1056768n, 3],
  [2105344n, 2],
  [4202496n, 1],
  [8396800n, 2],
  [16785408n, 3],
  [33562624n, 4],
  [67117056n, 5],
  [134225920n, 6],
  [268443648n, 5],
  [536879104n, 4],
  [1073750016n, 3],
  [2147491840n, 2],
  [4294975488n, 3],
  [8589942784n, 4],
  [17179877376n, 5],
  [34359746560n, 6],
  [68719484928n, 7],
  [137438961664n, 6],
  [274877915136n, 5],
  [549755822080n, 4],
  [1099511635968n, 3],
  [2199023263744n, 4],
  [4398046519296n, 5],
  [8796093030400n, 6],
  [17592186052608n, 7],
  [35184372097024n, 8],
  [70368744185856n, 7],
  [140737488363520n, 6],
  [281474976718848n, 5],
  [562949953429504n, 4],
  [1125899906850816n, 5],
  [2251799813693440n, 6],
  [4503599627378688n, 7],
  [9007199254749184n, 8],
  [18014398509490176n, 9],
  [36028797018972160n, 8],
  [72057594037936128n, 7],
  [144115188075864064n, 6],
  [288230376151719936n, 5],
  [576460752303431680n, 6],
  [1152921504606855168n, 7],
  [2305843009213702144n, 8],
  [4611686018427396096n, 9],
  [9223372036854784000n, 10],
  [18446744073709559808n, 9],
  [36893488147419111424n, 8],
  [73786976294838214656n, 7],
  [147573952589676421120n, 6],
  [295147905179352834048n, 7],
  [590295810358705659904n, 8],
  [1180591620717411311616n, 9],
  [2361183241434822615040n, 10],
  [4722366482869645221888n, 11],
  [9444732965739290435584n, 10],
  [18889465931478580862976n, 9],
  [37778931862957161717760n, 8],
  [75557863725914323427328n, 7],
  [151115727451828646846464n, 8],
  [302231454903657293684736n, 9],
  [604462909807314587361280n, 10],
  [1208925819614629174714368n, 11],
  [16385n, 6],
  [16386n, 5],
  [16388n, 4],
  [16392n, 3],
  [16400n, 2],
  [16416n, 1],
  [16448n, 2],
  [16512n, 3],
  [16640n, 4],
  [16896n, 5],
  [17408n, 4],
  [18432n, 3],
  [20480n, 2],
  [24576n, 1],
  [16384n, 0],
  [49152n, 1],
  [81920n, 2],
  [147456n, 3],
  [278528n, 6],
  [540672n, 5],
  [1064960n, 4],
  [2113536n, 3],
  [4210688n, 2],
  [8404992n, 1],
  [16793600n, 2],
  [33570816n, 3],
  [67125248n, 4],
  [134234112n, 7],
  [268451840n, 6],
  [536887296n, 5],
  [1073758208n, 4],
  [2147500032n, 3],
  [4294983680n, 2],
  [8589950976n, 3],
  [17179885568n, 4],
  [34359754752n, 5],
  [68719493120n, 8],
  [137438969856n, 7],
  [274877923328n, 6],
  [549755830272n, 5],
  [1099511644160n, 4],
  [2199023271936n, 3],
  [4398046527488n, 4],
  [8796093038592n, 5],
  [17592186060800n, 6],
  [35184372105216n, 9],
  [70368744194048n, 8],
  [140737488371712n, 7],
  [281474976727040n, 6],
  [562949953437696n, 5],
  [1125899906859008n, 4],
  [2251799813701632n, 5],
  [4503599627386880n, 6],
  [9007199254757376n, 7],
  [18014398509498368n, 10],
  [36028797018980352n, 9],
  [72057594037944320n, 8],
  [144115188075872256n, 7],
  [288230376151728128n, 6],
  [576460752303439872n, 5],
  [1152921504606863360n, 6],
  [2305843009213710336n, 7],
  [4611686018427404288n, 8],
  [9223372036854792192n, 11],
  [18446744073709568000n, 10],
  [36893488147419119616n, 9],
  [73786976294838222848n, 8],
  [147573952589676429312n, 7],
  [295147905179352842240n, 6],
  [590295810358705668096n, 7],
  [1180591620717411319808n, 8],
  [2361183241434822623232n, 9],
  [4722366482869645230080n, 12],
  [9444732965739290443776n, 11],
  [18889465931478580871168n, 10],
  [37778931862957161725952n, 9],
  [75557863725914323435520n, 8],
  [151115727451828646854656n, 7],
  [302231454903657293692928n, 8],
  [604462909807314587369472n, 9],
  [1208925819614629174722560n, 10],
  [32769n, 7],
  [32770n, 6],
  [32772n, 5],
  [32776n, 4],
  [32784n, 3],
  [32800n, 2],
  [32832n, 1],
  [32896n, 2],
  [33024n, 3],
  [33280n, 6],
  [33792n, 5],
  [34816n, 4],
  [36864n, 3],
  [40960n, 2],
  [49152n, 1],
  [32768n, 0],
  [98304n, 1],
  [163840n, 2],
  [294912n, 7],
  [557056n, 6],
  [1081344n, 5],
  [2129920n, 4],
  [4227072n, 3],
  [8421376n, 2],
  [16809984n, 1],
  [33587200n, 2],
  [67141632n, 3],
  [134250496n, 8],
  [268468224n, 7],
  [536903680n, 6],
  [1073774592n, 5],
  [2147516416n, 4],
  [4295000064n, 3],
  [8589967360n, 2],
  [17179901952n, 3],
  [34359771136n, 4],
  [68719509504n, 9],
  [137438986240n, 8],
  [274877939712n, 7],
  [549755846656n, 6],
  [1099511660544n, 5],
  [2199023288320n, 4],
  [4398046543872n, 3],
  [8796093054976n, 4],
  [17592186077184n, 5],
  [35184372121600n, 10],
  [70368744210432n, 9],
  [140737488388096n, 8],
  [281474976743424n, 7],
  [562949953454080n, 6],
  [1125899906875392n, 5],
  [2251799813718016n, 4],
  [4503599627403264n, 5],
  [9007199254773760n, 6],
  [18014398509514752n, 11],
  [36028797018996736n, 10],
  [72057594037960704n, 9],
  [144115188075888640n, 8],
  [288230376151744512n, 7],
  [576460752303456256n, 6],
  [1152921504606879744n, 5],
  [2305843009213726720n, 6],
  [4611686018427420672n, 7],
  [9223372036854808576n, 12],
  [18446744073709584384n, 11],
  [36893488147419136000n, 10],
  [73786976294838239232n, 9],
  [147573952589676445696n, 8],
  [295147905179352858624n, 7],
  [590295810358705684480n, 6],
  [1180591620717411336192n, 7],
  [2361183241434822639616n, 8],
  [4722366482869645246464n, 13],
  [9444732965739290460160n, 12],
  [18889465931478580887552n, 11],
  [37778931862957161742336n, 10],
  [75557863725914323451904n, 9],
  [151115727451828646871040n, 8],
  [302231454903657293709312n, 7],
  [604462909807314587385856n, 8],
  [1208925819614629174738944n, 9],
  [65537n, 8],
  [65538n, 7],
  [65540n, 6],
  [65544n, 5],
  [65552n, 4],
  [65568n, 3],
  [65600n, 2],
  [65664n, 1],
  [65792n, 2],
  [66048n, 7],
  [66560n, 6],
  [67584n, 5],
  [69632n, 4],
  [73728n, 3],
  [81920n, 2],
  [98304n, 1],
  [65536n, 0],
  [196608n, 1],
  [327680n, 8],
  [589824n, 7],
  [1114112n, 6],
  [2162688n, 5],
  [4259840n, 4],
  [8454144n, 3],
  [16842752n, 2],
  [33619968n, 1],
  [67174400n, 2],
  [134283264n, 9],
  [268500992n, 8],
  [536936448n, 7],
  [1073807360n, 6],
  [2147549184n, 5],
  [4295032832n, 4],
  [8590000128n, 3],
  [17179934720n, 2],
  [34359803904n, 3],
  [68719542272n, 10],
  [137439019008n, 9],
  [274877972480n, 8],
  [549755879424n, 7],
  [1099511693312n, 6],
  [2199023321088n, 5],
  [4398046576640n, 4],
  [8796093087744n, 3],
  [17592186109952n, 4],
  [35184372154368n, 11],
  [70368744243200n, 10],
  [140737488420864n, 9],
  [281474976776192n, 8],
  [562949953486848n, 7],
  [1125899906908160n, 6],
  [2251799813750784n, 5],
  [4503599627436032n, 4],
  [9007199254806528n, 5],
  [18014398509547520n, 12],
  [36028797019029504n, 11],
  [72057594037993472n, 10],
  [144115188075921408n, 9],
  [288230376151777280n, 8],
  [576460752303489024n, 7],
  [1152921504606912512n, 6],
  [2305843009213759488n, 5],
  [4611686018427453440n, 6],
  [9223372036854841344n, 13],
  [18446744073709617152n, 12],
  [36893488147419168768n, 11],
  [73786976294838272000n, 10],
  [147573952589676478464n, 9],
  [295147905179352891392n, 8],
  [590295810358705717248n, 7],
  [1180591620717411368960n, 6],
  [2361183241434822672384n, 7],
  [4722366482869645279232n, 14],
  [9444732965739290492928n, 13],
  [18889465931478580920320n, 12],
  [37778931862957161775104n, 11],
  [75557863725914323484672n, 10],
  [151115727451828646903808n, 9],
  [302231454903657293742080n, 8],
  [604462909807314587418624n, 7],
  [1208925819614629174771712n, 8],
  [131073n, 9],
  [131074n, 8],
  [131076n, 7],
  [131080n, 6],
  [131088n, 5],
  [131104n, 4],
  [131136n, 3],
  [131200n, 2],
  [131328n, 1],
  [131584n, 8],
  [132096n, 7],
  [133120n, 6],
  [135168n, 5],
  [139264n, 4],
  [147456n, 3],
  [163840n, 2],
  [196608n, 1],
  [131072n, 0],
  [393216n, 9],
  [655360n, 8],
  [1179648n, 7],
  [2228224n, 6],
  [4325376n, 5],
  [8519680n, 4],
  [16908288n, 3],
  [33685504n, 2],
  [67239936n, 1],
  [134348800n, 10],
  [268566528n, 9],
  [537001984n, 8],
  [1073872896n, 7],
  [2147614720n, 6],
  [4295098368n, 5],
  [8590065664n, 4],
  [17180000256n, 3],
  [34359869440n, 2],
  [68719607808n, 11],
  [137439084544n, 10],
  [274878038016n, 9],
  [549755944960n, 8],
  [1099511758848n, 7],
  [2199023386624n, 6],
  [4398046642176n, 5],
  [8796093153280n, 4],
  [17592186175488n, 3],
  [35184372219904n, 12],
  [70368744308736n, 11],
  [140737488486400n, 10],
  [281474976841728n, 9],
  [562949953552384n, 8],
  [1125899906973696n, 7],
  [2251799813816320n, 6],
  [4503599627501568n, 5],
  [9007199254872064n, 4],
  [18014398509613056n, 13],
  [36028797019095040n, 12],
  [72057594038059008n, 11],
  [144115188075986944n, 10],
  [288230376151842816n, 9],
  [576460752303554560n, 8],
  [1152921504606978048n, 7],
  [2305843009213825024n, 6],
  [4611686018427518976n, 5],
  [9223372036854906880n, 14],
  [18446744073709682688n, 13],
  [36893488147419234304n, 12],
  [73786976294838337536n, 11],
  [147573952589676544000n, 10],
  [295147905179352956928n, 9],
  [590295810358705782784n, 8],
  [1180591620717411434496n, 7],
  [2361183241434822737920n, 6],
  [4722366482869645344768n, 15],
  [9444732965739290558464n, 14],
  [18889465931478580985856n, 13],
  [37778931862957161840640n, 12],
  [75557863725914323550208n, 11],
  [151115727451828646969344n, 10],
  [302231454903657293807616n, 9],
  [604462909807314587484160n, 8],
  [1208925819614629174837248n, 7],
  [262145n, 2],
  [262146n, 3],
  [262148n, 4],
  [262152n, 5],
  [262160n, 6],
  [262176n, 7],
  [262208n, 8],
  [262272n, 9],
  [262400n, 10],
  [262656n, 1],
  [263168n, 2],
  [264192n, 3],
  [266240n, 4],
  [270336n, 5],
  [278528n, 6],
  [294912n, 7],
  [327680n, 8],
  [393216n, 9],
  [262144n, 0],
  [786432n, 1],
  [1310720n, 2],
  [2359296n, 3],
  [4456448n, 4],
  [8650752n, 5],
  [17039360n, 6],
  [33816576n, 7],
  [67371008n, 8],
  [134479872n, 1],
  [268697600n, 2],
  [537133056n, 3],
  [1074003968n, 4],
  [2147745792n, 5],
  [4295229440n, 6],
  [8590196736n, 7],
  [17180131328n, 8],
  [34360000512n, 9],
  [68719738880n, 2],
  [137439215616n, 3],
  [274878169088n, 4],
  [549756076032n, 5],
  [1099511889920n, 6],
  [2199023517696n, 7],
  [4398046773248n, 8],
  [8796093284352n, 9],
  [17592186306560n, 10],
  [35184372350976n, 3],
  [70368744439808n, 4],
  [140737488617472n, 5],
  [281474976972800n, 6],
  [562949953683456n, 7],
  [1125899907104768n, 8],
  [2251799813947392n, 9],
  [4503599627632640n, 10],
  [9007199255003136n, 11],
  [18014398509744128n, 4],
  [36028797019226112n, 5],
  [72057594038190080n, 6],
  [144115188076118016n, 7],
  [288230376151973888n, 8],
  [576460752303685632n, 9],
  [1152921504607109120n, 10],
  [2305843009213956096n, 11],
  [4611686018427650048n, 12],
  [9223372036855037952n, 5],
  [18446744073709813760n, 6],
  [36893488147419365376n, 7],
  [73786976294838468608n, 8],
  [147573952589676675072n, 9],
  [295147905179353088000n, 10],
  [590295810358705913856n, 11],
  [1180591620717411565568n, 12],
  [2361183241434822868992n, 13],
  [4722366482869645475840n, 6],
  [9444732965739290689536n, 7],
  [18889465931478581116928n, 8],
  [37778931862957161971712n, 9],
  [75557863725914323681280n, 10],
  [151115727451828647100416n, 11],
  [302231454903657293938688n, 12],
  [604462909807314587615232n, 13],
  [1208925819614629174968320n, 14],
  [524289n, 3],
  [524290n, 2],
  [524292n, 3],
  [524296n, 4],
  [524304n, 5],
  [524320n, 6],
  [524352n, 7],
  [524416n, 8],
  [524544n, 9],
  [524800n, 2],
  [525312n, 1],
  [526336n, 2],
  [528384n, 3],
  [532480n, 4],
  [540672n, 5],
  [557056n, 6],
  [589824n, 7],
  [655360n, 8],
  [786432n, 1],
  [524288n, 0],
  [1572864n, 1],
  [2621440n, 2],
  [4718592n, 3],
  [8912896n, 4],
  [17301504n, 5],
  [34078720n, 6],
  [67633152n, 7],
  [134742016n, 2],
  [268959744n, 1],
  [537395200n, 2],
  [1074266112n, 3],
  [2148007936n, 4],
  [4295491584n, 5],
  [8590458880n, 6],
  [17180393472n, 7],
  [34360262656n, 8],
  [68720001024n, 3],
  [137439477760n, 2],
  [274878431232n, 3],
  [549756338176n, 4],
  [1099512152064n, 5],
  [2199023779840n, 6],
  [4398047035392n, 7],
  [8796093546496n, 8],
  [17592186568704n, 9],
  [35184372613120n, 4],
  [70368744701952n, 3],
  [140737488879616n, 4],
  [281474977234944n, 5],
  [562949953945600n, 6],
  [1125899907366912n, 7],
  [2251799814209536n, 8],
  [4503599627894784n, 9],
  [9007199255265280n, 10],
  [18014398510006272n, 5],
  [36028797019488256n, 4],
  [72057594038452224n, 5],
  [144115188076380160n, 6],
  [288230376152236032n, 7],
  [576460752303947776n, 8],
  [1152921504607371264n, 9],
  [2305843009214218240n, 10],
  [4611686018427912192n, 11],
  [9223372036855300096n, 6],
  [18446744073710075904n, 5],
  [36893488147419627520n, 6],
  [73786976294838730752n, 7],
  [147573952589676937216n, 8],
  [295147905179353350144n, 9],
  [590295810358706176000n, 10],
  [1180591620717411827712n, 11],
  [2361183241434823131136n, 12],
  [4722366482869645737984n, 7],
  [9444732965739290951680n, 6],
  [18889465931478581379072n, 7],
  [37778931862957162233856n, 8],
  [75557863725914323943424n, 9],
  [151115727451828647362560n, 10],
  [302231454903657294200832n, 11],
  [604462909807314587877376n, 12],
  [1208925819614629175230464n, 13],
  [1048577n, 4],
  [1048578n, 3],
  [1048580n, 2],
  [1048584n, 3],
  [1048592n, 4],
  [1048608n, 5],
  [1048640n, 6],
  [1048704n, 7],
  [1048832n, 8],
  [1049088n, 3],
  [1049600n, 2],
  [1050624n, 1],
  [1052672n, 2],
  [1056768n, 3],
  [1064960n, 4],
  [1081344n, 5],
  [1114112n, 6],
  [1179648n, 7],
  [1310720n, 2],
  [1572864n, 1],
  [1048576n, 0],
  [3145728n, 1],
  [5242880n, 2],
  [9437184n, 3],
  [17825792n, 4],
  [34603008n, 5],
  [68157440n, 6],
  [135266304n, 3],
  [269484032n, 2],
  [537919488n, 1],
  [1074790400n, 2],
  [2148532224n, 3],
  [4296015872n, 4],
  [8590983168n, 5],
  [17180917760n, 6],
  [34360786944n, 7],
  [68720525312n, 4],
  [137440002048n, 3],
  [274878955520n, 2],
  [549756862464n, 3],
  [1099512676352n, 4],
  [2199024304128n, 5],
  [4398047559680n, 6],
  [8796094070784n, 7],
  [17592187092992n, 8],
  [35184373137408n, 5],
  [70368745226240n, 4],
  [140737489403904n, 3],
  [281474977759232n, 4],
  [562949954469888n, 5],
  [1125899907891200n, 6],
  [2251799814733824n, 7],
  [4503599628419072n, 8],
  [9007199255789568n, 9],
  [18014398510530560n, 6],
  [36028797020012544n, 5],
  [72057594038976512n, 4],
  [144115188076904448n, 5],
  [288230376152760320n, 6],
  [576460752304472064n, 7],
  [1152921504607895552n, 8],
  [2305843009214742528n, 9],
  [4611686018428436480n, 10],
  [9223372036855824384n, 7],
  [18446744073710600192n, 6],
  [36893488147420151808n, 5],
  [73786976294839255040n, 6],
  [147573952589677461504n, 7],
  [295147905179353874432n, 8],
  [590295810358706700288n, 9],
  [1180591620717412352000n, 10],
  [2361183241434823655424n, 11],
  [4722366482869646262272n, 8],
  [9444732965739291475968n, 7],
  [18889465931478581903360n, 6],
  [37778931862957162758144n, 7],
  [75557863725914324467712n, 8],
  [151115727451828647886848n, 9],
  [302231454903657294725120n, 10],
  [604462909807314588401664n, 11],
  [1208925819614629175754752n, 12],
  [2097153n, 5],
  [2097154n, 4],
  [2097156n, 3],
  [2097160n, 2],
  [2097168n, 3],
  [2097184n, 4],
  [2097216n, 5],
  [2097280n, 6],
  [2097408n, 7],
  [2097664n, 4],
  [2098176n, 3],
  [2099200n, 2],
  [2101248n, 1],
  [2105344n, 2],
  [2113536n, 3],
  [2129920n, 4],
  [2162688n, 5],
  [2228224n, 6],
  [2359296n, 3],
  [2621440n, 2],
  [3145728n, 1],
  [2097152n, 0],
  [6291456n, 1],
  [10485760n, 2],
  [18874368n, 3],
  [35651584n, 4],
  [69206016n, 5],
  [136314880n, 4],
  [270532608n, 3],
  [538968064n, 2],
  [1075838976n, 1],
  [2149580800n, 2],
  [4297064448n, 3],
  [8592031744n, 4],
  [17181966336n, 5],
  [34361835520n, 6],
  [68721573888n, 5],
  [137441050624n, 4],
  [274880004096n, 3],
  [549757911040n, 2],
  [1099513724928n, 3],
  [2199025352704n, 4],
  [4398048608256n, 5],
  [8796095119360n, 6],
  [17592188141568n, 7],
  [35184374185984n, 6],
  [70368746274816n, 5],
  [140737490452480n, 4],
  [281474978807808n, 3],
  [562949955518464n, 4],
  [1125899908939776n, 5],
  [2251799815782400n, 6],
  [4503599629467648n, 7],
  [9007199256838144n, 8],
  [18014398511579136n, 7],
  [36028797021061120n, 6],
  [72057594040025088n, 5],
  [144115188077953024n, 4],
  [288230376153808896n, 5],
  [576460752305520640n, 6],
  [1152921504608944128n, 7],
  [2305843009215791104n, 8],
  [4611686018429485056n, 9],
  [9223372036856872960n, 8],
  [18446744073711648768n, 7],
  [36893488147421200384n, 6],
  [73786976294840303616n, 5],
  [147573952589678510080n, 6],
  [295147905179354923008n, 7],
  [590295810358707748864n, 8],
  [1180591620717413400576n, 9],
  [2361183241434824704000n, 10],
  [4722366482869647310848n, 9],
  [9444732965739292524544n, 8],
  [18889465931478582951936n, 7],
  [37778931862957163806720n, 6],
  [75557863725914325516288n, 7],
  [151115727451828648935424n, 8],
  [302231454903657295773696n, 9],
  [604462909807314589450240n, 10],
  [1208925819614629176803328n, 11],
  [4194305n, 6],
  [4194306n, 5],
  [4194308n, 4],
  [4194312n, 3],
  [4194320n, 2],
  [4194336n, 3],
  [4194368n, 4],
  [4194432n, 5],
  [4194560n, 6],
  [4194816n, 5],
  [4195328n, 4],
  [4196352n, 3],
  [4198400n, 2],
  [4202496n, 1],
  [4210688n, 2],
  [4227072n, 3],
  [4259840n, 4],
  [4325376n, 5],
  [4456448n, 4],
  [4718592n, 3],
  [5242880n, 2],
  [6291456n, 1],
  [4194304n, 0],
  [12582912n, 1],
  [20971520n, 2],
  [37748736n, 3],
  [71303168n, 4],
  [138412032n, 5],
  [272629760n, 4],
  [541065216n, 3],
  [1077936128n, 2],
  [2151677952n, 1],
  [4299161600n, 2],
  [8594128896n, 3],
  [17184063488n, 4],
  [34363932672n, 5],
  [68723671040n, 6],
  [137443147776n, 5],
  [274882101248n, 4],
  [549760008192n, 3],
  [1099515822080n, 2],
  [2199027449856n, 3],
  [4398050705408n, 4],
  [8796097216512n, 5],
  [17592190238720n, 6],
  [35184376283136n, 7],
  [70368748371968n, 6],
  [140737492549632n, 5],
  [281474980904960n, 4],
  [562949957615616n, 3],
  [1125899911036928n, 4],
  [2251799817879552n, 5],
  [4503599631564800n, 6],
  [9007199258935296n, 7],
  [18014398513676288n, 8],
  [36028797023158272n, 7],
  [72057594042122240n, 6],
  [144115188080050176n, 5],
  [288230376155906048n, 4],
  [576460752307617792n, 5],
  [1152921504611041280n, 6],
  [2305843009217888256n, 7],
  [4611686018431582208n, 8],
  [9223372036858970112n, 9],
  [18446744073713745920n, 8],
  [36893488147423297536n, 7],
  [73786976294842400768n, 6],
  [147573952589680607232n, 5],
  [295147905179357020160n, 6],
  [590295810358709846016n, 7],
  [1180591620717415497728n, 8],
  [2361183241434826801152n, 9],
  [4722366482869649408000n, 10],
  [9444732965739294621696n, 9],
  [18889465931478585049088n, 8],
  [37778931862957165903872n, 7],
  [75557863725914327613440n, 6],
  [151115727451828651032576n, 7],
  [302231454903657297870848n, 8],
  [604462909807314591547392n, 9],
  [1208925819614629178900480n, 10],
  [8388609n, 7],
  [8388610n, 6],
  [8388612n, 5],
  [8388616n, 4],
  [8388624n, 3],
  [8388640n, 2],
  [8388672n, 3],
  [8388736n, 4],
  [8388864n, 5],
  [8389120n, 6],
  [8389632n, 5],
  [8390656n, 4],
  [8392704n, 3],
  [8396800n, 2],
  [8404992n, 1],
  [8421376n, 2],
  [8454144n, 3],
  [8519680n, 4],
  [8650752n, 5],
  [8912896n, 4],
  [9437184n, 3],
  [10485760n, 2],
  [12582912n, 1],
  [8388608n, 0],
  [25165824n, 1],
  [41943040n, 2],
  [75497472n, 3],
  [142606336n, 6],
  [276824064n, 5],
  [545259520n, 4],
  [1082130432n, 3],
  [2155872256n, 2],
  [4303355904n, 1],
  [8598323200n, 2],
  [17188257792n, 3],
  [34368126976n, 4],
  [68727865344n, 7],
  [137447342080n, 6],
  [274886295552n, 5],
  [549764202496n, 4],
  [1099520016384n, 3],
  [2199031644160n, 2],
  [4398054899712n, 3],
  [8796101410816n, 4],
  [17592194433024n, 5],
  [35184380477440n, 8],
  [70368752566272n, 7],
  [140737496743936n, 6],
  [281474985099264n, 5],
  [562949961809920n, 4],
  [1125899915231232n, 3],
  [2251799822073856n, 4],
  [4503599635759104n, 5],
  [9007199263129600n, 6],
  [18014398517870592n, 9],
  [36028797027352576n, 8],
  [72057594046316544n, 7],
  [144115188084244480n, 6],
  [288230376160100352n, 5],
  [576460752311812096n, 4],
  [1152921504615235584n, 5],
  [2305843009222082560n, 6],
  [4611686018435776512n, 7],
  [9223372036863164416n, 10],
  [18446744073717940224n, 9],
  [36893488147427491840n, 8],
  [73786976294846595072n, 7],
  [147573952589684801536n, 6],
  [295147905179361214464n, 5],
  [590295810358714040320n, 6],
  [1180591620717419692032n, 7],
  [2361183241434830995456n, 8],
  [4722366482869653602304n, 11],
  [9444732965739298816000n, 10],
  [18889465931478589243392n, 9],
  [37778931862957170098176n, 8],
  [75557863725914331807744n, 7],
  [151115727451828655226880n, 6],
  [302231454903657302065152n, 7],
  [604462909807314595741696n, 8],
  [1208925819614629183094784n, 9],
  [16777217n, 8],
  [16777218n, 7],
  [16777220n, 6],
  [16777224n, 5],
  [16777232n, 4],
  [16777248n, 3],
  [16777280n, 2],
  [16777344n, 3],
  [16777472n, 4],
  [16777728n, 7],
  [16778240n, 6],
  [16779264n, 5],
  [16781312n, 4],
  [16785408n, 3],
  [16793600n, 2],
  [16809984n, 1],
  [16842752n, 2],
  [16908288n, 3],
  [17039360n, 6],
  [17301504n, 5],
  [17825792n, 4],
  [18874368n, 3],
  [20971520n, 2],
  [25165824n, 1],
  [16777216n, 0],
  [50331648n, 1],
  [83886080n, 2],
  [150994944n, 7],
  [285212672n, 6],
  [553648128n, 5],
  [1090519040n, 4],
  [2164260864n, 3],
  [4311744512n, 2],
  [8606711808n, 1],
  [17196646400n, 2],
  [34376515584n, 3],
  [68736253952n, 8],
  [137455730688n, 7],
  [274894684160n, 6],
  [549772591104n, 5],
  [1099528404992n, 4],
  [2199040032768n, 3],
  [4398063288320n, 2],
  [8796109799424n, 3],
  [17592202821632n, 4],
  [35184388866048n, 9],
  [70368760954880n, 8],
  [140737505132544n, 7],
  [281474993487872n, 6],
  [562949970198528n, 5],
  [1125899923619840n, 4],
  [2251799830462464n, 3],
  [4503599644147712n, 4],
  [9007199271518208n, 5],
  [18014398526259200n, 10],
  [36028797035741184n, 9],
  [72057594054705152n, 8],
  [144115188092633088n, 7],
  [288230376168488960n, 6],
  [576460752320200704n, 5],
  [1152921504623624192n, 4],
  [2305843009230471168n, 5],
  [4611686018444165120n, 6],
  [9223372036871553024n, 11],
  [18446744073726328832n, 10],
  [36893488147435880448n, 9],
  [73786976294854983680n, 8],
  [147573952589693190144n, 7],
  [295147905179369603072n, 6],
  [590295810358722428928n, 5],
  [1180591620717428080640n, 6],
  [2361183241434839384064n, 7],
  [4722366482869661990912n, 12],
  [9444732965739307204608n, 11],
  [18889465931478597632000n, 10],
  [37778931862957178486784n, 9],
  [75557863725914340196352n, 8],
  [151115727451828663615488n, 7],
  [302231454903657310453760n, 6],
  [604462909807314604130304n, 7],
  [1208925819614629191483392n, 8],
  [33554433n, 9],
  [33554434n, 8],
  [33554436n, 7],
  [33554440n, 6],
  [33554448n, 5],
  [33554464n, 4],
  [33554496n, 3],
  [33554560n, 2],
  [33554688n, 3],
  [33554944n, 8],
  [33555456n, 7],
  [33556480n, 6],
  [33558528n, 5],
  [33562624n, 4],
  [33570816n, 3],
  [33587200n, 2],
  [33619968n, 1],
  [33685504n, 2],
  [33816576n, 7],
  [34078720n, 6],
  [34603008n, 5],
  [35651584n, 4],
  [37748736n, 3],
  [41943040n, 2],
  [50331648n, 1],
  [33554432n, 0],
  [100663296n, 1],
  [167772160n, 8],
  [301989888n, 7],
  [570425344n, 6],
  [1107296256n, 5],
  [2181038080n, 4],
  [4328521728n, 3],
  [8623489024n, 2],
  [17213423616n, 1],
  [34393292800n, 2],
  [68753031168n, 9],
  [137472507904n, 8],
  [274911461376n, 7],
  [549789368320n, 6],
  [1099545182208n, 5],
  [2199056809984n, 4],
  [4398080065536n, 3],
  [8796126576640n, 2],
  [17592219598848n, 3],
  [35184405643264n, 10],
  [70368777732096n, 9],
  [140737521909760n, 8],
  [281475010265088n, 7],
  [562949986975744n, 6],
  [1125899940397056n, 5],
  [2251799847239680n, 4],
  [4503599660924928n, 3],
  [9007199288295424n, 4],
  [18014398543036416n, 11],
  [36028797052518400n, 10],
  [72057594071482368n, 9],
  [144115188109410304n, 8],
  [288230376185266176n, 7],
  [576460752336977920n, 6],
  [1152921504640401408n, 5],
  [2305843009247248384n, 4],
  [4611686018460942336n, 5],
  [9223372036888330240n, 12],
  [18446744073743106048n, 11],
  [36893488147452657664n, 10],
  [73786976294871760896n, 9],
  [147573952589709967360n, 8],
  [295147905179386380288n, 7],
  [590295810358739206144n, 6],
  [1180591620717444857856n, 5],
  [2361183241434856161280n, 6],
  [4722366482869678768128n, 13],
  [9444732965739323981824n, 12],
  [18889465931478614409216n, 11],
  [37778931862957195264000n, 10],
  [75557863725914356973568n, 9],
  [151115727451828680392704n, 8],
  [302231454903657327230976n, 7],
  [604462909807314620907520n, 6],
  [1208925819614629208260608n, 7],
  [67108865n, 10],
  [67108866n, 9],
  [67108868n, 8],
  [67108872n, 7],
  [67108880n, 6],
  [67108896n, 5],
  [67108928n, 4],
  [67108992n, 3],
  [67109120n, 2],
  [67109376n, 9],
  [67109888n, 8],
  [67110912n, 7],
  [67112960n, 6],
  [67117056n, 5],
  [67125248n, 4],
  [67141632n, 3],
  [67174400n, 2],
  [67239936n, 1],
  [67371008n, 8],
  [67633152n, 7],
  [68157440n, 6],
  [69206016n, 5],
  [71303168n, 4],
  [75497472n, 3],
  [83886080n, 2],
  [100663296n, 1],
  [67108864n, 0],
  [201326592n, 9],
  [335544320n, 8],
  [603979776n, 7],
  [1140850688n, 6],
  [2214592512n, 5],
  [4362076160n, 4],
  [8657043456n, 3],
  [17246978048n, 2],
  [34426847232n, 1],
  [68786585600n, 10],
  [137506062336n, 9],
  [274945015808n, 8],
  [549822922752n, 7],
  [1099578736640n, 6],
  [2199090364416n, 5],
  [4398113619968n, 4],
  [8796160131072n, 3],
  [17592253153280n, 2],
  [35184439197696n, 11],
  [70368811286528n, 10],
  [140737555464192n, 9],
  [281475043819520n, 8],
  [562950020530176n, 7],
  [1125899973951488n, 6],
  [2251799880794112n, 5],
  [4503599694479360n, 4],
  [9007199321849856n, 3],
  [18014398576590848n, 12],
  [36028797086072832n, 11],
  [72057594105036800n, 10],
  [144115188142964736n, 9],
  [288230376218820608n, 8],
  [576460752370532352n, 7],
  [1152921504673955840n, 6],
  [2305843009280802816n, 5],
  [4611686018494496768n, 4],
  [9223372036921884672n, 13],
  [18446744073776660480n, 12],
  [36893488147486212096n, 11],
  [73786976294905315328n, 10],
  [147573952589743521792n, 9],
  [295147905179419934720n, 8],
  [590295810358772760576n, 7],
  [1180591620717478412288n, 6],
  [2361183241434889715712n, 5],
  [4722366482869712322560n, 14],
  [9444732965739357536256n, 13],
  [18889465931478647963648n, 12],
  [37778931862957228818432n, 11],
  [75557863725914390528000n, 10],
  [151115727451828713947136n, 9],
  [302231454903657360785408n, 8],
  [604462909807314654461952n, 7],
  [1208925819614629241815040n, 6],
  [134217729n, 3],
  [134217730n, 4],
  [134217732n, 5],
  [134217736n, 6],
  [134217744n, 7],
  [134217760n, 8],
  [134217792n, 9],
  [134217856n, 10],
  [134217984n, 11],
  [134218240n, 2],
  [134218752n, 3],
  [134219776n, 4],
  [134221824n, 5],
  [134225920n, 6],
  [134234112n, 7],
  [134250496n, 8],
  [134283264n, 9],
  [134348800n, 10],
  [134479872n, 1],
  [134742016n, 2],
  [135266304n, 3],
  [136314880n, 4],
  [138412032n, 5],
  [142606336n, 6],
  [150994944n, 7],
  [167772160n, 8],
  [201326592n, 9],
  [134217728n, 0],
  [402653184n, 1],
  [671088640n, 2],
  [1207959552n, 3],
  [2281701376n, 4],
  [4429185024n, 5],
  [8724152320n, 6],
  [17314086912n, 7],
  [34493956096n, 8],
  [68853694464n, 1],
  [137573171200n, 2],
  [275012124672n, 3],
  [549890031616n, 4],
  [1099645845504n, 5],
  [2199157473280n, 6],
  [4398180728832n, 7],
  [8796227239936n, 8],
  [17592320262144n, 9],
  [35184506306560n, 2],
  [70368878395392n, 3],
  [140737622573056n, 4],
  [281475110928384n, 5],
  [562950087639040n, 6],
  [1125900041060352n, 7],
  [2251799947902976n, 8],
  [4503599761588224n, 9],
  [9007199388958720n, 10],
  [18014398643699712n, 3],
  [36028797153181696n, 4],
  [72057594172145664n, 5],
  [144115188210073600n, 6],
  [288230376285929472n, 7],
  [576460752437641216n, 8],
  [1152921504741064704n, 9],
  [2305843009347911680n, 10],
  [4611686018561605632n, 11],
  [9223372036988993536n, 4],
  [18446744073843769344n, 5],
  [36893488147553320960n, 6],
  [73786976294972424192n, 7],
  [147573952589810630656n, 8],
  [295147905179487043584n, 9],
  [590295810358839869440n, 10],
  [1180591620717545521152n, 11],
  [2361183241434956824576n, 12],
  [4722366482869779431424n, 5],
  [9444732965739424645120n, 6],
  [18889465931478715072512n, 7],
  [37778931862957295927296n, 8],
  [75557863725914457636864n, 9],
  [151115727451828781056000n, 10],
  [302231454903657427894272n, 11],
  [604462909807314721570816n, 12],
  [1208925819614629308923904n, 13],
  [268435457n, 4],
  [268435458n, 3],
  [268435460n, 4],
  [268435464n, 5],
  [268435472n, 6],
  [268435488n, 7],
  [268435520n, 8],
  [268435584n, 9],
  [268435712n, 10],
  [268435968n, 3],
  [268436480n, 2],
  [268437504n, 3],
  [268439552n, 4],
  [268443648n, 5],
  [268451840n, 6],
  [268468224n, 7],
  [268500992n, 8],
  [268566528n, 9],
  [268697600n, 2],
  [268959744n, 1],
  [269484032n, 2],
  [270532608n, 3],
  [272629760n, 4],
  [276824064n, 5],
  [285212672n, 6],
  [301989888n, 7],
  [335544320n, 8],
  [402653184n, 1],
  [268435456n, 0],
  [805306368n, 1],
  [1342177280n, 2],
  [2415919104n, 3],
  [4563402752n, 4],
  [8858370048n, 5],
  [17448304640n, 6],
  [34628173824n, 7],
  [68987912192n, 2],
  [137707388928n, 1],
  [275146342400n, 2],
  [550024249344n, 3],
  [1099780063232n, 4],
  [2199291691008n, 5],
  [4398314946560n, 6],
  [8796361457664n, 7],
  [17592454479872n, 8],
  [35184640524288n, 3],
  [70369012613120n, 2],
  [140737756790784n, 3],
  [281475245146112n, 4],
  [562950221856768n, 5],
  [1125900175278080n, 6],
  [2251800082120704n, 7],
  [4503599895805952n, 8],
  [9007199523176448n, 9],
  [18014398777917440n, 4],
  [36028797287399424n, 3],
  [72057594306363392n, 4],
  [144115188344291328n, 5],
  [288230376420147200n, 6],
  [576460752571858944n, 7],
  [1152921504875282432n, 8],
  [2305843009482129408n, 9],
  [4611686018695823360n, 10],
  [9223372037123211264n, 5],
  [18446744073977987072n, 4],
  [36893488147687538688n, 5],
  [73786976295106641920n, 6],
  [147573952589944848384n, 7],
  [295147905179621261312n, 8],
  [590295810358974087168n, 9],
  [1180591620717679738880n, 10],
  [2361183241435091042304n, 11],
  [4722366482869913649152n, 6],
  [9444732965739558862848n, 5],
  [18889465931478849290240n, 6],
  [37778931862957430145024n, 7],
  [75557863725914591854592n, 8],
  [151115727451828915273728n, 9],
  [302231454903657562112000n, 10],
  [604462909807314855788544n, 11],
  [1208925819614629443141632n, 12],
  [536870913n, 5],
  [536870914n, 4],
  [536870916n, 3],
  [536870920n, 4],
  [536870928n, 5],
  [536870944n, 6],
  [536870976n, 7],
  [536871040n, 8],
  [536871168n, 9],
  [536871424n, 4],
  [536871936n, 3],
  [536872960n, 2],
  [536875008n, 3],
  [536879104n, 4],
  [536887296n, 5],
  [536903680n, 6],
  [536936448n, 7],
  [537001984n, 8],
  [537133056n, 3],
  [537395200n, 2],
  [537919488n, 1],
  [538968064n, 2],
  [541065216n, 3],
  [545259520n, 4],
  [553648128n, 5],
  [570425344n, 6],
  [603979776n, 7],
  [671088640n, 2],
  [805306368n, 1],
  [536870912n, 0],
  [1610612736n, 1],
  [2684354560n, 2],
  [4831838208n, 3],
  [9126805504n, 4],
  [17716740096n, 5],
  [34896609280n, 6],
  [69256347648n, 3],
  [137975824384n, 2],
  [275414777856n, 1],
  [550292684800n, 2],
  [1100048498688n, 3],
  [2199560126464n, 4],
  [4398583382016n, 5],
  [8796629893120n, 6],
  [17592722915328n, 7],
  [35184908959744n, 4],
  [70369281048576n, 3],
  [140738025226240n, 2],
  [281475513581568n, 3],
  [562950490292224n, 4],
  [1125900443713536n, 5],
  [2251800350556160n, 6],
  [4503600164241408n, 7],
  [9007199791611904n, 8],
  [18014399046352896n, 5],
  [36028797555834880n, 4],
  [72057594574798848n, 3],
  [144115188612726784n, 4],
  [288230376688582656n, 5],
  [576460752840294400n, 6],
  [1152921505143717888n, 7],
  [2305843009750564864n, 8],
  [4611686018964258816n, 9],
  [9223372037391646720n, 6],
  [18446744074246422528n, 5],
  [36893488147955974144n, 4],
  [73786976295375077376n, 5],
  [147573952590213283840n, 6],
  [295147905179889696768n, 7],
  [590295810359242522624n, 8],
  [1180591620717948174336n, 9],
  [2361183241435359477760n, 10],
  [4722366482870182084608n, 7],
  [9444732965739827298304n, 6],
  [18889465931479117725696n, 5],
  [37778931862957698580480n, 6],
  [75557863725914860290048n, 7],
  [151115727451829183709184n, 8],
  [302231454903657830547456n, 9],
  [604462909807315124224000n, 10],
  [1208925819614629711577088n, 11],
  [1073741825n, 6],
  [1073741826n, 5],
  [1073741828n, 4],
  [1073741832n, 3],
  [1073741840n, 4],
  [1073741856n, 5],
  [1073741888n, 6],
  [1073741952n, 7],
  [1073742080n, 8],
  [1073742336n, 5],
  [1073742848n, 4],
  [1073743872n, 3],
  [1073745920n, 2],
  [1073750016n, 3],
  [1073758208n, 4],
  [1073774592n, 5],
  [1073807360n, 6],
  [1073872896n, 7],
  [1074003968n, 4],
  [1074266112n, 3],
  [1074790400n, 2],
  [1075838976n, 1],
  [1077936128n, 2],
  [1082130432n, 3],
  [1090519040n, 4],
  [1107296256n, 5],
  [1140850688n, 6],
  [1207959552n, 3],
  [1342177280n, 2],
  [1610612736n, 1],
  [1073741824n, 0],
  [3221225472n, 1],
  [5368709120n, 2],
  [9663676416n, 3],
  [18253611008n, 4],
  [35433480192n, 5],
  [69793218560n, 4],
  [138512695296n, 3],
  [275951648768n, 2],
  [550829555712n, 1],
  [1100585369600n, 2],
  [2200096997376n, 3],
  [4399120252928n, 4],
  [8797166764032n, 5],
  [17593259786240n, 6],
  [35185445830656n, 5],
  [70369817919488n, 4],
  [140738562097152n, 3],
  [281476050452480n, 2],
  [562951027163136n, 3],
  [1125900980584448n, 4],
  [2251800887427072n, 5],
  [4503600701112320n, 6],
  [9007200328482816n, 7],
  [18014399583223808n, 6],
  [36028798092705792n, 5],
  [72057595111669760n, 4],
  [144115189149597696n, 3],
  [288230377225453568n, 4],
  [576460753377165312n, 5],
  [1152921505680588800n, 6],
  [2305843010287435776n, 7],
  [4611686019501129728n, 8],
  [9223372037928517632n, 7],
  [18446744074783293440n, 6],
  [36893488148492845056n, 5],
  [73786976295911948288n, 4],
  [147573952590750154752n, 5],
  [295147905180426567680n, 6],
  [590295810359779393536n, 7],
  [1180591620718485045248n, 8],
  [2361183241435896348672n, 9],
  [4722366482870718955520n, 8],
  [9444732965740364169216n, 7],
  [18889465931479654596608n, 6],
  [37778931862958235451392n, 5],
  [75557863725915397160960n, 6],
  [151115727451829720580096n, 7],
  [302231454903658367418368n, 8],
  [604462909807315661094912n, 9],
  [1208925819614630248448000n, 10],
  [2147483649n, 7],
  [2147483650n, 6],
  [2147483652n, 5],
  [2147483656n, 4],
  [2147483664n, 3],
  [2147483680n, 4],
  [2147483712n, 5],
  [2147483776n, 6],
  [2147483904n, 7],
  [2147484160n, 6],
  [2147484672n, 5],
  [2147485696n, 4],
  [2147487744n, 3],
  [2147491840n, 2],
  [2147500032n, 3],
  [2147516416n, 4],
  [2147549184n, 5],
  [2147614720n, 6],
  [2147745792n, 5],
  [2148007936n, 4],
  [2148532224n, 3],
  [2149580800n, 2],
  [2151677952n, 1],
  [2155872256n, 2],
  [2164260864n, 3],
  [2181038080n, 4],
  [2214592512n, 5],
  [2281701376n, 4],
  [2415919104n, 3],
  [2684354560n, 2],
  [3221225472n, 1],
  [2147483648n, 0],
  [6442450944n, 1],
  [10737418240n, 2],
  [19327352832n, 3],
  [36507222016n, 4],
  [70866960384n, 5],
  [139586437120n, 4],
  [277025390592n, 3],
  [551903297536n, 2],
  [1101659111424n, 1],
  [2201170739200n, 2],
  [4400193994752n, 3],
  [8798240505856n, 4],
  [17594333528064n, 5],
  [35186519572480n, 6],
  [70370891661312n, 5],
  [140739635838976n, 4],
  [281477124194304n, 3],
  [562952100904960n, 2],
  [1125902054326272n, 3],
  [2251801961168896n, 4],
  [4503601774854144n, 5],
  [9007201402224640n, 6],
  [18014400656965632n, 7],
  [36028799166447616n, 6],
  [72057596185411584n, 5],
  [144115190223339520n, 4],
  [288230378299195392n, 3],
  [576460754450907136n, 4],
  [1152921506754330624n, 5],
  [2305843011361177600n, 6],
  [4611686020574871552n, 7],
  [9223372039002259456n, 8],
  [18446744075857035264n, 7],
  [36893488149566586880n, 6],
  [73786976296985690112n, 5],
  [147573952591823896576n, 4],
  [295147905181500309504n, 5],
  [590295810360853135360n, 6],
  [1180591620719558787072n, 7],
  [2361183241436970090496n, 8],
  [4722366482871792697344n, 9],
  [9444732965741437911040n, 8],
  [18889465931480728338432n, 7],
  [37778931862959309193216n, 6],
  [75557863725916470902784n, 5],
  [151115727451830794321920n, 6],
  [302231454903659441160192n, 7],
  [604462909807316734836736n, 8],
  [1208925819614631322189824n, 9],
  [4294967297n, 8],
  [4294967298n, 7],
  [4294967300n, 6],
  [4294967304n, 5],
  [4294967312n, 4],
  [4294967328n, 3],
  [4294967360n, 4],
  [4294967424n, 5],
  [4294967552n, 6],
  [4294967808n, 7],
  [4294968320n, 6],
  [4294969344n, 5],
  [4294971392n, 4],
  [4294975488n, 3],
  [4294983680n, 2],
  [4295000064n, 3],
  [4295032832n, 4],
  [4295098368n, 5],
  [4295229440n, 6],
  [4295491584n, 5],
  [4296015872n, 4],
  [4297064448n, 3],
  [4299161600n, 2],
  [4303355904n, 1],
  [4311744512n, 2],
  [4328521728n, 3],
  [4362076160n, 4],
  [4429185024n, 5],
  [4563402752n, 4],
  [4831838208n, 3],
  [5368709120n, 2],
  [6442450944n, 1],
  [4294967296n, 0],
  [12884901888n, 1],
  [21474836480n, 2],
  [38654705664n, 3],
  [73014444032n, 6],
  [141733920768n, 5],
  [279172874240n, 4],
  [554050781184n, 3],
  [1103806595072n, 2],
  [2203318222848n, 1],
  [4402341478400n, 2],
  [8800387989504n, 3],
  [17596481011712n, 4],
  [35188667056128n, 7],
  [70373039144960n, 6],
  [140741783322624n, 5],
  [281479271677952n, 4],
  [562954248388608n, 3],
  [1125904201809920n, 2],
  [2251804108652544n, 3],
  [4503603922337792n, 4],
  [9007203549708288n, 5],
  [18014402804449280n, 8],
  [36028801313931264n, 7],
  [72057598332895232n, 6],
  [144115192370823168n, 5],
  [288230380446679040n, 4],
  [576460756598390784n, 3],
  [1152921508901814272n, 4],
  [2305843013508661248n, 5],
  [4611686022722355200n, 6],
  [9223372041149743104n, 9],
  [18446744078004518912n, 8],
  [36893488151714070528n, 7],
  [73786976299133173760n, 6],
  [147573952593971380224n, 5],
  [295147905183647793152n, 4],
  [590295810363000619008n, 5],
  [1180591620721706270720n, 6],
  [2361183241439117574144n, 7],
  [4722366482873940180992n, 10],
  [9444732965743585394688n, 9],
  [18889465931482875822080n, 8],
  [37778931862961456676864n, 7],
  [75557863725918618386432n, 6],
  [151115727451832941805568n, 5],
  [302231454903661588643840n, 6],
  [604462909807318882320384n, 7],
  [1208925819614633469673472n, 8],
  [8589934593n, 9],
  [8589934594n, 8],
  [8589934596n, 7],
  [8589934600n, 6],
  [8589934608n, 5],
  [8589934624n, 4],
  [8589934656n, 3],
  [8589934720n, 4],
  [8589934848n, 5],
  [8589935104n, 8],
  [8589935616n, 7],
  [8589936640n, 6],
  [8589938688n, 5],
  [8589942784n, 4],
  [8589950976n, 3],
  [8589967360n, 2],
  [8590000128n, 3],
  [8590065664n, 4],
  [8590196736n, 7],
  [8590458880n, 6],
  [8590983168n, 5],
  [8592031744n, 4],
  [8594128896n, 3],
  [8598323200n, 2],
  [8606711808n, 1],
  [8623489024n, 2],
  [8657043456n, 3],
  [8724152320n, 6],
  [8858370048n, 5],
  [9126805504n, 4],
  [9663676416n, 3],
  [10737418240n, 2],
  [12884901888n, 1],
  [8589934592n, 0],
  [25769803776n, 1],
  [42949672960n, 2],
  [77309411328n, 7],
  [146028888064n, 6],
  [283467841536n, 5],
  [558345748480n, 4],
  [1108101562368n, 3],
  [2207613190144n, 2],
  [4406636445696n, 1],
  [8804682956800n, 2],
  [17600775979008n, 3],
  [35192962023424n, 8],
  [70377334112256n, 7],
  [140746078289920n, 6],
  [281483566645248n, 5],
  [562958543355904n, 4],
  [1125908496777216n, 3],
  [2251808403619840n, 2],
  [4503608217305088n, 3],
  [9007207844675584n, 4],
  [18014407099416576n, 9],
  [36028805608898560n, 8],
  [72057602627862528n, 7],
  [144115196665790464n, 6],
  [288230384741646336n, 5],
  [576460760893358080n, 4],
  [1152921513196781568n, 3],
  [2305843017803628544n, 4],
  [4611686027017322496n, 5],
  [9223372045444710400n, 10],
  [18446744082299486208n, 9],
  [36893488156009037824n, 8],
  [73786976303428141056n, 7],
  [147573952598266347520n, 6],
  [295147905187942760448n, 5],
  [590295810367295586304n, 4],
  [1180591620726001238016n, 5],
  [2361183241443412541440n, 6],
  [4722366482878235148288n, 11],
  [9444732965747880361984n, 10],
  [18889465931487170789376n, 9],
  [37778931862965751644160n, 8],
  [75557863725922913353728n, 7],
  [151115727451837236772864n, 6],
  [302231454903665883611136n, 5],
  [604462909807323177287680n, 6],
  [1208925819614637764640768n, 7],
  [17179869185n, 10],
  [17179869186n, 9],
  [17179869188n, 8],
  [17179869192n, 7],
  [17179869200n, 6],
  [17179869216n, 5],
  [17179869248n, 4],
  [17179869312n, 3],
  [17179869440n, 4],
  [17179869696n, 9],
  [17179870208n, 8],
  [17179871232n, 7],
  [17179873280n, 6],
  [17179877376n, 5],
  [17179885568n, 4],
  [17179901952n, 3],
  [17179934720n, 2],
  [17180000256n, 3],
  [17180131328n, 8],
  [17180393472n, 7],
  [17180917760n, 6],
  [17181966336n, 5],
  [17184063488n, 4],
  [17188257792n, 3],
  [17196646400n, 2],
  [17213423616n, 1],
  [17246978048n, 2],
  [17314086912n, 7],
  [17448304640n, 6],
  [17716740096n, 5],
  [18253611008n, 4],
  [19327352832n, 3],
  [21474836480n, 2],
  [25769803776n, 1],
  [17179869184n, 0],
  [51539607552n, 1],
  [85899345920n, 8],
  [154618822656n, 7],
  [292057776128n, 6],
  [566935683072n, 5],
  [1116691496960n, 4],
  [2216203124736n, 3],
  [4415226380288n, 2],
  [8813272891392n, 1],
  [17609365913600n, 2],
  [35201551958016n, 9],
  [70385924046848n, 8],
  [140754668224512n, 7],
  [281492156579840n, 6],
  [562967133290496n, 5],
  [1125917086711808n, 4],
  [2251816993554432n, 3],
  [4503616807239680n, 2],
  [9007216434610176n, 3],
  [18014415689351168n, 10],
  [36028814198833152n, 9],
  [72057611217797120n, 8],
  [144115205255725056n, 7],
  [288230393331580928n, 6],
  [576460769483292672n, 5],
  [1152921521786716160n, 4],
  [2305843026393563136n, 3],
  [4611686035607257088n, 4],
  [9223372054034644992n, 11],
  [18446744090889420800n, 10],
  [36893488164598972416n, 9],
  [73786976312018075648n, 8],
  [147573952606856282112n, 7],
  [295147905196532695040n, 6],
  [590295810375885520896n, 5],
  [1180591620734591172608n, 4],
  [2361183241452002476032n, 5],
  [4722366482886825082880n, 12],
  [9444732965756470296576n, 11],
  [18889465931495760723968n, 10],
  [37778931862974341578752n, 9],
  [75557863725931503288320n, 8],
  [151115727451845826707456n, 7],
  [302231454903674473545728n, 6],
  [604462909807331767222272n, 5],
  [1208925819614646354575360n, 6],
  [34359738369n, 11],
  [34359738370n, 10],
  [34359738372n, 9],
  [34359738376n, 8],
  [34359738384n, 7],
  [34359738400n, 6],
  [34359738432n, 5],
  [34359738496n, 4],
  [34359738624n, 3],
  [34359738880n, 10],
  [34359739392n, 9],
  [34359740416n, 8],
  [34359742464n, 7],
  [34359746560n, 6],
  [34359754752n, 5],
  [34359771136n, 4],
  [34359803904n, 3],
  [34359869440n, 2],
  [34360000512n, 9],
  [34360262656n, 8],
  [34360786944n, 7],
  [34361835520n, 6],
  [34363932672n, 5],
  [34368126976n, 4],
  [34376515584n, 3],
  [34393292800n, 2],
  [34426847232n, 1],
  [34493956096n, 8],
  [34628173824n, 7],
  [34896609280n, 6],
  [35433480192n, 5],
  [36507222016n, 4],
  [38654705664n, 3],
  [42949672960n, 2],
  [51539607552n, 1],
  [34359738368n, 0],
  [103079215104n, 9],
  [171798691840n, 8],
  [309237645312n, 7],
  [584115552256n, 6],
  [1133871366144n, 5],
  [2233382993920n, 4],
  [4432406249472n, 3],
  [8830452760576n, 2],
  [17626545782784n, 1],
  [35218731827200n, 10],
  [70403103916032n, 9],
  [140771848093696n, 8],
  [281509336449024n, 7],
  [562984313159680n, 6],
  [1125934266580992n, 5],
  [2251834173423616n, 4],
  [4503633987108864n, 3],
  [9007233614479360n, 2],
  [18014432869220352n, 11],
  [36028831378702336n, 10],
  [72057628397666304n, 9],
  [144115222435594240n, 8],
  [288230410511450112n, 7],
  [576460786663161856n, 6],
  [1152921538966585344n, 5],
  [2305843043573432320n, 4],
  [4611686052787126272n, 3],
  [9223372071214514176n, 12],
  [18446744108069289984n, 11],
  [36893488181778841600n, 10],
  [73786976329197944832n, 9],
  [147573952624036151296n, 8],
  [295147905213712564224n, 7],
  [590295810393065390080n, 6],
  [1180591620751771041792n, 5],
  [2361183241469182345216n, 4],
  [4722366482904004952064n, 13],
  [9444732965773650165760n, 12],
  [18889465931512940593152n, 11],
  [37778931862991521447936n, 10],
  [75557863725948683157504n, 9],
  [151115727451863006576640n, 8],
  [302231454903691653414912n, 7],
  [604462909807348947091456n, 6],
  [1208925819614663534444544n, 5],
  [68719476737n, 4],
  [68719476738n, 5],
  [68719476740n, 6],
  [68719476744n, 7],
  [68719476752n, 8],
  [68719476768n, 9],
  [68719476800n, 10],
  [68719476864n, 11],
  [68719476992n, 12],
  [68719477248n, 3],
  [68719477760n, 4],
  [68719478784n, 5],
  [68719480832n, 6],
  [68719484928n, 7],
  [68719493120n, 8],
  [68719509504n, 9],
  [68719542272n, 10],
  [68719607808n, 11],
  [68719738880n, 2],
  [68720001024n, 3],
  [68720525312n, 4],
  [68721573888n, 5],
  [68723671040n, 6],
  [68727865344n, 7],
  [68736253952n, 8],
  [68753031168n, 9],
  [68786585600n, 10],
  [68853694464n, 1],
  [68987912192n, 2],
  [69256347648n, 3],
  [69793218560n, 4],
  [70866960384n, 5],
  [73014444032n, 6],
  [77309411328n, 7],
  [85899345920n, 8],
  [103079215104n, 9],
  [68719476736n, 0],
  [206158430208n, 1],
  [343597383680n, 2],
  [618475290624n, 3],
  [1168231104512n, 4],
  [2267742732288n, 5],
  [4466765987840n, 6],
  [8864812498944n, 7],
  [17660905521152n, 8],
  [35253091565568n, 1],
  [70437463654400n, 2],
  [140806207832064n, 3],
  [281543696187392n, 4],
  [563018672898048n, 5],
  [1125968626319360n, 6],
  [2251868533161984n, 7],
  [4503668346847232n, 8],
  [9007267974217728n, 9],
  [18014467228958720n, 2],
  [36028865738440704n, 3],
  [72057662757404672n, 4],
  [144115256795332608n, 5],
  [288230444871188480n, 6],
  [576460821022900224n, 7],
  [1152921573326323712n, 8],
  [2305843077933170688n, 9],
  [4611686087146864640n, 10],
  [9223372105574252544n, 3],
  [18446744142429028352n, 4],
  [36893488216138579968n, 5],
  [73786976363557683200n, 6],
  [147573952658395889664n, 7],
  [295147905248072302592n, 8],
  [590295810427425128448n, 9],
  [1180591620786130780160n, 10],
  [2361183241503542083584n, 11],
  [4722366482938364690432n, 4],
  [9444732965808009904128n, 5],
  [18889465931547300331520n, 6],
  [37778931863025881186304n, 7],
  [75557863725983042895872n, 8],
  [151115727451897366315008n, 9],
  [302231454903726013153280n, 10],
  [604462909807383306829824n, 11],
  [1208925819614697894182912n, 12],
  [137438953473n, 5],
  [137438953474n, 4],
  [137438953476n, 5],
  [137438953480n, 6],
  [137438953488n, 7],
  [137438953504n, 8],
  [137438953536n, 9],
  [137438953600n, 10],
  [137438953728n, 11],
  [137438953984n, 4],
  [137438954496n, 3],
  [137438955520n, 4],
  [137438957568n, 5],
  [137438961664n, 6],
  [137438969856n, 7],
  [137438986240n, 8],
  [137439019008n, 9],
  [137439084544n, 10],
  [137439215616n, 3],
  [137439477760n, 2],
  [137440002048n, 3],
  [137441050624n, 4],
  [137443147776n, 5],
  [137447342080n, 6],
  [137455730688n, 7],
  [137472507904n, 8],
  [137506062336n, 9],
  [137573171200n, 2],
  [137707388928n, 1],
  [137975824384n, 2],
  [138512695296n, 3],
  [139586437120n, 4],
  [141733920768n, 5],
  [146028888064n, 6],
  [154618822656n, 7],
  [171798691840n, 8],
  [206158430208n, 1],
  [137438953472n, 0],
  [412316860416n, 1],
  [687194767360n, 2],
  [1236950581248n, 3],
  [2336462209024n, 4],
  [4535485464576n, 5],
  [8933531975680n, 6],
  [17729624997888n, 7],
  [35321811042304n, 2],
  [70506183131136n, 1],
  [140874927308800n, 2],
  [281612415664128n, 3],
  [563087392374784n, 4],
  [1126037345796096n, 5],
  [2251937252638720n, 6],
  [4503737066323968n, 7],
  [9007336693694464n, 8],
  [18014535948435456n, 3],
  [36028934457917440n, 2],
  [72057731476881408n, 3],
  [144115325514809344n, 4],
  [288230513590665216n, 5],
  [576460889742376960n, 6],
  [1152921642045800448n, 7],
  [2305843146652647424n, 8],
  [4611686155866341376n, 9],
  [9223372174293729280n, 4],
  [18446744211148505088n, 3],
  [36893488284858056704n, 4],
  [73786976432277159936n, 5],
  [147573952727115366400n, 6],
  [295147905316791779328n, 7],
  [590295810496144605184n, 8],
  [1180591620854850256896n, 9],
  [2361183241572261560320n, 10],
  [4722366483007084167168n, 5],
  [9444732965876729380864n, 4],
  [18889465931616019808256n, 5],
  [37778931863094600663040n, 6],
  [75557863726051762372608n, 7],
  [151115727451966085791744n, 8],
  [302231454903794732630016n, 9],
  [604462909807452026306560n, 10],
  [1208925819614766613659648n, 11],
  [274877906945n, 6],
  [274877906946n, 5],
  [274877906948n, 4],
  [274877906952n, 5],
  [274877906960n, 6],
  [274877906976n, 7],
  [274877907008n, 8],
  [274877907072n, 9],
  [274877907200n, 10],
  [274877907456n, 5],
  [274877907968n, 4],
  [274877908992n, 3],
  [274877911040n, 4],
  [274877915136n, 5],
  [274877923328n, 6],
  [274877939712n, 7],
  [274877972480n, 8],
  [274878038016n, 9],
  [274878169088n, 4],
  [274878431232n, 3],
  [274878955520n, 2],
  [274880004096n, 3],
  [274882101248n, 4],
  [274886295552n, 5],
  [274894684160n, 6],
  [274911461376n, 7],
  [274945015808n, 8],
  [275012124672n, 3],
  [275146342400n, 2],
  [275414777856n, 1],
  [275951648768n, 2],
  [277025390592n, 3],
  [279172874240n, 4],
  [283467841536n, 5],
  [292057776128n, 6],
  [309237645312n, 7],
  [343597383680n, 2],
  [412316860416n, 1],
  [274877906944n, 0],
  [824633720832n, 1],
  [1374389534720n, 2],
  [2473901162496n, 3],
  [4672924418048n, 4],
  [9070970929152n, 5],
  [17867063951360n, 6],
  [35459249995776n, 3],
  [70643622084608n, 2],
  [141012366262272n, 1],
  [281749854617600n, 2],
  [563224831328256n, 3],
  [1126174784749568n, 4],
  [2252074691592192n, 5],
  [4503874505277440n, 6],
  [9007474132647936n, 7],
  [18014673387388928n, 4],
  [36029071896870912n, 3],
  [72057868915834880n, 2],
  [144115462953762816n, 3],
  [288230651029618688n, 4],
  [576461027181330432n, 5],
  [1152921779484753920n, 6],
  [2305843284091600896n, 7],
  [4611686293305294848n, 8],
  [9223372311732682752n, 5],
  [18446744348587458560n, 4],
  [36893488422297010176n, 3],
  [73786976569716113408n, 4],
  [147573952864554319872n, 5],
  [295147905454230732800n, 6],
  [590295810633583558656n, 7],
  [1180591620992289210368n, 8],
  [2361183241709700513792n, 9],
  [4722366483144523120640n, 6],
  [9444732966014168334336n, 5],
  [18889465931753458761728n, 4],
  [37778931863232039616512n, 5],
  [75557863726189201326080n, 6],
  [151115727452103524745216n, 7],
  [302231454903932171583488n, 8],
  [604462909807589465260032n, 9],
  [1208925819614904052613120n, 10],
  [549755813889n, 7],
  [549755813890n, 6],
  [549755813892n, 5],
  [549755813896n, 4],
  [549755813904n, 5],
  [549755813920n, 6],
  [549755813952n, 7],
  [549755814016n, 8],
  [549755814144n, 9],
  [549755814400n, 6],
  [549755814912n, 5],
  [549755815936n, 4],
  [549755817984n, 3],
  [549755822080n, 4],
  [549755830272n, 5],
  [549755846656n, 6],
  [549755879424n, 7],
  [549755944960n, 8],
  [549756076032n, 5],
  [549756338176n, 4],
  [549756862464n, 3],
  [549757911040n, 2],
  [549760008192n, 3],
  [549764202496n, 4],
  [549772591104n, 5],
  [549789368320n, 6],
  [549822922752n, 7],
  [549890031616n, 4],
  [550024249344n, 3],
  [550292684800n, 2],
  [550829555712n, 1],
  [551903297536n, 2],
  [554050781184n, 3],
  [558345748480n, 4],
  [566935683072n, 5],
  [584115552256n, 6],
  [618475290624n, 3],
  [687194767360n, 2],
  [824633720832n, 1],
  [549755813888n, 0],
  [1649267441664n, 1],
  [2748779069440n, 2],
  [4947802324992n, 3],
  [9345848836096n, 4],
  [18141941858304n, 5],
  [35734127902720n, 4],
  [70918499991552n, 3],
  [141287244169216n, 2],
  [282024732524544n, 1],
  [563499709235200n, 2],
  [1126449662656512n, 3],
  [2252349569499136n, 4],
  [4504149383184384n, 5],
  [9007749010554880n, 6],
  [18014948265295872n, 5],
  [36029346774777856n, 4],
  [72058143793741824n, 3],
  [144115737831669760n, 2],
  [288230925907525632n, 3],
  [576461302059237376n, 4],
  [1152922054362660864n, 5],
  [2305843558969507840n, 6],
  [4611686568183201792n, 7],
  [9223372586610589696n, 6],
  [18446744623465365504n, 5],
  [36893488697174917120n, 4],
  [73786976844594020352n, 3],
  [147573953139432226816n, 4],
  [295147905729108639744n, 5],
  [590295810908461465600n, 6],
  [1180591621267167117312n, 7],
  [2361183241984578420736n, 8],
  [4722366483419401027584n, 7],
  [9444732966289046241280n, 6],
  [18889465932028336668672n, 5],
  [37778931863506917523456n, 4],
  [75557863726464079233024n, 5],
  [151115727452378402652160n, 6],
  [302231454904207049490432n, 7],
  [604462909807864343166976n, 8],
  [1208925819615178930520064n, 9],
  [1099511627777n, 8],
  [1099511627778n, 7],
  [1099511627780n, 6],
  [1099511627784n, 5],
  [1099511627792n, 4],
  [1099511627808n, 5],
  [1099511627840n, 6],
  [1099511627904n, 7],
  [1099511628032n, 8],
  [1099511628288n, 7],
  [1099511628800n, 6],
  [1099511629824n, 5],
  [1099511631872n, 4],
  [1099511635968n, 3],
  [1099511644160n, 4],
  [1099511660544n, 5],
  [1099511693312n, 6],
  [1099511758848n, 7],
  [1099511889920n, 6],
  [1099512152064n, 5],
  [1099512676352n, 4],
  [1099513724928n, 3],
  [1099515822080n, 2],
  [1099520016384n, 3],
  [1099528404992n, 4],
  [1099545182208n, 5],
  [1099578736640n, 6],
  [1099645845504n, 5],
  [1099780063232n, 4],
  [1100048498688n, 3],
  [1100585369600n, 2],
  [1101659111424n, 1],
  [1103806595072n, 2],
  [1108101562368n, 3],
  [1116691496960n, 4],
  [1133871366144n, 5],
  [1168231104512n, 4],
  [1236950581248n, 3],
  [1374389534720n, 2],
  [1649267441664n, 1],
  [1099511627776n, 0],
  [3298534883328n, 1],
  [5497558138880n, 2],
  [9895604649984n, 3],
  [18691697672192n, 4],
  [36283883716608n, 5],
  [71468255805440n, 4],
  [141836999983104n, 3],
  [282574488338432n, 2],
  [564049465049088n, 1],
  [1126999418470400n, 2],
  [2252899325313024n, 3],
  [4504699138998272n, 4],
  [9008298766368768n, 5],
  [18015498021109760n, 6],
  [36029896530591744n, 5],
  [72058693549555712n, 4],
  [144116287587483648n, 3],
  [288231475663339520n, 2],
  [576461851815051264n, 3],
  [1152922604118474752n, 4],
  [2305844108725321728n, 5],
  [4611687117939015680n, 6],
  [9223373136366403584n, 7],
  [18446745173221179392n, 6],
  [36893489246930731008n, 5],
  [73786977394349834240n, 4],
  [147573953689188040704n, 3],
  [295147906278864453632n, 4],
  [590295811458217279488n, 5],
  [1180591621816922931200n, 6],
  [2361183242534334234624n, 7],
  [4722366483969156841472n, 8],
  [9444732966838802055168n, 7],
  [18889465932578092482560n, 6],
  [37778931864056673337344n, 5],
  [75557863727013835046912n, 4],
  [151115727452928158466048n, 5],
  [302231454904756805304320n, 6],
  [604462909808414098980864n, 7],
  [1208925819615728686333952n, 8],
  [2199023255553n, 9],
  [2199023255554n, 8],
  [2199023255556n, 7],
  [2199023255560n, 6],
  [2199023255568n, 5],
  [2199023255584n, 4],
  [2199023255616n, 5],
  [2199023255680n, 6],
  [2199023255808n, 7],
  [2199023256064n, 8],
  [2199023256576n, 7],
  [2199023257600n, 6],
  [2199023259648n, 5],
  [2199023263744n, 4],
  [2199023271936n, 3],
  [2199023288320n, 4],
  [2199023321088n, 5],
  [2199023386624n, 6],
  [2199023517696n, 7],
  [2199023779840n, 6],
  [2199024304128n, 5],
  [2199025352704n, 4],
  [2199027449856n, 3],
  [2199031644160n, 2],
  [2199040032768n, 3],
  [2199056809984n, 4],
  [2199090364416n, 5],
  [2199157473280n, 6],
  [2199291691008n, 5],
  [2199560126464n, 4],
  [2200096997376n, 3],
  [2201170739200n, 2],
  [2203318222848n, 1],
  [2207613190144n, 2],
  [2216203124736n, 3],
  [2233382993920n, 4],
  [2267742732288n, 5],
  [2336462209024n, 4],
  [2473901162496n, 3],
  [2748779069440n, 2],
  [3298534883328n, 1],
  [2199023255552n, 0],
  [6597069766656n, 1],
  [10995116277760n, 2],
  [19791209299968n, 3],
  [37383395344384n, 6],
  [72567767433216n, 5],
  [142936511610880n, 4],
  [283673999966208n, 3],
  [565148976676864n, 2],
  [1128098930098176n, 1],
  [2253998836940800n, 2],
  [4505798650626048n, 3],
  [9009398277996544n, 4],
  [18016597532737536n, 7],
  [36030996042219520n, 6],
  [72059793061183488n, 5],
  [144117387099111424n, 4],
  [288232575174967296n, 3],
  [576462951326679040n, 2],
  [1152923703630102528n, 3],
  [2305845208236949504n, 4],
  [4611688217450643456n, 5],
  [9223374235878031360n, 8],
  [18446746272732807168n, 7],
  [36893490346442358784n, 6],
  [73786978493861462016n, 5],
  [147573954788699668480n, 4],
  [295147907378376081408n, 3],
  [590295812557728907264n, 4],
  [1180591622916434558976n, 5],
  [2361183243633845862400n, 6],
  [4722366485068668469248n, 9],
  [9444732967938313682944n, 8],
  [18889465933677604110336n, 7],
  [37778931865156184965120n, 6],
  [75557863728113346674688n, 5],
  [151115727454027670093824n, 4],
  [302231454905856316932096n, 5],
  [604462909809513610608640n, 6],
  [1208925819616828197961728n, 7],
  [4398046511105n, 10],
  [4398046511106n, 9],
  [4398046511108n, 8],
  [4398046511112n, 7],
  [4398046511120n, 6],
  [4398046511136n, 5],
  [4398046511168n, 4],
  [4398046511232n, 5],
  [4398046511360n, 6],
  [4398046511616n, 9],
  [4398046512128n, 8],
  [4398046513152n, 7],
  [4398046515200n, 6],
  [4398046519296n, 5],
  [4398046527488n, 4],
  [4398046543872n, 3],
  [4398046576640n, 4],
  [4398046642176n, 5],
  [4398046773248n, 8],
  [4398047035392n, 7],
  [4398047559680n, 6],
  [4398048608256n, 5],
  [4398050705408n, 4],
  [4398054899712n, 3],
  [4398063288320n, 2],
  [4398080065536n, 3],
  [4398113619968n, 4],
  [4398180728832n, 7],
  [4398314946560n, 6],
  [4398583382016n, 5],
  [4399120252928n, 4],
  [4400193994752n, 3],
  [4402341478400n, 2],
  [4406636445696n, 1],
  [4415226380288n, 2],
  [4432406249472n, 3],
  [4466765987840n, 6],
  [4535485464576n, 5],
  [4672924418048n, 4],
  [4947802324992n, 3],
  [5497558138880n, 2],
  [6597069766656n, 1],
  [4398046511104n, 0],
  [13194139533312n, 1],
  [21990232555520n, 2],
  [39582418599936n, 7],
  [74766790688768n, 6],
  [145135534866432n, 5],
  [285873023221760n, 4],
  [567347999932416n, 3],
  [1130297953353728n, 2],
  [2256197860196352n, 1],
  [4507997673881600n, 2],
  [9011597301252096n, 3],
  [18018796555993088n, 8],
  [36033195065475072n, 7],
  [72061992084439040n, 6],
  [144119586122366976n, 5],
  [288234774198222848n, 4],
  [576465150349934592n, 3],
  [1152925902653358080n, 2],
  [2305847407260205056n, 3],
  [4611690416473899008n, 4],
  [9223376434901286912n, 9],
  [18446748471756062720n, 8],
  [36893492545465614336n, 7],
  [73786980692884717568n, 6],
  [147573956987722924032n, 5],
  [295147909577399336960n, 4],
  [590295814756752162816n, 3],
  [1180591625115457814528n, 4],
  [2361183245832869117952n, 5],
  [4722366487267691724800n, 10],
  [9444732970137336938496n, 9],
  [18889465935876627365888n, 8],
  [37778931867355208220672n, 7],
  [75557863730312369930240n, 6],
  [151115727456226693349376n, 5],
  [302231454908055340187648n, 4],
  [604462909811712633864192n, 5],
  [1208925819619027221217280n, 6],
  [8796093022209n, 11],
  [8796093022210n, 10],
  [8796093022212n, 9],
  [8796093022216n, 8],
  [8796093022224n, 7],
  [8796093022240n, 6],
  [8796093022272n, 5],
  [8796093022336n, 4],
  [8796093022464n, 5],
  [8796093022720n, 10],
  [8796093023232n, 9],
  [8796093024256n, 8],
  [8796093026304n, 7],
  [8796093030400n, 6],
  [8796093038592n, 5],
  [8796093054976n, 4],
  [8796093087744n, 3],
  [8796093153280n, 4],
  [8796093284352n, 9],
  [8796093546496n, 8],
  [8796094070784n, 7],
  [8796095119360n, 6],
  [8796097216512n, 5],
  [8796101410816n, 4],
  [8796109799424n, 3],
  [8796126576640n, 2],
  [8796160131072n, 3],
  [8796227239936n, 8],
  [8796361457664n, 7],
  [8796629893120n, 6],
  [8797166764032n, 5],
  [8798240505856n, 4],
  [8800387989504n, 3],
  [8804682956800n, 2],
  [8813272891392n, 1],
  [8830452760576n, 2],
  [8864812498944n, 7],
  [8933531975680n, 6],
  [9070970929152n, 5],
  [9345848836096n, 4],
  [9895604649984n, 3],
  [10995116277760n, 2],
  [13194139533312n, 1],
  [8796093022208n, 0],
  [26388279066624n, 1],
  [43980465111040n, 8],
  [79164837199872n, 7],
  [149533581377536n, 6],
  [290271069732864n, 5],
  [571746046443520n, 4],
  [1134695999864832n, 3],
  [2260595906707456n, 2],
  [4512395720392704n, 1],
  [9015995347763200n, 2],
  [18023194602504192n, 9],
  [36037593111986176n, 8],
  [72066390130950144n, 7],
  [144123984168878080n, 6],
  [288239172244733952n, 5],
  [576469548396445696n, 4],
  [1152930300699869184n, 3],
  [2305851805306716160n, 2],
  [4611694814520410112n, 3],
  [9223380832947798016n, 10],
  [18446752869802573824n, 9],
  [36893496943512125440n, 8],
  [73786985090931228672n, 7],
  [147573961385769435136n, 6],
  [295147913975445848064n, 5],
  [590295819154798673920n, 4],
  [1180591629513504325632n, 3],
  [2361183250230915629056n, 4],
  [4722366491665738235904n, 11],
  [9444732974535383449600n, 10],
  [18889465940274673876992n, 9],
  [37778931871753254731776n, 8],
  [75557863734710416441344n, 7],
  [151115727460624739860480n, 6],
  [302231454912453386698752n, 5],
  [604462909816110680375296n, 4],
  [1208925819623425267728384n, 5],
  [17592186044417n, 12],
  [17592186044418n, 11],
  [17592186044420n, 10],
  [17592186044424n, 9],
  [17592186044432n, 8],
  [17592186044448n, 7],
  [17592186044480n, 6],
  [17592186044544n, 5],
  [17592186044672n, 4],
  [17592186044928n, 11],
  [17592186045440n, 10],
  [17592186046464n, 9],
  [17592186048512n, 8],
  [17592186052608n, 7],
  [17592186060800n, 6],
  [17592186077184n, 5],
  [17592186109952n, 4],
  [17592186175488n, 3],
  [17592186306560n, 10],
  [17592186568704n, 9],
  [17592187092992n, 8],
  [17592188141568n, 7],
  [17592190238720n, 6],
  [17592194433024n, 5],
  [17592202821632n, 4],
  [17592219598848n, 3],
  [17592253153280n, 2],
  [17592320262144n, 9],
  [17592454479872n, 8],
  [17592722915328n, 7],
  [17593259786240n, 6],
  [17594333528064n, 5],
  [17596481011712n, 4],
  [17600775979008n, 3],
  [17609365913600n, 2],
  [17626545782784n, 1],
  [17660905521152n, 8],
  [17729624997888n, 7],
  [17867063951360n, 6],
  [18141941858304n, 5],
  [18691697672192n, 4],
  [19791209299968n, 3],
  [21990232555520n, 2],
  [26388279066624n, 1],
  [17592186044416n, 0],
  [52776558133248n, 9],
  [87960930222080n, 8],
  [158329674399744n, 7],
  [299067162755072n, 6],
  [580542139465728n, 5],
  [1143492092887040n, 4],
  [2269391999729664n, 3],
  [4521191813414912n, 2],
  [9024791440785408n, 1],
  [18031990695526400n, 10],
  [36046389205008384n, 9],
  [72075186223972352n, 8],
  [144132780261900288n, 7],
  [288247968337756160n, 6],
  [576478344489467904n, 5],
  [1152939096792891392n, 4],
  [2305860601399738368n, 3],
  [4611703610613432320n, 2],
  [9223389629040820224n, 11],
  [18446761665895596032n, 10],
  [36893505739605147648n, 9],
  [73786993887024250880n, 8],
  [147573970181862457344n, 7],
  [295147922771538870272n, 6],
  [590295827950891696128n, 5],
  [1180591638309597347840n, 4],
  [2361183259027008651264n, 3],
  [4722366500461831258112n, 12],
  [9444732983331476471808n, 11],
  [18889465949070766899200n, 10],
  [37778931880549347753984n, 9],
  [75557863743506509463552n, 8],
  [151115727469420832882688n, 7],
  [302231454921249479720960n, 6],
  [604462909824906773397504n, 5],
  [1208925819632221360750592n, 4],
  [35184372088833n, 5],
  [35184372088834n, 6],
  [35184372088836n, 7],
  [35184372088840n, 8],
  [35184372088848n, 9],
  [35184372088864n, 10],
  [35184372088896n, 11],
  [35184372088960n, 12],
  [35184372089088n, 13],
  [35184372089344n, 4],
  [35184372089856n, 5],
  [35184372090880n, 6],
  [35184372092928n, 7],
  [35184372097024n, 8],
  [35184372105216n, 9],
  [35184372121600n, 10],
  [35184372154368n, 11],
  [35184372219904n, 12],
  [35184372350976n, 3],
  [35184372613120n, 4],
  [35184373137408n, 5],
  [35184374185984n, 6],
  [35184376283136n, 7],
  [35184380477440n, 8],
  [35184388866048n, 9],
  [35184405643264n, 10],
  [35184439197696n, 11],
  [35184506306560n, 2],
  [35184640524288n, 3],
  [35184908959744n, 4],
  [35185445830656n, 5],
  [35186519572480n, 6],
  [35188667056128n, 7],
  [35192962023424n, 8],
  [35201551958016n, 9],
  [35218731827200n, 10],
  [35253091565568n, 1],
  [35321811042304n, 2],
  [35459249995776n, 3],
  [35734127902720n, 4],
  [36283883716608n, 5],
  [37383395344384n, 6],
  [39582418599936n, 7],
  [43980465111040n, 8],
  [52776558133248n, 9],
  [35184372088832n, 0],
  [105553116266496n, 1],
  [175921860444160n, 2],
  [316659348799488n, 3],
  [598134325510144n, 4],
  [1161084278931456n, 5],
  [2286984185774080n, 6],
  [4538783999459328n, 7],
  [9042383626829824n, 8],
  [18049582881570816n, 1],
  [36063981391052800n, 2],
  [72092778410016768n, 3],
  [144150372447944704n, 4],
  [288265560523800576n, 5],
  [576495936675512320n, 6],
  [1152956688978935808n, 7],
  [2305878193585782784n, 8],
  [4611721202799476736n, 9],
  [9223407221226864640n, 2],
  [18446779258081640448n, 3],
  [36893523331791192064n, 4],
  [73787011479210295296n, 5],
  [147573987774048501760n, 6],
  [295147940363724914688n, 7],
  [590295845543077740544n, 8],
  [1180591655901783392256n, 9],
  [2361183276619194695680n, 10],
  [4722366518054017302528n, 3],
  [9444733000923662516224n, 4],
  [18889465966662952943616n, 5],
  [37778931898141533798400n, 6],
  [75557863761098695507968n, 7],
  [151115727487013018927104n, 8],
  [302231454938841665765376n, 9],
  [604462909842498959441920n, 10],
  [1208925819649813546795008n, 11],
  [70368744177665n, 6],
  [70368744177666n, 5],
  [70368744177668n, 6],
  [70368744177672n, 7],
  [70368744177680n, 8],
  [70368744177696n, 9],
  [70368744177728n, 10],
  [70368744177792n, 11],
  [70368744177920n, 12],
  [70368744178176n, 5],
  [70368744178688n, 4],
  [70368744179712n, 5],
  [70368744181760n, 6],
  [70368744185856n, 7],
  [70368744194048n, 8],
  [70368744210432n, 9],
  [70368744243200n, 10],
  [70368744308736n, 11],
  [70368744439808n, 4],
  [70368744701952n, 3],
  [70368745226240n, 4],
  [70368746274816n, 5],
  [70368748371968n, 6],
  [70368752566272n, 7],
  [70368760954880n, 8],
  [70368777732096n, 9],
  [70368811286528n, 10],
  [70368878395392n, 3],
  [70369012613120n, 2],
  [70369281048576n, 3],
  [70369817919488n, 4],
  [70370891661312n, 5],
  [70373039144960n, 6],
  [70377334112256n, 7],
  [70385924046848n, 8],
  [70403103916032n, 9],
  [70437463654400n, 2],
  [70506183131136n, 1],
  [70643622084608n, 2],
  [70918499991552n, 3],
  [71468255805440n, 4],
  [72567767433216n, 5],
  [74766790688768n, 6],
  [79164837199872n, 7],
  [87960930222080n, 8],
  [105553116266496n, 1],
  [70368744177664n, 0],
  [211106232532992n, 1],
  [351843720888320n, 2],
  [633318697598976n, 3],
  [1196268651020288n, 4],
  [2322168557862912n, 5],
  [4573968371548160n, 6],
  [9077567998918656n, 7],
  [18084767253659648n, 2],
  [36099165763141632n, 1],
  [72127962782105600n, 2],
  [144185556820033536n, 3],
  [288300744895889408n, 4],
  [576531121047601152n, 5],
  [1152991873351024640n, 6],
  [2305913377957871616n, 7],
  [4611756387171565568n, 8],
  [9223442405598953472n, 3],
  [18446814442453729280n, 2],
  [36893558516163280896n, 3],
  [73787046663582384128n, 4],
  [147574022958420590592n, 5],
  [295147975548097003520n, 6],
  [590295880727449829376n, 7],
  [1180591691086155481088n, 8],
  [2361183311803566784512n, 9],
  [4722366553238389391360n, 4],
  [9444733036108034605056n, 3],
  [18889466001847325032448n, 4],
  [37778931933325905887232n, 5],
  [75557863796283067596800n, 6],
  [151115727522197391015936n, 7],
  [302231454974026037854208n, 8],
  [604462909877683331530752n, 9],
  [1208925819684997918883840n, 10],
  [140737488355329n, 7],
  [140737488355330n, 6],
  [140737488355332n, 5],
  [140737488355336n, 6],
  [140737488355344n, 7],
  [140737488355360n, 8],
  [140737488355392n, 9],
  [140737488355456n, 10],
  [140737488355584n, 11],
  [140737488355840n, 6],
  [140737488356352n, 5],
  [140737488357376n, 4],
  [140737488359424n, 5],
  [140737488363520n, 6],
  [140737488371712n, 7],
  [140737488388096n, 8],
  [140737488420864n, 9],
  [140737488486400n, 10],
  [140737488617472n, 5],
  [140737488879616n, 4],
  [140737489403904n, 3],
  [140737490452480n, 4],
  [140737492549632n, 5],
  [140737496743936n, 6],
  [140737505132544n, 7],
  [140737521909760n, 8],
  [140737555464192n, 9],
  [140737622573056n, 4],
  [140737756790784n, 3],
  [140738025226240n, 2],
  [140738562097152n, 3],
  [140739635838976n, 4],
  [140741783322624n, 5],
  [140746078289920n, 6],
  [140754668224512n, 7],
  [140771848093696n, 8],
  [140806207832064n, 3],
  [140874927308800n, 2],
  [141012366262272n, 1],
  [141287244169216n, 2],
  [141836999983104n, 3],
  [142936511610880n, 4],
  [145135534866432n, 5],
  [149533581377536n, 6],
  [158329674399744n, 7],
  [175921860444160n, 2],
  [211106232532992n, 1],
  [140737488355328n, 0],
  [422212465065984n, 1],
  [703687441776640n, 2],
  [1266637395197952n, 3],
  [2392537302040576n, 4],
  [4644337115725824n, 5],
  [9147936743096320n, 6],
  [18155135997837312n, 3],
  [36169534507319296n, 2],
  [72198331526283264n, 1],
  [144255925564211200n, 2],
  [288371113640067072n, 3],
  [576601489791778816n, 4],
  [1153062242095202304n, 5],
  [2305983746702049280n, 6],
  [4611826755915743232n, 7],
  [9223512774343131136n, 4],
  [18446884811197906944n, 3],
  [36893628884907458560n, 2],
  [73787117032326561792n, 3],
  [147574093327164768256n, 4],
  [295148045916841181184n, 5],
  [590295951096194007040n, 6],
  [1180591761454899658752n, 7],
  [2361183382172310962176n, 8],
  [4722366623607133569024n, 5],
  [9444733106476778782720n, 4],
  [18889466072216069210112n, 3],
  [37778932003694650064896n, 4],
  [75557863866651811774464n, 5],
  [151115727592566135193600n, 6],
  [302231455044394782031872n, 7],
  [604462909948052075708416n, 8],
  [1208925819755366663061504n, 9],
  [281474976710657n, 8],
  [281474976710658n, 7],
  [281474976710660n, 6],
  [281474976710664n, 5],
  [281474976710672n, 6],
  [281474976710688n, 7],
  [281474976710720n, 8],
  [281474976710784n, 9],
  [281474976710912n, 10],
  [281474976711168n, 7],
  [281474976711680n, 6],
  [281474976712704n, 5],
  [281474976714752n, 4],
  [281474976718848n, 5],
  [281474976727040n, 6],
  [281474976743424n, 7],
  [281474976776192n, 8],
  [281474976841728n, 9],
  [281474976972800n, 6],
  [281474977234944n, 5],
  [281474977759232n, 4],
  [281474978807808n, 3],
  [281474980904960n, 4],
  [281474985099264n, 5],
  [281474993487872n, 6],
  [281475010265088n, 7],
  [281475043819520n, 8],
  [281475110928384n, 5],
  [281475245146112n, 4],
  [281475513581568n, 3],
  [281476050452480n, 2],
  [281477124194304n, 3],
  [281479271677952n, 4],
  [281483566645248n, 5],
  [281492156579840n, 6],
  [281509336449024n, 7],
  [281543696187392n, 4],
  [281612415664128n, 3],
  [281749854617600n, 2],
  [282024732524544n, 1],
  [282574488338432n, 2],
  [283673999966208n, 3],
  [285873023221760n, 4],
  [290271069732864n, 5],
  [299067162755072n, 6],
  [316659348799488n, 3],
  [351843720888320n, 2],
  [422212465065984n, 1],
  [281474976710656n, 0],
  [844424930131968n, 1],
  [1407374883553280n, 2],
  [2533274790395904n, 3],
  [4785074604081152n, 4],
  [9288674231451648n, 5],
  [18295873486192640n, 4],
  [36310271995674624n, 3],
  [72339069014638592n, 2],
  [144396663052566528n, 1],
  [288511851128422400n, 2],
  [576742227280134144n, 3],
  [1153202979583557632n, 4],
  [2306124484190404608n, 5],
  [4611967493404098560n, 6],
  [9223653511831486464n, 5],
  [18447025548686262272n, 4],
  [36893769622395813888n, 3],
  [73787257769814917120n, 2],
  [147574234064653123584n, 3],
  [295148186654329536512n, 4],
  [590296091833682362368n, 5],
  [1180591902192388014080n, 6],
  [2361183522909799317504n, 7],
  [4722366764344621924352n, 6],
  [9444733247214267138048n, 5],
  [18889466212953557565440n, 4],
  [37778932144432138420224n, 3],
  [75557864007389300129792n, 4],
  [151115727733303623548928n, 5],
  [302231455185132270387200n, 6],
  [604462910088789564063744n, 7],
  [1208925819896104151416832n, 8],
  [562949953421313n, 9],
  [562949953421314n, 8],
  [562949953421316n, 7],
  [562949953421320n, 6],
  [562949953421328n, 5],
  [562949953421344n, 6],
  [562949953421376n, 7],
  [562949953421440n, 8],
  [562949953421568n, 9],
  [562949953421824n, 8],
  [562949953422336n, 7],
  [562949953423360n, 6],
  [562949953425408n, 5],
  [562949953429504n, 4],
  [562949953437696n, 5],
  [562949953454080n, 6],
  [562949953486848n, 7],
  [562949953552384n, 8],
  [562949953683456n, 7],
  [562949953945600n, 6],
  [562949954469888n, 5],
  [562949955518464n, 4],
  [562949957615616n, 3],
  [562949961809920n, 4],
  [562949970198528n, 5],
  [562949986975744n, 6],
  [562950020530176n, 7],
  [562950087639040n, 6],
  [562950221856768n, 5],
  [562950490292224n, 4],
  [562951027163136n, 3],
  [562952100904960n, 2],
  [562954248388608n, 3],
  [562958543355904n, 4],
  [562967133290496n, 5],
  [562984313159680n, 6],
  [563018672898048n, 5],
  [563087392374784n, 4],
  [563224831328256n, 3],
  [563499709235200n, 2],
  [564049465049088n, 1],
  [565148976676864n, 2],
  [567347999932416n, 3],
  [571746046443520n, 4],
  [580542139465728n, 5],
  [598134325510144n, 4],
  [633318697598976n, 3],
  [703687441776640n, 2],
  [844424930131968n, 1],
  [562949953421312n, 0],
  [1688849860263936n, 1],
  [2814749767106560n, 2],
  [5066549580791808n, 3],
  [9570149208162304n, 4],
  [18577348462903296n, 5],
  [36591746972385280n, 4],
  [72620543991349248n, 3],
  [144678138029277184n, 2],
  [288793326105133056n, 1],
  [577023702256844800n, 2],
  [1153484454560268288n, 3],
  [2306405959167115264n, 4],
  [4612248968380809216n, 5],
  [9223934986808197120n, 6],
  [18447307023662972928n, 5],
  [36894051097372524544n, 4],
  [73787539244791627776n, 3],
  [147574515539629834240n, 2],
  [295148468129306247168n, 3],
  [590296373308659073024n, 4],
  [1180592183667364724736n, 5],
  [2361183804384776028160n, 6],
  [4722367045819598635008n, 7],
  [9444733528689243848704n, 6],
  [18889466494428534276096n, 5],
  [37778932425907115130880n, 4],
  [75557864288864276840448n, 3],
  [151115728014778600259584n, 4],
  [302231455466607247097856n, 5],
  [604462910370264540774400n, 6],
  [1208925820177579128127488n, 7],
  [1125899906842625n, 10],
  [1125899906842626n, 9],
  [1125899906842628n, 8],
  [1125899906842632n, 7],
  [1125899906842640n, 6],
  [1125899906842656n, 5],
  [1125899906842688n, 6],
  [1125899906842752n, 7],
  [1125899906842880n, 8],
  [1125899906843136n, 9],
  [1125899906843648n, 8],
  [1125899906844672n, 7],
  [1125899906846720n, 6],
  [1125899906850816n, 5],
  [1125899906859008n, 4],
  [1125899906875392n, 5],
  [1125899906908160n, 6],
  [1125899906973696n, 7],
  [1125899907104768n, 8],
  [1125899907366912n, 7],
  [1125899907891200n, 6],
  [1125899908939776n, 5],
  [1125899911036928n, 4],
  [1125899915231232n, 3],
  [1125899923619840n, 4],
  [1125899940397056n, 5],
  [1125899973951488n, 6],
  [1125900041060352n, 7],
  [1125900175278080n, 6],
  [1125900443713536n, 5],
  [1125900980584448n, 4],
  [1125902054326272n, 3],
  [1125904201809920n, 2],
  [1125908496777216n, 3],
  [1125917086711808n, 4],
  [1125934266580992n, 5],
  [1125968626319360n, 6],
  [1126037345796096n, 5],
  [1126174784749568n, 4],
  [1126449662656512n, 3],
  [1126999418470400n, 2],
  [1128098930098176n, 1],
  [1130297953353728n, 2],
  [1134695999864832n, 3],
  [1143492092887040n, 4],
  [1161084278931456n, 5],
  [1196268651020288n, 4],
  [1266637395197952n, 3],
  [1407374883553280n, 2],
  [1688849860263936n, 1],
  [1125899906842624n, 0],
  [3377699720527872n, 1],
  [5629499534213120n, 2],
  [10133099161583616n, 3],
  [19140298416324608n, 6],
  [37154696925806592n, 5],
  [73183493944770560n, 4],
  [145241087982698496n, 3],
  [289356276058554368n, 2],
  [577586652210266112n, 1],
  [1154047404513689600n, 2],
  [2306968909120536576n, 3],
  [4612811918334230528n, 4],
  [9224497936761618432n, 7],
  [18447869973616394240n, 6],
  [36894614047325945856n, 5],
  [73788102194745049088n, 4],
  [147575078489583255552n, 3],
  [295149031079259668480n, 2],
  [590296936258612494336n, 3],
  [1180592746617318146048n, 4],
  [2361184367334729449472n, 5],
  [4722367608769552056320n, 8],
  [9444734091639197270016n, 7],
  [18889467057378487697408n, 6],
  [37778932988857068552192n, 5],
  [75557864851814230261760n, 4],
  [151115728577728553680896n, 3],
  [302231456029557200519168n, 4],
  [604462910933214494195712n, 5],
  [1208925820740529081548800n, 6],
  [2251799813685249n, 11],
  [2251799813685250n, 10],
  [2251799813685252n, 9],
  [2251799813685256n, 8],
  [2251799813685264n, 7],
  [2251799813685280n, 6],
  [2251799813685312n, 5],
  [2251799813685376n, 6],
  [2251799813685504n, 7],
  [2251799813685760n, 10],
  [2251799813686272n, 9],
  [2251799813687296n, 8],
  [2251799813689344n, 7],
  [2251799813693440n, 6],
  [2251799813701632n, 5],
  [2251799813718016n, 4],
  [2251799813750784n, 5],
  [2251799813816320n, 6],
  [2251799813947392n, 9],
  [2251799814209536n, 8],
  [2251799814733824n, 7],
  [2251799815782400n, 6],
  [2251799817879552n, 5],
  [2251799822073856n, 4],
  [2251799830462464n, 3],
  [2251799847239680n, 4],
  [2251799880794112n, 5],
  [2251799947902976n, 8],
  [2251800082120704n, 7],
  [2251800350556160n, 6],
  [2251800887427072n, 5],
  [2251801961168896n, 4],
  [2251804108652544n, 3],
  [2251808403619840n, 2],
  [2251816993554432n, 3],
  [2251834173423616n, 4],
  [2251868533161984n, 7],
  [2251937252638720n, 6],
  [2252074691592192n, 5],
  [2252349569499136n, 4],
  [2252899325313024n, 3],
  [2253998836940800n, 2],
  [2256197860196352n, 1],
  [2260595906707456n, 2],
  [2269391999729664n, 3],
  [2286984185774080n, 6],
  [2322168557862912n, 5],
  [2392537302040576n, 4],
  [2533274790395904n, 3],
  [2814749767106560n, 2],
  [3377699720527872n, 1],
  [2251799813685248n, 0],
  [6755399441055744n, 1],
  [11258999068426240n, 2],
  [20266198323167232n, 7],
  [38280596832649216n, 6],
  [74309393851613184n, 5],
  [146366987889541120n, 4],
  [290482175965396992n, 3],
  [578712552117108736n, 2],
  [1155173304420532224n, 1],
  [2308094809027379200n, 2],
  [4613937818241073152n, 3],
  [9225623836668461056n, 8],
  [18448995873523236864n, 7],
  [36895739947232788480n, 6],
  [73789228094651891712n, 5],
  [147576204389490098176n, 4],
  [295150156979166511104n, 3],
  [590298062158519336960n, 2],
  [1180593872517224988672n, 3],
  [2361185493234636292096n, 4],
  [4722368734669458898944n, 9],
  [9444735217539104112640n, 8],
  [18889468183278394540032n, 7],
  [37778934114756975394816n, 6],
  [75557865977714137104384n, 5],
  [151115729703628460523520n, 4],
  [302231457155457107361792n, 3],
  [604462912059114401038336n, 4],
  [1208925821866428988391424n, 5],
  [4503599627370497n, 12],
  [4503599627370498n, 11],
  [4503599627370500n, 10],
  [4503599627370504n, 9],
  [4503599627370512n, 8],
  [4503599627370528n, 7],
  [4503599627370560n, 6],
  [4503599627370624n, 5],
  [4503599627370752n, 6],
  [4503599627371008n, 11],
  [4503599627371520n, 10],
  [4503599627372544n, 9],
  [4503599627374592n, 8],
  [4503599627378688n, 7],
  [4503599627386880n, 6],
  [4503599627403264n, 5],
  [4503599627436032n, 4],
  [4503599627501568n, 5],
  [4503599627632640n, 10],
  [4503599627894784n, 9],
  [4503599628419072n, 8],
  [4503599629467648n, 7],
  [4503599631564800n, 6],
  [4503599635759104n, 5],
  [4503599644147712n, 4],
  [4503599660924928n, 3],
  [4503599694479360n, 4],
  [4503599761588224n, 9],
  [4503599895805952n, 8],
  [4503600164241408n, 7],
  [4503600701112320n, 6],
  [4503601774854144n, 5],
  [4503603922337792n, 4],
  [4503608217305088n, 3],
  [4503616807239680n, 2],
  [4503633987108864n, 3],
  [4503668346847232n, 8],
  [4503737066323968n, 7],
  [4503874505277440n, 6],
  [4504149383184384n, 5],
  [4504699138998272n, 4],
  [4505798650626048n, 3],
  [4507997673881600n, 2],
  [4512395720392704n, 1],
  [4521191813414912n, 2],
  [4538783999459328n, 7],
  [4573968371548160n, 6],
  [4644337115725824n, 5],
  [4785074604081152n, 4],
  [5066549580791808n, 3],
  [5629499534213120n, 2],
  [6755399441055744n, 1],
  [4503599627370496n, 0],
  [13510798882111488n, 1],
  [22517998136852480n, 8],
  [40532396646334464n, 7],
  [76561193665298432n, 6],
  [148618787703226368n, 5],
  [292733975779082240n, 4],
  [580964351930793984n, 3],
  [1157425104234217472n, 2],
  [2310346608841064448n, 1],
  [4616189618054758400n, 2],
  [9227875636482146304n, 9],
  [18451247673336922112n, 8],
  [36897991747046473728n, 7],
  [73791479894465576960n, 6],
  [147578456189303783424n, 5],
  [295152408778980196352n, 4],
  [590300313958333022208n, 3],
  [1180596124317038673920n, 2],
  [2361187745034449977344n, 3],
  [4722370986469272584192n, 10],
  [9444737469338917797888n, 9],
  [18889470435078208225280n, 8],
  [37778936366556789080064n, 7],
  [75557868229513950789632n, 6],
  [151115731955428274208768n, 5],
  [302231459407256921047040n, 4],
  [604462914310914214723584n, 3],
  [1208925824118228802076672n, 4],
  [9007199254740993n, 13],
  [9007199254740994n, 12],
  [9007199254740996n, 11],
  [9007199254741000n, 10],
  [9007199254741008n, 9],
  [9007199254741024n, 8],
  [9007199254741056n, 7],
  [9007199254741120n, 6],
  [9007199254741248n, 5],
  [9007199254741504n, 12],
  [9007199254742016n, 11],
  [9007199254743040n, 10],
  [9007199254745088n, 9],
  [9007199254749184n, 8],
  [9007199254757376n, 7],
  [9007199254773760n, 6],
  [9007199254806528n, 5],
  [9007199254872064n, 4],
  [9007199255003136n, 11],
  [9007199255265280n, 10],
  [9007199255789568n, 9],
  [9007199256838144n, 8],
  [9007199258935296n, 7],
  [9007199263129600n, 6],
  [9007199271518208n, 5],
  [9007199288295424n, 4],
  [9007199321849856n, 3],
  [9007199388958720n, 10],
  [9007199523176448n, 9],
  [9007199791611904n, 8],
  [9007200328482816n, 7],
  [9007201402224640n, 6],
  [9007203549708288n, 5],
  [9007207844675584n, 4],
  [9007216434610176n, 3],
  [9007233614479360n, 2],
  [9007267974217728n, 9],
  [9007336693694464n, 8],
  [9007474132647936n, 7],
  [9007749010554880n, 6],
  [9008298766368768n, 5],
  [9009398277996544n, 4],
  [9011597301252096n, 3],
  [9015995347763200n, 2],
  [9024791440785408n, 1],
  [9042383626829824n, 8],
  [9077567998918656n, 7],
  [9147936743096320n, 6],
  [9288674231451648n, 5],
  [9570149208162304n, 4],
  [10133099161583616n, 3],
  [11258999068426240n, 2],
  [13510798882111488n, 1],
  [9007199254740992n, 0],
  [27021597764222976n, 9],
  [45035996273704960n, 8],
  [81064793292668928n, 7],
  [153122387330596864n, 6],
  [297237575406452736n, 5],
  [585467951558164480n, 4],
  [1161928703861587968n, 3],
  [2314850208468434944n, 2],
  [4620693217682128896n, 1],
  [9232379236109516800n, 10],
  [18455751272964292608n, 9],
  [36902495346673844224n, 8],
  [73795983494092947456n, 7],
  [147582959788931153920n, 6],
  [295156912378607566848n, 5],
  [590304817557960392704n, 4],
  [1180600627916666044416n, 3],
  [2361192248634077347840n, 2],
  [4722375490068899954688n, 11],
  [9444741972938545168384n, 10],
  [18889474938677835595776n, 9],
  [37778940870156416450560n, 8],
  [75557872733113578160128n, 7],
  [151115736459027901579264n, 6],
  [302231463910856548417536n, 5],
  [604462918814513842094080n, 4],
  [1208925828621828429447168n, 3],
  [18014398509481985n, 6],
  [18014398509481986n, 7],
  [18014398509481988n, 8],
  [18014398509481992n, 9],
  [18014398509482000n, 10],
  [18014398509482016n, 11],
  [18014398509482048n, 12],
  [18014398509482112n, 13],
  [18014398509482240n, 14],
  [18014398509482496n, 5],
  [18014398509483008n, 6],
  [18014398509484032n, 7],
  [18014398509486080n, 8],
  [18014398509490176n, 9],
  [18014398509498368n, 10],
  [18014398509514752n, 11],
  [18014398509547520n, 12],
  [18014398509613056n, 13],
  [18014398509744128n, 4],
  [18014398510006272n, 5],
  [18014398510530560n, 6],
  [18014398511579136n, 7],
  [18014398513676288n, 8],
  [18014398517870592n, 9],
  [18014398526259200n, 10],
  [18014398543036416n, 11],
  [18014398576590848n, 12],
  [18014398643699712n, 3],
  [18014398777917440n, 4],
  [18014399046352896n, 5],
  [18014399583223808n, 6],
  [18014400656965632n, 7],
  [18014402804449280n, 8],
  [18014407099416576n, 9],
  [18014415689351168n, 10],
  [18014432869220352n, 11],
  [18014467228958720n, 2],
  [18014535948435456n, 3],
  [18014673387388928n, 4],
  [18014948265295872n, 5],
  [18015498021109760n, 6],
  [18016597532737536n, 7],
  [18018796555993088n, 8],
  [18023194602504192n, 9],
  [18031990695526400n, 10],
  [18049582881570816n, 1],
  [18084767253659648n, 2],
  [18155135997837312n, 3],
  [18295873486192640n, 4],
  [18577348462903296n, 5],
  [19140298416324608n, 6],
  [20266198323167232n, 7],
  [22517998136852480n, 8],
  [27021597764222976n, 9],
  [18014398509481984n, 0],
  [54043195528445952n, 1],
  [90071992547409920n, 2],
  [162129586585337856n, 3],
  [306244774661193728n, 4],
  [594475150812905472n, 5],
  [1170935903116328960n, 6],
  [2323857407723175936n, 7],
  [4629700416936869888n, 8],
  [9241386435364257792n, 1],
  [18464758472219033600n, 2],
  [36911502545928585216n, 3],
  [73804990693347688448n, 4],
  [147591966988185894912n, 5],
  [295165919577862307840n, 6],
  [590313824757215133696n, 7],
  [1180609635115920785408n, 8],
  [2361201255833332088832n, 9],
  [4722384497268154695680n, 2],
  [9444750980137799909376n, 3],
  [18889483945877090336768n, 4],
  [37778949877355671191552n, 5],
  [75557881740312832901120n, 6],
  [151115745466227156320256n, 7],
  [302231472918055803158528n, 8],
  [604462927821713096835072n, 9],
  [1208925837629027684188160n, 10],
  [36028797018963969n, 7],
  [36028797018963970n, 6],
  [36028797018963972n, 7],
  [36028797018963976n, 8],
  [36028797018963984n, 9],
  [36028797018964000n, 10],
  [36028797018964032n, 11],
  [36028797018964096n, 12],
  [36028797018964224n, 13],
  [36028797018964480n, 6],
  [36028797018964992n, 5],
  [36028797018966016n, 6],
  [36028797018968064n, 7],
  [36028797018972160n, 8],
  [36028797018980352n, 9],
  [36028797018996736n, 10],
  [36028797019029504n, 11],
  [36028797019095040n, 12],
  [36028797019226112n, 5],
  [36028797019488256n, 4],
  [36028797020012544n, 5],
  [36028797021061120n, 6],
  [36028797023158272n, 7],
  [36028797027352576n, 8],
  [36028797035741184n, 9],
  [36028797052518400n, 10],
  [36028797086072832n, 11],
  [36028797153181696n, 4],
  [36028797287399424n, 3],
  [36028797555834880n, 4],
  [36028798092705792n, 5],
  [36028799166447616n, 6],
  [36028801313931264n, 7],
  [36028805608898560n, 8],
  [36028814198833152n, 9],
  [36028831378702336n, 10],
  [36028865738440704n, 3],
  [36028934457917440n, 2],
  [36029071896870912n, 3],
  [36029346774777856n, 4],
  [36029896530591744n, 5],
  [36030996042219520n, 6],
  [36033195065475072n, 7],
  [36037593111986176n, 8],
  [36046389205008384n, 9],
  [36063981391052800n, 2],
  [36099165763141632n, 1],
  [36169534507319296n, 2],
  [36310271995674624n, 3],
  [36591746972385280n, 4],
  [37154696925806592n, 5],
  [38280596832649216n, 6],
  [40532396646334464n, 7],
  [45035996273704960n, 8],
  [54043195528445952n, 1],
  [36028797018963968n, 0],
  [108086391056891904n, 1],
  [180143985094819840n, 2],
  [324259173170675712n, 3],
  [612489549322387456n, 4],
  [1188950301625810944n, 5],
  [2341871806232657920n, 6],
  [4647714815446351872n, 7],
  [9259400833873739776n, 2],
  [18482772870728515584n, 1],
  [36929516944438067200n, 2],
  [73823005091857170432n, 3],
  [147609981386695376896n, 4],
  [295183933976371789824n, 5],
  [590331839155724615680n, 6],
  [1180627649514430267392n, 7],
  [2361219270231841570816n, 8],
  [4722402511666664177664n, 3],
  [9444768994536309391360n, 2],
  [18889501960275599818752n, 3],
  [37778967891754180673536n, 4],
  [75557899754711342383104n, 5],
  [151115763480625665802240n, 6],
  [302231490932454312640512n, 7],
  [604462945836111606317056n, 8],
  [1208925855643426193670144n, 9],
  [72057594037927937n, 8],
  [72057594037927938n, 7],
  [72057594037927940n, 6],
  [72057594037927944n, 7],
  [72057594037927952n, 8],
  [72057594037927968n, 9],
  [72057594037928000n, 10],
  [72057594037928064n, 11],
  [72057594037928192n, 12],
  [72057594037928448n, 7],
  [72057594037928960n, 6],
  [72057594037929984n, 5],
  [72057594037932032n, 6],
  [72057594037936128n, 7],
  [72057594037944320n, 8],
  [72057594037960704n, 9],
  [72057594037993472n, 10],
  [72057594038059008n, 11],
  [72057594038190080n, 6],
  [72057594038452224n, 5],
  [72057594038976512n, 4],
  [72057594040025088n, 5],
  [72057594042122240n, 6],
  [72057594046316544n, 7],
  [72057594054705152n, 8],
  [72057594071482368n, 9],
  [72057594105036800n, 10],
  [72057594172145664n, 5],
  [72057594306363392n, 4],
  [72057594574798848n, 3],
  [72057595111669760n, 4],
  [72057596185411584n, 5],
  [72057598332895232n, 6],
  [72057602627862528n, 7],
  [72057611217797120n, 8],
  [72057628397666304n, 9],
  [72057662757404672n, 4],
  [72057731476881408n, 3],
  [72057868915834880n, 2],
  [72058143793741824n, 3],
  [72058693549555712n, 4],
  [72059793061183488n, 5],
  [72061992084439040n, 6],
  [72066390130950144n, 7],
  [72075186223972352n, 8],
  [72092778410016768n, 3],
  [72127962782105600n, 2],
  [72198331526283264n, 1],
  [72339069014638592n, 2],
  [72620543991349248n, 3],
  [73183493944770560n, 4],
  [74309393851613184n, 5],
  [76561193665298432n, 6],
  [81064793292668928n, 7],
  [90071992547409920n, 2],
  [108086391056891904n, 1],
  [72057594037927936n, 0],
  [216172782113783808n, 1],
  [360287970189639680n, 2],
  [648518346341351424n, 3],
  [1224979098644774912n, 4],
  [2377900603251621888n, 5],
  [4683743612465315840n, 6],
  [9295429630892703744n, 3],
  [18518801667747479552n, 2],
  [36965545741457031168n, 1],
  [73859033888876134400n, 2],
  [147646010183714340864n, 3],
  [295219962773390753792n, 4],
  [590367867952743579648n, 5],
  [1180663678311449231360n, 6],
  [2361255299028860534784n, 7],
  [4722438540463683141632n, 4],
  [9444805023333328355328n, 3],
  [18889537989072618782720n, 2],
  [37779003920551199637504n, 3],
  [75557935783508361347072n, 4],
  [151115799509422684766208n, 5],
  [302231526961251331604480n, 6],
  [604462981864908625281024n, 7],
  [1208925891672223212634112n, 8],
  [144115188075855873n, 9],
  [144115188075855874n, 8],
  [144115188075855876n, 7],
  [144115188075855880n, 6],
  [144115188075855888n, 7],
  [144115188075855904n, 8],
  [144115188075855936n, 9],
  [144115188075856000n, 10],
  [144115188075856128n, 11],
  [144115188075856384n, 8],
  [144115188075856896n, 7],
  [144115188075857920n, 6],
  [144115188075859968n, 5],
  [144115188075864064n, 6],
  [144115188075872256n, 7],
  [144115188075888640n, 8],
  [144115188075921408n, 9],
  [144115188075986944n, 10],
  [144115188076118016n, 7],
  [144115188076380160n, 6],
  [144115188076904448n, 5],
  [144115188077953024n, 4],
  [144115188080050176n, 5],
  [144115188084244480n, 6],
  [144115188092633088n, 7],
  [144115188109410304n, 8],
  [144115188142964736n, 9],
  [144115188210073600n, 6],
  [144115188344291328n, 5],
  [144115188612726784n, 4],
  [144115189149597696n, 3],
  [144115190223339520n, 4],
  [144115192370823168n, 5],
  [144115196665790464n, 6],
  [144115205255725056n, 7],
  [144115222435594240n, 8],
  [144115256795332608n, 5],
  [144115325514809344n, 4],
  [144115462953762816n, 3],
  [144115737831669760n, 2],
  [144116287587483648n, 3],
  [144117387099111424n, 4],
  [144119586122366976n, 5],
  [144123984168878080n, 6],
  [144132780261900288n, 7],
  [144150372447944704n, 4],
  [144185556820033536n, 3],
  [144255925564211200n, 2],
  [144396663052566528n, 1],
  [144678138029277184n, 2],
  [145241087982698496n, 3],
  [146366987889541120n, 4],
  [148618787703226368n, 5],
  [153122387330596864n, 6],
  [162129586585337856n, 3],
  [180143985094819840n, 2],
  [216172782113783808n, 1],
  [144115188075855872n, 0],
  [432345564227567616n, 1],
  [720575940379279360n, 2],
  [1297036692682702848n, 3],
  [2449958197289549824n, 4],
  [4755801206503243776n, 5],
  [9367487224930631680n, 4],
  [18590859261785407488n, 3],
  [37037603335494959104n, 2],
  [73931091482914062336n, 1],
  [147718067777752268800n, 2],
  [295292020367428681728n, 3],
  [590439925546781507584n, 4],
  [1180735735905487159296n, 5],
  [2361327356622898462720n, 6],
  [4722510598057721069568n, 5],
  [9444877080927366283264n, 4],
  [18889610046666656710656n, 3],
  [37779075978145237565440n, 2],
  [75558007841102399275008n, 3],
  [151115871567016722694144n, 4],
  [302231599018845369532416n, 5],
  [604463053922502663208960n, 6],
  [1208925963729817250562048n, 7],
  [288230376151711745n, 10],
  [288230376151711746n, 9],
  [288230376151711748n, 8],
  [288230376151711752n, 7],
  [288230376151711760n, 6],
  [288230376151711776n, 7],
  [288230376151711808n, 8],
  [288230376151711872n, 9],
  [288230376151712000n, 10],
  [288230376151712256n, 9],
  [288230376151712768n, 8],
  [288230376151713792n, 7],
  [288230376151715840n, 6],
  [288230376151719936n, 5],
  [288230376151728128n, 6],
  [288230376151744512n, 7],
  [288230376151777280n, 8],
  [288230376151842816n, 9],
  [288230376151973888n, 8],
  [288230376152236032n, 7],
  [288230376152760320n, 6],
  [288230376153808896n, 5],
  [288230376155906048n, 4],
  [288230376160100352n, 5],
  [288230376168488960n, 6],
  [288230376185266176n, 7],
  [288230376218820608n, 8],
  [288230376285929472n, 7],
  [288230376420147200n, 6],
  [288230376688582656n, 5],
  [288230377225453568n, 4],
  [288230378299195392n, 3],
  [288230380446679040n, 4],
  [288230384741646336n, 5],
  [288230393331580928n, 6],
  [288230410511450112n, 7],
  [288230444871188480n, 6],
  [288230513590665216n, 5],
  [288230651029618688n, 4],
  [288230925907525632n, 3],
  [288231475663339520n, 2],
  [288232575174967296n, 3],
  [288234774198222848n, 4],
  [288239172244733952n, 5],
  [288247968337756160n, 6],
  [288265560523800576n, 5],
  [288300744895889408n, 4],
  [288371113640067072n, 3],
  [288511851128422400n, 2],
  [288793326105133056n, 1],
  [289356276058554368n, 2],
  [290482175965396992n, 3],
  [292733975779082240n, 4],
  [297237575406452736n, 5],
  [306244774661193728n, 4],
  [324259173170675712n, 3],
  [360287970189639680n, 2],
  [432345564227567616n, 1],
  [288230376151711744n, 0],
  [864691128455135232n, 1],
  [1441151880758558720n, 2],
  [2594073385365405696n, 3],
  [4899916394579099648n, 4],
  [9511602413006487552n, 5],
  [18734974449861263360n, 4],
  [37181718523570814976n, 3],
  [74075206670989918208n, 2],
  [147862182965828124672n, 1],
  [295436135555504537600n, 2],
  [590584040734857363456n, 3],
  [1180879851093563015168n, 4],
  [2361471471810974318592n, 5],
  [4722654713245796925440n, 6],
  [9445021196115442139136n, 5],
  [18889754161854732566528n, 4],
  [37779220093333313421312n, 3],
  [75558151956290475130880n, 2],
  [151116015682204798550016n, 3],
  [302231743134033445388288n, 4],
  [604463198037690739064832n, 5],
  [1208926107845005326417920n, 6],
  [576460752303423489n, 11],
  [576460752303423490n, 10],
  [576460752303423492n, 9],
  [576460752303423496n, 8],
  [576460752303423504n, 7],
  [576460752303423520n, 6],
  [576460752303423552n, 7],
  [576460752303423616n, 8],
  [576460752303423744n, 9],
  [576460752303424000n, 10],
  [576460752303424512n, 9],
  [576460752303425536n, 8],
  [576460752303427584n, 7],
  [576460752303431680n, 6],
  [576460752303439872n, 5],
  [576460752303456256n, 6],
  [576460752303489024n, 7],
  [576460752303554560n, 8],
  [576460752303685632n, 9],
  [576460752303947776n, 8],
  [576460752304472064n, 7],
  [576460752305520640n, 6],
  [576460752307617792n, 5],
  [576460752311812096n, 4],
  [576460752320200704n, 5],
  [576460752336977920n, 6],
  [576460752370532352n, 7],
  [576460752437641216n, 8],
  [576460752571858944n, 7],
  [576460752840294400n, 6],
  [576460753377165312n, 5],
  [576460754450907136n, 4],
  [576460756598390784n, 3],
  [576460760893358080n, 4],
  [576460769483292672n, 5],
  [576460786663161856n, 6],
  [576460821022900224n, 7],
  [576460889742376960n, 6],
  [576461027181330432n, 5],
  [576461302059237376n, 4],
  [576461851815051264n, 3],
  [576462951326679040n, 2],
  [576465150349934592n, 3],
  [576469548396445696n, 4],
  [576478344489467904n, 5],
  [576495936675512320n, 6],
  [576531121047601152n, 5],
  [576601489791778816n, 4],
  [576742227280134144n, 3],
  [577023702256844800n, 2],
  [577586652210266112n, 1],
  [578712552117108736n, 2],
  [580964351930793984n, 3],
  [585467951558164480n, 4],
  [594475150812905472n, 5],
  [612489549322387456n, 4],
  [648518346341351424n, 3],
  [720575940379279360n, 2],
  [864691128455135232n, 1],
  [576460752303423488n, 0],
  [1729382256910270464n, 1],
  [2882303761517117440n, 2],
  [5188146770730811392n, 3],
  [9799832789158199296n, 6],
  [19023204826012975104n, 5],
  [37469948899722526720n, 4],
  [74363437047141629952n, 3],
  [148150413341979836416n, 2],
  [295724365931656249344n, 1],
  [590872271111009075200n, 2],
  [1181168081469714726912n, 3],
  [2361759702187126030336n, 4],
  [4722942943621948637184n, 7],
  [9445309426491593850880n, 6],
  [18890042392230884278272n, 5],
  [37779508323709465133056n, 4],
  [75558440186666626842624n, 3],
  [151116303912580950261760n, 2],
  [302232031364409597100032n, 3],
  [604463486268066890776576n, 4],
  [1208926396075381478129664n, 5],
  [1152921504606846977n, 12],
  [1152921504606846978n, 11],
  [1152921504606846980n, 10],
  [1152921504606846984n, 9],
  [1152921504606846992n, 8],
  [1152921504606847008n, 7],
  [1152921504606847040n, 6],
  [1152921504606847104n, 7],
  [1152921504606847232n, 8],
  [1152921504606847488n, 11],
  [1152921504606848000n, 10],
  [1152921504606849024n, 9],
  [1152921504606851072n, 8],
  [1152921504606855168n, 7],
  [1152921504606863360n, 6],
  [1152921504606879744n, 5],
  [1152921504606912512n, 6],
  [1152921504606978048n, 7],
  [1152921504607109120n, 10],
  [1152921504607371264n, 9],
  [1152921504607895552n, 8],
  [1152921504608944128n, 7],
  [1152921504611041280n, 6],
  [1152921504615235584n, 5],
  [1152921504623624192n, 4],
  [1152921504640401408n, 5],
  [1152921504673955840n, 6],
  [1152921504741064704n, 9],
  [1152921504875282432n, 8],
  [1152921505143717888n, 7],
  [1152921505680588800n, 6],
  [1152921506754330624n, 5],
  [1152921508901814272n, 4],
  [1152921513196781568n, 3],
  [1152921521786716160n, 4],
  [1152921538966585344n, 5],
  [1152921573326323712n, 8],
  [1152921642045800448n, 7],
  [1152921779484753920n, 6],
  [1152922054362660864n, 5],
  [1152922604118474752n, 4],
  [1152923703630102528n, 3],
  [1152925902653358080n, 2],
  [1152930300699869184n, 3],
  [1152939096792891392n, 4],
  [1152956688978935808n, 7],
  [1152991873351024640n, 6],
  [1153062242095202304n, 5],
  [1153202979583557632n, 4],
  [1153484454560268288n, 3],
  [1154047404513689600n, 2],
  [1155173304420532224n, 1],
  [1157425104234217472n, 2],
  [1161928703861587968n, 3],
  [1170935903116328960n, 6],
  [1188950301625810944n, 5],
  [1224979098644774912n, 4],
  [1297036692682702848n, 3],
  [1441151880758558720n, 2],
  [1729382256910270464n, 1],
  [1152921504606846976n, 0],
  [3458764513820540928n, 1],
  [5764607523034234880n, 2],
  [10376293541461622784n, 7],
  [19599665578316398592n, 6],
  [38046409652025950208n, 5],
  [74939897799445053440n, 4],
  [148726874094283259904n, 3],
  [296300826683959672832n, 2],
  [591448731863312498688n, 1],
  [1181744542222018150400n, 2],
  [2362336162939429453824n, 3],
  [4723519404374252060672n, 8],
  [9445885887243897274368n, 7],
  [18890618852983187701760n, 6],
  [37780084784461768556544n, 5],
  [75559016647418930266112n, 4],
  [151116880373333253685248n, 3],
  [302232607825161900523520n, 2],
  [604464062728819194200064n, 3],
  [1208926972536133781553152n, 4],
  [2305843009213693953n, 13],
  [2305843009213693954n, 12],
  [2305843009213693956n, 11],
  [2305843009213693960n, 10],
  [2305843009213693968n, 9],
  [2305843009213693984n, 8],
  [2305843009213694016n, 7],
  [2305843009213694080n, 6],
  [2305843009213694208n, 7],
  [2305843009213694464n, 12],
  [2305843009213694976n, 11],
  [2305843009213696000n, 10],
  [2305843009213698048n, 9],
  [2305843009213702144n, 8],
  [2305843009213710336n, 7],
  [2305843009213726720n, 6],
  [2305843009213759488n, 5],
  [2305843009213825024n, 6],
  [2305843009213956096n, 11],
  [2305843009214218240n, 10],
  [2305843009214742528n, 9],
  [2305843009215791104n, 8],
  [2305843009217888256n, 7],
  [2305843009222082560n, 6],
  [2305843009230471168n, 5],
  [2305843009247248384n, 4],
  [2305843009280802816n, 5],
  [2305843009347911680n, 10],
  [2305843009482129408n, 9],
  [2305843009750564864n, 8],
  [2305843010287435776n, 7],
  [2305843011361177600n, 6],
  [2305843013508661248n, 5],
  [2305843017803628544n, 4],
  [2305843026393563136n, 3],
  [2305843043573432320n, 4],
  [2305843077933170688n, 9],
  [2305843146652647424n, 8],
  [2305843284091600896n, 7],
  [2305843558969507840n, 6],
  [2305844108725321728n, 5],
  [2305845208236949504n, 4],
  [2305847407260205056n, 3],
  [2305851805306716160n, 2],
  [2305860601399738368n, 3],
  [2305878193585782784n, 8],
  [2305913377957871616n, 7],
  [2305983746702049280n, 6],
  [2306124484190404608n, 5],
  [2306405959167115264n, 4],
  [2306968909120536576n, 3],
  [2308094809027379200n, 2],
  [2310346608841064448n, 1],
  [2314850208468434944n, 2],
  [2323857407723175936n, 7],
  [2341871806232657920n, 6],
  [2377900603251621888n, 5],
  [2449958197289549824n, 4],
  [2594073385365405696n, 3],
  [2882303761517117440n, 2],
  [3458764513820540928n, 1],
  [2305843009213693952n, 0],
  [6917529027641081856n, 1],
  [11529215046068469760n, 8],
  [20752587082923245568n, 7],
  [39199331156632797184n, 6],
  [76092819304051900416n, 5],
  [149879795598890106880n, 4],
  [297453748188566519808n, 3],
  [592601653367919345664n, 2],
  [1182897463726624997376n, 1],
  [2363489084444036300800n, 2],
  [4724672325878858907648n, 9],
  [9447038808748504121344n, 8],
  [18891771774487794548736n, 7],
  [37781237705966375403520n, 6],
  [75560169568923537113088n, 5],
  [151118033294837860532224n, 4],
  [302233760746666507370496n, 3],
  [604465215650323801047040n, 2],
  [1208928125457638388400128n, 3],
  [4611686018427387905n, 14],
  [4611686018427387906n, 13],
  [4611686018427387908n, 12],
  [4611686018427387912n, 11],
  [4611686018427387920n, 10],
  [4611686018427387936n, 9],
  [4611686018427387968n, 8],
  [4611686018427388032n, 7],
  [4611686018427388160n, 6],
  [4611686018427388416n, 13],
  [4611686018427388928n, 12],
  [4611686018427389952n, 11],
  [4611686018427392000n, 10],
  [4611686018427396096n, 9],
  [4611686018427404288n, 8],
  [4611686018427420672n, 7],
  [4611686018427453440n, 6],
  [4611686018427518976n, 5],
  [4611686018427650048n, 12],
  [4611686018427912192n, 11],
  [4611686018428436480n, 10],
  [4611686018429485056n, 9],
  [4611686018431582208n, 8],
  [4611686018435776512n, 7],
  [4611686018444165120n, 6],
  [4611686018460942336n, 5],
  [4611686018494496768n, 4],
  [4611686018561605632n, 11],
  [4611686018695823360n, 10],
  [4611686018964258816n, 9],
  [4611686019501129728n, 8],
  [4611686020574871552n, 7],
  [4611686022722355200n, 6],
  [4611686027017322496n, 5],
  [4611686035607257088n, 4],
  [4611686052787126272n, 3],
  [4611686087146864640n, 10],
  [4611686155866341376n, 9],
  [4611686293305294848n, 8],
  [4611686568183201792n, 7],
  [4611687117939015680n, 6],
  [4611688217450643456n, 5],
  [4611690416473899008n, 4],
  [4611694814520410112n, 3],
  [4611703610613432320n, 2],
  [4611721202799476736n, 9],
  [4611756387171565568n, 8],
  [4611826755915743232n, 7],
  [4611967493404098560n, 6],
  [4612248968380809216n, 5],
  [4612811918334230528n, 4],
  [4613937818241073152n, 3],
  [4616189618054758400n, 2],
  [4620693217682128896n, 1],
  [4629700416936869888n, 8],
  [4647714815446351872n, 7],
  [4683743612465315840n, 6],
  [4755801206503243776n, 5],
  [4899916394579099648n, 4],
  [5188146770730811392n, 3],
  [5764607523034234880n, 2],
  [6917529027641081856n, 1],
  [4611686018427387904n, 0],
  [13835058055282163712n, 9],
  [23058430092136939520n, 8],
  [41505174165846491136n, 7],
  [78398662313265594368n, 6],
  [152185638608103800832n, 5],
  [299759591197780213760n, 4],
  [594907496377133039616n, 3],
  [1185203306735838691328n, 2],
  [2365794927453249994752n, 1],
  [4726978168888072601600n, 10],
  [9449344651757717815296n, 9],
  [18894077617497008242688n, 8],
  [37783543548975589097472n, 7],
  [75562475411932750807040n, 6],
  [151120339137847074226176n, 5],
  [302236066589675721064448n, 4],
  [604467521493333014740992n, 3],
  [1208930431300647602094080n, 2],
  [9223372036854775809n, 7],
  [9223372036854775810n, 8],
  [9223372036854775812n, 9],
  [9223372036854775816n, 10],
  [9223372036854775824n, 11],
  [9223372036854775840n, 12],
  [9223372036854775872n, 13],
  [9223372036854775936n, 14],
  [9223372036854776064n, 15],
  [9223372036854776320n, 6],
  [9223372036854776832n, 7],
  [9223372036854777856n, 8],
  [9223372036854779904n, 9],
  [9223372036854784000n, 10],
  [9223372036854792192n, 11],
  [9223372036854808576n, 12],
  [9223372036854841344n, 13],
  [9223372036854906880n, 14],
  [9223372036855037952n, 5],
  [9223372036855300096n, 6],
  [9223372036855824384n, 7],
  [9223372036856872960n, 8],
  [9223372036858970112n, 9],
  [9223372036863164416n, 10],
  [9223372036871553024n, 11],
  [9223372036888330240n, 12],
  [9223372036921884672n, 13],
  [9223372036988993536n, 4],
  [9223372037123211264n, 5],
  [9223372037391646720n, 6],
  [9223372037928517632n, 7],
  [9223372039002259456n, 8],
  [9223372041149743104n, 9],
  [9223372045444710400n, 10],
  [9223372054034644992n, 11],
  [9223372071214514176n, 12],
  [9223372105574252544n, 3],
  [9223372174293729280n, 4],
  [9223372311732682752n, 5],
  [9223372586610589696n, 6],
  [9223373136366403584n, 7],
  [9223374235878031360n, 8],
  [9223376434901286912n, 9],
  [9223380832947798016n, 10],
  [9223389629040820224n, 11],
  [9223407221226864640n, 2],
  [9223442405598953472n, 3],
  [9223512774343131136n, 4],
  [9223653511831486464n, 5],
  [9223934986808197120n, 6],
  [9224497936761618432n, 7],
  [9225623836668461056n, 8],
  [9227875636482146304n, 9],
  [9232379236109516800n, 10],
  [9241386435364257792n, 1],
  [9259400833873739776n, 2],
  [9295429630892703744n, 3],
  [9367487224930631680n, 4],
  [9511602413006487552n, 5],
  [9799832789158199296n, 6],
  [10376293541461622784n, 7],
  [11529215046068469760n, 8],
  [13835058055282163712n, 9],
  [9223372036854775808n, 0],
  [27670116110564327424n, 1],
  [46116860184273879040n, 2],
  [83010348331692982272n, 3],
  [156797324626531188736n, 4],
  [304371277216207601664n, 5],
  [599519182395560427520n, 6],
  [1189814992754266079232n, 7],
  [2370406613471677382656n, 8],
  [4731589854906499989504n, 1],
  [9453956337776145203200n, 2],
  [18898689303515435630592n, 3],
  [37788155234994016485376n, 4],
  [75567087097951178194944n, 5],
  [151124950823865501614080n, 6],
  [302240678275694148452352n, 7],
  [604472133179351442128896n, 8],
  [1208935042986666029481984n, 9],
  [18446744073709551617n, 8],
  [18446744073709551618n, 7],
  [18446744073709551620n, 8],
  [18446744073709551624n, 9],
  [18446744073709551632n, 10],
  [18446744073709551648n, 11],
  [18446744073709551680n, 12],
  [18446744073709551744n, 13],
  [18446744073709551872n, 14],
  [18446744073709552128n, 7],
  [18446744073709552640n, 6],
  [18446744073709553664n, 7],
  [18446744073709555712n, 8],
  [18446744073709559808n, 9],
  [18446744073709568000n, 10],
  [18446744073709584384n, 11],
  [18446744073709617152n, 12],
  [18446744073709682688n, 13],
  [18446744073709813760n, 6],
  [18446744073710075904n, 5],
  [18446744073710600192n, 6],
  [18446744073711648768n, 7],
  [18446744073713745920n, 8],
  [18446744073717940224n, 9],
  [18446744073726328832n, 10],
  [18446744073743106048n, 11],
  [18446744073776660480n, 12],
  [18446744073843769344n, 5],
  [18446744073977987072n, 4],
  [18446744074246422528n, 5],
  [18446744074783293440n, 6],
  [18446744075857035264n, 7],
  [18446744078004518912n, 8],
  [18446744082299486208n, 9],
  [18446744090889420800n, 10],
  [18446744108069289984n, 11],
  [18446744142429028352n, 4],
  [18446744211148505088n, 3],
  [18446744348587458560n, 4],
  [18446744623465365504n, 5],
  [18446745173221179392n, 6],
  [18446746272732807168n, 7],
  [18446748471756062720n, 8],
  [18446752869802573824n, 9],
  [18446761665895596032n, 10],
  [18446779258081640448n, 3],
  [18446814442453729280n, 2],
  [18446884811197906944n, 3],
  [18447025548686262272n, 4],
  [18447307023662972928n, 5],
  [18447869973616394240n, 6],
  [18448995873523236864n, 7],
  [18451247673336922112n, 8],
  [18455751272964292608n, 9],
  [18464758472219033600n, 2],
  [18482772870728515584n, 1],
  [18518801667747479552n, 2],
  [18590859261785407488n, 3],
  [18734974449861263360n, 4],
  [19023204826012975104n, 5],
  [19599665578316398592n, 6],
  [20752587082923245568n, 7],
  [23058430092136939520n, 8],
  [27670116110564327424n, 1],
  [18446744073709551616n, 0],
  [55340232221128654848n, 1],
  [92233720368547758080n, 2],
  [166020696663385964544n, 3],
  [313594649253062377472n, 4],
  [608742554432415203328n, 5],
  [1199038364791120855040n, 6],
  [2379629985508532158464n, 7],
  [4740813226943354765312n, 2],
  [9463179709812999979008n, 1],
  [18907912675552290406400n, 2],
  [37797378607030871261184n, 3],
  [75576310469988032970752n, 4],
  [151134174195902356389888n, 5],
  [302249901647731003228160n, 6],
  [604481356551388296904704n, 7],
  [1208944266358702884257792n, 8],
  [36893488147419103233n, 9],
  [36893488147419103234n, 8],
  [36893488147419103236n, 7],
  [36893488147419103240n, 8],
  [36893488147419103248n, 9],
  [36893488147419103264n, 10],
  [36893488147419103296n, 11],
  [36893488147419103360n, 12],
  [36893488147419103488n, 13],
  [36893488147419103744n, 8],
  [36893488147419104256n, 7],
  [36893488147419105280n, 6],
  [36893488147419107328n, 7],
  [36893488147419111424n, 8],
  [36893488147419119616n, 9],
  [36893488147419136000n, 10],
  [36893488147419168768n, 11],
  [36893488147419234304n, 12],
  [36893488147419365376n, 7],
  [36893488147419627520n, 6],
  [36893488147420151808n, 5],
  [36893488147421200384n, 6],
  [36893488147423297536n, 7],
  [36893488147427491840n, 8],
  [36893488147435880448n, 9],
  [36893488147452657664n, 10],
  [36893488147486212096n, 11],
  [36893488147553320960n, 6],
  [36893488147687538688n, 5],
  [36893488147955974144n, 4],
  [36893488148492845056n, 5],
  [36893488149566586880n, 6],
  [36893488151714070528n, 7],
  [36893488156009037824n, 8],
  [36893488164598972416n, 9],
  [36893488181778841600n, 10],
  [36893488216138579968n, 5],
  [36893488284858056704n, 4],
  [36893488422297010176n, 3],
  [36893488697174917120n, 4],
  [36893489246930731008n, 5],
  [36893490346442358784n, 6],
  [36893492545465614336n, 7],
  [36893496943512125440n, 8],
  [36893505739605147648n, 9],
  [36893523331791192064n, 4],
  [36893558516163280896n, 3],
  [36893628884907458560n, 2],
  [36893769622395813888n, 3],
  [36894051097372524544n, 4],
  [36894614047325945856n, 5],
  [36895739947232788480n, 6],
  [36897991747046473728n, 7],
  [36902495346673844224n, 8],
  [36911502545928585216n, 3],
  [36929516944438067200n, 2],
  [36965545741457031168n, 1],
  [37037603335494959104n, 2],
  [37181718523570814976n, 3],
  [37469948899722526720n, 4],
  [38046409652025950208n, 5],
  [39199331156632797184n, 6],
  [41505174165846491136n, 7],
  [46116860184273879040n, 2],
  [55340232221128654848n, 1],
  [36893488147419103232n, 0],
  [110680464442257309696n, 1],
  [184467440737095516160n, 2],
  [332041393326771929088n, 3],
  [627189298506124754944n, 4],
  [1217485108864830406656n, 5],
  [2398076729582241710080n, 6],
  [4759259971017064316928n, 3],
  [9481626453886709530624n, 2],
  [18926359419625999958016n, 1],
  [37815825351104580812800n, 2],
  [75594757214061742522368n, 3],
  [151152620939976065941504n, 4],
  [302268348391804712779776n, 5],
  [604499803295462006456320n, 6],
  [1208962713102776593809408n, 7],
  [73786976294838206465n, 10],
  [73786976294838206466n, 9],
  [73786976294838206468n, 8],
  [73786976294838206472n, 7],
  [73786976294838206480n, 8],
  [73786976294838206496n, 9],
  [73786976294838206528n, 10],
  [73786976294838206592n, 11],
  [73786976294838206720n, 12],
  [73786976294838206976n, 9],
  [73786976294838207488n, 8],
  [73786976294838208512n, 7],
  [73786976294838210560n, 6],
  [73786976294838214656n, 7],
  [73786976294838222848n, 8],
  [73786976294838239232n, 9],
  [73786976294838272000n, 10],
  [73786976294838337536n, 11],
  [73786976294838468608n, 8],
  [73786976294838730752n, 7],
  [73786976294839255040n, 6],
  [73786976294840303616n, 5],
  [73786976294842400768n, 6],
  [73786976294846595072n, 7],
  [73786976294854983680n, 8],
  [73786976294871760896n, 9],
  [73786976294905315328n, 10],
  [73786976294972424192n, 7],
  [73786976295106641920n, 6],
  [73786976295375077376n, 5],
  [73786976295911948288n, 4],
  [73786976296985690112n, 5],
  [73786976299133173760n, 6],
  [73786976303428141056n, 7],
  [73786976312018075648n, 8],
  [73786976329197944832n, 9],
  [73786976363557683200n, 6],
  [73786976432277159936n, 5],
  [73786976569716113408n, 4],
  [73786976844594020352n, 3],
  [73786977394349834240n, 4],
  [73786978493861462016n, 5],
  [73786980692884717568n, 6],
  [73786985090931228672n, 7],
  [73786993887024250880n, 8],
  [73787011479210295296n, 5],
  [73787046663582384128n, 4],
  [73787117032326561792n, 3],
  [73787257769814917120n, 2],
  [73787539244791627776n, 3],
  [73788102194745049088n, 4],
  [73789228094651891712n, 5],
  [73791479894465576960n, 6],
  [73795983494092947456n, 7],
  [73804990693347688448n, 4],
  [73823005091857170432n, 3],
  [73859033888876134400n, 2],
  [73931091482914062336n, 1],
  [74075206670989918208n, 2],
  [74363437047141629952n, 3],
  [74939897799445053440n, 4],
  [76092819304051900416n, 5],
  [78398662313265594368n, 6],
  [83010348331692982272n, 3],
  [92233720368547758080n, 2],
  [110680464442257309696n, 1],
  [73786976294838206464n, 0],
  [221360928884514619392n, 1],
  [368934881474191032320n, 2],
  [664082786653543858176n, 3],
  [1254378597012249509888n, 4],
  [2434970217729660813312n, 5],
  [4796153459164483420160n, 4],
  [9518519942034128633856n, 3],
  [18963252907773419061248n, 2],
  [37852718839251999916032n, 1],
  [75631650702209161625600n, 2],
  [151189514428123485044736n, 3],
  [302305241879952131883008n, 4],
  [604536696783609425559552n, 5],
  [1208999606590924012912640n, 6],
  [147573952589676412929n, 11],
  [147573952589676412930n, 10],
  [147573952589676412932n, 9],
  [147573952589676412936n, 8],
  [147573952589676412944n, 7],
  [147573952589676412960n, 8],
  [147573952589676412992n, 9],
  [147573952589676413056n, 10],
  [147573952589676413184n, 11],
  [147573952589676413440n, 10],
  [147573952589676413952n, 9],
  [147573952589676414976n, 8],
  [147573952589676417024n, 7],
  [147573952589676421120n, 6],
  [147573952589676429312n, 7],
  [147573952589676445696n, 8],
  [147573952589676478464n, 9],
  [147573952589676544000n, 10],
  [147573952589676675072n, 9],
  [147573952589676937216n, 8],
  [147573952589677461504n, 7],
  [147573952589678510080n, 6],
  [147573952589680607232n, 5],
  [147573952589684801536n, 6],
  [147573952589693190144n, 7],
  [147573952589709967360n, 8],
  [147573952589743521792n, 9],
  [147573952589810630656n, 8],
  [147573952589944848384n, 7],
  [147573952590213283840n, 6],
  [147573952590750154752n, 5],
  [147573952591823896576n, 4],
  [147573952593971380224n, 5],
  [147573952598266347520n, 6],
  [147573952606856282112n, 7],
  [147573952624036151296n, 8],
  [147573952658395889664n, 7],
  [147573952727115366400n, 6],
  [147573952864554319872n, 5],
  [147573953139432226816n, 4],
  [147573953689188040704n, 3],
  [147573954788699668480n, 4],
  [147573956987722924032n, 5],
  [147573961385769435136n, 6],
  [147573970181862457344n, 7],
  [147573987774048501760n, 6],
  [147574022958420590592n, 5],
  [147574093327164768256n, 4],
  [147574234064653123584n, 3],
  [147574515539629834240n, 2],
  [147575078489583255552n, 3],
  [147576204389490098176n, 4],
  [147578456189303783424n, 5],
  [147582959788931153920n, 6],
  [147591966988185894912n, 5],
  [147609981386695376896n, 4],
  [147646010183714340864n, 3],
  [147718067777752268800n, 2],
  [147862182965828124672n, 1],
  [148150413341979836416n, 2],
  [148726874094283259904n, 3],
  [149879795598890106880n, 4],
  [152185638608103800832n, 5],
  [156797324626531188736n, 4],
  [166020696663385964544n, 3],
  [184467440737095516160n, 2],
  [221360928884514619392n, 1],
  [147573952589676412928n, 0],
  [442721857769029238784n, 1],
  [737869762948382064640n, 2],
  [1328165573307087716352n, 3],
  [2508757194024499019776n, 4],
  [4869940435459321626624n, 5],
  [9592306918328966840320n, 4],
  [19037039884068257267712n, 3],
  [37926505815546838122496n, 2],
  [75705437678503999832064n, 1],
  [151263301404418323251200n, 2],
  [302379028856246970089472n, 3],
  [604610483759904263766016n, 4],
  [1209073393567218851119104n, 5],
  [295147905179352825857n, 12],
  [295147905179352825858n, 11],
  [295147905179352825860n, 10],
  [295147905179352825864n, 9],
  [295147905179352825872n, 8],
  [295147905179352825888n, 7],
  [295147905179352825920n, 8],
  [295147905179352825984n, 9],
  [295147905179352826112n, 10],
  [295147905179352826368n, 11],
  [295147905179352826880n, 10],
  [295147905179352827904n, 9],
  [295147905179352829952n, 8],
  [295147905179352834048n, 7],
  [295147905179352842240n, 6],
  [295147905179352858624n, 7],
  [295147905179352891392n, 8],
  [295147905179352956928n, 9],
  [295147905179353088000n, 10],
  [295147905179353350144n, 9],
  [295147905179353874432n, 8],
  [295147905179354923008n, 7],
  [295147905179357020160n, 6],
  [295147905179361214464n, 5],
  [295147905179369603072n, 6],
  [295147905179386380288n, 7],
  [295147905179419934720n, 8],
  [295147905179487043584n, 9],
  [295147905179621261312n, 8],
  [295147905179889696768n, 7],
  [295147905180426567680n, 6],
  [295147905181500309504n, 5],
  [295147905183647793152n, 4],
  [295147905187942760448n, 5],
  [295147905196532695040n, 6],
  [295147905213712564224n, 7],
  [295147905248072302592n, 8],
  [295147905316791779328n, 7],
  [295147905454230732800n, 6],
  [295147905729108639744n, 5],
  [295147906278864453632n, 4],
  [295147907378376081408n, 3],
  [295147909577399336960n, 4],
  [295147913975445848064n, 5],
  [295147922771538870272n, 6],
  [295147940363724914688n, 7],
  [295147975548097003520n, 6],
  [295148045916841181184n, 5],
  [295148186654329536512n, 4],
  [295148468129306247168n, 3],
  [295149031079259668480n, 2],
  [295150156979166511104n, 3],
  [295152408778980196352n, 4],
  [295156912378607566848n, 5],
  [295165919577862307840n, 6],
  [295183933976371789824n, 5],
  [295219962773390753792n, 4],
  [295292020367428681728n, 3],
  [295436135555504537600n, 2],
  [295724365931656249344n, 1],
  [296300826683959672832n, 2],
  [297453748188566519808n, 3],
  [299759591197780213760n, 4],
  [304371277216207601664n, 5],
  [313594649253062377472n, 4],
  [332041393326771929088n, 3],
  [368934881474191032320n, 2],
  [442721857769029238784n, 1],
  [295147905179352825856n, 0],
  [885443715538058477568n, 1],
  [1475739525896764129280n, 2],
  [2656331146614175432704n, 3],
  [5017514388048998039552n, 6],
  [9739880870918643253248n, 5],
  [19184613836657933680640n, 4],
  [38074079768136514535424n, 3],
  [75853011631093676244992n, 2],
  [151410875357007999664128n, 1],
  [302526602808836646502400n, 2],
  [604758057712493940178944n, 3],
  [1209220967519808527532032n, 4],
  [590295810358705651713n, 13],
  [590295810358705651714n, 12],
  [590295810358705651716n, 11],
  [590295810358705651720n, 10],
  [590295810358705651728n, 9],
  [590295810358705651744n, 8],
  [590295810358705651776n, 7],
  [590295810358705651840n, 8],
  [590295810358705651968n, 9],
  [590295810358705652224n, 12],
  [590295810358705652736n, 11],
  [590295810358705653760n, 10],
  [590295810358705655808n, 9],
  [590295810358705659904n, 8],
  [590295810358705668096n, 7],
  [590295810358705684480n, 6],
  [590295810358705717248n, 7],
  [590295810358705782784n, 8],
  [590295810358705913856n, 11],
  [590295810358706176000n, 10],
  [590295810358706700288n, 9],
  [590295810358707748864n, 8],
  [590295810358709846016n, 7],
  [590295810358714040320n, 6],
  [590295810358722428928n, 5],
  [590295810358739206144n, 6],
  [590295810358772760576n, 7],
  [590295810358839869440n, 10],
  [590295810358974087168n, 9],
  [590295810359242522624n, 8],
  [590295810359779393536n, 7],
  [590295810360853135360n, 6],
  [590295810363000619008n, 5],
  [590295810367295586304n, 4],
  [590295810375885520896n, 5],
  [590295810393065390080n, 6],
  [590295810427425128448n, 9],
  [590295810496144605184n, 8],
  [590295810633583558656n, 7],
  [590295810908461465600n, 6],
  [590295811458217279488n, 5],
  [590295812557728907264n, 4],
  [590295814756752162816n, 3],
  [590295819154798673920n, 4],
  [590295827950891696128n, 5],
  [590295845543077740544n, 8],
  [590295880727449829376n, 7],
  [590295951096194007040n, 6],
  [590296091833682362368n, 5],
  [590296373308659073024n, 4],
  [590296936258612494336n, 3],
  [590298062158519336960n, 2],
  [590300313958333022208n, 3],
  [590304817557960392704n, 4],
  [590313824757215133696n, 7],
  [590331839155724615680n, 6],
  [590367867952743579648n, 5],
  [590439925546781507584n, 4],
  [590584040734857363456n, 3],
  [590872271111009075200n, 2],
  [591448731863312498688n, 1],
  [592601653367919345664n, 2],
  [594907496377133039616n, 3],
  [599519182395560427520n, 6],
  [608742554432415203328n, 5],
  [627189298506124754944n, 4],
  [664082786653543858176n, 3],
  [737869762948382064640n, 2],
  [885443715538058477568n, 1],
  [590295810358705651712n, 0],
  [1770887431076116955136n, 1],
  [2951479051793528258560n, 2],
  [5312662293228350865408n, 7],
  [10035028776097996079104n, 6],
  [19479761741837286506496n, 5],
  [38369227673315867361280n, 4],
  [76148159536273029070848n, 3],
  [151706023262187352489984n, 2],
  [302821750714015999328256n, 1],
  [605053205617673293004800n, 2],
  [1209516115424987880357888n, 3],
  [1180591620717411303425n, 14],
  [1180591620717411303426n, 13],
  [1180591620717411303428n, 12],
  [1180591620717411303432n, 11],
  [1180591620717411303440n, 10],
  [1180591620717411303456n, 9],
  [1180591620717411303488n, 8],
  [1180591620717411303552n, 7],
  [1180591620717411303680n, 8],
  [1180591620717411303936n, 13],
  [1180591620717411304448n, 12],
  [1180591620717411305472n, 11],
  [1180591620717411307520n, 10],
  [1180591620717411311616n, 9],
  [1180591620717411319808n, 8],
  [1180591620717411336192n, 7],
  [1180591620717411368960n, 6],
  [1180591620717411434496n, 7],
  [1180591620717411565568n, 12],
  [1180591620717411827712n, 11],
  [1180591620717412352000n, 10],
  [1180591620717413400576n, 9],
  [1180591620717415497728n, 8],
  [1180591620717419692032n, 7],
  [1180591620717428080640n, 6],
  [1180591620717444857856n, 5],
  [1180591620717478412288n, 6],
  [1180591620717545521152n, 11],
  [1180591620717679738880n, 10],
  [1180591620717948174336n, 9],
  [1180591620718485045248n, 8],
  [1180591620719558787072n, 7],
  [1180591620721706270720n, 6],
  [1180591620726001238016n, 5],
  [1180591620734591172608n, 4],
  [1180591620751771041792n, 5],
  [1180591620786130780160n, 10],
  [1180591620854850256896n, 9],
  [1180591620992289210368n, 8],
  [1180591621267167117312n, 7],
  [1180591621816922931200n, 6],
  [1180591622916434558976n, 5],
  [1180591625115457814528n, 4],
  [1180591629513504325632n, 3],
  [1180591638309597347840n, 4],
  [1180591655901783392256n, 9],
  [1180591691086155481088n, 8],
  [1180591761454899658752n, 7],
  [1180591902192388014080n, 6],
  [1180592183667364724736n, 5],
  [1180592746617318146048n, 4],
  [1180593872517224988672n, 3],
  [1180596124317038673920n, 2],
  [1180600627916666044416n, 3],
  [1180609635115920785408n, 8],
  [1180627649514430267392n, 7],
  [1180663678311449231360n, 6],
  [1180735735905487159296n, 5],
  [1180879851093563015168n, 4],
  [1181168081469714726912n, 3],
  [1181744542222018150400n, 2],
  [1182897463726624997376n, 1],
  [1185203306735838691328n, 2],
  [1189814992754266079232n, 7],
  [1199038364791120855040n, 6],
  [1217485108864830406656n, 5],
  [1254378597012249509888n, 4],
  [1328165573307087716352n, 3],
  [1475739525896764129280n, 2],
  [1770887431076116955136n, 1],
  [1180591620717411303424n, 0],
  [3541774862152233910272n, 1],
  [5902958103587056517120n, 8],
  [10625324586456701730816n, 7],
  [20070057552195992158208n, 6],
  [38959523483674573012992n, 5],
  [76738455346631734722560n, 4],
  [152296319072546058141696n, 3],
  [303412046524374704979968n, 2],
  [605643501428031998656512n, 1],
  [1210106411235346586009600n, 2],
  [2361183241434822606849n, 15],
  [2361183241434822606850n, 14],
  [2361183241434822606852n, 13],
  [2361183241434822606856n, 12],
  [2361183241434822606864n, 11],
  [2361183241434822606880n, 10],
  [2361183241434822606912n, 9],
  [2361183241434822606976n, 8],
  [2361183241434822607104n, 7],
  [2361183241434822607360n, 14],
  [2361183241434822607872n, 13],
  [2361183241434822608896n, 12],
  [2361183241434822610944n, 11],
  [2361183241434822615040n, 10],
  [2361183241434822623232n, 9],
  [2361183241434822639616n, 8],
  [2361183241434822672384n, 7],
  [2361183241434822737920n, 6],
  [2361183241434822868992n, 13],
  [2361183241434823131136n, 12],
  [2361183241434823655424n, 11],
  [2361183241434824704000n, 10],
  [2361183241434826801152n, 9],
  [2361183241434830995456n, 8],
  [2361183241434839384064n, 7],
  [2361183241434856161280n, 6],
  [2361183241434889715712n, 5],
  [2361183241434956824576n, 12],
  [2361183241435091042304n, 11],
  [2361183241435359477760n, 10],
  [2361183241435896348672n, 9],
  [2361183241436970090496n, 8],
  [2361183241439117574144n, 7],
  [2361183241443412541440n, 6],
  [2361183241452002476032n, 5],
  [2361183241469182345216n, 4],
  [2361183241503542083584n, 11],
  [2361183241572261560320n, 10],
  [2361183241709700513792n, 9],
  [2361183241984578420736n, 8],
  [2361183242534334234624n, 7],
  [2361183243633845862400n, 6],
  [2361183245832869117952n, 5],
  [2361183250230915629056n, 4],
  [2361183259027008651264n, 3],
  [2361183276619194695680n, 10],
  [2361183311803566784512n, 9],
  [2361183382172310962176n, 8],
  [2361183522909799317504n, 7],
  [2361183804384776028160n, 6],
  [2361184367334729449472n, 5],
  [2361185493234636292096n, 4],
  [2361187745034449977344n, 3],
  [2361192248634077347840n, 2],
  [2361201255833332088832n, 9],
  [2361219270231841570816n, 8],
  [2361255299028860534784n, 7],
  [2361327356622898462720n, 6],
  [2361471471810974318592n, 5],
  [2361759702187126030336n, 4],
  [2362336162939429453824n, 3],
  [2363489084444036300800n, 2],
  [2365794927453249994752n, 1],
  [2370406613471677382656n, 8],
  [2379629985508532158464n, 7],
  [2398076729582241710080n, 6],
  [2434970217729660813312n, 5],
  [2508757194024499019776n, 4],
  [2656331146614175432704n, 3],
  [2951479051793528258560n, 2],
  [3541774862152233910272n, 1],
  [2361183241434822606848n, 0],
  [7083549724304467820544n, 9],
  [11805916207174113034240n, 8],
  [21250649172913403461632n, 7],
  [40140115104391984316416n, 6],
  [77919046967349146025984n, 5],
  [153476910693263469445120n, 4],
  [304592638145092116283392n, 3],
  [606824093048749409959936n, 2],
  [1211287002856063997313024n, 1],
  [4722366482869645213697n, 8],
  [4722366482869645213698n, 9],
  [4722366482869645213700n, 10],
  [4722366482869645213704n, 11],
  [4722366482869645213712n, 12],
  [4722366482869645213728n, 13],
  [4722366482869645213760n, 14],
  [4722366482869645213824n, 15],
  [4722366482869645213952n, 16],
  [4722366482869645214208n, 7],
  [4722366482869645214720n, 8],
  [4722366482869645215744n, 9],
  [4722366482869645217792n, 10],
  [4722366482869645221888n, 11],
  [4722366482869645230080n, 12],
  [4722366482869645246464n, 13],
  [4722366482869645279232n, 14],
  [4722366482869645344768n, 15],
  [4722366482869645475840n, 6],
  [4722366482869645737984n, 7],
  [4722366482869646262272n, 8],
  [4722366482869647310848n, 9],
  [4722366482869649408000n, 10],
  [4722366482869653602304n, 11],
  [4722366482869661990912n, 12],
  [4722366482869678768128n, 13],
  [4722366482869712322560n, 14],
  [4722366482869779431424n, 5],
  [4722366482869913649152n, 6],
  [4722366482870182084608n, 7],
  [4722366482870718955520n, 8],
  [4722366482871792697344n, 9],
  [4722366482873940180992n, 10],
  [4722366482878235148288n, 11],
  [4722366482886825082880n, 12],
  [4722366482904004952064n, 13],
  [4722366482938364690432n, 4],
  [4722366483007084167168n, 5],
  [4722366483144523120640n, 6],
  [4722366483419401027584n, 7],
  [4722366483969156841472n, 8],
  [4722366485068668469248n, 9],
  [4722366487267691724800n, 10],
  [4722366491665738235904n, 11],
  [4722366500461831258112n, 12],
  [4722366518054017302528n, 3],
  [4722366553238389391360n, 4],
  [4722366623607133569024n, 5],
  [4722366764344621924352n, 6],
  [4722367045819598635008n, 7],
  [4722367608769552056320n, 8],
  [4722368734669458898944n, 9],
  [4722370986469272584192n, 10],
  [4722375490068899954688n, 11],
  [4722384497268154695680n, 2],
  [4722402511666664177664n, 3],
  [4722438540463683141632n, 4],
  [4722510598057721069568n, 5],
  [4722654713245796925440n, 6],
  [4722942943621948637184n, 7],
  [4723519404374252060672n, 8],
  [4724672325878858907648n, 9],
  [4726978168888072601600n, 10],
  [4731589854906499989504n, 1],
  [4740813226943354765312n, 2],
  [4759259971017064316928n, 3],
  [4796153459164483420160n, 4],
  [4869940435459321626624n, 5],
  [5017514388048998039552n, 6],
  [5312662293228350865408n, 7],
  [5902958103587056517120n, 8],
  [7083549724304467820544n, 9],
  [4722366482869645213696n, 0],
  [14167099448608935641088n, 1],
  [23611832414348226068480n, 2],
  [42501298345826806923264n, 3],
  [80280230208783968632832n, 4],
  [155838093934698292051968n, 5],
  [306953821386526938890240n, 6],
  [609185276290184232566784n, 7],
  [1213648186097498819919872n, 8],
  [9444732965739290427393n, 9],
  [9444732965739290427394n, 8],
  [9444732965739290427396n, 9],
  [9444732965739290427400n, 10],
  [9444732965739290427408n, 11],
  [9444732965739290427424n, 12],
  [9444732965739290427456n, 13],
  [9444732965739290427520n, 14],
  [9444732965739290427648n, 15],
  [9444732965739290427904n, 8],
  [9444732965739290428416n, 7],
  [9444732965739290429440n, 8],
  [9444732965739290431488n, 9],
  [9444732965739290435584n, 10],
  [9444732965739290443776n, 11],
  [9444732965739290460160n, 12],
  [9444732965739290492928n, 13],
  [9444732965739290558464n, 14],
  [9444732965739290689536n, 7],
  [9444732965739290951680n, 6],
  [9444732965739291475968n, 7],
  [9444732965739292524544n, 8],
  [9444732965739294621696n, 9],
  [9444732965739298816000n, 10],
  [9444732965739307204608n, 11],
  [9444732965739323981824n, 12],
  [9444732965739357536256n, 13],
  [9444732965739424645120n, 6],
  [9444732965739558862848n, 5],
  [9444732965739827298304n, 6],
  [9444732965740364169216n, 7],
  [9444732965741437911040n, 8],
  [9444732965743585394688n, 9],
  [9444732965747880361984n, 10],
  [9444732965756470296576n, 11],
  [9444732965773650165760n, 12],
  [9444732965808009904128n, 5],
  [9444732965876729380864n, 4],
  [9444732966014168334336n, 5],
  [9444732966289046241280n, 6],
  [9444732966838802055168n, 7],
  [9444732967938313682944n, 8],
  [9444732970137336938496n, 9],
  [9444732974535383449600n, 10],
  [9444732983331476471808n, 11],
  [9444733000923662516224n, 4],
  [9444733036108034605056n, 3],
  [9444733106476778782720n, 4],
  [9444733247214267138048n, 5],
  [9444733528689243848704n, 6],
  [9444734091639197270016n, 7],
  [9444735217539104112640n, 8],
  [9444737469338917797888n, 9],
  [9444741972938545168384n, 10],
  [9444750980137799909376n, 3],
  [9444768994536309391360n, 2],
  [9444805023333328355328n, 3],
  [9444877080927366283264n, 4],
  [9445021196115442139136n, 5],
  [9445309426491593850880n, 6],
  [9445885887243897274368n, 7],
  [9447038808748504121344n, 8],
  [9449344651757717815296n, 9],
  [9453956337776145203200n, 2],
  [9463179709812999979008n, 1],
  [9481626453886709530624n, 2],
  [9518519942034128633856n, 3],
  [9592306918328966840320n, 4],
  [9739880870918643253248n, 5],
  [10035028776097996079104n, 6],
  [10625324586456701730816n, 7],
  [11805916207174113034240n, 8],
  [14167099448608935641088n, 1],
  [9444732965739290427392n, 0],
  [28334198897217871282176n, 1],
  [47223664828696452136960n, 2],
  [85002596691653613846528n, 3],
  [160560460417567937265664n, 4],
  [311676187869396584103936n, 5],
  [613907642773053877780480n, 6],
  [1218370552580368465133568n, 7],
  [18889465931478580854785n, 10],
  [18889465931478580854786n, 9],
  [18889465931478580854788n, 8],
  [18889465931478580854792n, 9],
  [18889465931478580854800n, 10],
  [18889465931478580854816n, 11],
  [18889465931478580854848n, 12],
  [18889465931478580854912n, 13],
  [18889465931478580855040n, 14],
  [18889465931478580855296n, 9],
  [18889465931478580855808n, 8],
  [18889465931478580856832n, 7],
  [18889465931478580858880n, 8],
  [18889465931478580862976n, 9],
  [18889465931478580871168n, 10],
  [18889465931478580887552n, 11],
  [18889465931478580920320n, 12],
  [18889465931478580985856n, 13],
  [18889465931478581116928n, 8],
  [18889465931478581379072n, 7],
  [18889465931478581903360n, 6],
  [18889465931478582951936n, 7],
  [18889465931478585049088n, 8],
  [18889465931478589243392n, 9],
  [18889465931478597632000n, 10],
  [18889465931478614409216n, 11],
  [18889465931478647963648n, 12],
  [18889465931478715072512n, 7],
  [18889465931478849290240n, 6],
  [18889465931479117725696n, 5],
  [18889465931479654596608n, 6],
  [18889465931480728338432n, 7],
  [18889465931482875822080n, 8],
  [18889465931487170789376n, 9],
  [18889465931495760723968n, 10],
  [18889465931512940593152n, 11],
  [18889465931547300331520n, 6],
  [18889465931616019808256n, 5],
  [18889465931753458761728n, 4],
  [18889465932028336668672n, 5],
  [18889465932578092482560n, 6],
  [18889465933677604110336n, 7],
  [18889465935876627365888n, 8],
  [18889465940274673876992n, 9],
  [18889465949070766899200n, 10],
  [18889465966662952943616n, 5],
  [18889466001847325032448n, 4],
  [18889466072216069210112n, 3],
  [18889466212953557565440n, 4],
  [18889466494428534276096n, 5],
  [18889467057378487697408n, 6],
  [18889468183278394540032n, 7],
  [18889470435078208225280n, 8],
  [18889474938677835595776n, 9],
  [18889483945877090336768n, 4],
  [18889501960275599818752n, 3],
  [18889537989072618782720n, 2],
  [18889610046666656710656n, 3],
  [18889754161854732566528n, 4],
  [18890042392230884278272n, 5],
  [18890618852983187701760n, 6],
  [18891771774487794548736n, 7],
  [18894077617497008242688n, 8],
  [18898689303515435630592n, 3],
  [18907912675552290406400n, 2],
  [18926359419625999958016n, 1],
  [18963252907773419061248n, 2],
  [19037039884068257267712n, 3],
  [19184613836657933680640n, 4],
  [19479761741837286506496n, 5],
  [20070057552195992158208n, 6],
  [21250649172913403461632n, 7],
  [23611832414348226068480n, 2],
  [28334198897217871282176n, 1],
  [18889465931478580854784n, 0],
  [56668397794435742564352n, 1],
  [94447329657392904273920n, 2],
  [170005193383307227693056n, 3],
  [321120920835135874531328n, 4],
  [623352375738793168207872n, 5],
  [1227815285546107755560960n, 6],
  [37778931862957161709569n, 11],
  [37778931862957161709570n, 10],
  [37778931862957161709572n, 9],
  [37778931862957161709576n, 8],
  [37778931862957161709584n, 9],
  [37778931862957161709600n, 10],
  [37778931862957161709632n, 11],
  [37778931862957161709696n, 12],
  [37778931862957161709824n, 13],
  [37778931862957161710080n, 10],
  [37778931862957161710592n, 9],
  [37778931862957161711616n, 8],
  [37778931862957161713664n, 7],
  [37778931862957161717760n, 8],
  [37778931862957161725952n, 9],
  [37778931862957161742336n, 10],
  [37778931862957161775104n, 11],
  [37778931862957161840640n, 12],
  [37778931862957161971712n, 9],
  [37778931862957162233856n, 8],
  [37778931862957162758144n, 7],
  [37778931862957163806720n, 6],
  [37778931862957165903872n, 7],
  [37778931862957170098176n, 8],
  [37778931862957178486784n, 9],
  [37778931862957195264000n, 10],
  [37778931862957228818432n, 11],
  [37778931862957295927296n, 8],
  [37778931862957430145024n, 7],
  [37778931862957698580480n, 6],
  [37778931862958235451392n, 5],
  [37778931862959309193216n, 6],
  [37778931862961456676864n, 7],
  [37778931862965751644160n, 8],
  [37778931862974341578752n, 9],
  [37778931862991521447936n, 10],
  [37778931863025881186304n, 7],
  [37778931863094600663040n, 6],
  [37778931863232039616512n, 5],
  [37778931863506917523456n, 4],
  [37778931864056673337344n, 5],
  [37778931865156184965120n, 6],
  [37778931867355208220672n, 7],
  [37778931871753254731776n, 8],
  [37778931880549347753984n, 9],
  [37778931898141533798400n, 6],
  [37778931933325905887232n, 5],
  [37778932003694650064896n, 4],
  [37778932144432138420224n, 3],
  [37778932425907115130880n, 4],
  [37778932988857068552192n, 5],
  [37778934114756975394816n, 6],
  [37778936366556789080064n, 7],
  [37778940870156416450560n, 8],
  [37778949877355671191552n, 5],
  [37778967891754180673536n, 4],
  [37779003920551199637504n, 3],
  [37779075978145237565440n, 2],
  [37779220093333313421312n, 3],
  [37779508323709465133056n, 4],
  [37780084784461768556544n, 5],
  [37781237705966375403520n, 6],
  [37783543548975589097472n, 7],
  [37788155234994016485376n, 4],
  [37797378607030871261184n, 3],
  [37815825351104580812800n, 2],
  [37852718839251999916032n, 1],
  [37926505815546838122496n, 2],
  [38074079768136514535424n, 3],
  [38369227673315867361280n, 4],
  [38959523483674573012992n, 5],
  [40140115104391984316416n, 6],
  [42501298345826806923264n, 3],
  [47223664828696452136960n, 2],
  [56668397794435742564352n, 1],
  [37778931862957161709568n, 0],
  [113336795588871485128704n, 1],
  [188894659314785808547840n, 2],
  [340010386766614455386112n, 3],
  [642241841670271749062656n, 4],
  [1246704751477586336415744n, 5],
  [75557863725914323419137n, 12],
  [75557863725914323419138n, 11],
  [75557863725914323419140n, 10],
  [75557863725914323419144n, 9],
  [75557863725914323419152n, 8],
  [75557863725914323419168n, 9],
  [75557863725914323419200n, 10],
  [75557863725914323419264n, 11],
  [75557863725914323419392n, 12],
  [75557863725914323419648n, 11],
  [75557863725914323420160n, 10],
  [75557863725914323421184n, 9],
  [75557863725914323423232n, 8],
  [75557863725914323427328n, 7],
  [75557863725914323435520n, 8],
  [75557863725914323451904n, 9],
  [75557863725914323484672n, 10],
  [75557863725914323550208n, 11],
  [75557863725914323681280n, 10],
  [75557863725914323943424n, 9],
  [75557863725914324467712n, 8],
  [75557863725914325516288n, 7],
  [75557863725914327613440n, 6],
  [75557863725914331807744n, 7],
  [75557863725914340196352n, 8],
  [75557863725914356973568n, 9],
  [75557863725914390528000n, 10],
  [75557863725914457636864n, 9],
  [75557863725914591854592n, 8],
  [75557863725914860290048n, 7],
  [75557863725915397160960n, 6],
  [75557863725916470902784n, 5],
  [75557863725918618386432n, 6],
  [75557863725922913353728n, 7],
  [75557863725931503288320n, 8],
  [75557863725948683157504n, 9],
  [75557863725983042895872n, 8],
  [75557863726051762372608n, 7],
  [75557863726189201326080n, 6],
  [75557863726464079233024n, 5],
  [75557863727013835046912n, 4],
  [75557863728113346674688n, 5],
  [75557863730312369930240n, 6],
  [75557863734710416441344n, 7],
  [75557863743506509463552n, 8],
  [75557863761098695507968n, 7],
  [75557863796283067596800n, 6],
  [75557863866651811774464n, 5],
  [75557864007389300129792n, 4],
  [75557864288864276840448n, 3],
  [75557864851814230261760n, 4],
  [75557865977714137104384n, 5],
  [75557868229513950789632n, 6],
  [75557872733113578160128n, 7],
  [75557881740312832901120n, 6],
  [75557899754711342383104n, 5],
  [75557935783508361347072n, 4],
  [75558007841102399275008n, 3],
  [75558151956290475130880n, 2],
  [75558440186666626842624n, 3],
  [75559016647418930266112n, 4],
  [75560169568923537113088n, 5],
  [75562475411932750807040n, 6],
  [75567087097951178194944n, 5],
  [75576310469988032970752n, 4],
  [75594757214061742522368n, 3],
  [75631650702209161625600n, 2],
  [75705437678503999832064n, 1],
  [75853011631093676244992n, 2],
  [76148159536273029070848n, 3],
  [76738455346631734722560n, 4],
  [77919046967349146025984n, 5],
  [80280230208783968632832n, 4],
  [85002596691653613846528n, 3],
  [94447329657392904273920n, 2],
  [113336795588871485128704n, 1],
  [75557863725914323419136n, 0],
  [226673591177742970257408n, 1],
  [377789318629571617095680n, 2],
  [680020773533228910772224n, 3],
  [1284483683340543498125312n, 4],
  [151115727451828646838273n, 13],
  [151115727451828646838274n, 12],
  [151115727451828646838276n, 11],
  [151115727451828646838280n, 10],
  [151115727451828646838288n, 9],
  [151115727451828646838304n, 8],
  [151115727451828646838336n, 9],
  [151115727451828646838400n, 10],
  [151115727451828646838528n, 11],
  [151115727451828646838784n, 12],
  [151115727451828646839296n, 11],
  [151115727451828646840320n, 10],
  [151115727451828646842368n, 9],
  [151115727451828646846464n, 8],
  [151115727451828646854656n, 7],
  [151115727451828646871040n, 8],
  [151115727451828646903808n, 9],
  [151115727451828646969344n, 10],
  [151115727451828647100416n, 11],
  [151115727451828647362560n, 10],
  [151115727451828647886848n, 9],
  [151115727451828648935424n, 8],
  [151115727451828651032576n, 7],
  [151115727451828655226880n, 6],
  [151115727451828663615488n, 7],
  [151115727451828680392704n, 8],
  [151115727451828713947136n, 9],
  [151115727451828781056000n, 10],
  [151115727451828915273728n, 9],
  [151115727451829183709184n, 8],
  [151115727451829720580096n, 7],
  [151115727451830794321920n, 6],
  [151115727451832941805568n, 5],
  [151115727451837236772864n, 6],
  [151115727451845826707456n, 7],
  [151115727451863006576640n, 8],
  [151115727451897366315008n, 9],
  [151115727451966085791744n, 8],
  [151115727452103524745216n, 7],
  [151115727452378402652160n, 6],
  [151115727452928158466048n, 5],
  [151115727454027670093824n, 4],
  [151115727456226693349376n, 5],
  [151115727460624739860480n, 6],
  [151115727469420832882688n, 7],
  [151115727487013018927104n, 8],
  [151115727522197391015936n, 7],
  [151115727592566135193600n, 6],
  [151115727733303623548928n, 5],
  [151115728014778600259584n, 4],
  [151115728577728553680896n, 3],
  [151115729703628460523520n, 4],
  [151115731955428274208768n, 5],
  [151115736459027901579264n, 6],
  [151115745466227156320256n, 7],
  [151115763480625665802240n, 6],
  [151115799509422684766208n, 5],
  [151115871567016722694144n, 4],
  [151116015682204798550016n, 3],
  [151116303912580950261760n, 2],
  [151116880373333253685248n, 3],
  [151118033294837860532224n, 4],
  [151120339137847074226176n, 5],
  [151124950823865501614080n, 6],
  [151134174195902356389888n, 5],
  [151152620939976065941504n, 4],
  [151189514428123485044736n, 3],
  [151263301404418323251200n, 2],
  [151410875357007999664128n, 1],
  [151706023262187352489984n, 2],
  [152296319072546058141696n, 3],
  [153476910693263469445120n, 4],
  [155838093934698292051968n, 5],
  [160560460417567937265664n, 4],
  [170005193383307227693056n, 3],
  [188894659314785808547840n, 2],
  [226673591177742970257408n, 1],
  [151115727451828646838272n, 0],
  [453347182355485940514816n, 1],
  [755578637259143234191360n, 2],
  [1360041547066457821544448n, 3],
  [302231454903657293676545n, 14],
  [302231454903657293676546n, 13],
  [302231454903657293676548n, 12],
  [302231454903657293676552n, 11],
  [302231454903657293676560n, 10],
  [302231454903657293676576n, 9],
  [302231454903657293676608n, 8],
  [302231454903657293676672n, 9],
  [302231454903657293676800n, 10],
  [302231454903657293677056n, 13],
  [302231454903657293677568n, 12],
  [302231454903657293678592n, 11],
  [302231454903657293680640n, 10],
  [302231454903657293684736n, 9],
  [302231454903657293692928n, 8],
  [302231454903657293709312n, 7],
  [302231454903657293742080n, 8],
  [302231454903657293807616n, 9],
  [302231454903657293938688n, 12],
  [302231454903657294200832n, 11],
  [302231454903657294725120n, 10],
  [302231454903657295773696n, 9],
  [302231454903657297870848n, 8],
  [302231454903657302065152n, 7],
  [302231454903657310453760n, 6],
  [302231454903657327230976n, 7],
  [302231454903657360785408n, 8],
  [302231454903657427894272n, 11],
  [302231454903657562112000n, 10],
  [302231454903657830547456n, 9],
  [302231454903658367418368n, 8],
  [302231454903659441160192n, 7],
  [302231454903661588643840n, 6],
  [302231454903665883611136n, 5],
  [302231454903674473545728n, 6],
  [302231454903691653414912n, 7],
  [302231454903726013153280n, 10],
  [302231454903794732630016n, 9],
  [302231454903932171583488n, 8],
  [302231454904207049490432n, 7],
  [302231454904756805304320n, 6],
  [302231454905856316932096n, 5],
  [302231454908055340187648n, 4],
  [302231454912453386698752n, 5],
  [302231454921249479720960n, 6],
  [302231454938841665765376n, 9],
  [302231454974026037854208n, 8],
  [302231455044394782031872n, 7],
  [302231455185132270387200n, 6],
  [302231455466607247097856n, 5],
  [302231456029557200519168n, 4],
  [302231457155457107361792n, 3],
  [302231459407256921047040n, 4],
  [302231463910856548417536n, 5],
  [302231472918055803158528n, 8],
  [302231490932454312640512n, 7],
  [302231526961251331604480n, 6],
  [302231599018845369532416n, 5],
  [302231743134033445388288n, 4],
  [302232031364409597100032n, 3],
  [302232607825161900523520n, 2],
  [302233760746666507370496n, 3],
  [302236066589675721064448n, 4],
  [302240678275694148452352n, 7],
  [302249901647731003228160n, 6],
  [302268348391804712779776n, 5],
  [302305241879952131883008n, 4],
  [302379028856246970089472n, 3],
  [302526602808836646502400n, 2],
  [302821750714015999328256n, 1],
  [303412046524374704979968n, 2],
  [304592638145092116283392n, 3],
  [306953821386526938890240n, 6],
  [311676187869396584103936n, 5],
  [321120920835135874531328n, 4],
  [340010386766614455386112n, 3],
  [377789318629571617095680n, 2],
  [453347182355485940514816n, 1],
  [302231454903657293676544n, 0],
  [906694364710971881029632n, 1],
  [1511157274518286468382720n, 2],
  [604462909807314587353089n, 15],
  [604462909807314587353090n, 14],
  [604462909807314587353092n, 13],
  [604462909807314587353096n, 12],
  [604462909807314587353104n, 11],
  [604462909807314587353120n, 10],
  [604462909807314587353152n, 9],
  [604462909807314587353216n, 8],
  [604462909807314587353344n, 9],
  [604462909807314587353600n, 14],
  [604462909807314587354112n, 13],
  [604462909807314587355136n, 12],
  [604462909807314587357184n, 11],
  [604462909807314587361280n, 10],
  [604462909807314587369472n, 9],
  [604462909807314587385856n, 8],
  [604462909807314587418624n, 7],
  [604462909807314587484160n, 8],
  [604462909807314587615232n, 13],
  [604462909807314587877376n, 12],
  [604462909807314588401664n, 11],
  [604462909807314589450240n, 10],
  [604462909807314591547392n, 9],
  [604462909807314595741696n, 8],
  [604462909807314604130304n, 7],
  [604462909807314620907520n, 6],
  [604462909807314654461952n, 7],
  [604462909807314721570816n, 12],
  [604462909807314855788544n, 11],
  [604462909807315124224000n, 10],
  [604462909807315661094912n, 9],
  [604462909807316734836736n, 8],
  [604462909807318882320384n, 7],
  [604462909807323177287680n, 6],
  [604462909807331767222272n, 5],
  [604462909807348947091456n, 6],
  [604462909807383306829824n, 11],
  [604462909807452026306560n, 10],
  [604462909807589465260032n, 9],
  [604462909807864343166976n, 8],
  [604462909808414098980864n, 7],
  [604462909809513610608640n, 6],
  [604462909811712633864192n, 5],
  [604462909816110680375296n, 4],
  [604462909824906773397504n, 5],
  [604462909842498959441920n, 10],
  [604462909877683331530752n, 9],
  [604462909948052075708416n, 8],
  [604462910088789564063744n, 7],
  [604462910370264540774400n, 6],
  [604462910933214494195712n, 5],
  [604462912059114401038336n, 4],
  [604462914310914214723584n, 3],
  [604462918814513842094080n, 4],
  [604462927821713096835072n, 9],
  [604462945836111606317056n, 8],
  [604462981864908625281024n, 7],
  [604463053922502663208960n, 6],
  [604463198037690739064832n, 5],
  [604463486268066890776576n, 4],
  [604464062728819194200064n, 3],
  [604465215650323801047040n, 2],
  [604467521493333014740992n, 3],
  [604472133179351442128896n, 8],
  [604481356551388296904704n, 7],
  [604499803295462006456320n, 6],
  [604536696783609425559552n, 5],
  [604610483759904263766016n, 4],
  [604758057712493940178944n, 3],
  [605053205617673293004800n, 2],
  [605643501428031998656512n, 1],
  [606824093048749409959936n, 2],
  [609185276290184232566784n, 7],
  [613907642773053877780480n, 6],
  [623352375738793168207872n, 5],
  [642241841670271749062656n, 4],
  [680020773533228910772224n, 3],
  [755578637259143234191360n, 2],
  [906694364710971881029632n, 1],
  [604462909807314587353088n, 0],
  [1813388729421943762059264n, 1],
  [1208925819614629174706177n, 16],
  [1208925819614629174706178n, 15],
  [1208925819614629174706180n, 14],
  [1208925819614629174706184n, 13],
  [1208925819614629174706192n, 12],
  [1208925819614629174706208n, 11],
  [1208925819614629174706240n, 10],
  [1208925819614629174706304n, 9],
  [1208925819614629174706432n, 8],
  [1208925819614629174706688n, 15],
  [1208925819614629174707200n, 14],
  [1208925819614629174708224n, 13],
  [1208925819614629174710272n, 12],
  [1208925819614629174714368n, 11],
  [1208925819614629174722560n, 10],
  [1208925819614629174738944n, 9],
  [1208925819614629174771712n, 8],
  [1208925819614629174837248n, 7],
  [1208925819614629174968320n, 14],
  [1208925819614629175230464n, 13],
  [1208925819614629175754752n, 12],
  [1208925819614629176803328n, 11],
  [1208925819614629178900480n, 10],
  [1208925819614629183094784n, 9],
  [1208925819614629191483392n, 8],
  [1208925819614629208260608n, 7],
  [1208925819614629241815040n, 6],
  [1208925819614629308923904n, 13],
  [1208925819614629443141632n, 12],
  [1208925819614629711577088n, 11],
  [1208925819614630248448000n, 10],
  [1208925819614631322189824n, 9],
  [1208925819614633469673472n, 8],
  [1208925819614637764640768n, 7],
  [1208925819614646354575360n, 6],
  [1208925819614663534444544n, 5],
  [1208925819614697894182912n, 12],
  [1208925819614766613659648n, 11],
  [1208925819614904052613120n, 10],
  [1208925819615178930520064n, 9],
  [1208925819615728686333952n, 8],
  [1208925819616828197961728n, 7],
  [1208925819619027221217280n, 6],
  [1208925819623425267728384n, 5],
  [1208925819632221360750592n, 4],
  [1208925819649813546795008n, 11],
  [1208925819684997918883840n, 10],
  [1208925819755366663061504n, 9],
  [1208925819896104151416832n, 8],
  [1208925820177579128127488n, 7],
  [1208925820740529081548800n, 6],
  [1208925821866428988391424n, 5],
  [1208925824118228802076672n, 4],
  [1208925828621828429447168n, 3],
  [1208925837629027684188160n, 10],
  [1208925855643426193670144n, 9],
  [1208925891672223212634112n, 8],
  [1208925963729817250562048n, 7],
  [1208926107845005326417920n, 6],
  [1208926396075381478129664n, 5],
  [1208926972536133781553152n, 4],
  [1208928125457638388400128n, 3],
  [1208930431300647602094080n, 2],
  [1208935042986666029481984n, 9],
  [1208944266358702884257792n, 8],
  [1208962713102776593809408n, 7],
  [1208999606590924012912640n, 6],
  [1209073393567218851119104n, 5],
  [1209220967519808527532032n, 4],
  [1209516115424987880357888n, 3],
  [1210106411235346586009600n, 2],
  [1211287002856063997313024n, 1],
  [1213648186097498819919872n, 8],
  [1218370552580368465133568n, 7],
  [1227815285546107755560960n, 6],
  [1246704751477586336415744n, 5],
  [1284483683340543498125312n, 4],
  [1360041547066457821544448n, 3],
  [1511157274518286468382720n, 2],
  [1813388729421943762059264n, 1],
  [1208925819614629174706176n, 0],
]);

export const impure = {
  getRandomIndex(list) {
    return Math.floor(Math.random() * list.length);
  },
};

class Side {
  constructor(
    pawns, knights, towers, // bit boards
    pawnCount, knightCount, towerCount, // piece counts
    nearTo4, // pawn- and knight-based bit boards
    promotionPoints, dragonRadius, tightSpotCount, circleEvaluation, won, // tower-based evaluations
    dragonTag, promotionTag, // tower-based cache tags
  ) {
    // Bit boards:
    this.pawns = pawns;
    this.knights = knights;
    this.towers = towers;
    // Piece counts:
    if (pawnCount !== undefined) {
      this.pawnCount = pawnCount;
    } else {
      this.pawnCount = 0n;
      for (let bits = pawns; bits;) {
        const bit = bits & ~(bits - 1n);
        ++this.pawnCount;
        bits -= bit;
      }
    }
    if (knightCount !== undefined) {
      this.knightCount = knightCount;
    } else {
      this.knightCount = 0n;
      for (let bits = knights; bits;) {
        const bit = bits & ~(bits - 1n);
        ++this.knightCount;
        bits -= bit;
      }
    }
    if (towerCount !== undefined) {
      this.towerCount = towerCount;
    } else {
      this.towerCount = 0n;
      for (let bits = towers; bits;) {
        const bit = bits & ~(bits - 1n);
        ++this.towerCount;
        bits -= bit;
      }
    }
    this.population = this.pawnCount + this.knightCount;
    // Pawn- and knight-based bit boards:
    this.mobile = pawns | knights;
    this._nearTo4 = nearTo4;
    // Tower-based bit boards (and cache tags):
    if (promotionPoints !== undefined) {
      this.promotionPoints = promotionPoints;
    } else {
      const promotesFromEast = (towers >> 1n) & 1206560015662350056947455n;
      const promotesFromNorth = (towers >> 9n) & 4722366482869645213695n;
      const promotesFromWest = (towers << 1n) & 2413120031324700113894910n;
      const promotesFromSouth = (towers << 9n) & 2417851639229258349411840n;
      this.promotionPoints =
        promotesFromEast |
        promotesFromNorth |
        promotesFromWest |
        promotesFromSouth;
    }
    this.dragonRadius = dragonRadius;
    this.tightSpotCount = tightSpotCount;
    this.circleEvaluation = circleEvaluation;
    this._won = won;
    this.dragonTag = dragonTag;
    this.promotionTag = promotionTag;
    // Other derived bit boards:
    this.material = this.mobile | towers;
    const boostsFromEast = (this.material >> 1n) & 1206560015662350056947455n;
    this.boostsBy0 = 2417851639229258349412351n & ~boostsFromEast;
    const boostsFromNorth = (this.material >> 9n) & 4722366482869645213695n;
    let boostsNegation = ~boostsFromNorth;
    this.boostsBy2 = boostsFromEast & boostsFromNorth;
    this.boostsBy1 = boostsFromEast & boostsNegation;
    this.boostsBy1 |= this.boostsBy0 & boostsFromNorth;
    this.boostsBy0 &= boostsNegation;
    const boostsFromWest = (this.material << 1n) & 2413120031324700113894910n;
    boostsNegation = ~boostsFromWest;
    this.boostsBy3 = this.boostsBy2 & boostsFromWest;
    this.boostsBy2 &= boostsNegation;
    this.boostsBy2 |= this.boostsBy1 & boostsFromWest;
    this.boostsBy1 &= boostsNegation;
    this.boostsBy1 |= this.boostsBy0 & boostsFromWest;
    this.boostsBy0 &= boostsNegation;
    const boostsFromSouth = (this.material << 9n) & 2417851639229258349411840n;
    boostsNegation = ~boostsFromSouth;
    this.boostsBy4 = this.boostsBy3 & boostsFromSouth;
    this.boostsBy3 &= boostsNegation;
    this.boostsBy3 |= this.boostsBy2 & boostsFromSouth;
    this.boostsBy2 &= boostsNegation;
    this.boostsBy2 |= this.boostsBy1 & boostsFromSouth;
    this.boostsBy1 &= boostsNegation;
    this.boostsBy1 |= this.boostsBy0 & boostsFromSouth;
    this.boostsBy0 &= boostsNegation;
    // Evaluations:
    // (See the descriptions of --ai-pawn, --ai-knight, --ai-endgame-knight, --ai-tower, and --ai-endgame-tower in
    // README.md.)
    this.materialEvaluation =
      (this.population < 4n ?
        700 * Number(this.towerCount) - 1150 :
        125 * Number(this.towerCount)) +
      (this.towerCount < 2n ?
        350 * Number(this.knightCount) - 260 :
        220 * Number(this.knightCount)) +
      200 * Number(this.pawnCount);
    this._staticEvaluation = undefined;
  }

  withPawnsSetTo(pawns) {
    return new Side(
      pawns, this.knights, this.towers,
      undefined, this.knightCount, this.towerCount,
      undefined,
      this.promotionPoints, this.dragonRadius, this.tightSpotCount, this.circleEvaluation, this._won,
      this.dragonTag, this.promotionTag,
    );
  }

  withPawnsMovedTo(pawns) {
    return new Side(
      pawns, this.knights, this.towers,
      this.pawnCount, this.knightCount, this.towerCount,
      undefined,
      this.promotionPoints, this.dragonRadius, this.tightSpotCount, this.circleEvaluation, this._won,
      this.dragonTag, this.promotionTag,
    );
  }

  withKnightsSetTo(knights) {
    return new Side(
      this.pawns, knights, this.towers,
      this.pawnCount, undefined, this.towerCount,
      undefined,
      this.promotionPoints, this.dragonRadius, this.tightSpotCount, this.circleEvaluation, this._won,
      this.dragonTag, this.promotionTag,
    );
  }

  withKnightsMovedTo(knights) {
    return new Side(
      this.pawns, knights, this.towers,
      this.pawnCount, this.knightCount, this.towerCount,
      undefined,
      this.promotionPoints, this.dragonRadius, this.tightSpotCount, this.circleEvaluation, this._won,
      this.dragonTag, this.promotionTag,
    );
  }

  withTowersSetTo(towers) {
    return new Side(
      this.pawns, this.knights, towers,
      this.pawnCount, this.knightCount, undefined,
      this._nearTo4,
      undefined, undefined, undefined, undefined, undefined,
      undefined, undefined,
    );
  }

  withConstruction(towers) {
    return new Side(
      this.pawns, this.knights, towers,
      this.pawnCount, this.knightCount, this.towerCount + 1n,
      this._nearTo4,
      undefined, undefined, undefined, undefined, undefined,
      undefined, undefined,
    );
  }

  withPromotion(newPawns, newKnights) {
    return new Side(
      newPawns, newKnights, this.towers,
      this.pawnCount - 1n, this.knightCount + 1n, this.towerCount,
      this._nearTo4,
      this.promotionPoints, this.dragonRadius, this.tightSpotCount, this.circleEvaluation, this._won,
      this.dragonTag, this.promotionTag,
    );
  }

  filter(survivalMask) {
    const newPawns = this.pawns & survivalMask;
    const newKnights = this.knights & survivalMask;
    const newTowers = this.towers & survivalMask;
    const samePawns = newPawns === this.pawns;
    const sameKnights = newKnights === this.knights;
    const sameTowers = newTowers === this.towers;
    if (samePawns && sameKnights && sameTowers) {
      return this;
    }
    const pawnCount = samePawns ? this.pawnCount : undefined;
    const knightCount = sameKnights ? this.knightCount : undefined;
    const towerCount = sameTowers ? this.towerCount : undefined;
    const nearTo4 = samePawns && sameKnights ? this._nearTo4 : undefined;
    const promotionPoints = sameTowers ? this.promotionPoints : undefined;
    const dragonRadius = sameTowers ? this.dragonRadius : undefined;
    const tightSpotCount = sameTowers ? this.tightSpotCount : undefined;
    const circleEvaluation = sameTowers ? this.circleEvaluation : undefined;
    const won = sameTowers ? this._won : undefined;
    const dragonTag = sameTowers ? this.dragonTag : undefined;
    const promotionTag = sameTowers ? this.promotionTag : undefined;
    return new Side(
      newPawns, newKnights, newTowers,
      pawnCount, knightCount, towerCount,
      nearTo4,
      promotionPoints, dragonRadius, tightSpotCount, circleEvaluation, won,
      dragonTag, promotionTag,
    );
  }

  get openingSignature() {
    return (this.pawns | this.knights) |
      (this.towers << 81n);
  }

  get signature() {
    return this.pawns |
      (this.knights << 81n) |
      (this.towers << 162n);
  }

  get dragonSerialization() {
    return `${this.pawns}`;
  }

  get serialization() {
    return `${this.pawns}/${this.knights}/${this.towers}`;
  }

  getPieceType(x, y) {
    if ((((this.pawns) >> ((x) + (y) * 9n)) & 1n)) {
      return '♟';
    }
    if ((((this.knights) >> ((x) + (y) * 9n)) & 1n)) {
      return '♞';
    }
    if ((((this.towers) >> ((x) + (y) * 9n)) & 1n)) {
      return '♜';
    }
    return undefined;
  }

  get nearTo4() {
    if (this._nearTo4 === undefined) {
      let nearTo0 = 2417851639229258349412351n;
      let nearTo1 = 0n;
      let nearTo2 = 0n;
      let nearTo3 = 0n;
      let nearTo4 = 0n;
      for (let bits = this.mobile; bits;) {
        const bit = bits & ~(bits - 1n);
        const diamond = BITS_TO_DIAMONDS.get(bit);
        const diamondNegation = ~diamond;
        nearTo4 |= nearTo3 & diamond;
        nearTo3 &= diamondNegation;
        nearTo3 |= nearTo2 & diamond;
        nearTo2 &= diamondNegation;
        nearTo2 |= nearTo1 & diamond;
        nearTo1 &= diamondNegation;
        nearTo1 |= nearTo0 & diamond;
        nearTo0 &= diamondNegation;
        bits -= bit;
      }
      this._nearTo4 = nearTo4;
    }
    return this._nearTo4;
  }

  get hasSufficientMaterial() {
    return this.population > 0n && (this.population >= 4n || this.towerCount > 0n);
  }

  get staticEvaluation() {
    if (this._staticEvaluation === undefined) {
      if (this.hasSufficientMaterial) {
        // (See the description of --ai-crowd-member in README.md.)
        let lonelyCount = 0n;
        for (let bits = this.material & ~this.nearTo4; bits;) {
          const bit = bits & ~(bits - 1n);
          ++lonelyCount;
          bits -= bit;
        }
        this._staticEvaluation = this.materialEvaluation - 1 * Number(lonelyCount);
      } else {
        this._staticEvaluation = -1000000;
      }
    }
    return this._staticEvaluation;
  }

  getKnightActivityEvaluation(all) {
    // (See the descriptions of --ai-knight-activity, --ai-zero-activity-distance, and --ai-edge-knight-penalty in
    // README.md.)
    const otherMaterial = all.material & ~this.material;
    let activity = 0;
    let edgeKnightCount = 0;
    for (let knightBits = this.knights; knightBits;) {
      const knightBit = knightBits & ~(knightBits - 1n);
      let radius = 16;
      for (let otherBits = otherMaterial; otherBits;) {
        const otherBit = otherBits & ~(otherBits - 1n);
        const distance = BIT_PAIRS_TO_DISTANCES.get(knightBit | otherBit);
        if (distance < radius) {
          radius = distance;
        }
        otherBits -= otherBit;
      }
      activity += 4 - radius;
      if ((knightBit & 2347321108901937150976n) === 0n) {
        ++edgeKnightCount;
      }
      knightBits -= knightBit;
    }
    return 5 * activity - 30 * edgeKnightCount;
  }

  getConstructionEvaluation(all) {
    // (See the description of --ai-construction-site in README.md.)
    let result = 0;
    let unplacedSiteCount = Math.min(Number(this.population), 2) - Number(this.towerCount);
    if (unplacedSiteCount > 0) {
      const sites = 2347321108901937150976n & this.nearTo4 & ~all.material & ~all.promotionPoints;
      let siteCount = 0n;
      for (let bits = sites & this.boostsBy4; bits;) {
        const bit = bits & ~(bits - 1n);
        ++siteCount;
        bits -= bit;
      }
      let castSiteCount = Math.min(Number(siteCount), unplacedSiteCount);
      result = castSiteCount;
      unplacedSiteCount -= castSiteCount;
      if (unplacedSiteCount > 0) {
        siteCount = 0n;
        for (let bits = sites & this.boostsBy3; bits;) {
          const bit = bits & ~(bits - 1n);
          ++siteCount;
          bits -= bit;
        }
        castSiteCount = Math.min(Number(siteCount), unplacedSiteCount);
        result += 0.5625 * castSiteCount;
        unplacedSiteCount -= castSiteCount;
        if (unplacedSiteCount > 0) {
          siteCount = 0n;
          for (let bits = sites & this.boostsBy2; bits;) {
            const bit = bits & ~(bits - 1n);
            ++siteCount;
            bits -= bit;
          }
          castSiteCount = Math.min(Number(siteCount), unplacedSiteCount);
          result += 0.25 * castSiteCount;
          unplacedSiteCount -= castSiteCount;
          if (unplacedSiteCount > 0) {
            siteCount = 0n;
            for (let bits = sites & this.boostsBy1; bits;) {
              const bit = bits & ~(bits - 1n);
              ++siteCount;
              bits -= bit;
            }
            castSiteCount = Math.min(Number(siteCount), unplacedSiteCount);
            result += 0.0625 * castSiteCount;
            unplacedSiteCount -= castSiteCount;
          }
        }
      }
    }
    return 80 * result;
  }

  _recomputeDragonRadius(dragons, all) {
    // (See the description of --ai-dragon-proximity in README.md.)
    this.dragonRadius = 16;
    for (let towerBits = this.towers & 2347321108901937150976n & ~all.promotionPoints; towerBits;) {
      const towerBit = towerBits & ~(towerBits - 1n);
      let radius1 = 16;
      let radius2 = 16;
      let radius3 = 16;
      let radius4 = 16;
      for (let dragonBits = dragons.pawns; dragonBits;) {
        const dragonBit = dragonBits & ~(dragonBits - 1n);
        const distance = BIT_PAIRS_TO_DISTANCES.get(towerBit | dragonBit);
        if (distance < radius1) {
          radius4 = radius3;
          radius3 = radius2;
          radius2 = radius1;
          radius1 = distance;
        } else if (distance < radius2) {
          radius4 = radius3;
          radius3 = radius2;
          radius2 = distance;
        } else if (distance < radius3) {
          radius4 = radius3;
          radius3 = distance;
        } else if (distance < radius4) {
          radius4 = distance;
        }
        dragonBits -= dragonBit;
      }
      this.dragonRadius = Math.min(this.dragonRadius, radius4);
      towerBits -= towerBit;
    }
  }

  _recomputeTightSpots(dragons) {
    const tightSpots =
      dragons.pawns &
      (((this.towers) >> 10n) |
       ((this.towers) >> 8n) |
       ((this.towers) << 10n) |
       ((this.towers) << 8n)) &
      -2347321108901937150977n;
    this.tightSpotCount = 0;
    for (let bits = tightSpots; bits;) {
      const bit = bits & ~(bits - 1n);
      ++this.tightSpotCount;
      bits -= bit;
    }
  }

  _recomputeCircleProgress(dragons, all) {
    // (See the description of --ai-circle-dragon and --ai-victory in README.md.)
    this._won = false;
    const viableTowers = this.towers & 2347321108901937150976n & dragons.nearTo4 & ~all.promotionPoints;
    if (viableTowers & dragons.boostsBy4) {
      this._won = true;
      this.circleEvaluation = 10000000;
    } else if (viableTowers & dragons.boostsBy3) {
      this.circleEvaluation = 300;
    } else if (viableTowers & dragons.boostsBy2) {
      this.circleEvaluation = 200;
    } else if (viableTowers & dragons.boostsBy1) {
      this.circleEvaluation = 100;
    } else {
      this.circleEvaluation = 0;
    }
  }

  _maybeRecomputeDragonDependentValues(dragons, all) {
    if (dragons.pawns !== this.dragonTag || all.promotionPoints !== this.promotionTag) {
      this._recomputeDragonRadius(dragons, all);
      this._recomputeTightSpots(dragons);
      this._recomputeCircleProgress(dragons, all);
      this.dragonTag = dragons.pawns;
      this.promotionTag = all.promotionPoints;
    }
  }

  getDragonClaimEvaluation(dragons, all) {
    if (dragons.pawnCount < 4n) {
      return 0;
    }
    this._maybeRecomputeDragonDependentValues(dragons, all);
    return -10 * this.dragonRadius - 30 * this.tightSpotCount;
  }

  getDragonCircleEvaluation(dragons, all) {
    if (dragons.pawnCount < 4n) {
      return 0;
    }
    this._maybeRecomputeDragonDependentValues(dragons, all);
    return this.circleEvaluation;
  }

  isWon(dragons, all) {
    this._maybeRecomputeDragonDependentValues(dragons, all);
    return this._won;
  }
}

class Position {
  constructor(dragons, sides, all = undefined) {
    this.dragons = dragons;
    this.sides = sides;
    let pawns = dragons.pawns;
    let knights = dragons.knights;
    let towers = dragons.towers;
    pawns |= sides[0].pawns;
    knights |= sides[0].knights;
    towers |= sides[0].towers;
    pawns |= sides[1].pawns;
    knights |= sides[1].knights;
    towers |= sides[1].towers;
    this.all = all !== undefined ? all : new Side(pawns, knights, towers);
    this._staticEvaluations = undefined;
  }

  get playingSideSignature() {
    return this.dragons.signature | (this.sides[0].signature << 243n);
  }

  get signature() {
    return this.dragons.signature | (this.sides[0].signature << 243n) | (this.sides[1].signature << 486n);
  }

  get nextSignature() {
    return this.dragons.signature | (this.sides[1].signature << 243n) | (this.sides[0].signature << 486n);
  }

  get serialization() {
    return `${this.dragons.dragonSerialization};${this.sides[0].serialization},${this.sides[1].serialization}`;
  }

  getColorAndPieceType(x, y) {
    const castX = BigInt(x);
    const castY = BigInt(y);
    let piece = this.dragons.getPieceType(castX, castY);
    if (piece !== undefined) {
      return [undefined, '🐉'];
    }
    piece = this.sides[0].getPieceType(castX, castY);
    if (piece !== undefined) {
      return [0, piece];
    }
    piece = this.sides[1].getPieceType(castX, castY);
    if (piece !== undefined) {
      return [1, piece];
    }
    return [undefined, undefined];
  }

  modified(x, y, color, pieceType) {
    const bit = (1n << ((BigInt(x)) + (BigInt(y)) * 9n));
    const survivalMask = ~bit;
    const newDragons = pieceType === '🐉' ?
      this.dragons.withPawnsSetTo(this.dragons.pawns | bit) :
      this.dragons.withPawnsSetTo(this.dragons.pawns & survivalMask);
    const newSides = this.sides.map((side, index) => {
      const filtered = side.filter(survivalMask);
      if (index === color) {
        switch (pieceType) {
        case '♟':
          return filtered.withPawnsSetTo(side.pawns | bit);
        case '♞':
          return filtered.withKnightsSetTo(side.knights | bit);
        case '♜':
          return filtered.withTowersSetTo(side.towers | bit);
        default:
        }
      }
      return filtered;
    });
    return new Position(newDragons, newSides);
  }

  getCircleEvaluation(color) {
    return this.sides[color].getDragonCircleEvaluation(this.dragons, this.all);
  }

  _staticallyEvaluate() {
    if (this.sides[0].hasSufficientMaterial + this.sides[1].hasSufficientMaterial <= 1) {
      return [this.sides[0].hasSufficientMaterial ? 10000000 : -10000000, this.sides[1].hasSufficientMaterial ? 10000000 : -10000000];
    }
    if (this.sides[0].isWon(this.dragons, this.all) || this.sides[1].isWon(this.dragons, this.all)) {
      return [this.sides[0].isWon(this.dragons, this.all) ? 10000000 : -10000000, this.sides[1].isWon(this.dragons, this.all) ? 10000000 : -10000000];
    }
    const oneSidedScores = [this.sides[0].staticEvaluation + this.sides[0].getKnightActivityEvaluation(this.all) + this.sides[0].getConstructionEvaluation(this.all) + this.sides[0].getDragonClaimEvaluation(this.dragons, this.all) + this.sides[0].getDragonCircleEvaluation(this.dragons, this.all), this.sides[1].staticEvaluation + this.sides[1].getKnightActivityEvaluation(this.all) + this.sides[1].getConstructionEvaluation(this.all) + this.sides[1].getDragonClaimEvaluation(this.dragons, this.all) + this.sides[1].getDragonCircleEvaluation(this.dragons, this.all)];
    return [this.sides[0].hasSufficientMaterial ? (oneSidedScores[0] + -oneSidedScores[1]) : -1000000, this.sides[1].hasSufficientMaterial ? (-oneSidedScores[0] + oneSidedScores[1]) : -1000000];
  }

  getStaticEvaluation(color) {
    if (this._staticEvaluations === undefined) {
      this._staticEvaluations = this._staticallyEvaluate();
    }
    return this._staticEvaluations[color];
  }

  get inBookBonus() {
    return 30;
  }

  get live() {
    return this.sides[0].hasSufficientMaterial + this.sides[1].hasSufficientMaterial > 1 && !(this.sides[0].isWon(this.dragons, this.all) || this.sides[1].isWon(this.dragons, this.all));
  }

  get nextTurn() {
    return new Position(this.dragons, [this.sides[1], this.sides[0]], this.all);
  }

  get children() {
    if (!this.sides[0].hasSufficientMaterial) {
      return [this];
    }
    const dragons = this.dragons;
    const side0 = this.sides[0];
    const side1 = this.sides[1];
    const free = 2417851639229258349412351n & ~this.all.material;
    const freeEast = ((free) >> 1n) & 1206560015662350056947455n;
    const freeNorth = ((free) >> 9n) & 4722366482869645213695n;
    const freeWest = ((free) << 1n) & 2413120031324700113894910n;
    const freeSouth = ((free) << 9n) & 2417851639229258349411840n;
    const freeEastEast = ((free & freeEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthEast = ((free & freeEast) >> 9n) & 4722366482869645213695n;
    const freeSouthEast = ((free & freeEast) << 9n) & 2417851639229258349411840n;
    const freeEastNorth = ((free & freeNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorth = ((free & freeNorth) >> 9n) & 4722366482869645213695n;
    const freeWestNorth = ((free & freeNorth) << 1n) & 2413120031324700113894910n;
    const freeNorthWest = ((free & freeWest) >> 9n) & 4722366482869645213695n;
    const freeWestWest = ((free & freeWest) << 1n) & 2413120031324700113894910n;
    const freeSouthWest = ((free & freeWest) << 9n) & 2417851639229258349411840n;
    const freeEastSouth = ((free & freeSouth) >> 1n) & 1206560015662350056947455n;
    const freeWestSouth = ((free & freeSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthSouth = ((free & freeSouth) << 9n) & 2417851639229258349411840n;
    const freeEastEastEast = ((free & freeEastEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastEast = ((free & freeEastEast) >> 9n) & 4722366482869645213695n;
    const freeSouthEastEast = ((free & freeEastEast) << 9n) & 2417851639229258349411840n;
    const freeEastNorthEast = ((free & freeNorthEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthEast = ((free & freeNorthEast) >> 9n) & 4722366482869645213695n;
    const freeWestNorthEast = ((free & freeNorthEast) << 1n) & 2413120031324700113894910n;
    const freeEastSouthEast = ((free & freeSouthEast) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthEast = ((free & freeSouthEast) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthEast = ((free & freeSouthEast) << 9n) & 2417851639229258349411840n;
    const freeEastEastNorth = ((free & freeEastNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastNorth = ((free & freeEastNorth) >> 9n) & 4722366482869645213695n;
    const freeSouthEastNorth = ((free & freeEastNorth) << 9n) & 2417851639229258349411840n;
    const freeEastNorthNorth = ((free & freeNorthNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthNorth = ((free & freeNorthNorth) >> 9n) & 4722366482869645213695n;
    const freeWestNorthNorth = ((free & freeNorthNorth) << 1n) & 2413120031324700113894910n;
    const freeNorthWestNorth = ((free & freeWestNorth) >> 9n) & 4722366482869645213695n;
    const freeWestWestNorth = ((free & freeWestNorth) << 1n) & 2413120031324700113894910n;
    const freeSouthWestNorth = ((free & freeWestNorth) << 9n) & 2417851639229258349411840n;
    const freeEastNorthWest = ((free & freeNorthWest) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthWest = ((free & freeNorthWest) >> 9n) & 4722366482869645213695n;
    const freeWestNorthWest = ((free & freeNorthWest) << 1n) & 2413120031324700113894910n;
    const freeNorthWestWest = ((free & freeWestWest) >> 9n) & 4722366482869645213695n;
    const freeWestWestWest = ((free & freeWestWest) << 1n) & 2413120031324700113894910n;
    const freeSouthWestWest = ((free & freeWestWest) << 9n) & 2417851639229258349411840n;
    const freeEastSouthWest = ((free & freeSouthWest) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthWest = ((free & freeSouthWest) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthWest = ((free & freeSouthWest) << 9n) & 2417851639229258349411840n;
    const freeEastEastSouth = ((free & freeEastSouth) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastSouth = ((free & freeEastSouth) >> 9n) & 4722366482869645213695n;
    const freeSouthEastSouth = ((free & freeEastSouth) << 9n) & 2417851639229258349411840n;
    const freeNorthWestSouth = ((free & freeWestSouth) >> 9n) & 4722366482869645213695n;
    const freeWestWestSouth = ((free & freeWestSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthWestSouth = ((free & freeWestSouth) << 9n) & 2417851639229258349411840n;
    const freeEastSouthSouth = ((free & freeSouthSouth) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthSouth = ((free & freeSouthSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthSouth = ((free & freeSouthSouth) << 9n) & 2417851639229258349411840n;
    const freeEastEastEastEast = ((free & freeEastEastEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastEastEast = ((free & freeEastEastEast) >> 9n) & 4722366482869645213695n;
    const freeSouthEastEastEast = ((free & freeEastEastEast) << 9n) & 2417851639229258349411840n;
    const freeEastNorthEastEast = ((free & freeNorthEastEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthEastEast = ((free & freeNorthEastEast) >> 9n) & 4722366482869645213695n;
    const freeWestNorthEastEast = ((free & freeNorthEastEast) << 1n) & 2413120031324700113894910n;
    const freeEastSouthEastEast = ((free & freeSouthEastEast) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthEastEast = ((free & freeSouthEastEast) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthEastEast = ((free & freeSouthEastEast) << 9n) & 2417851639229258349411840n;
    const freeEastEastNorthEast = ((free & freeEastNorthEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastNorthEast = ((free & freeEastNorthEast) >> 9n) & 4722366482869645213695n;
    const freeSouthEastNorthEast = ((free & freeEastNorthEast) << 9n) & 2417851639229258349411840n;
    const freeEastNorthNorthEast = ((free & freeNorthNorthEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthNorthEast = ((free & freeNorthNorthEast) >> 9n) & 4722366482869645213695n;
    const freeWestNorthNorthEast = ((free & freeNorthNorthEast) << 1n) & 2413120031324700113894910n;
    const freeNorthWestNorthEast = ((free & freeWestNorthEast) >> 9n) & 4722366482869645213695n;
    const freeWestWestNorthEast = ((free & freeWestNorthEast) << 1n) & 2413120031324700113894910n;
    const freeEastEastSouthEast = ((free & freeEastSouthEast) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastSouthEast = ((free & freeEastSouthEast) >> 9n) & 4722366482869645213695n;
    const freeSouthEastSouthEast = ((free & freeEastSouthEast) << 9n) & 2417851639229258349411840n;
    const freeWestWestSouthEast = ((free & freeWestSouthEast) << 1n) & 2413120031324700113894910n;
    const freeSouthWestSouthEast = ((free & freeWestSouthEast) << 9n) & 2417851639229258349411840n;
    const freeEastSouthSouthEast = ((free & freeSouthSouthEast) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthSouthEast = ((free & freeSouthSouthEast) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthSouthEast = ((free & freeSouthSouthEast) << 9n) & 2417851639229258349411840n;
    const freeEastEastEastNorth = ((free & freeEastEastNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastEastNorth = ((free & freeEastEastNorth) >> 9n) & 4722366482869645213695n;
    const freeSouthEastEastNorth = ((free & freeEastEastNorth) << 9n) & 2417851639229258349411840n;
    const freeEastNorthEastNorth = ((free & freeNorthEastNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthEastNorth = ((free & freeNorthEastNorth) >> 9n) & 4722366482869645213695n;
    const freeWestNorthEastNorth = ((free & freeNorthEastNorth) << 1n) & 2413120031324700113894910n;
    const freeEastSouthEastNorth = ((free & freeSouthEastNorth) >> 1n) & 1206560015662350056947455n;
    const freeSouthSouthEastNorth = ((free & freeSouthEastNorth) << 9n) & 2417851639229258349411840n;
    const freeEastEastNorthNorth = ((free & freeEastNorthNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastNorthNorth = ((free & freeEastNorthNorth) >> 9n) & 4722366482869645213695n;
    const freeSouthEastNorthNorth = ((free & freeEastNorthNorth) << 9n) & 2417851639229258349411840n;
    const freeEastNorthNorthNorth = ((free & freeNorthNorthNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthNorthNorth = ((free & freeNorthNorthNorth) >> 9n) & 4722366482869645213695n;
    const freeWestNorthNorthNorth = ((free & freeNorthNorthNorth) << 1n) & 2413120031324700113894910n;
    const freeNorthWestNorthNorth = ((free & freeWestNorthNorth) >> 9n) & 4722366482869645213695n;
    const freeWestWestNorthNorth = ((free & freeWestNorthNorth) << 1n) & 2413120031324700113894910n;
    const freeSouthWestNorthNorth = ((free & freeWestNorthNorth) << 9n) & 2417851639229258349411840n;
    const freeEastNorthWestNorth = ((free & freeNorthWestNorth) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthWestNorth = ((free & freeNorthWestNorth) >> 9n) & 4722366482869645213695n;
    const freeWestNorthWestNorth = ((free & freeNorthWestNorth) << 1n) & 2413120031324700113894910n;
    const freeNorthWestWestNorth = ((free & freeWestWestNorth) >> 9n) & 4722366482869645213695n;
    const freeWestWestWestNorth = ((free & freeWestWestNorth) << 1n) & 2413120031324700113894910n;
    const freeSouthWestWestNorth = ((free & freeWestWestNorth) << 9n) & 2417851639229258349411840n;
    const freeWestSouthWestNorth = ((free & freeSouthWestNorth) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthWestNorth = ((free & freeSouthWestNorth) << 9n) & 2417851639229258349411840n;
    const freeEastEastNorthWest = ((free & freeEastNorthWest) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastNorthWest = ((free & freeEastNorthWest) >> 9n) & 4722366482869645213695n;
    const freeEastNorthNorthWest = ((free & freeNorthNorthWest) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthNorthWest = ((free & freeNorthNorthWest) >> 9n) & 4722366482869645213695n;
    const freeWestNorthNorthWest = ((free & freeNorthNorthWest) << 1n) & 2413120031324700113894910n;
    const freeNorthWestNorthWest = ((free & freeWestNorthWest) >> 9n) & 4722366482869645213695n;
    const freeWestWestNorthWest = ((free & freeWestNorthWest) << 1n) & 2413120031324700113894910n;
    const freeSouthWestNorthWest = ((free & freeWestNorthWest) << 9n) & 2417851639229258349411840n;
    const freeEastNorthWestWest = ((free & freeNorthWestWest) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthWestWest = ((free & freeNorthWestWest) >> 9n) & 4722366482869645213695n;
    const freeWestNorthWestWest = ((free & freeNorthWestWest) << 1n) & 2413120031324700113894910n;
    const freeNorthWestWestWest = ((free & freeWestWestWest) >> 9n) & 4722366482869645213695n;
    const freeWestWestWestWest = ((free & freeWestWestWest) << 1n) & 2413120031324700113894910n;
    const freeSouthWestWestWest = ((free & freeWestWestWest) << 9n) & 2417851639229258349411840n;
    const freeEastSouthWestWest = ((free & freeSouthWestWest) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthWestWest = ((free & freeSouthWestWest) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthWestWest = ((free & freeSouthWestWest) << 9n) & 2417851639229258349411840n;
    const freeEastEastSouthWest = ((free & freeEastSouthWest) >> 1n) & 1206560015662350056947455n;
    const freeSouthEastSouthWest = ((free & freeEastSouthWest) << 9n) & 2417851639229258349411840n;
    const freeNorthWestSouthWest = ((free & freeWestSouthWest) >> 9n) & 4722366482869645213695n;
    const freeWestWestSouthWest = ((free & freeWestSouthWest) << 1n) & 2413120031324700113894910n;
    const freeSouthWestSouthWest = ((free & freeWestSouthWest) << 9n) & 2417851639229258349411840n;
    const freeEastSouthSouthWest = ((free & freeSouthSouthWest) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthSouthWest = ((free & freeSouthSouthWest) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthSouthWest = ((free & freeSouthSouthWest) << 9n) & 2417851639229258349411840n;
    const freeEastEastEastSouth = ((free & freeEastEastSouth) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastEastSouth = ((free & freeEastEastSouth) >> 9n) & 4722366482869645213695n;
    const freeSouthEastEastSouth = ((free & freeEastEastSouth) << 9n) & 2417851639229258349411840n;
    const freeEastNorthEastSouth = ((free & freeNorthEastSouth) >> 1n) & 1206560015662350056947455n;
    const freeNorthNorthEastSouth = ((free & freeNorthEastSouth) >> 9n) & 4722366482869645213695n;
    const freeEastSouthEastSouth = ((free & freeSouthEastSouth) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthEastSouth = ((free & freeSouthEastSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthEastSouth = ((free & freeSouthEastSouth) << 9n) & 2417851639229258349411840n;
    const freeNorthNorthWestSouth = ((free & freeNorthWestSouth) >> 9n) & 4722366482869645213695n;
    const freeWestNorthWestSouth = ((free & freeNorthWestSouth) << 1n) & 2413120031324700113894910n;
    const freeNorthWestWestSouth = ((free & freeWestWestSouth) >> 9n) & 4722366482869645213695n;
    const freeWestWestWestSouth = ((free & freeWestWestSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthWestWestSouth = ((free & freeWestWestSouth) << 9n) & 2417851639229258349411840n;
    const freeEastSouthWestSouth = ((free & freeSouthWestSouth) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthWestSouth = ((free & freeSouthWestSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthWestSouth = ((free & freeSouthWestSouth) << 9n) & 2417851639229258349411840n;
    const freeEastEastSouthSouth = ((free & freeEastSouthSouth) >> 1n) & 1206560015662350056947455n;
    const freeNorthEastSouthSouth = ((free & freeEastSouthSouth) >> 9n) & 4722366482869645213695n;
    const freeSouthEastSouthSouth = ((free & freeEastSouthSouth) << 9n) & 2417851639229258349411840n;
    const freeNorthWestSouthSouth = ((free & freeWestSouthSouth) >> 9n) & 4722366482869645213695n;
    const freeWestWestSouthSouth = ((free & freeWestSouthSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthWestSouthSouth = ((free & freeWestSouthSouth) << 9n) & 2417851639229258349411840n;
    const freeEastSouthSouthSouth = ((free & freeSouthSouthSouth) >> 1n) & 1206560015662350056947455n;
    const freeWestSouthSouthSouth = ((free & freeSouthSouthSouth) << 1n) & 2413120031324700113894910n;
    const freeSouthSouthSouthSouth = ((free & freeSouthSouthSouth) << 9n) & 2417851639229258349411840n;
    const semifree = 2417851639229258349412351n & ~(dragons.pawns | side0.material);
    const semifreeEast = ((semifree) >> 1n) & 1206560015662350056947455n;
    const semifreeNorth = ((semifree) >> 9n) & 4722366482869645213695n;
    const semifreeWest = ((semifree) << 1n) & 2413120031324700113894910n;
    const semifreeSouth = ((semifree) << 9n) & 2417851639229258349411840n;
    const semifreeEastEast = ((free & semifreeEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEast = ((free & semifreeEast) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEast = ((free & semifreeEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorth = ((free & semifreeNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorth = ((free & semifreeNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorth = ((free & semifreeNorth) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWest = ((free & semifreeWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestWest = ((free & semifreeWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWest = ((free & semifreeWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouth = ((free & semifreeSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouth = ((free & semifreeSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouth = ((free & semifreeSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastEast = ((free & semifreeEastEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastEast = ((free & semifreeEastEast) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastEast = ((free & semifreeEastEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthEast = ((free & semifreeNorthEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthEast = ((free & semifreeNorthEast) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthEast = ((free & semifreeNorthEast) << 1n) & 2413120031324700113894910n;
    const semifreeEastSouthEast = ((free & semifreeSouthEast) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthEast = ((free & semifreeSouthEast) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthEast = ((free & semifreeSouthEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastNorth = ((free & semifreeEastNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastNorth = ((free & semifreeEastNorth) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastNorth = ((free & semifreeEastNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthNorth = ((free & semifreeNorthNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthNorth = ((free & semifreeNorthNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthNorth = ((free & semifreeNorthNorth) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestNorth = ((free & semifreeWestNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestNorth = ((free & semifreeWestNorth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestNorth = ((free & semifreeWestNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthWest = ((free & semifreeNorthWest) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthWest = ((free & semifreeNorthWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthWest = ((free & semifreeNorthWest) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestWest = ((free & semifreeWestWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestWest = ((free & semifreeWestWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestWest = ((free & semifreeWestWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthWest = ((free & semifreeSouthWest) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthWest = ((free & semifreeSouthWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthWest = ((free & semifreeSouthWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastSouth = ((free & semifreeEastSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastSouth = ((free & semifreeEastSouth) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastSouth = ((free & semifreeEastSouth) << 9n) & 2417851639229258349411840n;
    const semifreeNorthWestSouth = ((free & semifreeWestSouth) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestSouth = ((free & semifreeWestSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestSouth = ((free & semifreeWestSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthSouth = ((free & semifreeSouthSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthSouth = ((free & semifreeSouthSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthSouth = ((free & semifreeSouthSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastEastEast = ((free & semifreeEastEastEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastEastEast = ((free & semifreeEastEastEast) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastEastEast = ((free & semifreeEastEastEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthEastEast = ((free & semifreeNorthEastEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthEastEast = ((free & semifreeNorthEastEast) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthEastEast = ((free & semifreeNorthEastEast) << 1n) & 2413120031324700113894910n;
    const semifreeEastSouthEastEast = ((free & semifreeSouthEastEast) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthEastEast = ((free & semifreeSouthEastEast) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthEastEast = ((free & semifreeSouthEastEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastNorthEast = ((free & semifreeEastNorthEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastNorthEast = ((free & semifreeEastNorthEast) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastNorthEast = ((free & semifreeEastNorthEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthNorthEast = ((free & semifreeNorthNorthEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthNorthEast = ((free & semifreeNorthNorthEast) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthNorthEast = ((free & semifreeNorthNorthEast) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestNorthEast = ((free & semifreeWestNorthEast) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestNorthEast = ((free & semifreeWestNorthEast) << 1n) & 2413120031324700113894910n;
    const semifreeEastEastSouthEast = ((free & semifreeEastSouthEast) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastSouthEast = ((free & semifreeEastSouthEast) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastSouthEast = ((free & semifreeEastSouthEast) << 9n) & 2417851639229258349411840n;
    const semifreeWestWestSouthEast = ((free & semifreeWestSouthEast) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestSouthEast = ((free & semifreeWestSouthEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthSouthEast = ((free & semifreeSouthSouthEast) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthSouthEast = ((free & semifreeSouthSouthEast) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthSouthEast = ((free & semifreeSouthSouthEast) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastEastNorth = ((free & semifreeEastEastNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastEastNorth = ((free & semifreeEastEastNorth) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastEastNorth = ((free & semifreeEastEastNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthEastNorth = ((free & semifreeNorthEastNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthEastNorth = ((free & semifreeNorthEastNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthEastNorth = ((free & semifreeNorthEastNorth) << 1n) & 2413120031324700113894910n;
    const semifreeEastSouthEastNorth = ((free & semifreeSouthEastNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeSouthSouthEastNorth = ((free & semifreeSouthEastNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastNorthNorth = ((free & semifreeEastNorthNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastNorthNorth = ((free & semifreeEastNorthNorth) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastNorthNorth = ((free & semifreeEastNorthNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthNorthNorth = ((free & semifreeNorthNorthNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthNorthNorth = ((free & semifreeNorthNorthNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthNorthNorth = ((free & semifreeNorthNorthNorth) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestNorthNorth = ((free & semifreeWestNorthNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestNorthNorth = ((free & semifreeWestNorthNorth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestNorthNorth = ((free & semifreeWestNorthNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthWestNorth = ((free & semifreeNorthWestNorth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthWestNorth = ((free & semifreeNorthWestNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthWestNorth = ((free & semifreeNorthWestNorth) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestWestNorth = ((free & semifreeWestWestNorth) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestWestNorth = ((free & semifreeWestWestNorth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestWestNorth = ((free & semifreeWestWestNorth) << 9n) & 2417851639229258349411840n;
    const semifreeWestSouthWestNorth = ((free & semifreeSouthWestNorth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthWestNorth = ((free & semifreeSouthWestNorth) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastNorthWest = ((free & semifreeEastNorthWest) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastNorthWest = ((free & semifreeEastNorthWest) >> 9n) & 4722366482869645213695n;
    const semifreeEastNorthNorthWest = ((free & semifreeNorthNorthWest) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthNorthWest = ((free & semifreeNorthNorthWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthNorthWest = ((free & semifreeNorthNorthWest) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestNorthWest = ((free & semifreeWestNorthWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestNorthWest = ((free & semifreeWestNorthWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestNorthWest = ((free & semifreeWestNorthWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthWestWest = ((free & semifreeNorthWestWest) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthWestWest = ((free & semifreeNorthWestWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthWestWest = ((free & semifreeNorthWestWest) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestWestWest = ((free & semifreeWestWestWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestWestWest = ((free & semifreeWestWestWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestWestWest = ((free & semifreeWestWestWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthWestWest = ((free & semifreeSouthWestWest) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthWestWest = ((free & semifreeSouthWestWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthWestWest = ((free & semifreeSouthWestWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastSouthWest = ((free & semifreeEastSouthWest) >> 1n) & 1206560015662350056947455n;
    const semifreeSouthEastSouthWest = ((free & semifreeEastSouthWest) << 9n) & 2417851639229258349411840n;
    const semifreeNorthWestSouthWest = ((free & semifreeWestSouthWest) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestSouthWest = ((free & semifreeWestSouthWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestSouthWest = ((free & semifreeWestSouthWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthSouthWest = ((free & semifreeSouthSouthWest) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthSouthWest = ((free & semifreeSouthSouthWest) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthSouthWest = ((free & semifreeSouthSouthWest) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastEastSouth = ((free & semifreeEastEastSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastEastSouth = ((free & semifreeEastEastSouth) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastEastSouth = ((free & semifreeEastEastSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastNorthEastSouth = ((free & semifreeNorthEastSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthNorthEastSouth = ((free & semifreeNorthEastSouth) >> 9n) & 4722366482869645213695n;
    const semifreeEastSouthEastSouth = ((free & semifreeSouthEastSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthEastSouth = ((free & semifreeSouthEastSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthEastSouth = ((free & semifreeSouthEastSouth) << 9n) & 2417851639229258349411840n;
    const semifreeNorthNorthWestSouth = ((free & semifreeNorthWestSouth) >> 9n) & 4722366482869645213695n;
    const semifreeWestNorthWestSouth = ((free & semifreeNorthWestSouth) << 1n) & 2413120031324700113894910n;
    const semifreeNorthWestWestSouth = ((free & semifreeWestWestSouth) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestWestSouth = ((free & semifreeWestWestSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestWestSouth = ((free & semifreeWestWestSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthWestSouth = ((free & semifreeSouthWestSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthWestSouth = ((free & semifreeSouthWestSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthWestSouth = ((free & semifreeSouthWestSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastEastSouthSouth = ((free & semifreeEastSouthSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeNorthEastSouthSouth = ((free & semifreeEastSouthSouth) >> 9n) & 4722366482869645213695n;
    const semifreeSouthEastSouthSouth = ((free & semifreeEastSouthSouth) << 9n) & 2417851639229258349411840n;
    const semifreeNorthWestSouthSouth = ((free & semifreeWestSouthSouth) >> 9n) & 4722366482869645213695n;
    const semifreeWestWestSouthSouth = ((free & semifreeWestSouthSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthWestSouthSouth = ((free & semifreeWestSouthSouth) << 9n) & 2417851639229258349411840n;
    const semifreeEastSouthSouthSouth = ((free & semifreeSouthSouthSouth) >> 1n) & 1206560015662350056947455n;
    const semifreeWestSouthSouthSouth = ((free & semifreeSouthSouthSouth) << 1n) & 2413120031324700113894910n;
    const semifreeSouthSouthSouthSouth = ((free & semifreeSouthSouthSouth) << 9n) & 2417851639229258349411840n;
    const children = [];
    const adjacentDragons = dragons.pawns & ~side0.boostsBy0;
    const adjacentDragonsBoostedBy1 = adjacentDragons & this.all.boostsBy1;
    const adjacentDragonsBoostedBy2 = adjacentDragons & this.all.boostsBy2;
    const adjacentDragonsBoostedBy3 = adjacentDragons & this.all.boostsBy3;

    for (let bits = adjacentDragonsBoostedBy1 & (freeEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 2n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeNorthEast | freeEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 10n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeSouthEast | freeEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 8n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 18n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeWestNorth | freeNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 8n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 2n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeSouthWest | freeWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 10n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy1 & (freeSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 18n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeEastEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 3n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeNorthEastEast | freeEastNorthEast | freeEastEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 11n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthEastEast | freeEastSouthEast | freeEastEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 7n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeNorthNorthEast | freeNorthEastNorth | freeEastNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 19n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeWestNorthEast | freeEastNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 9n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeWestSouthEast | freeEastSouthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 9n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthSouthEast | freeSouthEastSouth | freeEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 17n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthEastNorth | freeNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 1n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 27n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeWestNorthNorth | freeNorthWestNorth | freeNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 17n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeWestWestNorth | freeWestNorthWest | freeNorthWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 7n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthWestNorth | freeNorthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 1n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 3n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthWestWest | freeWestSouthWest | freeWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 11n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthSouthWest | freeSouthWestSouth | freeWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 19n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy2 & (freeSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 27n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeEastEastEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 4n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeNorthEastEastEast | freeEastNorthEastEast | freeEastEastNorthEast | freeEastEastEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 12n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthEastEastEast | freeEastSouthEastEast | freeEastEastSouthEast | freeEastEastEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 6n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeNorthNorthEastEast | freeNorthEastNorthEast | freeEastNorthNorthEast | freeNorthEastEastNorth | freeEastNorthEastNorth | freeEastEastNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 20n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestNorthEastEast | freeSouthEastNorthNorth | freeEastEastNorthWest | freeNorthNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 10n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestSouthEastEast | freeSouthSouthEastNorth | freeEastEastSouthWest | freeNorthEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 8n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthSouthEastEast | freeSouthEastSouthEast | freeEastSouthSouthEast | freeSouthEastEastSouth | freeEastSouthEastSouth | freeEastEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 16n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthEastNorthEast | freeNorthEastSouthEast | freeSouthEastEastNorth | freeEastSouthEastNorth | freeNorthEastEastSouth | freeEastNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 2n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeNorthNorthNorthEast | freeNorthNorthEastNorth | freeNorthEastNorthNorth | freeEastNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 28n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestNorthNorthEast | freeNorthWestNorthEast | freeWestNorthEastNorth | freeEastNorthWestNorth | freeNorthEastNorthWest | freeEastNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 18n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestWestNorthEast | freeSouthWestNorthNorth | freeEastNorthWestWest | freeNorthNorthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 8n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestWestSouthEast | freeSouthSouthWestNorth | freeEastSouthWestWest | freeNorthWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 10n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthWestSouthEast | freeWestSouthSouthEast | freeSouthEastSouthWest | freeEastSouthSouthWest | freeWestSouthEastSouth | freeEastSouthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 18n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthSouthSouthEast | freeSouthSouthEastSouth | freeSouthEastSouthSouth | freeEastSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 26n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeNorthNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 36n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestNorthNorthNorth | freeNorthWestNorthNorth | freeNorthNorthWestNorth | freeNorthNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 26n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestWestNorthNorth | freeWestNorthWestNorth | freeNorthWestWestNorth | freeWestNorthNorthWest | freeNorthWestNorthWest | freeNorthNorthWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 16n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestWestWestNorth | freeWestWestNorthWest | freeWestNorthWestWest | freeNorthWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 6n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthWestWestNorth | freeWestSouthWestNorth | freeSouthWestNorthWest | freeNorthWestSouthWest | freeWestNorthWestSouth | freeNorthWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 2n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeWestWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 4n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthWestWestWest | freeWestSouthWestWest | freeWestWestSouthWest | freeWestWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 12n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthSouthWestWest | freeSouthWestSouthWest | freeWestSouthSouthWest | freeSouthWestWestSouth | freeWestSouthWestSouth | freeWestWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 20n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthSouthSouthWest | freeSouthSouthWestSouth | freeSouthWestSouthSouth | freeWestSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 28n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = adjacentDragonsBoostedBy3 & (freeSouthSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 36n);
      const newDragons = (dragons.pawns & ~bit) | captureBit;
      const flankers = newDragons | side0.material;
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons.withPawnsMovedTo(newDragons), [side0, side1.filter(survivalMask)]));
      bits -= bit;
    }
    const pawnsBoostedBy0 = side0.pawns & this.all.boostsBy0;
    const pawnsBoostedBy1 = side0.pawns & this.all.boostsBy1;
    const pawnsBoostedBy2 = side0.pawns & this.all.boostsBy2;
    const pawnsBoostedBy3 = side0.pawns & this.all.boostsBy3;
    for (let bits = pawnsBoostedBy0 & (freeEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 1n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy0 & (freeNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 9n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy0 & (freeWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 1n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy0 & (freeSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 9n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 2n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeNorthEast | freeEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 10n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeSouthEast | freeEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 8n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 18n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeWestNorth | freeNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 8n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 2n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeSouthWest | freeWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 10n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy1 & (freeSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 18n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeEastEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 3n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeNorthEastEast | freeEastNorthEast | freeEastEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 11n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthEastEast | freeEastSouthEast | freeEastEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 7n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeNorthNorthEast | freeNorthEastNorth | freeEastNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 19n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeWestNorthEast | freeEastNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 9n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeWestSouthEast | freeEastSouthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 9n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthSouthEast | freeSouthEastSouth | freeEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 17n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthEastNorth | freeNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 1n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 27n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeWestNorthNorth | freeNorthWestNorth | freeNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 17n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeWestWestNorth | freeWestNorthWest | freeNorthWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 7n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthWestNorth | freeNorthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 1n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 3n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthWestWest | freeWestSouthWest | freeWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 11n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthSouthWest | freeSouthWestSouth | freeWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 19n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy2 & (freeSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 27n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeEastEastEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 4n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeNorthEastEastEast | freeEastNorthEastEast | freeEastEastNorthEast | freeEastEastEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 12n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthEastEastEast | freeEastSouthEastEast | freeEastEastSouthEast | freeEastEastEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 6n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeNorthNorthEastEast | freeNorthEastNorthEast | freeEastNorthNorthEast | freeNorthEastEastNorth | freeEastNorthEastNorth | freeEastEastNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 20n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestNorthEastEast | freeSouthEastNorthNorth | freeEastEastNorthWest | freeNorthNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 10n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestSouthEastEast | freeSouthSouthEastNorth | freeEastEastSouthWest | freeNorthEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 8n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthSouthEastEast | freeSouthEastSouthEast | freeEastSouthSouthEast | freeSouthEastEastSouth | freeEastSouthEastSouth | freeEastEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 16n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthEastNorthEast | freeNorthEastSouthEast | freeSouthEastEastNorth | freeEastSouthEastNorth | freeNorthEastEastSouth | freeEastNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 2n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeNorthNorthNorthEast | freeNorthNorthEastNorth | freeNorthEastNorthNorth | freeEastNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 28n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestNorthNorthEast | freeNorthWestNorthEast | freeWestNorthEastNorth | freeEastNorthWestNorth | freeNorthEastNorthWest | freeEastNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 18n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestWestNorthEast | freeSouthWestNorthNorth | freeEastNorthWestWest | freeNorthNorthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 8n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestWestSouthEast | freeSouthSouthWestNorth | freeEastSouthWestWest | freeNorthWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 10n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthWestSouthEast | freeWestSouthSouthEast | freeSouthEastSouthWest | freeEastSouthSouthWest | freeWestSouthEastSouth | freeEastSouthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 18n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthSouthSouthEast | freeSouthSouthEastSouth | freeSouthEastSouthSouth | freeEastSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 26n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeNorthNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 36n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestNorthNorthNorth | freeNorthWestNorthNorth | freeNorthNorthWestNorth | freeNorthNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 26n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestWestNorthNorth | freeWestNorthWestNorth | freeNorthWestWestNorth | freeWestNorthNorthWest | freeNorthWestNorthWest | freeNorthNorthWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 16n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestWestWestNorth | freeWestWestNorthWest | freeWestNorthWestWest | freeNorthWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 6n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthWestWestNorth | freeWestSouthWestNorth | freeSouthWestNorthWest | freeNorthWestSouthWest | freeWestNorthWestSouth | freeNorthWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 2n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeWestWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 4n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthWestWestWest | freeWestSouthWestWest | freeWestWestSouthWest | freeWestWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 12n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthSouthWestWest | freeSouthWestSouthWest | freeWestSouthSouthWest | freeSouthWestWestSouth | freeWestSouthWestSouth | freeWestWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 20n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthSouthSouthWest | freeSouthSouthWestSouth | freeSouthWestSouthSouth | freeWestSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 28n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = pawnsBoostedBy3 & (freeSouthSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 36n);
      const newPawns = (side0.pawns & ~bit) | captureBit;
      const flankers = dragons.pawns | (side0.material & ~bit);
      const survivalMask = ~((((captureBit) << 1n) & ((flankers) >> 1n) & 1201828407757791821430014n) | (((captureBit) << 9n) & ((flankers) >> 9n)) | (((captureBit) >> 1n) & ((flankers) << 1n) & 1201828407757791821430014n) | (((captureBit) >> 9n) & ((flankers) << 9n)));
      children.push(new Position(dragons, [side0.withPawnsMovedTo(newPawns), side1.filter(survivalMask)]));
      bits -= bit;
    }
    const knightsBoostedBy0 = side0.knights & this.all.boostsBy0;
    const knightsBoostedBy1 = side0.knights & this.all.boostsBy1;
    const knightsBoostedBy2 = side0.knights & this.all.boostsBy2;
    const knightsBoostedBy3 = side0.knights & this.all.boostsBy3;
    for (let bits = knightsBoostedBy0 & (semifreeEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 1n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy0 & (semifreeNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 9n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy0 & (semifreeWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 1n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy0 & (semifreeSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 9n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 2n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeNorthEast | semifreeEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 10n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeSouthEast | semifreeEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 8n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 18n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeWestNorth | semifreeNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 8n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 2n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeSouthWest | semifreeWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 10n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy1 & (semifreeSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 18n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeEastEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 3n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeNorthEastEast | semifreeEastNorthEast | semifreeEastEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 11n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthEastEast | semifreeEastSouthEast | semifreeEastEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 7n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeNorthNorthEast | semifreeNorthEastNorth | semifreeEastNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 19n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeWestNorthEast | semifreeEastNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 9n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeWestSouthEast | semifreeEastSouthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 9n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthSouthEast | semifreeSouthEastSouth | semifreeEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 17n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthEastNorth | semifreeNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 1n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 27n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeWestNorthNorth | semifreeNorthWestNorth | semifreeNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 17n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeWestWestNorth | semifreeWestNorthWest | semifreeNorthWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 7n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthWestNorth | semifreeNorthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 1n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 3n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthWestWest | semifreeWestSouthWest | semifreeWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 11n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthSouthWest | semifreeSouthWestSouth | semifreeWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 19n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy2 & (semifreeSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 27n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeEastEastEastEast); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 4n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeNorthEastEastEast | semifreeEastNorthEastEast | semifreeEastEastNorthEast | semifreeEastEastEastNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 12n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthEastEastEast | semifreeEastSouthEastEast | semifreeEastEastSouthEast | semifreeEastEastEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 6n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeNorthNorthEastEast | semifreeNorthEastNorthEast | semifreeEastNorthNorthEast | semifreeNorthEastEastNorth | semifreeEastNorthEastNorth | semifreeEastEastNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 20n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestNorthEastEast | semifreeSouthEastNorthNorth | semifreeEastEastNorthWest | semifreeNorthNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 10n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestSouthEastEast | semifreeSouthSouthEastNorth | semifreeEastEastSouthWest | semifreeNorthEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 8n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthSouthEastEast | semifreeSouthEastSouthEast | semifreeEastSouthSouthEast | semifreeSouthEastEastSouth | semifreeEastSouthEastSouth | semifreeEastEastSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 16n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthEastNorthEast | semifreeNorthEastSouthEast | semifreeSouthEastEastNorth | semifreeEastSouthEastNorth | semifreeNorthEastEastSouth | semifreeEastNorthEastSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 2n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeNorthNorthNorthEast | semifreeNorthNorthEastNorth | semifreeNorthEastNorthNorth | semifreeEastNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 28n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestNorthNorthEast | semifreeNorthWestNorthEast | semifreeWestNorthEastNorth | semifreeEastNorthWestNorth | semifreeNorthEastNorthWest | semifreeEastNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 18n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestWestNorthEast | semifreeSouthWestNorthNorth | semifreeEastNorthWestWest | semifreeNorthNorthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 8n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestWestSouthEast | semifreeSouthSouthWestNorth | semifreeEastSouthWestWest | semifreeNorthWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 10n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthWestSouthEast | semifreeWestSouthSouthEast | semifreeSouthEastSouthWest | semifreeEastSouthSouthWest | semifreeWestSouthEastSouth | semifreeEastSouthWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 18n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthSouthSouthEast | semifreeSouthSouthEastSouth | semifreeSouthEastSouthSouth | semifreeEastSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 26n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeNorthNorthNorthNorth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 36n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestNorthNorthNorth | semifreeNorthWestNorthNorth | semifreeNorthNorthWestNorth | semifreeNorthNorthNorthWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 26n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestWestNorthNorth | semifreeWestNorthWestNorth | semifreeNorthWestWestNorth | semifreeWestNorthNorthWest | semifreeNorthWestNorthWest | semifreeNorthNorthWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 16n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestWestWestNorth | semifreeWestWestNorthWest | semifreeWestNorthWestWest | semifreeNorthWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) << 6n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthWestWestNorth | semifreeWestSouthWestNorth | semifreeSouthWestNorthWest | semifreeNorthWestSouthWest | semifreeWestNorthWestSouth | semifreeNorthWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 2n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeWestWestWestWest); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 4n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthWestWestWest | semifreeWestSouthWestWest | semifreeWestWestSouthWest | semifreeWestWestWestSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 12n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthSouthWestWest | semifreeSouthWestSouthWest | semifreeWestSouthSouthWest | semifreeSouthWestWestSouth | semifreeWestSouthWestSouth | semifreeWestWestSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 20n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthSouthSouthWest | semifreeSouthSouthWestSouth | semifreeSouthWestSouthSouth | semifreeWestSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 28n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    for (let bits = knightsBoostedBy3 & (semifreeSouthSouthSouthSouth); bits;) {
      const bit = bits & ~(bits - 1n);
      const captureBit = ((bit) >> 36n);
      const newKnights = (side0.knights & ~bit) | captureBit;
      const survivalMask = ~captureBit;
      children.push(new Position(dragons, [side0.withKnightsMovedTo(newKnights), side1.filter(survivalMask)]));
      bits -= bit;
    }
    if (side0.towerCount < 2) {
      for (let bits = side0.boostsBy4 & ~this.all.material; bits;) {
        const bit = bits & ~(bits - 1n);
        const newTowers = side0.towers | bit;
        children.push(new Position(dragons, [side0.withConstruction(newTowers), side1]));
        bits -= bit;
      }
    }
    if (side0.knightCount < side0.towerCount) {
      for (let bits = side0.pawns & side0.promotionPoints; bits;) {
        const bit = bits & ~(bits - 1n);
        const newPawns = side0.pawns & ~bit;
        const newKnights = side0.knights | bit;
        children.push(new Position(dragons, [side0.withPromotion(newPawns, newKnights), side1]));
        bits -= bit;
      }
    }
    if (children.length === 0) {
      children.push(this);
    }
    return children;
  }

  getEncodedMoveTo(child) {
    return this.playingSideSignature ^ child.playingSideSignature;
  }

  getChildByEncodedMove(encodedMove) {
    const ownPlayingSideSignature = this.playingSideSignature;
    for (const child of this.children) {
      if ((ownPlayingSideSignature ^ child.playingSideSignature) === encodedMove) {
        return child;
      }
    }
    return undefined;
  }

  getMoveTo(child) {
    const predecessor = this.dragons.pawns | this.sides[0].material;
    const successor = child.dragons.pawns | child.sides[0].material;
    const loss = predecessor & ~successor;
    const gain = (~predecessor & successor) | (~this.sides[0].knights & child.sides[0].knights);
    try {
      // eslint-disable-next-line no-use-before-define
      return GAME.prettifyMove(...BITS_TO_COORDINATES.get(loss), ...BITS_TO_COORDINATES.get(gain));
    } catch (exception) {
      if (exception instanceof TypeError) {
        throw new RangeError(
          `There is no move from ${this.serialization} that reaches the non-child ${child.serialization}`,
        );
      }
      throw exception;
    }
  }

  getChildByMove(move) {
    for (const child of this.children) {
      if (move === this.getMoveTo(child)) {
        return child;
      }
    }
    return undefined;
  }
}

class Game {
  get identifier() {
    return 'boost-9-9-2-8-2';
  }

  get boardWidth() {
    return 9;
  }

  get boardHeight() {
    return 9;
  }

  get compensationFromPoint() {
    return [4, 4];
  }

  get compensationToPoint() {
    return [4, 5];
  }

  get playerCount() {
    return 2;
  }

  get startingPopulationLimit() {
    return 8;
  }

  get towerLimit() {
    return 2;
  }

  get victory() {
    return 10000000;
  }

  get dragon() {
    return '🐉';
  }

  get pawn() {
    return '♟';
  }

  get knight() {
    return '♞';
  }

  get tower() {
    return '♜';
  }

  prettifyFile(file) {
    return String.fromCodePoint(97 + file);
  }

  unprettifyFile(character) {
    return character.codePointAt(0) - 97;
  }

  prettifyRank(rank) {
    return `${rank + 1}`;
  }

  unprettifyRank(substring) {
    return Number(substring) - 1;
  }

  get noPoint() {
    return '-';
  }

  prettifyPoint(file, rank) {
    if (file === undefined || rank === undefined) {
      return this.noPoint;
    }
    return this.prettifyFile(file) + (rank + 1);
  }

  unprettifyPoint(point) {
    if (point === this.noPoint) {
      return [undefined, undefined];
    }
    return [this.unprettifyFile(point[0]), this.unprettifyRank(point.substring(1))];
  }

  get pass() {
    return '--';
  }

  joinPoints(fromPoint, toPoint) {
    if (fromPoint !== this.noPoint) {
      if (toPoint === this.noPoint || toPoint === fromPoint) {
        return fromPoint;
      }
    } else if (toPoint !== this.noPoint) {
      return toPoint;
    }
    return fromPoint + toPoint;
  }

  splitMove(move) {
    const results = move.split(/(?!\d)(?=[^\d])/);
    if (results.length === 1) {
      results.push(results[0]);
    }
    return results;
  }

  prettifyMove(fromFile, fromRank, toFile, toRank) {
    return this.joinPoints(this.prettifyPoint(fromFile, fromRank), this.prettifyPoint(toFile, toRank));
  }

  unprettifyMove(move) {
    const [fromPoint, toPoint] = this.splitMove(move);
    return [...this.unprettifyPoint(fromPoint), ...this.unprettifyPoint(toPoint)];
  }

  get blankPosition() {
    return new Position(new Side(0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n), [new Side(0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n), new Side(0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n)]);
  }

  get startingPosition() {
    return new Position(new Side(0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n), [new Side(495n, 0n, 0n, undefined, 0n, 0n, undefined, 0n), new Side(2337571409020474380779520n, 0n, 0n, undefined, 0n, 0n, undefined, 0n)]);
  }

  buildStartingPositionWithDragons(requestedDragonCount) {
    let result = new Position(new Side(0n, 0n, 0n, 0n, 0n, 0n, 0n, 0n), [new Side(495n, 0n, 0n, undefined, 0n, 0n, undefined, 0n), new Side(2337571409020474380779520n, 0n, 0n, undefined, 0n, 0n, undefined, 0n)]);
    if (true && requestedDragonCount % 2 === 1) {
      result = result.modified(4, 4, undefined, '🐉');
    }
    const pool = [[4, 0, 4, 8], [0, 1, 8, 7], [1, 1, 7, 7], [2, 1, 6, 7], [3, 1, 5, 7], [4, 1, 4, 7], [5, 1, 3, 7], [6, 1, 2, 7], [7, 1, 1, 7], [8, 1, 0, 7], [0, 2, 8, 6], [1, 2, 7, 6], [2, 2, 6, 6], [3, 2, 5, 6], [4, 2, 4, 6], [5, 2, 3, 6], [6, 2, 2, 6], [7, 2, 1, 6], [8, 2, 0, 6], [0, 3, 8, 5], [1, 3, 7, 5], [2, 3, 6, 5], [3, 3, 5, 5], [5, 3, 3, 5], [6, 3, 2, 5], [7, 3, 1, 5], [8, 3, 0, 5], [0, 4, 8, 4], [1, 4, 7, 4], [2, 4, 6, 4], [3, 4, 5, 4]];
    for (let remainingPairs = Math.floor(Math.min(requestedDragonCount / 2, 23.25));
      remainingPairs > 0;
      --remainingPairs) {
      const index = impure.getRandomIndex(pool);
      const [x, y, mirrorX, mirrorY] = pool[index];
      const replacement = pool.pop();
      if (index < pool.length) {
        pool[index] = replacement;
      }
      result = result.modified(x, y, undefined, '🐉');
      result = result.modified(mirrorX, mirrorY, undefined, '🐉');
    }
    return result;
  }

  _deserializeDragons(dragonString) {
    return new Side(
      BigInt(dragonString), 0n, 0n,
      undefined, 0n, 0n,
      undefined,
      0n, undefined, undefined, undefined, undefined,
      undefined, undefined,
    );
  }

  _deserializeSide(serialization) {
    const [pawnString, knightString, towerString] = serialization.split('/');
    return new Side(BigInt(pawnString), BigInt(knightString), BigInt(towerString));
  }

  deserializePosition(serialization) {
    const [dragonString, colorsString] = serialization.split(';');
    return new Position(this._deserializeDragons(dragonString), colorsString.split(',').map(this._deserializeSide));
  }
}

const GAME = new Game();
export default GAME;
