import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  useClientIdentity,
} from '../../client.js';

import { Caption } from './caption.js';
import { Board } from './board.js';
import { ScoreSheet } from './scoreSheet.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';
import { Controls } from './controls.js';
import { AI } from './ai.js';
import { Analysis } from './analysis.js';

import styles from './player.module.css';
import resetIcon from '../../icons/reset.svg';
import rotateIcon from '../../icons/rotate.svg';
import boardIcon from '../../icons/board.svg';
import scoreSheetIcon from '../../icons/scoreSheet.svg';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectNetworkCodes,
  selectPlayers,
  selectNextAvailabilities,
  rotateBoard,
} from './gameTreesSlice.js';

export function Player(props) {
  const clientIdentity = useClientIdentity();

  const treesBySlot = useSelector(selectTreesBySlot);
  const treeName = treesBySlot[props.slot];
  const overNetwork = useSelector(selectNetworkCodes)[treeName] !== undefined;
  const players = useSelector(selectPlayers)[treeName];
  const nextAvailable = useSelector(selectNextAvailabilities)[treeName];

  const dispatch = useDispatch();

  const [showingScoreSheet, setShowingScoreSheet] = useState(false);

  if (props.remote && clientIdentity === undefined) {
    return (
      <Navigate to={props.backTo} />
    );
  }

  if (props.settingsTo !== undefined && (players === undefined || players.includes(null))) {
    return (
      <Navigate to={props.settingsTo} />
    );
  }

  return (
    <>
      { showingScoreSheet ?
        <ScoreSheet slot={props.slot} locked={props.boardLocked || overNetwork} /> :
        <div className={styles.player}>
          <Caption slot={props.slot} caption={props.caption} analysisOnly={props.analysisOnly} />
          <Board
            slot={props.slot}
            analysisOnly={props.analysisOnly}
            analysisDepth={props.analysisDepth}
            forceShowAnalysisIndicator={props.forceShowAnalysisIndicator}
            disabled={props.boardDisabled}
            locked={props.boardLocked || (overNetwork && nextAvailable)}
            hideFiles={props.hideFiles}
            hideRanks={props.hideRanks}
            hidePoints={props.hidePoints}
            highlightFiles={props.highlightFiles}
            highlightRanks={props.highlightRanks}
            highlightPoints={props.highlightPoints}
            target={props.target}
            backTo={props.backTo} />
        </div> }
      <ButtonBar>
        { props.replaceRotateWithReset ?
          <Button
            image={resetIcon}
            altText={'Reset Tutorial'}
            disabled={props.boardDisabled || props.onReset === undefined}
            onClick={props.onReset} /> :
          <Button
            image={rotateIcon}
            altText={'Rotate Board'}
            disabled={props.rotationLocked}
            onClick={() => dispatch(rotateBoard({ treeName }))} /> }
        <Controls
          slot={props.slot}
          playerIndex={props.controlsPlayerIndex}
          onFirst={props.onFirst}
          onBoundaryPrevious={props.onBoundaryPrevious}
          onBoundaryNext={props.onBoundaryNext}
          onLast={props.onLast}
          forceLastAvailable={props.forceLastAvailable}
          disabled={props.boardDisabled} />
        { showingScoreSheet ?
          <Button image={boardIcon} altText={'Show Board'} onClick={() => setShowingScoreSheet(false)} /> :
          <Button
            image={scoreSheetIcon}
            altText={'Show Score Sheet'}
            onClick={() => setShowingScoreSheet(true)} /> }
      </ButtonBar>
      <AI slot={props.slot} forceInactive={props.analysisOnly} />
      { props.analysisDepth !== undefined ?
        <Analysis slot={props.slot} analysisDepth={props.analysisDepth} forceAnalysis={props.analysisOnly} /> :
        null }
    </>
  );
}

Player.propTypes = {
  slot: PropTypes.string.isRequired,
  remote: PropTypes.bool.isRequired,
  caption: PropTypes.node,
  analysisOnly: PropTypes.bool.isRequired,
  analysisDepth: PropTypes.number,
  forceShowAnalysisIndicator: PropTypes.bool,
  boardDisabled: PropTypes.bool,
  boardLocked: PropTypes.bool,
  rotationLocked: PropTypes.bool,
  controlsPlayerIndex: PropTypes.number,
  hideFiles: PropTypes.func,
  hideRanks: PropTypes.func,
  hidePoints: PropTypes.func,
  highlightFiles: PropTypes.func,
  highlightRanks: PropTypes.func,
  highlightPoints: PropTypes.func,
  target: PropTypes.string,
  onReset: PropTypes.func,
  onFirst: PropTypes.func,
  onBoundaryPrevious: PropTypes.func,
  onBoundaryNext: PropTypes.func,
  onLast: PropTypes.func,
  replaceRotateWithReset: PropTypes.bool,
  forceLastAvailable: PropTypes.bool,
  settingsTo: PropTypes.string,
  backTo: PropTypes.string,
};
