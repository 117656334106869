import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HashRouter as Router } from 'react-router-dom';

import { store, persistor } from './app/store.js';
import { NetworkClientProvider } from './client.js';
import { App } from './app.js';

import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NetworkClientProvider>
          <Router>
            <div id="portrait">
              <div id="app">
                <App />
              </div>
            </div>
          </Router>
        </NetworkClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
