import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { takeTime } from './gameTreesSlice.js';

// Sometimes the app wants to dispatch Redux actions (like to take spent time
// off of a game clock), but there won't be any time for Redux to process those
// actions because the user has abruptly closed the app.  Using this code, we
// can stash those actions away in local storage and then run them immediately
// when the app is opened again.

const UNRESOLVED_TAKE_TIME_LOCAL_STORAGE_KEY = 'unresolvedTakeTime';

export function useOnAbruptShutdown(effect) {
  useEffect(() => {
    const listener = document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        effect();
      }
    });
    return () => document.removeEventListener('visibilitychange', listener);
  });
}

export function takeTimeDuringAbruptShutdown(action) {
  localStorage.setItem(UNRESOLVED_TAKE_TIME_LOCAL_STORAGE_KEY, JSON.stringify(action));
}

export function AbruptShutdownRecovery() {
  const dispatch = useDispatch();
  useEffect(() => {
    const serialization = localStorage.getItem(UNRESOLVED_TAKE_TIME_LOCAL_STORAGE_KEY);
    if (serialization) {
      localStorage.removeItem(UNRESOLVED_TAKE_TIME_LOCAL_STORAGE_KEY);
      try {
        dispatch(takeTime(JSON.parse(serialization)));
      } catch (exception) {
        if (!(exception instanceof SyntaxError)) {
          throw exception;
        }
      }
    }
  });
  return null;
}
