/* eslint-disable no-magic-numbers */
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
/* eslint-enable no-magic-numbers */

function getQuotientAndRemainder(numerator, denominator) {
  const quotient = Math.floor(numerator / denominator);
  const remainder = numerator - quotient * denominator;
  return [quotient, remainder];
}

function toWidth(value, width) {
  const trimmed = `${value}`;
  return width !== undefined ? `${'0'.repeat(Math.max(width - trimmed.length, 0))}${trimmed}` : trimmed;
}

export function formatDuration(milliseconds) {
  let prefix = '';
  let remaining = Math.max(Math.round(milliseconds / SECOND) * SECOND, 0);
  for (const [width, unitName, unit] of [
    [2, 'd', DAY],
    [2, 'h', HOUR],
    [2, 'm', MINUTE],
  ]) {
    const [quotient, remainder] = getQuotientAndRemainder(remaining, unit);
    if (prefix !== '' || quotient > 0) {
      prefix += `${prefix === '' ? quotient : toWidth(quotient, width)}${unitName} `;
    }
    remaining = remainder;
  }
  const [quotient] = getQuotientAndRemainder(remaining, SECOND);
  return `${prefix}${prefix === '' ? quotient : toWidth(quotient, 2)}s`;
}

export function untilNextUserVisibleTick(milliseconds) {
  const result = milliseconds % SECOND;
  return result > 0 ? result : SECOND;
}
