import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '../../widgets/buttonBar.js';

import firstIcon from '../../icons/first.svg';
import previousIcon from '../../icons/previous.svg';
import nextIcon from '../../icons/next.svg';
import lastIcon from '../../icons/last.svg';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectPreviousAvailabilities,
  selectNextAvailabilities,
  jumpToFirst,
  jumpToPrevious,
  jumpToNext,
  jumpToLast,
} from './gameTreesSlice.js';

export function Controls(props) {
  const treeName = useSelector(selectTreesBySlot)[props.slot];
  const previousAvailable = useSelector(selectPreviousAvailabilities)[treeName];
  const nextAvailable = useSelector(selectNextAvailabilities)[treeName];

  const firstButtonEnabled = (previousAvailable || props.onBoundaryPrevious !== undefined) && !props.disabled;
  const previousButtonEnabled = (previousAvailable || props.onBoundaryPrevious !== undefined) && !props.disabled;
  const nextButtonEnabled = (nextAvailable || props.onBoundaryNext !== undefined) && !props.disabled;
  const lastButtonEnabled = (nextAvailable || props.forceLastAvailable) && !props.disabled;

  const dispatch = useDispatch();

  const onFirst = () => {
    dispatch(jumpToFirst({
      treeName,
    }));
    if (props.onFirst !== undefined) {
      props.onFirst();
    }
  };
  const onPrevious = previousAvailable ?
    () => dispatch(jumpToPrevious({
      treeName,
      playerIndex: props.playerIndex,
    })) :
    props.onBoundaryPrevious;
  const onNext = nextAvailable ?
    () => dispatch(jumpToNext({
      treeName,
      playerIndex: props.playerIndex,
    })) :
    props.onBoundaryNext;
  const onLast = () => {
    dispatch(jumpToLast({
      treeName,
    }));
    if (props.onLast !== undefined) {
      props.onLast();
    }
  };

  return (
    <>
      <Button image={firstIcon} altText={'First'} disabled={!firstButtonEnabled} onClick={onFirst} />
      <Button image={previousIcon} altText={'Previous'} disabled={!previousButtonEnabled} onClick={onPrevious} />
      <Button image={nextIcon} altText={'Next'} disabled={!nextButtonEnabled} onClick={onNext} />
      <Button image={lastIcon} altText={'Last'} disabled={!lastButtonEnabled} onClick={onLast} />
    </>
  );
}

Controls.propTypes = {
  slot: PropTypes.string.isRequired,
  playerIndex: PropTypes.number,
  onFirst: PropTypes.func,
  onBoundaryPrevious: PropTypes.func,
  onBoundaryNext: PropTypes.func,
  onLast: PropTypes.func,
  forceLastAvailable: PropTypes.bool,
  disabled: PropTypes.bool,
};
