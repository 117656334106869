import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './caption.module.css';
import thinkingIcon from '../../icons/thinking.svg';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectNetworkCodes,
  selectPlayers,
  selectWinnersOnTime,
  selectWinnersByResignation,
  selectGames,
  selectNextAvailabilities,
  selectPositions,
  selectRecentMoves,
  selectMoveSets,
} from './gameTreesSlice.js';

import { HUMAN_PLAYER, REMOTE_PLAYER } from './playerTypes.js';

const NUMBER_WORDS = [
  'Zero',
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
];

function asIndefiniteArticleAndNumber(number) {
  const digits = `${number}`;
  // eslint-disable-next-line no-magic-numbers
  const article = number === 11 || number === 18 || digits.startsWith('8') ? 'an' : 'a';
  const word = NUMBER_WORDS[number] || digits;
  return `${article} ${word}`;
}

/* eslint-disable no-irregular-whitespace */

export function Caption(props) {
  const treeName = useSelector(selectTreesBySlot)[props.slot];
  const networkCode = useSelector(selectNetworkCodes)[treeName];
  const players = useSelector(selectPlayers)[treeName];
  const winnerOnTime = useSelector(selectWinnersOnTime)[treeName];
  const winnerByResignation = useSelector(selectWinnersByResignation)[treeName];
  const game = useSelector(selectGames)[treeName];
  const nextAvailable = useSelector(selectNextAvailabilities)[treeName];
  const position = useSelector(selectPositions)[treeName];
  const recentMove = useSelector(selectRecentMoves)[treeName];
  const moves = useSelector(selectMoveSets)[treeName];

  let caption = props.caption;
  let etiquette = null;
  if (caption === undefined) {
    if (game === undefined || position === undefined) {
      caption = <p>No game.</p>;
    } else if (!players) {
      caption = <p>Waiting for players to be set…</p>;
    } else {
      const humanCount = players.reduce((count, player) => count + (player?.type === HUMAN_PLAYER ? 1 : 0), 0);
      if (networkCode !== undefined &&
          players.some((player) => player?.type === REMOTE_PLAYER && player.name === undefined)) {
        caption = null;
      } else {
        let description = undefined;
        if (recentMove !== undefined) {
          const previousPly = position.ply - 1;
          const turnNumber = 1 + Math.floor(previousPly / game.playerCount);
          const ellipsisCount = previousPly % game.playerCount;
          description = `${turnNumber}.${' …'.repeat(ellipsisCount)} ${recentMove}.`;
        } else {
          description = `Start of ${asIndefiniteArticleAndNumber(game.playerCount)}-Player Game.`;
        }
        const toName = (playerIndex = undefined) => { // returns `undefined` for "you"
          const definedIndex = playerIndex !== undefined ? playerIndex : position.ply % game.playerCount;
          if (humanCount === 1 && players[definedIndex].type === HUMAN_PLAYER) {
            return undefined;
          }
          if (players.some((player) => player?.type === REMOTE_PLAYER)) {
            const name = players[definedIndex]?.name;
            if (name !== undefined) {
              return `"${name}"`;
            }
          }
          return `Player ${definedIndex + 1}`;
        };
        if (position.mostRecentMove !== undefined) {
          const name = toName();
          const status = name === undefined ? 'You to move.' : `${name} to move.`;
          caption = <p>{description}<br />{status}</p>;
        } else if (winnerByResignation !== undefined) {
          const name = toName(winnerByResignation);
          const status = name === undefined ?
            'You win because your opponent resigned.' :
            players.length === 2 && toName(1 - winnerByResignation) === undefined ?
              `${name} wins because you resigned.` :
              `${name} wins because their ${players.length > 2 ? 'opponents' : 'opponent'} resigned.`;
          caption = <p>{description}<br /><strong>{status}</strong></p>;
        } else if (!position.live) {
          const deserializedPosition = game.deserializePosition(position.serialization);
          let winner = undefined;
          for (let i = 0; i < game.playerCount; ++i) {
            if (deserializedPosition.getStaticEvaluation(i) >= game.victory) {
              winner = (position.ply + i) % game.playerCount;
              break;
            }
          }
          if (winnerOnTime !== undefined && winnerOnTime !== winner) {
            if (winner !== undefined) {
              const winnerOnTimeName = toName(winnerOnTime);
              const timeStatus = winnerOnTimeName === undefined ?
                'You win on time!' :
                `${winnerOnTimeName} wins on time!`;
              const winnerName = toName(winner);
              const status = winnerName === undefined ? 'You win on the board.' : `${winnerName} wins on the board.`;
              caption =
                <p>
                  {description}
                  <br />
                  <strong>{timeStatus}</strong>
                  <br />
                  ({status})
                </p>;
            } else {
              const name = toName(winnerOnTime);
              const status = name === undefined ? 'You win on time!' : `${name} wins on time!`;
              caption = <p>{description}<br /><strong>{status}</strong></p>;
            }
          } else if (winner !== undefined) {
            const name = toName(winner);
            const status = name === undefined ? 'You win!' : `${name} wins!`;
            caption = <p>{description}<br /><strong>{status}</strong></p>;
          } else {
            caption = <p>{description}<br /><strong>Game over.</strong></p>;
          }
        } else if (props.analysisOnly) {
          const name = toName();
          const status = name === undefined ? 'You to move.' : `${name} to move.`;
          caption = <p>{description}<br />{status}</p>;
        } else if (players[position.ply % game.playerCount].type === HUMAN_PLAYER) {
          if (moves.length === 1 && moves[0] === game.pass) {
            caption = <p>{description}<br />Automatically passing…</p>;
          } else {
            caption = <p>{description}<br />Your turn.</p>;
          }
        } else {
          const thinking = <img src={thinkingIcon} alt="" />;
          const name = toName();
          console.assert(name !== undefined, 'Tried to use the pronoun "you" for a non-human player.');
          caption = <p>{description}<br />{thinking}&nbsp;Waiting for {name} to move…</p>;
        }
        if (networkCode !== undefined && humanCount > 0) {
          if (recentMove === undefined && !nextAvailable && winnerOnTime === undefined) {
            etiquette = <p>Good luck, and have fun!</p>;
          }
          if (!position.live || winnerByResignation !== undefined) {
            etiquette = <p>Good game!</p>;
          }
        }
      }
    }
  }

  return (
    <div className={styles.caption}>
      <div>
        {caption}
        {etiquette}
      </div>
    </div>
  );
}

Caption.propTypes = {
  slot: PropTypes.string.isRequired,
  caption: PropTypes.node,
  analysisOnly: PropTypes.bool.isRequired,
};
