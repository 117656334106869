import { combineReducers, getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import tutorialProgressSlice from '../features/tutorial/tutorialProgressSlice.js';
import defaultSettingsSlice from '../features/lobby/defaultSettingsSlice.js';
import treeSlotsSlice from '../features/lobby/treeSlotsSlice.js';
import gameTreesSlice from '../features/play/gameTreesSlice.js';
import piecesSlice from '../features/preferences/piecesSlice.js';

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
  },
  combineReducers({
    [tutorialProgressSlice.name]: tutorialProgressSlice.reducer,
    [defaultSettingsSlice.name]: defaultSettingsSlice.reducer,
    [treeSlotsSlice.name]: treeSlotsSlice.reducer,
    [gameTreesSlice.name]: gameTreesSlice.reducer,
    [piecesSlice.name]: piecesSlice.reducer,
  }),
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    immutableCheck: false,
    // If you want to enable serializableCheck, then set its value to the object
    // {
    //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    // }
    // instead of true, where those constants are imported from redux-persist
    // (see https://github.com/rt2zz/redux-persist/issues/988).
    serializableCheck: false,
  }),
});
export const persistor = persistStore(store);
