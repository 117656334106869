import { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './form.module.css';

export const Field = forwardRef((props, ref) => {
  const validated = props.required || props.minimumLength !== undefined;
  const [originalValue, setOriginalValue] = useState(undefined);
  const [modified, setModified] = useState(false);
  useEffect(() => {
    if (originalValue === undefined) {
      setOriginalValue(props.value);
    } else if (props.value !== originalValue) {
      setModified(true);
    }
  }, [originalValue, props.value]);
  const input = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => input.current.focus(),
    markUnmodified: () => setModified(false),
    get validity() {
      return input.current?.validity;
    },
  }));
  const inputClasses = classNames({
    [styles.validated]: validated && modified,
  });
  useEffect(() => {
    if (props.autofocus) {
      input.current.focus();
    }
  }, [props.autofocus, input]);
  const [ignoreNextEnter, setIgnoreNextEnter] = useState(false);
  const onCompositionEnd = (event) => setIgnoreNextEnter(true);
  const onKeyUp = (event) => {
    if (props.onEnterKey !== undefined && event.key === 'Enter' && !ignoreNextEnter) {
      props.onEnterKey();
    }
    setIgnoreNextEnter(false);
  };
  return (
    <label className={styles.field}>
      <span>{props.label} </span>
      <input
        ref={input}
        className={inputClasses}
        type={props.type}
        required={props.required}
        aria-required={props.required}
        minLength={props.minimumLength}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange}
        onCompositionEnd={onCompositionEnd}
        onKeyUp={onKeyUp} />
    </label>
  );
});

Field.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  minimumLength: PropTypes.number,
  value: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnterKey: PropTypes.func,
};

export function Form(props) {
  return (
    <div className={styles.form}>
      {props.children}
    </div>
  );
}
