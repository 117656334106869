import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useClientIdentity, observeLiveGames, unobserveLiveGames, useOnCleanup } from '../../client.js';

import { Modal, createModalOpener } from '../../widgets/modal.js';
import { Menu, MenuText, MenuButton, MenuTextBox } from '../../widgets/menu.js';
import { Form } from '../../widgets/form.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';

import { Board } from '../play/board.js';

import styles from './settingsMenu.module.css';
import accountIcon from '../../icons/account.svg';
import inviteIcon from '../../icons/invite.svg';
import thinkingIcon from '../../icons/thinking.svg';

import {
  selectDefaultSlowestTimeControlsBySlot,
} from './defaultSettingsSlice.js';
import {
  loadTree,
} from './treeSlotsSlice.js';
import {
  selectPreviews,
  newGame,
  touchGame,
  moveGame,
  deleteGame,
  retitleGame,
} from '../play/gameTreesSlice.js';

import GAME_LIBRARY from '../../gameLibrary.js';
import { formatAsText } from '../play/playerTypes.js';

export const impure = {
  createTimestamp() {
    return new Date();
  },
};

const ORIGINAL_DEFAULT_REMOTE_TIME_CONTROLS = 720;

function SavedGame(props) {
  const title = props.preview.title || `Game from ${props.preview.modificationTime}`;
  const versus = props.preview.players !== undefined && !props.preview.players.includes(null) ?
    props.preview.players.map(formatAsText).join(' vs. ') :
    '';
  const editSubpath = `savedGameContextMenu${props.index}`;
  const renameSubpath = `rename${props.index}`;

  const [newTitle, setNewTitle] = useState('');

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const onLoadGame = () => {
    dispatch(loadTree({
      slot: props.slot,
      treeName: props.preview.treeName,
    }));
    navigate(props.to);
  };
  const onDeleteGame = () => {
    dispatch(deleteGame({
      treeName: props.preview.treeName,
    }));
    navigate(-1);
  };
  const onStartRename = (event) => {
    setNewTitle(props.preview.title || '');
    return createModalOpener(location, navigate, renameSubpath)(event);
  };
  const onNewTitleChange = (event) => {
    setNewTitle(event.target.value);
  };
  const onRenameGame = () => {
    dispatch(retitleGame({
      treeName: props.preview.treeName,
      title: newTitle.trim(),
    }));
    // eslint-disable-next-line no-magic-numbers
    navigate(-2);
  };

  return (
    <>
      <MenuButton onClick={onLoadGame} onContextMenu={createModalOpener(location, navigate, editSubpath)}>
        <Board
          treeName={props.preview.treeName}
          forceRunningClock={props.forceRunningClock}
          disabled={true}
          analysisOnly={false} />
        {title}<br />
        {versus}
      </MenuButton>
      <Modal subpath={editSubpath} altText={title}>
        <Menu>
          <MenuText>
            {`"${title}"`}
          </MenuText>
          <MenuButton onClick={onStartRename}>
            Rename
          </MenuButton>
          <MenuButton disabled={props.remote} onClick={onDeleteGame}>
            Delete
          </MenuButton>
        </Menu>
      </Modal>
      <Modal subpath={renameSubpath} altText={title}>
        <Menu>
          <MenuText>
            {`Rename "${title}" to:`}
          </MenuText>
          <MenuTextBox value={newTitle} autofocus={true} onChange={onNewTitleChange} onEnterKey={onRenameGame} />
          <MenuButton disabled={!/[^\p{Z}\p{C}]/u.test(newTitle)} onClick={onRenameGame}>
            Rename
          </MenuButton>
        </Menu>
      </Modal>
    </>
  );
}

SavedGame.propTypes = {
  index: PropTypes.number,
  slot: PropTypes.string.isRequired,
  remote: PropTypes.bool.isRequired,
  preview: PropTypes.shape({
    treeName: PropTypes.string.isRequired,
    title: PropTypes.string,
    modificationTime: PropTypes.instanceOf(Date),
  }),
  forceRunningClock: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

function LoadedGameMenu(props) {
  const game = GAME_LIBRARY.get(props.gameIdentifier);

  const defaultTimeControls = useSelector(selectDefaultSlowestTimeControlsBySlot)[props.slot];
  const previews = useSelector(selectPreviews);
  const newGamePreviewTreeName = `${props.slot}Preview`;
  const newGamePreview = previews[newGamePreviewTreeName];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (newGamePreview === undefined) {
      dispatch(newGame({
        treeName: newGamePreviewTreeName,
        gameIdentifier: props.gameIdentifier,
        timeControls: props.remote ?
          defaultTimeControls !== undefined ? defaultTimeControls : ORIGINAL_DEFAULT_REMOTE_TIME_CONTROLS :
          undefined,
        rootPosition: game.buildStartingPositionWithDragons(props.dragons || 0).serialization,
        eternal: false,
        compensated: true,
      }));
    }
  });

  const onNewGame = () => {
    const treeName = `${props.slot}/${impure.createTimestamp()}`;
    dispatch(moveGame({
      fromTreeName: newGamePreviewTreeName,
      toTreeName: treeName,
    }));
    dispatch(touchGame({
      treeName,
    }));
    dispatch(loadTree({
      slot: props.slot,
      treeName,
    }));
    navigate(props.newGameTo);
  };

  const savedGamePreviews = [];
  for (const treeName of Object.getOwnPropertyNames(previews)) {
    if (treeName.startsWith(`${props.slot}/`)) {
      const preview = previews[treeName];
      if (preview.game === game) {
        savedGamePreviews.push({
          treeName,
          ...preview,
        });
      }
    }
  }
  const savedGames = savedGamePreviews.sort((left, right) => {
    if (left.modificationTime < right.modificationTime) {
      return -1;
    }
    if (left.modificationTime > right.modificationTime) {
      return 1;
    }
    return 0;
  }).map((preview, index) =>
    <SavedGame
      key={index}
      index={index}
      slot={props.slot}
      remote={props.remote}
      preview={preview}
      forceRunningClock={props.remote}
      to={props.loadGameTo} />);

  return (
    <>
      <Menu bottomAligned={true}>
        { savedGames.length > 0 ?
          savedGames :
          <MenuButton disabled={true}>
            [No saved games available.]
          </MenuButton> }
        <MenuButton scrolledIntoView={true} onClick={onNewGame}>
          <Board treeName={newGamePreviewTreeName} disabled={true} analysisOnly={false} />
          New Game
        </MenuButton>
      </Menu>
      { props.accountTo !== undefined || props.acceptTo !== undefined ?
        <ButtonBar>
          { props.accountTo !== undefined ?
            <Button image={accountIcon} altText={'Account'} onClick={() => navigate(props.accountTo)} /> :
            null }
          { props.acceptTo !== undefined ?
            <Button image={inviteIcon} altText={'Accept an Invitation'} onClick={() => navigate(props.acceptTo)} /> :
            null }
        </ButtonBar> :
        null }
    </>
  );
}

LoadedGameMenu.propTypes = {
  slot: PropTypes.string.isRequired,
  gameIdentifier: PropTypes.string.isRequired,
  dragons: PropTypes.number,
  remote: PropTypes.bool.isRequired,
  accountTo: PropTypes.string,
  acceptTo: PropTypes.string,
  newGameTo: PropTypes.string.isRequired,
  loadGameTo: PropTypes.string.isRequired,
};

function LoadingGameMenu(props) {
  const navigate = useNavigate();
  return (
    <>
      <Form>
        <p className={styles.message}>
          <strong>
            <img src={thinkingIcon} alt="" />&nbsp;Loading network games…
          </strong>
        </p>
      </Form>
      <ButtonBar>
        <Button image={accountIcon} altText={'Account'} onClick={() => navigate(props.accountTo)} />
      </ButtonBar>
    </>
  );
}

LoadingGameMenu.propTypes = {
  accountTo: PropTypes.string.isRequired,
};

export function GameMenu(props) {
  const clientIdentity = useClientIdentity();
  const [loading, setLoading] = useState(props.remote);
  useOnCleanup(() => setLoading(false));
  useEffect(() => {
    if (clientIdentity === undefined || !loading) {
      return undefined;
    }
    observeLiveGames().catch(() => {});
    return () => unobserveLiveGames().catch(() => {});
  }, [clientIdentity, loading]);

  if (props.remote && clientIdentity === undefined) {
    return (
      <Navigate to={props.accountTo} />
    );
  }

  return (
    <>
      { loading ?
        <LoadingGameMenu accountTo={props.accountTo} /> :
        <LoadedGameMenu
          slot={props.slot}
          gameIdentifier={props.gameIdentifier}
          dragons={props.dragons}
          remote={props.remote}
          accountTo={props.accountTo}
          acceptTo={props.acceptTo}
          newGameTo={props.newGameTo}
          loadGameTo={props.loadGameTo} /> }
    </>
  );
}

GameMenu.propTypes = {
  slot: PropTypes.string.isRequired,
  gameIdentifier: PropTypes.string.isRequired,
  dragons: PropTypes.number,
  remote: PropTypes.bool.isRequired,
  accountTo: PropTypes.string,
  acceptTo: PropTypes.string,
  newGameTo: PropTypes.string.isRequired,
  loadGameTo: PropTypes.string.isRequired,
};
