import PropTypes from 'prop-types';

import styles from './heading.module.css';

export function Heading(props) {
  return (
    <div className={styles.heading}>
      <h1>{props.text}</h1>
    </div>
  );
}

Heading.propTypes = {
  text: PropTypes.string.isRequired,
};
