export default class Communicator {
  constructor(worker) {
    this.worker = worker;
    this.communication = (async() => {})();
    this.resolve = undefined;
    this.worker.addEventListener('message', (message) => this.receiveMessage(message.data));
  }

  _schedule(communication) {
    this.communication = this.communication.then(communication);
  }

  _sendMessage(type, ...values) {
    this.worker.postMessage([type, ...values].join(' '));
  }

  _response() {
    return new Promise((resolve) => {
      console.assert(this.resolve === undefined, 'Communication with the web worker must be synchronized.');
      this.resolve = () => {
        this.resolve = undefined;
        resolve();
      };
      this._sendMessage('isready');
    });
  }

  onReceiveMessage(type, ...values) {}

  receiveMessage(message) {
    if (message === 'readyok') {
      console.assert(this.resolve !== undefined, 'Communication with the web worker must be synchronized.');
      this.resolve();
    } else {
      this.onReceiveMessage(...message.split(' '));
    }
  }
}
