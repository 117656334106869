import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useClientIdentity, accept, useOnInvitationAccepted } from '../../client.js';

import {
  loadTree,
} from './treeSlotsSlice.js';

import { Field, Form } from '../../widgets/form.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';

import backIcon from '../../icons/back.svg';

export function Invitation(props) {
  const clientIdentity = useClientIdentity();

  const messageElement = useRef();

  const [disabled, setDisabled] = useState(false);
  const [message, setMessageDirectly] = useState();

  const [networkCode, setNetworkCode] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useOnInvitationAccepted((event) => {
    if (event.gameDescription.networkCode === networkCode.trim()) {
      const treeName = `${props.slot}/${event.gameDescription.networkCode}`;
      dispatch(loadTree({
        slot: props.slot,
        treeName,
      }));
      navigate(props.to);
    }
  });

  const setMessage = (content) => {
    setMessageDirectly(content);
    if (messageElement.current && messageElement.current.scrollIntoView !== undefined) {
      messageElement.current.scrollIntoView();
    }
  };

  const onAccept = () => {
    setDisabled(true);
    setMessage('Accepting invitation…');
    accept(networkCode.trim()).catch(() => {
      setDisabled(false);
      setMessage('Invitation acceptance failed.  Please check the invite code and try again.');
    });
  };

  if (clientIdentity === undefined) {
    return (
      <Navigate to={props.backTo} />
    );
  }

  return (
    <>
      <Form>
        <p ref={messageElement}>
          <strong>{message !== undefined ? message : ''}</strong>
        </p>
        <Field
          label={'Invite Code:'}
          type={'text'}
          value={networkCode}
          autofocus={true}
          disabled={disabled}
          onChange={(event) => setNetworkCode(event.target.value)}
          onEnterKey={onAccept} />
        <ButtonBar>
          <Button text={'Accept'} disabled={disabled} onClick={onAccept} />
        </ButtonBar>
      </Form>
      <ButtonBar>
        <Button image={backIcon} altText={'Back'} onClick={() => navigate(props.backTo)} />
      </ButtonBar>
    </>
  );
}

Invitation.propTypes = {
  slot: PropTypes.string.isRequired,
  backTo: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
