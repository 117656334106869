import { createSlice } from '@reduxjs/toolkit';

const treeSlotsSlice = createSlice({
  name: 'treeSlots',
  initialState: {},
  reducers: {
    loadTree: (treeSlots, action) => {
      const {
        slot,
        treeName,
      } = action.payload;
      treeSlots[slot] = treeName;
    },
  },
});
export default treeSlotsSlice;

export const { loadTree } = treeSlotsSlice.actions;

export function selectTreesBySlot(state) {
  return state.treeSlots;
}
