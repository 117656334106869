import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { observeGame, unobserveGame } from '../../client.js';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectNetworkCodes,
} from './gameTreesSlice.js';

export function OneGameMonitor(props) {
  const treeName = useSelector(selectTreesBySlot)[props.slot];
  const networkCode = useSelector(selectNetworkCodes)[treeName];
  useEffect(() => {
    if (networkCode === undefined) {
      return undefined;
    }
    observeGame(networkCode).catch(() => {});
    return () => unobserveGame(networkCode).catch(() => {});
  }, [networkCode]);
  return null;
}

OneGameMonitor.propTypes = {
  slot: PropTypes.string.isRequired,
};
