import Communicator from './communicator.js';

export default class Controller extends Communicator {
  constructor(engineURL, engineThreadURL, gameIdentifier, propertyHandler = () => {}, moveHandler = () => {}) {
    super(new Worker(engineURL));
    this._engineThreadURL = engineThreadURL;
    this._compatible = undefined;
    this._connected = false;
    this.gameIdentifier = gameIdentifier;
    this._playing = false;
    this.propertyHandler = propertyHandler;
    this._thinking = false;
    this._movesWanted = [];
    this.moveHandler = moveHandler;
  }

  async connectionCheck() {
    if (this._compatible === undefined) {
      this._sendMessage('bei', this._engineThreadURL);
      await this._response();
    }
    return this._connected;
  }

  async playingCheck() {
    if (this.gameIdentifier === undefined || !await this.connectionCheck()) {
      return false;
    }
    if (!this._playing) {
      this._sendMessage('newgame', this.gameIdentifier);
      await this._response();
    }
    return this._playing;
  }

  setStrength(strength) {
    this._schedule(async() => {
      if (!await this.connectionCheck()) {
        return;
      }
      this._sendMessage('setoption', 'strength', strength);
      await this._response();
    });
  }

  setDepth(depth) {
    this._schedule(async() => {
      if (!await this.connectionCheck()) {
        return;
      }
      this._sendMessage('setoption', 'depth', depth);
      await this._response();
    });
  }

  setLine(position, taboo) {
    this._schedule(async() => {
      if (!await this.playingCheck()) {
        return;
      }
      this._sendMessage(
        'setline',
        position.serialization,
        ...taboo.map((tabooPosition) => tabooPosition.serialization),
      );
      await this._response();
    });
  }

  setMoveHandler(moveHandler) {
    this.moveHandler = moveHandler;
  }

  go() {
    this._schedule(async() => {
      if (!this._thinking) {
        if (!await this.playingCheck()) {
          return;
        }
        this._thinking = true;
        this._movesWanted.push(true);
        this._sendMessage('go');
        await this._response();
      }
    });
  }

  stop(wantMove = false) {
    this._schedule(async() => {
      if (this._thinking) {
        if (!await this.playingCheck()) {
          return;
        }
        this._movesWanted[this._movesWanted.length - 1] = wantMove;
        this._thinking = false;
        this._sendMessage('stop');
        await this._response();
      }
    });
  }

  onReceiveMessage(type, ...values) {
    switch (type) {
    case 'protocol':
      this._compatible = values[0] === '2.0.0';
      break;
    case 'id':
      this.propertyHandler(...values);
      break;
    case 'beiok':
      this._connected = this._compatible;
      break;
    case 'known':
      this._playing = true;
      break;
    case 'unknown':
      this._playing = false;
      break;
    case 'move':
      if (this._movesWanted.shift()) {
        this._thinking = this._movesWanted.length > 0;
        const [score, ...moves] = values;
        this.moveHandler(Number(score), moves[0], moves);
      }
      break;
    case 'log':
      console.log('Log message from engine:', ...values);
      break;
    default:
      console.warn(`Unhandled message type from engine: ${type}`);
    }
  }
}
