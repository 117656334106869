import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './menu.module.css';

export function MenuText(props) {
  return (
    <button disabled className={styles.decorative} role={'heading'} aria-level={2}>
      {props.children}
    </button>
  );
}

export function MenuButton(props) {
  const button = useRef();
  useEffect(() => {
    if (props.scrolledIntoView) {
      button.current.scrollIntoView();
    }
  }, [props.scrolledIntoView, button]);
  return (
    <button ref={button} disabled={props.disabled} onClick={props.onClick} onContextMenu={props.onContextMenu}>
      {props.children}
    </button>
  );
}

MenuButton.propTypes = {
  scrolledIntoView: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
};

export function MenuTextBox(props) {
  const input = useRef();
  useEffect(() => {
    if (props.autofocus) {
      input.current.focus();
    }
  }, [props.autofocus, input]);
  const [ignoreNextEnter, setIgnoreNextEnter] = useState(false);
  const onCompositionEnd = (event) => setIgnoreNextEnter(true);
  const onKeyUp = (event) => {
    if (props.onEnterKey !== undefined && event.key === 'Enter' && !ignoreNextEnter) {
      props.onEnterKey();
    }
    setIgnoreNextEnter(false);
  };
  return (
    <input
      ref={input}
      type="text"
      value={props.value}
      onChange={props.onChange}
      onCompositionEnd={onCompositionEnd}
      onKeyUp={onKeyUp} />
  );
}

MenuTextBox.propTypes = {
  value: PropTypes.string.isRequired,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEnterKey: PropTypes.func,
};

export function Menu(props) {
  const menuClasses = classNames({
    [styles.menu]: true,
    [styles['bottom-aligned']]: props.bottomAligned,
  });
  return (
    <div className={menuClasses}>
      <div className={styles.scrollable}>
        <div className={styles.list}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

MenuButton.propTypes = {
  bottomAligned: PropTypes.bool,
};
