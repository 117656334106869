import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  useClientIdentity,
  logInAsGuest,
  register,
  logIn,
  logOut,
  changeEmail,
  changePassword,
  requestPasswordResetEmail,
  resetPassword,
} from '../../client.js';

import { Field, Form } from '../../widgets/form.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';

import styles from './account.module.css';
import backIcon from '../../icons/back.svg';

const MINIMUM_PASSWORD_LENGTH = 8;

function cleanIdentity(identity) {
  return identity.replaceAll(/[\p{Zl}\p{Zp}\p{C}]/gu, '').replaceAll('＠', '@');
}

function cleanName(name) {
  return name.replaceAll(/[\p{Zl}\p{Zp}\p{C}"@＠]/gu, '');
}

function isInvalid(fieldRef) {
  return !fieldRef.current?.validity?.valid;
}

function LoginAndRegistration(props) {
  const messageElement = useRef();
  const identityField = useRef();
  const loginPasswordField = useRef();
  const loginButton = useRef();
  const nameField = useRef();
  const emailField = useRef();
  const registrationPasswordField = useRef();
  const registrationButton = useRef();
  const firstRecoveryIdentityField = useRef();
  const requestRecoveryButton = useRef();
  const secondRecoveryIdentityField = useRef();
  const recoveryCodeField = useRef();
  const recoveryPasswordField = useRef();
  const recoveryButton = useRef();

  const [disabled, setDisabled] = useState(false);
  const [message, setMessageDirectly] = useState();

  const [identity, setIdentity] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [registrationPassword, setRegistrationPassword] = useState('');
  const [recoveryIdentity, setRecoveryIdentity] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [recoveryPassword, setRecoveryPassword] = useState('');

  const navigate = useNavigate();

  const setMessage = (content) => {
    setMessageDirectly(content);
    if (messageElement.current && messageElement.current.scrollIntoView !== undefined) {
      messageElement.current.scrollIntoView();
    }
  };

  const onGuestLogin = () => {
    setDisabled(true);
    setMessage('Logging in…');
    logInAsGuest().then(() => navigate(props.listTo)).catch(() => {
      setDisabled(false);
      setMessage('Guest login failed.  Please try again.');
    });
  };
  const onLogin = () => {
    loginButton.current.focus();
    setDisabled(true);
    setMessage('Logging in…');
    logIn(identity.trim(), loginPassword).then(() => navigate(props.listTo)).catch(() => {
      setDisabled(false);
      setMessage('Authentication failed.  Please try again.');
    });
  };
  const onRegistration = () => {
    registrationButton.current.focus();
    setDisabled(true);
    setMessage('Registering account…');
    register(name.trim(), email.trim(), registrationPassword).then(() => navigate(props.listTo)).catch(() => {
      setDisabled(false);
      setMessage('Registration failed.  Please try again.');
    });
  };
  const onRequestRecovery = () => {
    requestRecoveryButton.current.focus();
    setDisabled(true);
    setMessage('Requesting recovery code…');
    requestPasswordResetEmail(recoveryIdentity.trim()).then(() => {
      setDisabled(false);
      setMessage('Recovery code email sent.');
    }).catch(() => {
      setDisabled(false);
      setMessage('Recovery code request failed.  Please try again.');
    });
  };
  const onRecover = () => {
    recoveryButton.current.focus();
    setDisabled(true);
    setMessage('Resetting password…');
    resetPassword(recoveryIdentity.trim(), recoveryCode.trim(), recoveryPassword).then(() => {
      setDisabled(false);
      setMessage('Password reset.');
      setRecoveryCode('');
      setRecoveryPassword('');
      recoveryCodeField.current.markUnmodified();
      recoveryPasswordField.current.markUnmodified();
    }).catch(() => {
      setDisabled(false);
      setMessage('Password reset failed.  Please try again.');
    });
  };

  const loginInvalid = isInvalid(identityField) || isInvalid(loginPasswordField);
  const registrationInvalid = isInvalid(nameField) || isInvalid(emailField) || isInvalid(registrationPasswordField);
  const recoveryRequestInvalid = isInvalid(firstRecoveryIdentityField);
  const recoveryInvalid =
    isInvalid(secondRecoveryIdentityField) || isInvalid(recoveryCodeField) || isInvalid(recoveryPasswordField);

  return (
    <Form>
      <p ref={messageElement} className={styles['logged-out']}>
        <strong>{message !== undefined ? message : 'You are currently logged out.'}</strong>
      </p>
      <p>Log in as a guest:</p>
      <ButtonBar>
        <Button
          text={'Log in as Guest'}
          disabled={disabled}
          onClick={onGuestLogin} />
      </ButtonBar>
      <p>Log in with an existing account:</p>
      <Field
        ref={identityField}
        label={'Name or Email:'}
        type={'text'}
        required={true}
        minimumLength={1}
        value={identity}
        autofocus={true}
        disabled={disabled}
        onChange={(event) => setIdentity(cleanIdentity(event.target.value))}
        onEnterKey={() => loginPasswordField.current.focus()} />
      <Field
        ref={loginPasswordField}
        label={'Password:'}
        type={'password'}
        required={true}
        minimumLength={MINIMUM_PASSWORD_LENGTH}
        value={loginPassword}
        disabled={disabled}
        onChange={(event) => setLoginPassword(event.target.value)}
        onEnterKey={onLogin} />
      <ButtonBar>
        <Button
          ref={loginButton}
          text={'Log In'}
          disabled={disabled || loginInvalid}
          onClick={onLogin} />
      </ButtonBar>
      <p>Register a new account:</p>
      <Field
        ref={nameField}
        label={'Name:'}
        type={'text'}
        required={true}
        minimumLength={1}
        value={name}
        disabled={disabled}
        onChange={(event) => setName(cleanName(event.target.value))}
        onEnterKey={() => emailField.current.focus()} />
      <Field
        ref={emailField}
        label={'Email:'}
        type={'email'}
        required={true}
        value={email}
        disabled={disabled}
        onChange={(event) => setEmail(cleanIdentity(event.target.value))}
        onEnterKey={() => registrationPasswordField.current.focus()} />
      <Field
        ref={registrationPasswordField}
        label={'Password:'}
        type={'password'}
        required={true}
        minimumLength={MINIMUM_PASSWORD_LENGTH}
        value={registrationPassword}
        disabled={disabled}
        onChange={(event) => setRegistrationPassword(event.target.value)}
        onEnterKey={onRegistration} />
      <p>(Choose a password that you do not use elsewhere.  Your password must be at least eight characters long.)</p>
      <ButtonBar>
        <Button
          ref={registrationButton}
          text={'Register'}
          disabled={disabled || registrationInvalid}
          onClick={onRegistration} />
      </ButtonBar>
      <p>Or recover an existing account by requesting a recovery code email:</p>
      <Field
        ref={firstRecoveryIdentityField}
        label={'Name or Email:'}
        type={'text'}
        required={true}
        minimumLength={1}
        value={recoveryIdentity}
        disabled={disabled}
        onChange={(event) => setRecoveryIdentity(cleanIdentity(event.target.value))}
        onEnterKey={onRequestRecovery} />
      <ButtonBar>
        <Button
          ref={requestRecoveryButton}
          text={'Request Recovery Code'}
          disabled={disabled || recoveryRequestInvalid}
          onClick={onRequestRecovery} />
      </ButtonBar>
      <p>And then providing that code and choosing a new password:</p>
      <Field
        ref={secondRecoveryIdentityField}
        label={'Name or Email:'}
        type={'text'}
        required={true}
        minimumLength={1}
        value={recoveryIdentity}
        disabled={disabled}
        onChange={(event) => setRecoveryIdentity(cleanIdentity(event.target.value))}
        onEnterKey={() => recoveryCodeField.current.focus()} />
      <Field
        ref={recoveryCodeField}
        label={'Recovery Code:'}
        type={'text'}
        required={true}
        minimumLength={1}
        value={recoveryCode}
        disabled={disabled}
        onChange={(event) => setRecoveryCode(event.target.value)}
        onEnterKey={() => recoveryPasswordField.current.focus()} />
      <Field
        ref={recoveryPasswordField}
        label={'New Password:'}
        type={'password'}
        required={true}
        minimumLength={MINIMUM_PASSWORD_LENGTH}
        value={recoveryPassword}
        disabled={disabled}
        onChange={(event) => setRecoveryPassword(event.target.value)}
        onEnterKey={onRecover} />
      <ButtonBar>
        <Button
          ref={recoveryButton}
          text={'Reset Password'}
          disabled={disabled || recoveryInvalid}
          onClick={onRecover} />
      </ButtonBar>
    </Form>
  );
}

LoginAndRegistration.propTypes = {
  listTo: PropTypes.string.isRequired,
};

function AccountSettings(props) {
  const clientIdentity = useClientIdentity();

  const messageElement = useRef();
  const emailField = useRef();
  const passwordField = useRef();
  const changeEmailButton = useRef();
  const oldPasswordField = useRef();
  const newPasswordField = useRef();
  const changePasswordButton = useRef();

  const [disabled, setDisabled] = useState(false);
  const [message, setMessageDirectly] = useState(undefined);

  const [email, setEmail] = useState(clientIdentity?.email !== undefined ? clientIdentity.email : '');
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const navigate = useNavigate();

  const setMessage = (content) => {
    setMessageDirectly(content);
    if (messageElement.current && messageElement.current.scrollIntoView !== undefined) {
      messageElement.current.scrollIntoView();
    }
  };

  const onChangeEmail = () => {
    changeEmailButton.current.focus();
    setDisabled(true);
    setMessage('Changing email address…');
    changeEmail(email.trim(), password).then(() => {
      setDisabled(false);
      setMessage(undefined);
      setPassword('');
      passwordField.current.markUnmodified();
    }).catch(() => {
      setDisabled(false);
      setMessage('Email address change failed.  Please try again.');
    });
  };
  const onChangePassword = () => {
    changePasswordButton.current.focus();
    setDisabled(true);
    setMessage('Changing password…');
    changePassword(oldPassword, newPassword).then(() => {
      setDisabled(false);
      setMessage('Password changed.');
      setOldPassword('');
      setNewPassword('');
      oldPasswordField.current.markUnmodified();
      newPasswordField.current.markUnmodified();
    }).catch(() => {
      setDisabled(false);
      setMessage('Password change failed.  Please try again.');
    });
  };
  const onLogout = () => {
    setDisabled(true);
    setMessage('Logging out…');
    logOut().catch(() => {
      setDisabled(false);
      setMessage('Logout failed.  Please try again.');
    });
  };

  let identity = `"${clientIdentity.name}"`;
  if (clientIdentity.email) {
    identity += ` <${clientIdentity.email}>`;
  }
  const emailChangeInvalid = isInvalid(emailField) || isInvalid(passwordField);
  const passwordChangeInvalid = isInvalid(oldPasswordField) || isInvalid(newPasswordField);

  return (
    <>
      <Form>
        <p ref={messageElement} className={message === undefined ? styles['logged-in'] : null}>
          <strong>{message !== undefined ? message : `You are currently logged in as ${identity}.`}</strong>
        </p>
        { clientIdentity.email ?
          <>
            <p>Change your email address:</p>
            <Field
              ref={emailField}
              label={'Email:'}
              type={'email'}
              required={true}
              minimumLength={1}
              value={email}
              autofocus={true}
              disabled={disabled}
              onChange={(event) => setEmail(event.target.value)}
              onEnterKey={() => passwordField.current.focus()} />
            <Field
              ref={passwordField}
              label={'Confirm Password:'}
              type={'password'}
              required={true}
              minimumLength={MINIMUM_PASSWORD_LENGTH}
              value={password}
              disabled={disabled}
              onChange={(event) => setPassword(event.target.value)}
              onEnterKey={onChangeEmail} />
            <ButtonBar>
              <Button
                ref={changeEmailButton}
                text={'Change Email Address'}
                disabled={disabled || emailChangeInvalid}
                onClick={onChangeEmail} />
            </ButtonBar>
            <p>Change your password:</p>
            <Field
              ref={oldPasswordField}
              label={'Old Password:'}
              type={'password'}
              required={true}
              minimumLength={MINIMUM_PASSWORD_LENGTH}
              value={oldPassword}
              disabled={disabled}
              onChange={(event) => setOldPassword(event.target.value)}
              onEnterKey={() => newPasswordField.current.focus()} />
            <Field
              ref={newPasswordField}
              label={'New Password:'}
              type={'password'}
              required={true}
              minimumLength={MINIMUM_PASSWORD_LENGTH}
              value={newPassword}
              disabled={disabled}
              onChange={(event) => setNewPassword(event.target.value)}
              onEnterKey={onChangePassword} />
            <ButtonBar>
              <Button
                ref={changePasswordButton}
                text={'Change Password'}
                disabled={disabled || passwordChangeInvalid}
                onClick={onChangePassword} />
            </ButtonBar>
            <p>Or log out:</p>
          </> : null }
        <ButtonBar>
          <Button
            text={'Log Out'}
            disabled={disabled}
            onClick={onLogout} />
        </ButtonBar>
      </Form>
      <ButtonBar>
        <Button image={backIcon} altText={'Back'} onClick={() => navigate(props.listTo)} />
      </ButtonBar>
    </>
  );
}

AccountSettings.propTypes = {
  listTo: PropTypes.string.isRequired,
};

export function Account(props) {
  const clientIdentity = useClientIdentity();
  return (
    <>
      { clientIdentity === undefined ?
        <LoginAndRegistration listTo={props.listTo} /> :
        <AccountSettings listTo={props.listTo} /> }
    </>
  );
}

Account.propTypes = {
  listTo: PropTypes.string.isRequired,
};
