import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectPlayers,
  selectGames,
  selectPositions,
  selectTaboos,
  selectAssistanceFlags,
  setAnalysis,
} from './gameTreesSlice.js';

import Controller from '@unlsoft/boost-engine';
import { HUMAN_PLAYER } from './playerTypes.js';

function suggest(treeName, depth, game, position, taboo, controller, moveHandler) {
  if (controller.current === undefined) {
    controller.current = new Controller(
      'engine.js',
      'engineThread.js',
      game.identifier,
      () => {},
    );
  }
  controller.current.setDepth(depth);
  controller.current.setLine(position, taboo);
  controller.current.setMoveHandler(moveHandler);
  controller.current.go(true);
  return () => {
    if (controller.current !== undefined) {
      controller.current.stop();
    }
  };
}

const MINIMUM_ANALYSIS_DEPTH = 3;

function getNextDepth(depth, maximum) {
  const candidate = Math.max((depth || 0) + 1, MINIMUM_ANALYSIS_DEPTH);
  if (candidate > maximum) {
    return undefined;
  }
  return Math.min(candidate % 2 === 0 ? candidate + 1 : candidate, maximum);
}

export function Analysis(props) {
  const treeName = useSelector(selectTreesBySlot)[props.slot];
  const players = useSelector(selectPlayers)[treeName];
  const game = useSelector(selectGames)[treeName];
  const position = useSelector(selectPositions)[treeName];
  const taboo = useSelector(selectTaboos)[treeName];
  const assistanceFlag = useSelector(selectAssistanceFlags)[treeName];

  const controller = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    if (game === undefined ||
        position === undefined ||
        (!props.forceAnalysis &&
         (players[position.ply % game.playerCount].type !== HUMAN_PLAYER || !assistanceFlag))) {
      return undefined;
    }
    const nextDepth = getNextDepth(position.analysisDepth, props.analysisDepth);
    if (nextDepth !== undefined && nextDepth <= props.analysisDepth) {
      const moveHandler = (score, move, moves) => dispatch(setAnalysis({
        treeName,
        positionIdentity: position.identity,
        analysisDepth: nextDepth,
        advantage: nextDepth === props.analysisDepth ?
          position.ply % game.playerCount === 0 ? score : -score :
          undefined,
        suggestions: moves,
      }));
      return suggest(treeName, nextDepth, game, position, taboo, controller, moveHandler);
    }
    return undefined;
  });

  return null;
}

Analysis.propTypes = {
  slot: PropTypes.string.isRequired,
  analysisDepth: PropTypes.number.isRequired,
  forceAnalysis: PropTypes.bool,
};
