import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { claimToken } from '../../client.js';

import { Form } from '../../widgets/form.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';

import {
  loadTree,
} from './treeSlotsSlice.js';

import styles from './settingsMenu.module.css';
import backIcon from '../../icons/back.svg';
import thinkingIcon from '../../icons/thinking.svg';

export const internals = {
  claimResolution: undefined,
};

export function NotificationHandler(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [networkCode, token] = location.pathname.split('/').slice(-1)[0].split('|');
  dispatch(loadTree({
    slot: props.slot,
    treeName: `${props.slot}/${networkCode}`,
  }));
  if (token === undefined) {
    return <Navigate to={props.accountTo} />;
  }
  internals.claimResolution = // save the promise for testing purposes
    claimToken(token).then(() => navigate(props.to)).catch(() => navigate(props.accountTo));
  return (
    <>
      <Form>
        <p className={styles.message}>
          <strong>
            <img src={thinkingIcon} alt="" />&nbsp;Loading network game…
          </strong>
        </p>
      </Form>
      <ButtonBar>
        <Button image={backIcon} altText={'Cancel'} onClick={() => navigate(props.backTo)} />
      </ButtonBar>
    </>
  );
}

NotificationHandler.propTypes = {
  slot: PropTypes.string.isRequired,
  backTo: PropTypes.string.isRequired,
  accountTo: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};
