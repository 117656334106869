import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectPlayers,
  selectGames,
  selectPositions,
  selectTaboos,
  selectMoveSets,
  makeMove,
} from './gameTreesSlice.js';

import Controller from '@unlsoft/boost-engine';
import { DUMMY_PLAYER, ENGINE_PLAYER } from './playerTypes.js';

export const impure = {
  createTimestamp() {
    return Date.now();
  },
};

function playAsDummy(treeName, player, game, position, dispatch) {
  if (player.moves !== undefined) {
    const move = player.moves[Math.floor(position.ply / game.playerCount)];
    if (move !== undefined) {
      if (move instanceof Array) {
        for (const alternative of move) {
          dispatch(makeMove({
            treeName,
            positionIdentity: position.identity,
            move: alternative,
            time: 0,
          }));
        }
      } else {
        dispatch(makeMove({
          treeName,
          positionIdentity: position.identity,
          move,
          time: 0,
        }));
      }
    }
  } else {
    dispatch(makeMove({
      treeName,
      positionIdentity: position.identity,
      move: 'n/a',
      isNull: true,
      time: 0,
    }));
  }
  return undefined;
}

function playAsEngine(treeName, player, game, position, taboo, controller, dispatch, getElapsedTime) {
  if (controller.current === undefined) {
    controller.current = new Controller(
      'engine.js',
      'engineThread.js',
      game.identifier,
      () => {},
    );
  }
  controller.current.setStrength(player.strength);
  controller.current.setLine(position, taboo);
  controller.current.setMoveHandler((_, move) => dispatch(makeMove({
    treeName,
    positionIdentity: position.identity,
    move,
    time: getElapsedTime(),
  })));
  controller.current.go();
  return () => {
    if (controller.current !== undefined) {
      controller.current.stop();
    }
  };
}

const MINIMUM_TURN_LENGTH = 400; // milliseconds

export function AI(props) {
  const treeName = useSelector(selectTreesBySlot)[props.slot];
  const players = useSelector(selectPlayers)[treeName];
  const game = useSelector(selectGames)[treeName];
  const position = useSelector(selectPositions)[treeName];
  const taboo = useSelector(selectTaboos)[treeName];
  const moves = useSelector(selectMoveSets)[treeName];

  const controller = useRef();
  const timestamp = useRef(-Infinity);

  const dispatch = useDispatch();
  const rateLimitedDispatch = (action) => setTimeout(
    () => dispatch(action),
    Math.max(timestamp.current + MINIMUM_TURN_LENGTH - impure.createTimestamp(), 0),
  );

  const lastUpdate = impure.createTimestamp();
  const getElapsedTime = () => (impure.createTimestamp() - lastUpdate) / 1000;

  useEffect(() => {
    if (game === undefined || position === undefined || !position.live || props.forceInactive) {
      return undefined;
    }
    const player = players[position.ply % game.playerCount];
    if (position.mostRecentMove !== undefined && !player.autoReplay) {
      return undefined;
    }
    if (moves.length === 1 && moves[0] === game.pass &&
      (player.type === DUMMY_PLAYER || player.type === ENGINE_PLAYER)) {
      dispatch(makeMove({
        treeName,
        positionIdentity: position.identity,
        move: game.pass,
        isNull: true,
        time: 0,
      }));
      return undefined;
    }
    timestamp.current = impure.createTimestamp();
    switch (player.type) {
    case DUMMY_PLAYER:
      return playAsDummy(treeName, player, game, position, rateLimitedDispatch);
    case ENGINE_PLAYER:
      return playAsEngine(treeName, player, game, position, taboo, controller, rateLimitedDispatch, getElapsedTime);
    default:
      return undefined;
    }
  });

  return null;
}

AI.propTypes = {
  slot: PropTypes.string.isRequired,
  forceInactive: PropTypes.bool,
};
