import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Modal, createModalOpener } from '../../widgets/modal.js';
import { Menu, MenuText, MenuButton } from '../../widgets/menu.js';

import {
  selectAvailableColorNames,
  selectColorNames,
  resetPieceColors,
  setPieceColor,
} from '../preferences/piecesSlice.js';

export function PiecesSubMenu(props) {
  const availableColorNames = useSelector(selectAvailableColorNames);
  const colorNames = useSelector(selectColorNames);
  const selectedColorName = colorNames.get(props.playerIndex);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submenuButtons = [];
  for (const colorName of availableColorNames) {
    const marker = colorName === selectedColorName ? ' ✓' : '';
    const onClick = () => {
      dispatch(setPieceColor({
        playerIndex: props.playerIndex,
        colorName,
      }));
      // eslint-disable-next-line no-magic-numbers
      navigate(-2);
    };
    submenuButtons.push(
      <MenuButton key={colorName} onClick={onClick}>
        {colorName}{marker}
      </MenuButton>,
    );
  }

  return (
    <Modal subpath={props.subpath} altText={props.title}>
      <Menu>
        <MenuText>
          {props.title}
        </MenuText>
        {submenuButtons}
      </Menu>
    </Modal>
  );
}

PiecesSubMenu.propTypes = {
  subpath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  playerIndex: PropTypes.number.isRequired,
};

export function PiecesMenu(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const menuButtons = [];
  const submenus = [];
  for (let playerIndex = 0; playerIndex < props.playerCount; ++playerIndex) {
    const subpath = `player${playerIndex + 1}`;
    const title = `Change Player ${playerIndex + 1}'s Color`;
    submenus.push(
      <PiecesSubMenu key={playerIndex} subpath={subpath} title={title} playerIndex={playerIndex} />,
    );
    menuButtons.push(
      <MenuButton key={playerIndex} onClick={createModalOpener(location, navigate, subpath)}>
        {title}
      </MenuButton>,
    );
  }

  const onReset = () => {
    dispatch(resetPieceColors());
    navigate(-1);
  };

  return (
    <>
      <Modal subpath={props.subpath} altText={'Piece Settings'}>
        <Menu>
          <MenuText>
            Board Settings
          </MenuText>
          {menuButtons}
          <MenuButton onClick={onReset}>
            Reset Colors
          </MenuButton>
        </Menu>
      </Modal>
      {submenus}
    </>
  );
}

PiecesMenu.propTypes = {
  subpath: PropTypes.string.isRequired,
  playerCount: PropTypes.number.isRequired,
};
