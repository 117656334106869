import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  useOnSeekReady,
  useOnInvitationRescinded,
  useOnInvitationAccepted,
  useOnSynchronization,
  useOnCleanup,
} from '../../client.js';

import {
  synchronizeNetworkGame,
  deleteGame,
  deleteGames,
} from './gameTreesSlice.js';

export function Network(props) {
  const dispatch = useDispatch();
  useOnSeekReady((event) => {
    dispatch(synchronizeNetworkGame({
      treeName: `${props.slot}/${event.gameDescription.networkCode}`,
      ...event.gameDescription,
    }));
  });
  useOnInvitationRescinded((event) => {
    dispatch(deleteGame({
      treeName: `${props.slot}/${event.networkCode}`,
    }));
  });
  useOnInvitationAccepted((event) => {
    dispatch(synchronizeNetworkGame({
      treeName: `${props.slot}/${event.gameDescription.networkCode}`,
      ...event.gameDescription,
    }));
  });
  useOnSynchronization((event) => {
    dispatch(synchronizeNetworkGame({
      treeName: `${props.slot}/${event.gameDescription.networkCode}`,
      ...event.gameDescription,
    }));
  });
  useOnCleanup((event) => {
    dispatch(deleteGames({
      treeNamePrefix: `${props.slot}/`,
      protectedSuffices: event.networkCodes,
    }));
  });
  return null;
}

Network.propTypes = {
  slot: PropTypes.string.isRequired,
};
