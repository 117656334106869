import { createSlice } from '@reduxjs/toolkit';

const defaultSettingsSlice = createSlice({
  name: 'defaultSettings',
  initialState: {
    timeControlFlags: {},
    fastestTimeControls: {},
    slowestTimeControls: {},
    players: {},
  },
  reducers: {
    setDefaultTimeControlFlag: (defaultSettings, action) => {
      const {
        slot,
        timeControlFlag,
      } = action.payload;
      defaultSettings.timeControlFlags[slot] = timeControlFlag;
    },
    setDefaultFastestTimeControls: (defaultSettings, action) => {
      const {
        slot,
        timeControls,
      } = action.payload;
      defaultSettings.fastestTimeControls[slot] = timeControls;
    },
    setDefaultSlowestTimeControls: (defaultSettings, action) => {
      const {
        slot,
        timeControls,
      } = action.payload;
      defaultSettings.slowestTimeControls[slot] = timeControls;
    },
    setDefaultPlayers: (defaultSettings, action) => {
      const {
        slot,
        players,
      } = action.payload;
      defaultSettings.players[slot] = players;
    },
  },
});
export default defaultSettingsSlice;

export const {
  setDefaultTimeControlFlag,
  setDefaultFastestTimeControls,
  setDefaultSlowestTimeControls,
  setDefaultPlayers,
} = defaultSettingsSlice.actions;

export function selectDefaultTimeControlFlagsBySlot(state) {
  return state.defaultSettings.timeControlFlags;
}

export function selectDefaultFastestTimeControlsBySlot(state) {
  return state.defaultSettings.fastestTimeControls;
}

export function selectDefaultSlowestTimeControlsBySlot(state) {
  return state.defaultSettings.slowestTimeControls;
}

export function selectDefaultPlayersBySlot(state) {
  return state.defaultSettings.players;
}
