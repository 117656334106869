import { createContext, useContext, useState, useEffect } from 'react';

import {
  HUMAN_PLAYER,
  REMOTE_PLAYER,
  getNetworkGamePlayers,
} from './features/play/playerTypes.js';

// From <https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState>:
const OPEN = 1;

const RECONNECTION_ATTEMPT_COUNT = 12;
const RECONNECTION_ATTEMPT_DELAY = 5000; // in millseconds

const DISCONNECT = Symbol('DISCONNECT');

export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL !== undefined ? process.env.REACT_APP_SERVER_URL : 'ws://localhost:4000';

function log(...rest) {
  if (process.env.JEST_WORKER_ID === undefined) {
    console.log(...rest);
  }
}

export class NetworkError extends Error {
  constructor() {
    super('Network connection lost');
  }
}

export class ServerError extends Error {
  constructor(serverMessage) {
    super(JSON.stringify(serverMessage));
    this.serverMessage = serverMessage;
  }
}

class GameDescription {
  constructor(message) {
    this.networkCode = message.code;
    this.timestamp = message.timestamp;
    this.gameIdentifier = message.gameIdentifier;
    this.timeControls = message.timeControls;
    this.timeUsed = message.timeUsed;
    this.winnerOnTime = message.winnerOnTime === 'violet' ? 0 : message.winnerOnTime === 'cyan' ? 1 : undefined;
    this.winnerByResignation = message.winnerByResignation === 'violet' ? 0 :
      message.winnerByResignation === 'cyan' ? 1 : undefined;
    this.dragonPoints = message.dragons;
    this.players = getNetworkGamePlayers(
      message.color === 'violet' ? 0 : message.color === 'cyan' ? 1 : undefined,
      message.violet,
      message.cyan,
    );
    this.moves = message.record;
  }
}

class SeekReadyEvent extends Event {
  constructor(message) {
    super('seekready');
    this.gameDescription = new GameDescription(message);
  }
}

class InvitationMadeEvent extends Event {
  constructor(message) {
    super('invitationmade');
    this.gameDescription = new GameDescription(message);
  }
}

class InvitationRescindedEvent extends Event {
  constructor(message) {
    super('invitationrescinded');
    this.networkCode = message.code;
  }
}

class InvitationAcceptedEvent extends Event {
  constructor(message) {
    super('invitationaccepted');
    this.gameDescription = new GameDescription(message);
  }
}

class SynchronizationEvent extends Event {
  constructor(message) {
    super('synchronization');
    this.gameDescription = new GameDescription(message);
  }
}

class CleanupEvent extends Event {
  constructor(games) {
    super('cleanup');
    this.networkCodes = new Set(games.map((game) => game.code));
  }
}

class Client extends EventTarget {
  constructor(autodisconnect = true) {
    super();
    this.autodisconnect = autodisconnect;
    this.websocket = undefined;
    this.openListener = () => this._onOpen();
    this.messageListener = (message) => this._onReceiveMessage(message);
    this.closeListener = () => this._onClose();
    this.expectingClose = false;
    this.attemptingToReconnect = false;
    this.reconnectionAttemptsRemaining = 0;
    this.pendingOutboxItem = undefined;
    this.outbox = [];
    this.session = undefined;
    this.identity = undefined;
    this.seeking = false;
  }

  _reconnect() {
    console.assert(this.websocket === undefined, 'Tried to reconnect when not aware of any connection loss.');
    this.websocket = new WebSocket(SERVER_URL);
    this.websocket.addEventListener('open', this.openListener);
    this.websocket.addEventListener('message', this.messageListener);
    this.websocket.addEventListener('close', this.closeListener);
    this.expectingClose = false;
  }

  _sendToServer(outboxItem) {
    this.pendingOutboxItem = outboxItem;
    this.websocket.send(JSON.stringify(outboxItem.message));
  }

  _onOpen() {
    log('Connected to server.');
    this.attemptingToReconnect = false;
    this.reconnectionAttemptsRemaining = RECONNECTION_ATTEMPT_COUNT;
    if (this.pendingOutboxItem !== undefined) {
      this._sendToServer(this.pendingOutboxItem);
    } else {
      this._dispatchMessages();
    }
  }

  _onClose() {
    console.assert(this.websocket !== undefined, 'Got a connection close event when not aware of any connection.');
    this.websocket.removeEventListener('open', this.openListener);
    this.websocket.removeEventListener('message', this.messageListener);
    this.websocket.removeEventListener('close', this.closeListener);
    this.websocket = undefined;
    this.attemptingToReconnect = false;
    this._resetState();
    if (this.pendingOutboxItem !== undefined || this.outbox.length > 0) {
      if (this.expectingClose) {
        log('Attempting to reconnect to server immediately…');
        this._reconnect();
      } else if (this.reconnectionAttemptsRemaining > 0) {
        log('Will attempt to reconnect in a little bit…');
        this.attemptingToReconnect = true;
        --this.reconnectionAttemptsRemaining;
        const index = RECONNECTION_ATTEMPT_COUNT - this.reconnectionAttemptsRemaining;
        setTimeout(() => {
          log(`Attempting to reconnect to server (attempt ${index}/${RECONNECTION_ATTEMPT_COUNT})…`);
          this._reconnect();
        }, RECONNECTION_ATTEMPT_DELAY);
      } else {
        log('Reconnection failed.');
        const toFulfill = [];
        if (this.pendingOutboxItem !== undefined) {
          toFulfill.push(this.pendingOutboxItem);
        }
        this.pendingOutboxItem = undefined;
        for (const outboxItem of this.outbox) {
          toFulfill.push(outboxItem);
        }
        this.outbox = [];
        // Only start resolving/rejecting once the client is in a consistent state.
        for (const outboxItem of toFulfill) {
          if (outboxItem.message === DISCONNECT) {
            outboxItem.resolve();
          } else {
            outboxItem.reject(new NetworkError());
          }
        }
      }
    } else {
      log('Disconnected from server.');
    }
  }

  _dispatchMessagesWithoutYetResolvingDisconnects() {
    const toResolve = [];
    let wantDisconnect = false;
    if (this.pendingOutboxItem === undefined) {
      while (this.outbox.length > 0 && this.outbox[0].message === DISCONNECT) {
        wantDisconnect = true;
        toResolve.push(this.outbox.shift());
      }
      if (this.outbox.length === 0) {
        if (wantDisconnect && this.websocket !== undefined) {
          this.expectingClose = true;
          this.websocket.close();
        }
        return toResolve;
      }
      if (this.websocket !== undefined && this.websocket.readyState === OPEN) {
        this._sendToServer(this.outbox.shift());
        return toResolve;
      }
    }
    if (this.websocket === undefined) {
      log('Connecting to server…');
      this._reconnect();
    }
    return toResolve;
  }

  _dispatchMessages() {
    if (this.attemptingToReconnect) {
      return;
    }
    // Only start resolving once the client is in a consistent state.
    for (const outboxItem of this._dispatchMessagesWithoutYetResolvingDisconnects()) {
      outboxItem.resolve();
    }
  }

  _modifyAuthentication(session, name, email) {
    this.session = session;
    this.identity = name !== undefined && email !== undefined ? {
      name,
      email,
    } : undefined;
    this.dispatchEvent(new Event('authenticationchanged'));
  }

  _modifySeeking(seeking) {
    this.seeking = seeking;
    this.dispatchEvent(new Event('seekingchanged'));
  }

  _resetState() {
    this._modifyAuthentication(undefined, undefined, undefined);
    this._modifySeeking(false);
  }

  async _subscribe(session, vapidPublicKey) {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      let subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey,
        });
      }
      this.websocket.send(JSON.stringify({
        action: 'subscribe',
        session,
        subscription: subscription.toJSON(),
      }));
      log('Subscribed to push notifications.');
    } catch (exception) {
      log('Unable to subscribe to push notifications because subscription is unsupported or was blocked.');
    }
  }

  _onReceiveMessage(encodedMessage) {
    let message = undefined;
    try {
      message = JSON.parse(encodedMessage.data);
    } catch (exception) {
      console.error(`Received invalid message: ${encodedMessage}.`);
      return;
    }
    if (message.success) {
      switch (message.action) {
      case 'register':
      case 'logIn':
        this._subscribe(message.session, message.vapidPublicKey);
        // intentionally fall through
      case 'logInAsGuest':
      case 'claimToken':
      case 'changeEmail':
        this._modifyAuthentication(message.session, message.name, message.email);
        break;
      case 'logOut':
        this._resetState();
        if (this.autodisconnect) {
          this.disconnect();
        }
        break;
      case 'seekReady':
        this._modifySeeking(false);
        this.dispatchEvent(new SeekReadyEvent(message));
        break;
      case 'invite':
        this.dispatchEvent(new InvitationMadeEvent(message));
        break;
      case 'uninvite':
        this.dispatchEvent(new InvitationRescindedEvent(message));
        break;
      case 'accept':
        this.dispatchEvent(new InvitationAcceptedEvent(message));
        break;
      case 'observeLiveGames':
        for (const game of message.games) {
          this.dispatchEvent(new SynchronizationEvent(game));
        }
        this.dispatchEvent(new CleanupEvent(message.games));
        break;
      case 'observeGame':
      case 'makeMove':
      case 'move':
      case 'resign':
        this.dispatchEvent(new SynchronizationEvent(message));
        break;
      default:
      }
    } else if (message.recourse === 'logIn') {
      this._resetState();
    }
    if (this.pendingOutboxItem !== undefined && message.action === this.pendingOutboxItem.message.action) {
      if (message.success) {
        this.pendingOutboxItem.resolve(message);
      } else {
        this.pendingOutboxItem.reject(new ServerError(message));
      }
      this.pendingOutboxItem = undefined;
      this._dispatchMessages();
    }
  }

  _send(message) {
    return new Promise((resolve, reject) => {
      this.outbox.push({
        message,
        resolve,
        reject,
      });
      this._dispatchMessages();
    });
  }

  logInAsGuest() {
    return this._send({
      action: 'logInAsGuest',
    });
  }

  register(name, email, password) {
    return this._send({
      action: 'register',
      name,
      email,
      password,
    });
  }

  logIn(identity, password) {
    return this._send({
      action: 'logIn',
      identity,
      password,
    });
  }

  claimToken(token) {
    return this._send({
      action: 'claimToken',
      session: this.session,
      token,
    });
  }

  logOut() {
    return this._send({
      action: 'logOut',
      session: this.session,
    });
  }

  changeEmail(email, password) {
    return this._send({
      action: 'changeEmail',
      session: this.session,
      email,
      password,
    });
  }

  changePassword(oldPassword, newPassword) {
    return this._send({
      action: 'changePassword',
      session: this.session,
      oldPassword,
      newPassword,
    });
  }

  requestPasswordResetEmail(identity) {
    return this._send({
      action: 'requestPasswordResetEmail',
      identity,
    });
  }

  resetPassword(identity, passwordResetCode, password) {
    return this._send({
      action: 'resetPassword',
      identity,
      passwordResetCode,
      password,
    });
  }

  seek(fastestTimeControls, slowestTimeControls, opponentIndex = undefined) {
    this._modifySeeking(true);
    return this._send({
      action: 'seek',
      session: this.session,
      fastestTimeControls,
      slowestTimeControls,
      colorSought: opponentIndex === 0 ? 'violet' : opponentIndex === 1 ? 'cyan' : null,
    });
  }

  unseek() {
    if (!this.seeking) {
      return (async() => {})();
    }
    this._modifySeeking(false);
    return this._send({
      action: 'unseek',
      session: this.session,
    });
  }

  invite(dragons, players, timeControls) {
    console.assert(
      players.length === 2,
      `Tried to create a two-player network game invite with ${players.length} player(s).`,
    );
    console.assert(
      players.some((player) => player.type === HUMAN_PLAYER),
      'Tried to create a network game invite with no local human player.',
    );
    console.assert(
      players.some((player) => player.type === REMOTE_PLAYER),
      'Tried to create a network game invite with no space for a network player.',
    );
    return this._send({
      action: 'invite',
      session: this.session,
      dragons,
      timeControls,
      colorSought: players[0].type === REMOTE_PLAYER ? 'violet' : 'cyan',
    });
  }

  uninvite(networkCode) {
    return this._send({
      action: 'uninvite',
      session: this.session,
      code: networkCode,
    });
  }

  accept(networkCode) {
    return this._send({
      action: 'accept',
      session: this.session,
      code: networkCode,
    });
  }

  observeLiveGames() {
    return this._send({
      action: 'observeLiveGames',
      session: this.session,
    });
  }

  unobserveLiveGames() {
    return this._send({
      action: 'unobserveLiveGames',
    });
  }

  observeGame(networkCode) {
    return this._send({
      action: 'observeGame',
      session: this.session,
      code: networkCode,
    });
  }

  unobserveGame(networkCode) {
    return this._send({
      action: 'unobserveGame',
      code: networkCode,
    });
  }

  makeMove(networkCode, move) {
    return this._send({
      action: 'makeMove',
      session: this.session,
      code: networkCode,
      move,
    });
  }

  resign(networkCode) {
    return this._send({
      action: 'resign',
      session: this.session,
      code: networkCode,
    });
  }

  disconnect() {
    // Non-testing code can probably ignore this return value; from the calling
    // code's perspective, the disconnect takes effect immediately, and there is
    // not much value in waiting for the underlying resource cleanup.
    return this._send(DISCONNECT);
  }
}

const CLIENT = new Client();

export const {
  logInAsGuest,
  register,
  logIn,
  claimToken,
  logOut,
  changeEmail,
  changePassword,
  requestPasswordResetEmail,
  resetPassword,
  seek,
  unseek,
  invite,
  uninvite,
  accept,
  observeLiveGames,
  unobserveLiveGames,
  observeGame,
  unobserveGame,
  makeMove,
  resign,
  disconnect,
} = Object.assign({}, ...Object.getOwnPropertyNames(Object.getPrototypeOf(CLIENT)).map((method) => ({
  [method]: CLIENT[method].bind(CLIENT),
})));

const CLIENT_IDENTITY_CONTEXT = createContext();
const SEEKING_CONTEXT = createContext();

export function NetworkClientProvider(props) {
  const [clientIdentity, setClientIdentity] = useState(undefined);
  const [seeking, setSeeking] = useState(false);

  useEffect(() => {
    const listener = () => setClientIdentity(CLIENT.identity);
    CLIENT.addEventListener('authenticationchanged', listener);
    return () => CLIENT.removeEventListener('authenticationchanged', listener);
  });
  useEffect(() => {
    const listener = () => setSeeking(CLIENT.seeking);
    CLIENT.addEventListener('seekingchanged', listener);
    return () => CLIENT.removeEventListener('seekingchanged', listener);
  });

  return (
    <CLIENT_IDENTITY_CONTEXT.Provider value={clientIdentity}>
      <SEEKING_CONTEXT.Provider value={seeking}>
        {props.children}
      </SEEKING_CONTEXT.Provider>
    </CLIENT_IDENTITY_CONTEXT.Provider>
  );
}

export function useClientIdentity() {
  return useContext(CLIENT_IDENTITY_CONTEXT);
}

export function useSeekingFlag() {
  return useContext(SEEKING_CONTEXT);
}

function useOnEvent(eventName, listener) {
  useEffect(() => {
    CLIENT.addEventListener(eventName, listener);
    return () => CLIENT.removeEventListener(eventName, listener);
  });
}

export function useOnSeekReady(listener) {
  useOnEvent('seekready', listener);
}

export function useOnInvitationMade(listener) {
  useOnEvent('invitationmade', listener);
}

export function useOnInvitationRescinded(listener) {
  useOnEvent('invitationrescinded', listener);
}

export function useOnInvitationAccepted(listener) {
  useOnEvent('invitationaccepted', listener);
}

export function useOnSynchronization(listener) {
  useOnEvent('synchronization', listener);
}

export function useOnCleanup(listener) {
  useOnEvent('cleanup', listener);
}

export const internals = {
  Client,
  CLIENT,
  SeekReadyEvent,
  InvitationMadeEvent,
  InvitationRescindedEvent,
  InvitationAcceptedEvent,
  SynchronizationEvent,
  CleanupEvent,
};
