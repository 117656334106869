import { createSlice } from '@reduxjs/toolkit';

import dragon from './pieces/dragon.svg';

import blackPawn from './pieces/black/pawn.svg';
import blackKnight from './pieces/black/knight.svg';
import blackTower from './pieces/black/tower.svg';

import whitePawn from './pieces/white/pawn.svg';
import whiteKnight from './pieces/white/knight.svg';
import whiteTower from './pieces/white/tower.svg';

import pinkPawn from './pieces/pink/pawn.svg';
import pinkKnight from './pieces/pink/knight.svg';
import pinkTower from './pieces/pink/tower.svg';

import redPawn from './pieces/red/pawn.svg';
import redKnight from './pieces/red/knight.svg';
import redTower from './pieces/red/tower.svg';

import orangePawn from './pieces/orange/pawn.svg';
import orangeKnight from './pieces/orange/knight.svg';
import orangeTower from './pieces/orange/tower.svg';

import yellowPawn from './pieces/yellow/pawn.svg';
import yellowKnight from './pieces/yellow/knight.svg';
import yellowTower from './pieces/yellow/tower.svg';

import greenPawn from './pieces/green/pawn.svg';
import greenKnight from './pieces/green/knight.svg';
import greenTower from './pieces/green/tower.svg';

import cyanPawn from './pieces/cyan/pawn.svg';
import cyanKnight from './pieces/cyan/knight.svg';
import cyanTower from './pieces/cyan/tower.svg';

import bluePawn from './pieces/blue/pawn.svg';
import blueKnight from './pieces/blue/knight.svg';
import blueTower from './pieces/blue/tower.svg';

import violetPawn from './pieces/violet/pawn.svg';
import violetKnight from './pieces/violet/knight.svg';
import violetTower from './pieces/violet/tower.svg';

import magentaPawn from './pieces/magenta/pawn.svg';
import magentaKnight from './pieces/magenta/knight.svg';
import magentaTower from './pieces/magenta/tower.svg';

const COLOR_SPECIFICATIONS = new Map([
  ['Black', 'rgba(0, 0, 0, 1)'],
  ['White', 'rgba(255, 255, 255, 1)'],
  ['Pink', 'rgba(255, 127, 127, 1)'],
  ['Red', 'rgba(127, 0, 0, 1)'],
  ['Orange', 'rgba(191, 95, 0, 1)'],
  ['Yellow', 'rgba(191, 191, 63, 1)'],
  ['Green', 'rgba(0, 127, 0, 1)'],
  ['Cyan', 'rgba(0, 127, 127, 1)'],
  ['Blue', 'rgba(0, 0, 127, 1)'],
  ['Violet', 'rgba(63, 0, 127, 1)'],
  ['Magenta', 'rgba(127, 0, 127, 1)'],
]);

const PAWNS = new Map([
  ['Black', blackPawn],
  ['White', whitePawn],
  ['Pink', pinkPawn],
  ['Red', redPawn],
  ['Orange', orangePawn],
  ['Yellow', yellowPawn],
  ['Green', greenPawn],
  ['Cyan', cyanPawn],
  ['Blue', bluePawn],
  ['Violet', violetPawn],
  ['Magenta', magentaPawn],
]);

const KNIGHTS = new Map([
  ['Black', blackKnight],
  ['White', whiteKnight],
  ['Pink', pinkKnight],
  ['Red', redKnight],
  ['Orange', orangeKnight],
  ['Yellow', yellowKnight],
  ['Green', greenKnight],
  ['Cyan', cyanKnight],
  ['Blue', blueKnight],
  ['Violet', violetKnight],
  ['Magenta', magentaKnight],
]);

const TOWERS = new Map([
  ['Black', blackTower],
  ['White', whiteTower],
  ['Pink', pinkTower],
  ['Red', redTower],
  ['Orange', orangeTower],
  ['Yellow', yellowTower],
  ['Green', greenTower],
  ['Cyan', cyanTower],
  ['Blue', blueTower],
  ['Violet', violetTower],
  ['Magenta', magentaTower],
]);

// This ordering is the same one shown to the user in the preferences menu.
const AVAILABLE_COLOR_NAMES = [
  'Black',
  'White',
  'Pink',
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Cyan',
  'Blue',
  'Violet',
  'Magenta',
];

// This ordering is used to assign default colors; the ith color in this list
// gives the default color for the ith player's pieces.
const DEFAULT_COLOR_NAMES = [
  'Violet',
  'Cyan',
  'Yellow',
  'Red',
  'Black',
  'Magenta',
  'Green',
  'Orange',
  'Pink',
  'Blue',
  'White',
];

function getDefaultColor(playerIndex) {
  return DEFAULT_COLOR_NAMES[playerIndex % DEFAULT_COLOR_NAMES.length];
}

const piecesSlice = createSlice({
  name: 'pieces',
  initialState: {
    colorNames: [],
    editCounter: 0,
  },
  reducers: {
    resetPieceColors: (pieces) => {
      pieces.colorNames = [];
      ++pieces.editCounter;
    },
    setPieceColor: (pieces, action) => {
      const {
        playerIndex,
        colorName,
      } = action.payload;
      while (pieces.colorNames.length < playerIndex) {
        pieces.colorNames.push(getDefaultColor(pieces.colorNames.length));
      }
      pieces.colorNames[playerIndex] = colorName;
      ++pieces.editCounter;
    },
  },
});
export default piecesSlice;

export const { resetPieceColors, setPieceColor } = piecesSlice.actions;

class ValuesByPlayerIndex extends Map {
  constructor(obtainDefault, pairs) {
    super(pairs);
    this.obtainDefault = obtainDefault;
  }

  get(playerIndex) {
    if (super.has(playerIndex)) {
      return super.get(playerIndex);
    }
    return this.obtainDefault(playerIndex);
  }
}

function cachedByEditCounter(selector) {
  let cachedEditCounter = undefined;
  let cachedResult = undefined;
  return (state) => {
    if (state.pieces.editCounter !== cachedEditCounter) {
      cachedEditCounter = state.pieces.editCounter;
      cachedResult = selector(state);
    }
    return cachedResult;
  };
}

export function selectAvailableColorNames() {
  return AVAILABLE_COLOR_NAMES;
}

export const selectColorNames = cachedByEditCounter(
  (state) => new ValuesByPlayerIndex(
    getDefaultColor,
    state.pieces.colorNames.map((colorName, index) => [index, colorName]),
  ),
);

export function selectDragon() {
  return dragon;
}

export const selectColorSpecifications = cachedByEditCounter(
  (state) => new ValuesByPlayerIndex(
    (playerIndex) => COLOR_SPECIFICATIONS.get(getDefaultColor(playerIndex)),
    state.pieces.colorNames.map((colorName, index) => [index, COLOR_SPECIFICATIONS.get(colorName)]),
  ),
);

export const selectPawns = cachedByEditCounter(
  (state) => new ValuesByPlayerIndex(
    (playerIndex) => PAWNS.get(getDefaultColor(playerIndex)),
    state.pieces.colorNames.map((colorName, index) => [index, PAWNS.get(colorName)]),
  ),
);

export const selectKnights = cachedByEditCounter(
  (state) => new ValuesByPlayerIndex(
    (playerIndex) => KNIGHTS.get(getDefaultColor(playerIndex)),
    state.pieces.colorNames.map((colorName, index) => [index, KNIGHTS.get(colorName)]),
  ),
);

export const selectTowers = cachedByEditCounter(
  (state) => new ValuesByPlayerIndex(
    (playerIndex) => TOWERS.get(getDefaultColor(playerIndex)),
    state.pieces.colorNames.map((colorName, index) => [index, TOWERS.get(colorName)]),
  ),
);

export const internals = {
  DEFAULT_COLOR_NAMES,
  PAWNS,
  KNIGHTS,
  TOWERS,
};
