import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Player } from '../play/player.js';

import {
  selectTutorialProgress,
  selectFurthestTutorialProgress,
  resetTutorialProgress,
  changeTutorialProgress,
  setTutorialProgress,
} from './tutorialProgressSlice.js';
import {
  loadTree,
} from '../lobby/treeSlotsSlice.js';
import {
  selectGames,
  selectPositions,
  selectEndsOfContinuations,
  newGame,
  deleteGame,
  jumpToFirst,
  jumpToLast,
} from '../play/gameTreesSlice.js';

import { DUMMY_PLAYER, HUMAN_PLAYER } from '../play/playerTypes.js';

function getStatus(page, game, position) {
  const moves = page.moves || [];
  const isExercise = page.done !== undefined && moves[0] === undefined;
  const isDone = (page.done || (() => true))(game, position);
  const isSuccessful = (page.successful || (() => true))(game, position);
  return {
    moves,
    isExercise,
    isDone,
    isSuccessful,
  };
}

export function Tutorial(props) {
  const progress = useSelector(selectTutorialProgress)[props.slot] || 0;
  const furthestProgress = useSelector(selectFurthestTutorialProgress)[props.slot] || 0;
  const currentPage = props.tutorial.pages[progress];
  const currentTreeName = `${props.slot}/${currentPage.treeName}`;

  const games = useSelector(selectGames);
  const currentGame = games[currentTreeName];
  const positions = useSelector(selectPositions);
  const currentPosition = positions[currentTreeName];
  const endsOfContinuations = useSelector(selectEndsOfContinuations);

  const dispatch = useDispatch();

  useEffect(() => {
    for (const page of props.tutorial.pages) {
      const treeName = `${props.slot}/${page.treeName}`;
      if (page.rootPosition !== undefined && positions[treeName] === undefined) {
        const moves = page.moves || [];
        dispatch(newGame({
          treeName,
          gameIdentifier: props.tutorial.gameIdentifier,
          players: [
            {
              type: moves[0] !== undefined ? DUMMY_PLAYER : HUMAN_PLAYER,
              moves: moves[0] || [],
            },
            {
              type: DUMMY_PLAYER,
              moves: moves[1],
              autoReplay: page.singlePlayer,
            },
          ],
          rootPosition: page.rootPosition,
          compensated: page.compensated || false,
          eternal: true,
          suggestions: page.suggestions,
        }));
      }
    }
  });

  useEffect(() => {
    dispatch(loadTree({
      slot: props.slot,
      treeName: currentTreeName,
    }));
  });

  const currentStatus = getStatus(currentPage, currentGame, currentPosition);
  const caption = currentStatus.isExercise && currentStatus.isDone ?
    currentStatus.isSuccessful ? props.tutorial.successMessage : props.tutorial.failureMessage :
    currentPage.caption;
  const onReset = progress - 1 >= 0 ?
    () => {
      dispatch(resetTutorialProgress({
        tutorial: props.slot,
      }));
      for (const page of props.tutorial.pages) {
        const treeName = `${props.slot}/${page.treeName}`;
        dispatch(deleteGame({
          treeName,
        }));
      }
    } :
    undefined;
  const onFirst = () => {
    for (let i = progress; i >= 0; --i) {
      const page = props.tutorial.pages[i];
      dispatch(jumpToFirst({
        treeName: `${props.slot}/${page.treeName}`,
      }));
    }
    dispatch(setTutorialProgress({
      tutorial: props.slot,
      progress: 0,
    }));
  };
  const onBoundaryPrevious = progress - 1 >= 0 ?
    () => dispatch(changeTutorialProgress({
      tutorial: props.slot,
      change: -1,
    })) :
    undefined;
  const onBoundaryNext =
    currentStatus.isDone && currentStatus.isSuccessful && progress + 1 < props.tutorial.pages.length ?
      () => dispatch(changeTutorialProgress({
        tutorial: props.slot,
        change: 1,
      })) :
      undefined;
  const onLast = () => {
    let newProgress = progress;
    for (; newProgress <= furthestProgress; ++newProgress) {
      const page = props.tutorial.pages[newProgress];
      const treeName = `${props.slot}/${page.treeName}`;
      dispatch(jumpToLast({
        treeName,
      }));
      const game = games[treeName];
      const endOfContinuation = endsOfContinuations[treeName];
      const status = getStatus(page, game, endOfContinuation);
      if (!status.isDone || !status.isSuccessful) {
        break;
      }
    }
    dispatch(setTutorialProgress({
      tutorial: props.slot,
      progress: Math.min(newProgress, furthestProgress),
    }));
  };
  const forceLastAvailable = currentStatus.isDone && currentStatus.isSuccessful && progress < furthestProgress;

  return (
    <>
      <Player
        slot={props.slot}
        caption={caption}
        analysisOnly={false}
        forceShowAnalysisIndicator={currentPosition !== undefined && currentPosition.suggestions.length > 0}
        boardLocked={!currentStatus.isExercise || currentStatus.isDone}
        rotationLocked={true}
        controlsPlayerIndex={currentPage.singlePlayer ? 0 : undefined}
        hideFiles={currentPage.hideFiles}
        hideRanks={currentPage.hideRanks}
        hidePoints={currentPage.hidePoints}
        highlightFiles={currentPage.highlightFiles}
        highlightRanks={currentPage.highlightRanks}
        highlightPoints={currentPage.highlightPoints}
        target={currentPage.target}
        onReset={onReset}
        onFirst={onFirst}
        onBoundaryPrevious={onBoundaryPrevious}
        onBoundaryNext={onBoundaryNext}
        onLast={onLast}
        replaceRotateWithReset={true}
        forceLastAvailable={forceLastAvailable} />
    </>
  );
}

Tutorial.propTypes = {
  slot: PropTypes.string.isRequired,
  tutorial: PropTypes.shape({
    title: PropTypes.string.isRequired,
    gameIdentifier: PropTypes.string.isRequired,
    successMessage: PropTypes.node.isRequired,
    failureMessage: PropTypes.node.isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape({
      caption: PropTypes.node.isRequired,
      treeName: PropTypes.string.isRequired,
      rootPosition: PropTypes.string,
      target: PropTypes.string,
      moves: PropTypes.array,
      done: PropTypes.func,
      successful: PropTypes.func,
      highlightFiles: PropTypes.func,
      highlightRanks: PropTypes.func,
      highlightPoints: PropTypes.func,
      hideFiles: PropTypes.func,
      hideRanks: PropTypes.func,
      hidePoints: PropTypes.func,
    })),
  }),
};
