import { forwardRef, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import styles from './buttonBar.module.css';

export const Button = forwardRef((props, ref) => {
  console.assert(
    props.image === undefined || props.altText !== undefined,
    `Tried to use a button image (${props.image}) without alt text.`,
  );
  console.assert(
    props.altText === undefined || props.image !== undefined,
    `Tried to use alt text ("${props.altText}") without a button image.`,
  );
  const fallbackRef = useRef();
  const button = ref || fallbackRef;
  useEffect(() => {
    if (props.autofocus) {
      button.current.focus();
    }
  }, [props.autofocus, button]);
  const buttonClasses = classNames({
    [styles.highlight]: props.highlighted,
  });
  return (
    <div>
      <button ref={button} className={buttonClasses} disabled={props.disabled} onClick={props.onClick}>
        { props.image ?
          <img src={props.image} alt={props.altText} /> :
          props.text }
      </button>
    </div>
  );
});

Button.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  altText: PropTypes.string,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  onClick: PropTypes.func,
};

export function ButtonBar(props) {
  return (
    <div className={styles['button-bar']}>
      {props.children}
    </div>
  );
}
