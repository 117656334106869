import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Modal, createModalOpener } from '../../widgets/modal.js';
import { Menu, MenuText, MenuButton } from '../../widgets/menu.js';

import classNames from 'classnames';
import styles from './scoreSheet.module.css';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectGames,
  selectMoveTrees,
  selectPositions,
  makeMainLine,
  deleteLine,
  setPosition,
} from './gameTreesSlice.js';

function Move(props) {
  const button = useRef();
  useEffect(() => {
    if (props.active && button.current !== undefined) {
      button.current.scrollIntoView();
    }
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const editSubpath = `editLine${props.position}`;

  const classes = classNames({
    [styles.move]: true,
    [styles.active]: props.active,
  });
  const onClick = () => dispatch(setPosition({
    treeName: props.treeName,
    position: props.position,
  }));
  const onMakeMainLine = () => {
    dispatch(makeMainLine({
      treeName: props.treeName,
      positionIdentity: props.position,
    }));
    navigate(-1);
  };
  const onDeleteLine = () => {
    dispatch(deleteLine({
      treeName: props.treeName,
      positionIdentity: props.position,
    }));
    navigate(-1);
  };

  return (
    <>
      <button
        ref={button}
        className={classes}
        disabled={props.disabled}
        onClick={onClick}
        onContextMenu={props.locked ? undefined : createModalOpener(location, navigate, editSubpath)}>
        {props.move}
      </button>
      { props.locked ?
        null :
        <Modal subpath={editSubpath} altText={'Edit Line'}>
          <Menu>
            <MenuText>
              {'Edit Line'}
            </MenuText>
            <MenuButton onClick={onMakeMainLine}>
              Make Main Line
            </MenuButton>
            <MenuButton onClick={onDeleteLine}>
              Delete Line
            </MenuButton>
          </Menu>
        </Modal> }
    </>
  );
}

Move.propTypes = {
  treeName: PropTypes.string,
  position: PropTypes.any,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
};

function Turn(props) {
  return (
    <span className={styles.turn}>
      <span className={styles['turn-number']}>
        {props.turn}
      </span>
      {props.children}
    </span>
  );
}

Turn.propTypes = {
  turn: PropTypes.number.isRequired,
};

function Variation(props) {
  return (
    <div className={styles.variation}>
      {props.children}
    </div>
  );
}

function renderUnbranchingMove(treeName, game, parent, move, current, disabled, locked) {
  const child = parent.children.get(move);
  const [sameTurnContinuations, laterTurnContinuations] =
    render(treeName, game, child, current, disabled, locked, true);
  return [
    <React.Fragment key={`fragment ${child.identity}`}>
      <Move
        treeName={treeName}
        move={move}
        position={child.identity}
        active={child.identity === current}
        disabled={disabled}
        locked={locked} />
      {sameTurnContinuations}
    </React.Fragment>,
    laterTurnContinuations,
  ];
}

function renderBranchingMove(treeName, game, parent, move, current, disabled, locked) {
  const child = parent.children.get(move);
  const ellipses = [...Array(parent.ply % game.playerCount).keys()].map(
    (index) => <Move key={`gap ${child.identity} ${index}`} move={'…'} disabled={true} locked={true} />,
  );
  const [sameTurnContinuations, laterTurnContinuations] =
    render(treeName, game, child, current, disabled, locked, true);
  return [
    null,
    <React.Fragment key={`fragment ${child.identity}`}>
      <Turn turn={Math.floor(parent.ply / game.playerCount) + 1}>
        {ellipses}
        <Move
          treeName={treeName}
          move={move}
          position={child.identity}
          active={child.identity === current}
          disabled={disabled}
          locked={locked} />
        {sameTurnContinuations}
      </Turn>
      {laterTurnContinuations}
    </React.Fragment>,
  ];
}

function render(treeName, game, parent, current, disabled, locked, recursive = false) {
  if (parent === undefined || parent.moves.length === 0) {
    return [null, null];
  }
  if (parent.moves.length === 1 && parent.ply % game.playerCount !== 0 && recursive) {
    return renderUnbranchingMove(treeName, game, parent, parent.moves[0], current, disabled, locked);
  }
  const [mainLineMove, ...variationMoves] = parent.moves;
  const variations = [];
  for (const move of variationMoves) {
    const laterTurnContinuations = renderBranchingMove(treeName, game, parent, move, current, disabled, locked)[1];
    variations.push(
      <Variation key={`variation ${parent.identity} ${move}`}>
        {laterTurnContinuations}
      </Variation>,
    );
  }
  const mainLine = renderBranchingMove(treeName, game, parent, mainLineMove, current, disabled, locked)[1];
  if (recursive) {
    variations.push(mainLine);
  } else {
    variations.unshift(mainLine);
  }
  return [null, variations];
}

export function ScoreSheet(props) {
  const treeName = useSelector(selectTreesBySlot)[props.slot];
  const game = useSelector(selectGames)[treeName];
  const moveTree = useSelector(selectMoveTrees)[treeName];
  const position = useSelector(selectPositions)[treeName];

  const positionIdentity = position !== undefined ? position.identity : undefined;
  const lines = render(treeName, game, moveTree, positionIdentity, props.disabled, props.locked)[1];

  return (
    <div className={styles.score}>
      { lines !== null ?
        lines :
        <p className={styles['no-moves']}>[No moves played yet.]</p> }
    </div>
  );
}

ScoreSheet.propTypes = {
  slot: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
};
