import { useLocation, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import styles from './modal.module.css';

export function Modal(props) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <ReactModal
      isOpen={location.pathname.endsWith(props.subpath)}
      onRequestClose={() => navigate(-1)}
      contentLabel={props.altText}
      appElement={document.querySelector('#app') || document.querySelector('body')}
      parentSelector={() => document.querySelector('#portrait')}
      overlayClassName={styles.overlay}
      className={styles.content}>
      {props.children}
    </ReactModal>
  );
}

Modal.propTypes = {
  subpath: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export function createModalOpener(location, navigate, subpath) {
  return (event) => {
    event.preventDefault();
    navigate(`${location.pathname}/${subpath}`);
  };
}
