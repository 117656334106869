import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Caption } from './caption.js';
import { Board, PIECE_REMOVAL_MODE, PIECE_ADDITION_MODE, PIECE_TELEPORTATION_MODE } from './board.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';

import styles from './editor.module.css';
import removePieceIcon from '../../icons/removePiece.svg';
import teleportPieceIcon from '../../icons/teleportPiece.svg';
import rotateIcon from '../../icons/rotate.svg';

import {
  selectTreesBySlot,
} from '../lobby/treeSlotsSlice.js';
import {
  selectGames,
  rotateBoard,
} from './gameTreesSlice.js';
import {
  selectDragon,
  selectPawns,
  selectKnights,
  selectTowers,
} from '../preferences/piecesSlice.js';

function AdditionButton(props) {
  const dragon = useSelector(selectDragon);
  const pawns = useSelector(selectPawns);
  const knights = useSelector(selectKnights);
  const towers = useSelector(selectTowers);

  const pieceName =
    props.pieceType === props.game.pawn ? 'Pawn' :
      props.pieceType === props.game.knight ? 'Knight' :
        props.pieceType === props.game.tower ? 'Tower' :
          'Dragon';
  const image =
    props.pieceType === props.game.pawn ? pawns.get(props.playerIndex) :
      props.pieceType === props.game.knight ? knights.get(props.playerIndex) :
        props.pieceType === props.game.tower ? towers.get(props.playerIndex) :
          dragon;
  const highlighted =
    props.currentMode === PIECE_ADDITION_MODE &&
    props.currentPlayerIndex === props.playerIndex &&
    props.currentPieceType === props.pieceType;
  const onClick = () => {
    props.setCurrentMode(PIECE_ADDITION_MODE);
    props.setCurrentPlayerIndex(props.playerIndex);
    props.setCurrentPieceType(props.pieceType);
  };

  return (
    <Button
      image={image}
      altText={`"Add ${pieceName} for Player ${props.playerIndex + 1}" Mode`}
      highlighted={highlighted}
      onClick={onClick} />
  );
}

AdditionButton.propTypes = {
  game: PropTypes.shape({
    pawn: PropTypes.string.isRequired,
    knight: PropTypes.string.isRequired,
    tower: PropTypes.string.isRequired,
    dragon: PropTypes.string.isRequired,
  }).isRequired,
  playerIndex: PropTypes.number.isRequired,
  pieceType: PropTypes.string.isRequired,
  currentMode: PropTypes.symbol.isRequired,
  setCurrentMode: PropTypes.func.isRequired,
  currentPlayerIndex: PropTypes.number.isRequired,
  setCurrentPlayerIndex: PropTypes.func.isRequired,
  currentPieceType: PropTypes.string,
  setCurrentPieceType: PropTypes.func.isRequired,
};

export function Editor(props) {
  const treesBySlot = useSelector(selectTreesBySlot);
  const treeName = treesBySlot[props.slot];
  const game = useSelector(selectGames)[treeName];

  const dispatch = useDispatch();

  const [currentMode, setCurrentMode] = useState(PIECE_TELEPORTATION_MODE);
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);
  const [currentPieceType, setCurrentPieceType] = useState(undefined);
  if (currentPieceType === undefined && game !== undefined) {
    setCurrentPieceType(game.pawn);
  }

  const caption =
    <>
      Editing position.
    </>;

  const additionButtons = [];
  if (game !== undefined) {
    for (let playerIndex = 0; playerIndex < game.playerCount; ++playerIndex) {
      for (const pieceType of [game.pawn, game.knight, game.tower]) {
        additionButtons.push(
          <AdditionButton
            key={`mode button for ${playerIndex}/${pieceType}`}
            game={game}
            playerIndex={playerIndex}
            pieceType={pieceType}
            currentMode={currentMode}
            setCurrentMode={setCurrentMode}
            currentPlayerIndex={currentPlayerIndex}
            setCurrentPlayerIndex={setCurrentPlayerIndex}
            currentPieceType={currentPieceType}
            setCurrentPieceType={setCurrentPieceType} />,
        );
      }
    }
  }

  return (
    <>
      <div className={styles.editor}>
        <Caption slot={props.slot} caption={caption} analysisOnly={true} />
        <Board
          mode={currentMode}
          additionPlayerIndex={currentPlayerIndex}
          additionPieceType={currentPieceType}
          slot={props.slot}
          analysisOnly={true} />
      </div>
      <ButtonBar>
        <Button
          image={rotateIcon}
          altText={'Rotate Board'}
          onClick={() => dispatch(rotateBoard({ treeName }))} />
        <Button
          image={teleportPieceIcon}
          altText={'"Move Pieces" Mode'}
          highlighted={currentMode === PIECE_TELEPORTATION_MODE}
          onClick={() => setCurrentMode(PIECE_TELEPORTATION_MODE)} />
        {additionButtons}
        <Button
          image={removePieceIcon}
          altText={'"Remove Pieces" Mode'}
          highlighted={currentMode === PIECE_REMOVAL_MODE}
          onClick={() => setCurrentMode(PIECE_REMOVAL_MODE)} />
      </ButtonBar>
    </>
  );
}

Editor.propTypes = {
  slot: PropTypes.string.isRequired,
};
