export const DUMMY_PLAYER = 'DUMMY';
export const ENGINE_PLAYER = 'ENGINE';
export const HUMAN_PLAYER = 'HUMAN';
export const REMOTE_PLAYER = 'REMOTE';

export const TEACHING_STRENGTH = 100;
export const FASTEST_STRENGTH = 400;
export const DEMONSTRATION_STRENGTH = 1550;
export const STANDARD_OPENING_ASSISTANCE = 12;

const PLAYER_DESCRIPTION_FORMATS = new Map([
  [ENGINE_PLAYER, (player) =>
    [
      player.strength === TEACHING_STRENGTH ? 'Teaching-Game Bot' :
        player.strength === FASTEST_STRENGTH ? 'Fastest Bot' :
          player.strength === DEMONSTRATION_STRENGTH ? 'Demonstration Bot' :
            'Bot',
      `Strength ${player.strength}`,
    ],
  ],
  [HUMAN_PLAYER, (player) => {
    if (player.assistance <= 0) {
      return ['Human', 'Unassisted'];
    }
    if (player.assistance === Infinity) {
      return ['Human', 'with Full-Game Assistance'];
    }
    return ['Human', 'with Opening Assistance'];
  }],
  [REMOTE_PLAYER, (player) => [
    player.name !== undefined ? `"${player.name}"` : 'Network Opponent',
    player.bySeek ? 'By Automatic Pairing' :
      player.byInvite ? 'By Invitation Code' :
        'Remote',
  ]],
]);

function formatUnknownPlayerType(player) {
  return ['Other', `${player.type}`];
}

export function formatHandicap(handicap) {
  if (handicap === undefined || handicap <= 0) {
    return 'No Handicap';
  }
  if (handicap === 1) {
    return 'Skips First Turn';
  }
  return `Skips First ${handicap} Turns`;
}

function format(player) {
  return [
    ...(PLAYER_DESCRIPTION_FORMATS.get(player.type) || formatUnknownPlayerType)(player),
    formatHandicap(player.handicap),
  ];
}

export function formatAsText(player) {
  const [general, specific] = format(player);
  return `${general} (${specific})`;
}

export function formatAsHTML(player) {
  const [general, specific, handicap] = format(player);
  return <>{general}<br />{specific}<br />{handicap}</>;
}

/* eslint-disable no-magic-numbers */
function*strengths() {
  for (let strength = 100; strength < 1500; strength += 100) {
    yield strength;
  }
  for (let strength = 1500; strength <= 2000; strength += 50) {
    yield strength;
  }
}
/* eslint-enable no-magic-numbers */

export const AVAILABLE_LOCAL_PLAYERS = [
  {
    type: HUMAN_PLAYER,
    assistance: Infinity,
  },
  {
    type: HUMAN_PLAYER,
    assistance: STANDARD_OPENING_ASSISTANCE,
  },
  {
    type: HUMAN_PLAYER,
    assistance: 0,
  },
];

for (const strength of strengths()) {
  AVAILABLE_LOCAL_PLAYERS.push({
    type: ENGINE_PLAYER,
    strength,
  });
}

export const AVAILABLE_REMOTE_PLAYERS = [
  {
    type: HUMAN_PLAYER,
    assistance: 0,
  },
  {
    type: REMOTE_PLAYER,
    bySeek: true,
    byInvite: false,
  },
  {
    type: REMOTE_PLAYER,
    bySeek: false,
    byInvite: true,
  },
];

export function getNetworkGamePlayers(humanIndex, ...names) {
  const user = {
    type: HUMAN_PLAYER,
    assistance: 0,
  };
  const other = {
    type: REMOTE_PLAYER,
  };
  const results = [other, other];
  if (humanIndex !== undefined) {
    results[humanIndex] = user;
  }
  for (let i = 0; i < results.length && i < names.length; ++i) {
    results[i] = {
      ...results[i],
      name: names[i],
    };
  }
  return results;
}

const defaultPlayerCache = new Map();

export function getOriginalDefaultPlayers(game, remote) {
  const key = `${game.identifier}/${remote ? 'remote' : 'local'}`;
  let result = defaultPlayerCache.get(key);
  if (result === undefined) {
    if (remote) {
      result = [
        {
          type: HUMAN_PLAYER,
          assistance: 0,
        },
      ];
      while (result.length < game.playerCount) {
        result.push({
          type: REMOTE_PLAYER,
          bySeek: true,
        });
      }
    } else {
      result = [
        {
          type: HUMAN_PLAYER,
          assistance: STANDARD_OPENING_ASSISTANCE,
        },
      ];
      while (result.length < game.playerCount) {
        result.push({
          type: ENGINE_PLAYER,
          strength: TEACHING_STRENGTH,
        });
      }
    }
    defaultPlayerCache.set(key, result);
  }
  return result;
}

export const internals = {
  defaultPlayerCache,
};
