import { createSlice } from '@reduxjs/toolkit';
import { createObjectSelector } from 'reselect-map';

function getEntry(tutorialProgress, tutorial) {
  const entry = tutorialProgress[tutorial] || {
    progress: 0,
    furthestProgress: 0,
  };
  tutorialProgress[tutorial] = entry;
  return entry;
}

const tutorialProgressSlice = createSlice({
  name: 'tutorialProgress',
  initialState: {},
  reducers: {
    resetTutorialProgress: (tutorialProgress, action) => {
      const {
        tutorial,
      } = action.payload;
      const entry = getEntry(tutorialProgress, tutorial);
      entry.progress = 0;
      entry.furthestProgress = 0;
    },
    changeTutorialProgress: (tutorialProgress, action) => {
      const {
        tutorial,
        change,
      } = action.payload;
      const entry = getEntry(tutorialProgress, tutorial);
      entry.progress += change;
      entry.furthestProgress = Math.max(entry.progress, entry.furthestProgress);
    },
    setTutorialProgress: (tutorialProgress, action) => {
      const {
        tutorial,
        progress,
      } = action.payload;
      const entry = getEntry(tutorialProgress, tutorial);
      entry.progress = progress;
      entry.furthestProgress = Math.max(entry.progress, entry.furthestProgress);
    },
  },
});
export default tutorialProgressSlice;

export const {
  resetTutorialProgress,
  changeTutorialProgress,
  setTutorialProgress,
} = tutorialProgressSlice.actions;

function selectEntries(state) {
  return state.tutorialProgress;
}

export const selectTutorialProgress = createObjectSelector(
  [selectEntries],
  (entry) => entry.progress,
);

export const selectFurthestTutorialProgress = createObjectSelector(
  [selectEntries],
  (entry) => entry.furthestProgress,
);
