// Work around React not using the es2020 env or newer in ESLint:
// eslint-disable-next-line no-redeclare
/* global BigInt */

import { createSlice } from '@reduxjs/toolkit';
import { createObjectSelector } from 'reselect-map';

import {
  HUMAN_PLAYER,
  REMOTE_PLAYER,
} from './playerTypes.js';

import GAME_LIBRARY from '../../gameLibrary.js';

const NO_IDENTITY = null; // must be a value not lost in redux-persist serialization/deserialization

export const impure = {
  createIdentity() {
    return Math.random(); // 53 significand bits is plenty of randomness for a game tree
  },
  createTimestamp() {
    return new Date().toUTCString();
  },
};

function toPlayerIndex(game, internalColor, ply) {
  return internalColor !== undefined ? (internalColor + ply) % game.playerCount : undefined;
}

function toColor(game, playerIndex, ply) {
  const inverse = (ply % game.playerCount) + game.playerCount;
  return playerIndex !== undefined ? (playerIndex + inverse) % game.playerCount : undefined;
}

function encodePiece(identity, playerIndex, type, point) {
  return {
    identity,
    playerIndex,
    type,
    point,
  };
}

function encodePieces(game, ply, position, findIdentity) {
  const pieces = {};
  for (let x = 0; x < game.boardWidth; ++x) {
    for (let y = 0; y < game.boardHeight; ++y) {
      const [internalColor, pieceType] = position.getColorAndPieceType(x, y);
      if (pieceType !== undefined) {
        const key = game.prettifyPoint(x, y);
        pieces[key] = encodePiece(findIdentity(key), toPlayerIndex(game, internalColor, ply), pieceType, key);
      }
    }
  }
  return pieces;
}

function encodeRootPieces(game, ply, position) {
  return encodePieces(game, ply, position, impure.createIdentity);
}

function encodeChildPieces(game, parentEncoding, ply, fromKey, toKey, child) {
  const movedPiece = parentEncoding.pieces[fromKey];
  const movedPieceIdentity = movedPiece !== undefined ? movedPiece.identity : impure.createIdentity();
  return encodePieces(
    game,
    ply,
    child,
    (key) => key === toKey ? movedPieceIdentity : parentEncoding.pieces[key].identity,
  );
}

function encodeFalseChildPieces(game, parentEncoding, ply, child) {
  return encodePieces(
    game,
    ply,
    child,
    (key) => parentEncoding.pieces[key] !== undefined ? parentEncoding.pieces[key].identity : impure.createIdentity(),
  );
}

function encodePosition(game, eternal, parentIdentity, ply, position, pieces) {
  const result = {
    identity: impure.createIdentity(),
    parentIdentity,
    ply,
    signature: `${position.signature}`,
    serialization: position.serialization,
    pieces,
    live: eternal || position.live,
    analysisDepth: undefined,
    advantage: undefined,
    suggestions: [],
    mainLineMove: undefined,
    mostRecentMove: undefined,
    children: {},
  };
  if (result.live) {
    for (const child of position.children) {
      result.children[position.getMoveTo(child)] = NO_IDENTITY;
    }
  }
  return result;
}

function encodeRootPosition(game, eternal, ply, position, originalEncoding = undefined) {
  const encodedPieces = originalEncoding === undefined ?
    encodeRootPieces(game, ply, position) :
    encodeFalseChildPieces(game, originalEncoding, ply, position);
  return encodePosition(game, eternal, NO_IDENTITY, ply, position, encodedPieces);
}

function encodeReplacementRootPosition(game, eternal, ply, position, originalEncoding, fromKey, toKey) {
  const encodedPieces = encodeChildPieces(game, originalEncoding, ply, fromKey, toKey, position);
  return encodePosition(game, eternal, NO_IDENTITY, ply, position, encodedPieces);
}

function obtainChildEncoding(tree, game, parentEncoding, move, preferMainLine, childFactory = undefined) {
  const candidate = parentEncoding.children[move];
  let result = tree.positions[candidate];
  if (candidate === undefined || candidate === NO_IDENTITY) {
    const ply = parentEncoding.ply + 1;
    const parent = game.deserializePosition(parentEncoding.serialization);
    const child = childFactory !== undefined ? childFactory(parent, move) : parent.getChildByMove(move).nextTurn;
    const pieces = childFactory !== undefined ?
      encodeFalseChildPieces(game, parentEncoding, ply, child) :
      encodeChildPieces(game, parentEncoding, ply, ...game.splitMove(move), child);
    result = encodePosition(game, tree.eternal, parentEncoding.identity, ply, child, pieces);
    parentEncoding.children[move] = result.identity;
  }
  if ((preferMainLine && !tree.complete) || parentEncoding.mainLineMove === undefined) {
    parentEncoding.mainLineMove = move;
  }
  parentEncoding.mostRecentMove = move;
  return result;
}

function updateWinnerOnTime(tree) {
  if (tree.winnerOnTime === undefined && Number.isFinite(tree.timeControls)) {
    for (let index = 0; index < tree.timeUsed.length; ++index) {
      if (tree.timeUsed[index] <= tree.timeControls) {
        if (tree.winnerOnTime === undefined) {
          tree.winnerOnTime = index;
        } else {
          tree.winnerOnTime = undefined;
          break;
        }
      }
    }
  }
}

function consumeTime(tree, playerIndex, time, makingMove) {
  tree.timeUsed[tree.runningClockIndex] += time;
  updateWinnerOnTime(tree);
  if (tree.winnerOnTime === undefined && playerIndex !== tree.runningClockIndex) {
    let reallocation = 0;
    for (let index = 0; index < tree.timeUsed.length; ++index) {
      reallocation += tree.timeUsed[index] - tree.timeUsedBeforeMove[index];
      tree.timeUsed[index] = tree.timeUsedBeforeMove[index];
    }
    tree.timeUsed[playerIndex] += reallocation;
    updateWinnerOnTime(tree);
  }
  if (makingMove || tree.winnerOnTime !== undefined) {
    for (let index = 0; index < tree.timeUsed.length; ++index) {
      tree.timeUsedBeforeMove[index] = tree.timeUsed[index];
    }
  }
  ++tree.timeUsedEditCount;
}

function getRotationFor(players, playerType) {
  const halfLength = Math.floor(players.length / 2);
  let unrotatedVotes = 0;
  for (let i = 0; i < halfLength; ++i) {
    unrotatedVotes += players[i] !== null && players[i].type === playerType ? 1 : 0;
  }
  let rotatedVotes = 0;
  for (let i = players.length - halfLength; i < players.length; ++i) {
    rotatedVotes += players[i] !== null && players[i].type === playerType ? 1 : 0;
  }
  if (unrotatedVotes > rotatedVotes) {
    return false;
  } else if (rotatedVotes > unrotatedVotes) {
    return true;
  }
  return undefined; // tie
}

function deletePosition(tree, positionIdentity) {
  let currentPositionDeleted = positionIdentity === tree.currentPositionIdentity;
  if (positionIdentity !== NO_IDENTITY) {
    const positionEncoding = tree.positions[positionIdentity];
    for (const move of Object.getOwnPropertyNames(positionEncoding.children)) {
      currentPositionDeleted ||= deletePosition(tree, positionEncoding.children[move]);
    }
    const parentEncoding = tree.positions[positionEncoding.parentIdentity];
    let moribundMove = undefined;
    let replacementMove = undefined;
    for (const move of Object.getOwnPropertyNames(parentEncoding.children)) {
      if (parentEncoding.children[move] === positionIdentity) {
        moribundMove = move;
      } else if (parentEncoding.children[move] !== NO_IDENTITY) {
        replacementMove = move;
      }
    }
    if (parentEncoding.mostRecentMove === moribundMove) {
      parentEncoding.mostRecentMove = replacementMove;
    }
    if (parentEncoding.mainLineMove === moribundMove) {
      parentEncoding.mainLineMove = replacementMove;
    }
    parentEncoding.children[moribundMove] = NO_IDENTITY;
    delete tree.positions[positionIdentity];
  }
  return currentPositionDeleted;
}

function jump(tree, forward, repeat, playerIndex) {
  if (tree === undefined) {
    return;
  }
  const playerCount = GAME_LIBRARY.get(tree.game).playerCount;
  let looping = true;
  let positionEncoding = tree.positions[tree.currentPositionIdentity];
  // The ?: rather than a plain && is to avoid a false positive from the eslint rule no-unmodified-loop-condition.
  while (looping || (playerIndex !== undefined ? positionEncoding.ply % playerCount !== playerIndex : false)) {
    if (positionEncoding === undefined ||
      (!forward && positionEncoding.parentIdentity === NO_IDENTITY) ||
      (forward && positionEncoding.mostRecentMove === undefined)) {
      break;
    }
    if (forward) {
      tree.currentPositionIdentity = positionEncoding.children[positionEncoding.mostRecentMove];
      positionEncoding = tree.positions[tree.currentPositionIdentity];
    } else {
      tree.currentPositionIdentity = positionEncoding.parentIdentity;
      const parent = tree.positions[tree.currentPositionIdentity];
      for (const move of Object.getOwnPropertyNames(parent.children)) {
        if (parent.children[move] === positionEncoding.identity) {
          parent.mostRecentMove = move;
          break;
        }
      }
      positionEncoding = parent;
    }
    looping = repeat;
  }
}

const gameTreesSlice = createSlice({
  name: 'gameTrees',
  initialState: {},
  reducers: {
    newGame: (gameTrees, action) => {
      const {
        treeName,
        gameIdentifier,
        timeControls,
        players,
        rootPosition,
        rootPly,
        compensated,
        eternal,
        rotated,
        suggestions,
        originalEncoding,
      } = action.payload;
      const game = GAME_LIBRARY.get(gameIdentifier);
      if (game === undefined) {
        return;
      }
      console.assert(
        players === undefined || players.length === game.playerCount,
        `It is not possible to create a new game requiring ${game.playerCount} player(s) with a list of ` +
          `${players !== undefined ? players.length : 0} player(s).`,
      );
      console.assert(
        players === undefined || players.every((player) => player.type !== REMOTE_PLAYER),
        'Network games should be created with `synchronizeNetworkGame`, not `newGame`.',
      );
      const position = rootPosition !== undefined ? game.deserializePosition(rootPosition) : game.startingPosition;
      const positionEncoding = encodeRootPosition(game, eternal, rootPly || 0, position, originalEncoding);
      if (suggestions !== undefined) {
        positionEncoding.analysisDepth = 0;
        positionEncoding.advantage = 0;
        positionEncoding.suggestions = suggestions;
      }
      gameTrees[treeName] = {
        title: undefined,
        players: players !== undefined ? players : Array(game.playerCount).fill(null),
        game: gameIdentifier,
        timeControls: timeControls !== undefined ? timeControls : Infinity, // in seconds
        timeUsed: Array(game.playerCount).fill(0), // in seconds
        timeUsedBeforeMove: Array(game.playerCount).fill(0), // in seconds
        timeUsedEditCount: 0,
        runningClockIndex: 0,
        compensated,
        eternal: eternal || false,
        winnerOnTime: undefined,
        winnerByResignation: undefined,
        complete: false,
        positions: {
          [positionEncoding.identity]: positionEncoding,
        },
        rootIdentity: positionEncoding.identity,
        currentPositionIdentity: positionEncoding.identity,
        modificationTime: impure.createTimestamp(),
        rotated: rotated || false,
      };
    },
    synchronizeNetworkGame: (gameTrees, action) => {
      const {
        treeName,
        networkCode,
        timestamp,
        gameIdentifier,
        timeControls,
        timeUsed,
        dragonPoints,
        players,
        moves,
        winnerOnTime,
        winnerByResignation,
      } = action.payload;
      const game = GAME_LIBRARY.get(gameIdentifier);
      if (game === undefined) {
        return;
      }
      console.assert(players !== undefined, 'It is not possible to sync a network game with no players.');
      console.assert(
        players.length === game.playerCount,
        `It is not possible to sync a network game requiring ${game.playerCount} player(s) with a list of ` +
        `${players.length} player(s).`,
      );
      console.assert(networkCode !== undefined, 'It is not possible to sync a network game with no network code.');
      let tree = gameTrees[treeName];
      const hasLocalPlayer = players.some((player) => player.type === HUMAN_PLAYER);
      let wasShowingCurrentPosition = true;
      if (tree === undefined) {
        const compensationFromPoint = game.prettifyPoint(...game.compensationFromPoint);
        const compensationToPoint = game.prettifyPoint(...game.compensationToPoint);
        let rootPosition = game.startingPosition;
        for (const dragonPoint of dragonPoints) {
          const compensatedPoint = (!hasLocalPlayer || moves.length > 0) && dragonPoint === compensationFromPoint ?
            compensationToPoint :
            dragonPoint;
          rootPosition = rootPosition.modified(...game.unprettifyPoint(compensatedPoint), undefined, game.dragon);
        }
        const rootPositionEncoding = encodeRootPosition(game, false, 0, rootPosition);
        tree = {
          title: undefined,
          networkCode,
          players: undefined,
          game: gameIdentifier,
          timeControls: undefined,
          timeUsed: undefined,
          timeUsedBeforeMove: undefined,
          timeUsedEditCount: -1,
          runningClockIndex: undefined,
          compensated: true,
          eternal: false,
          winnerOnTime: undefined,
          winnerByResignation: undefined,
          complete: undefined,
          positions: {
            [rootPositionEncoding.identity]: rootPositionEncoding,
          },
          rootIdentity: rootPositionEncoding.identity,
          currentPositionIdentity: undefined,
          modificationTime: new Date(timestamp).toUTCString(),
          rotated: getRotationFor(players, HUMAN_PLAYER) || false,
        };
        gameTrees[treeName] = tree;
      } else {
        const currrentPositionEncoding = tree.positions[tree.currentPositionIdentity];
        wasShowingCurrentPosition = currrentPositionEncoding.mostRecentMove === undefined;
        console.assert(
          tree.networkCode === networkCode,
          `Tried to overwrite a game with network code ${tree.networkCode} based on network code ${networkCode}.`,
        );
      }
      let positionEncoding = tree.positions[tree.rootIdentity];
      for (const move of moves) {
        positionEncoding = obtainChildEncoding(tree, game, positionEncoding, move, true);
        tree.positions[positionEncoding.identity] = positionEncoding;
      }
      tree.players = players;
      tree.timeControls = timeControls;
      tree.timeUsed = timeUsed;
      tree.timeUsedBeforeMove = timeUsed;
      ++tree.timeUsedEditCount;
      tree.runningClockIndex = positionEncoding.live && winnerByResignation === undefined ?
        moves.length % game.playerCount : undefined;
      tree.winnerOnTime = winnerOnTime;
      tree.winnerByResignation = winnerByResignation;
      tree.complete = !positionEncoding.live;
      if (hasLocalPlayer || wasShowingCurrentPosition) {
        tree.currentPositionIdentity = positionEncoding.identity;
      }
    },
    touchGame: (gameTrees, action) => {
      const {
        treeName,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        gameTrees[treeName].modificationTime = impure.createTimestamp();
      }
    },
    moveGame: (gameTrees, action) => {
      const {
        fromTreeName,
        toTreeName,
      } = action.payload;
      if (gameTrees[fromTreeName] !== undefined) {
        gameTrees[toTreeName] = gameTrees[fromTreeName];
        delete gameTrees[fromTreeName];
      }
    },
    deleteGame: (gameTrees, action) => {
      const {
        treeName,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        delete gameTrees[treeName];
      }
    },
    deleteGames: (gameTrees, action) => {
      const {
        treeNamePrefix,
        protectedSuffices,
      } = action.payload;
      for (const treeName of Object.getOwnPropertyNames(gameTrees)) {
        if (treeName.startsWith(treeNamePrefix) &&
          !protectedSuffices.has(treeName.slice(treeNamePrefix.length)) &&
          gameTrees[treeName].title === undefined) {
          delete gameTrees[treeName];
        }
      }
    },
    retitleGame: (gameTrees, action) => {
      const {
        treeName,
        title,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        gameTrees[treeName].title = title;
      }
    },
    setPlayer: (gameTrees, action) => {
      const {
        treeName,
        playerIndex,
        player,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        gameTrees[treeName].players[playerIndex] = player;
      }
    },
    setPlayers: (gameTrees, action) => {
      const {
        treeName,
        players,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree !== undefined) {
        console.assert(
          players.length === tree.players.length,
          `It is not possible to change a ${tree.players.length}-player game to a ${players.length}-player game.`,
        );
        for (let playerIndex = players.length; playerIndex--;) {
          tree.players[playerIndex] = players[playerIndex];
        }
      }
    },
    setTimeControls: (gameTrees, action) => {
      const {
        treeName,
        timeControls,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        gameTrees[treeName].timeControls = timeControls;
      }
    },
    takeTime: (gameTrees, action) => {
      const {
        treeName,
        time,
        fromEditCount,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        const tree = gameTrees[treeName];
        if (tree.timeUsedEditCount === fromEditCount && tree.runningClockIndex !== undefined) {
          consumeTime(tree, tree.runningClockIndex, time, false);
        }
      }
    },
    setCompensation: (gameTrees, action) => {
      const {
        treeName,
        compensated,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        gameTrees[treeName].compensated = compensated;
      }
    },
    rotateBoard: (gameTrees, action) => {
      const {
        treeName,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        gameTrees[treeName].rotated = !gameTrees[treeName].rotated;
      }
    },
    rotateBoardFor: (gameTrees, action) => {
      const {
        treeName,
        playerType,
      } = action.payload;
      if (gameTrees[treeName] !== undefined) {
        const rotation = getRotationFor(gameTrees[treeName].players, playerType);
        // (Do nothing in case of a tie; there might be a user-selected rotation
        // that we don't want to overwrite.)
        if (rotation !== undefined) {
          gameTrees[treeName].rotated = rotation;
        }
      }
    },
    rerootWithModification: (gameTrees, action) => {
      const {
        treeName,
        point,
        playerIndex,
        pieceType,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree === undefined) {
        return;
      }
      const game = GAME_LIBRARY.get(tree.game);
      const originalEncoding = tree.positions[tree.currentPositionIdentity];
      const original = game.deserializePosition(originalEncoding.serialization);
      const [x, y] = game.unprettifyPoint(point);
      if (original.getColorAndPieceType(x, y)[1] === game.dragon) {
        return;
      }
      const positionEncoding = encodeRootPosition(
        game,
        tree.eternal,
        originalEncoding.ply,
        original.modified(x, y, toColor(game, playerIndex, originalEncoding.ply), pieceType),
      );
      tree.positions = {
        [positionEncoding.identity]: positionEncoding,
      };
      tree.rootIdentity = positionEncoding.identity;
      tree.currentPositionIdentity = positionEncoding.identity;
      tree.modificationTime = impure.createTimestamp();
    },
    rerootWithTeleportation: (gameTrees, action) => {
      const {
        treeName,
        from,
        to,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree === undefined) {
        return;
      }
      const game = GAME_LIBRARY.get(tree.game);
      const originalEncoding = tree.positions[tree.currentPositionIdentity];
      const original = game.deserializePosition(originalEncoding.serialization);
      const [fromX, fromY] = game.unprettifyPoint(from);
      const [toX, toY] = game.unprettifyPoint(to);
      const [color, pieceType] = original.getColorAndPieceType(fromX, fromY);
      if (pieceType === undefined || original.getColorAndPieceType(toX, toY)[1] !== undefined) {
        return;
      }
      const positionEncoding = encodeReplacementRootPosition(
        game,
        tree.eternal,
        originalEncoding.ply,
        original.modified(fromX, fromY, undefined, undefined).modified(toX, toY, color, pieceType),
        originalEncoding,
        from,
        to,
      );
      tree.positions = {
        [positionEncoding.identity]: positionEncoding,
      };
      tree.rootIdentity = positionEncoding.identity;
      tree.currentPositionIdentity = positionEncoding.identity;
      tree.modificationTime = impure.createTimestamp();
    },
    rerootWithCompensation: (gameTrees, action) => {
      const {
        treeName,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree === undefined || !tree.compensated || Object.getOwnPropertyNames(tree.positions).length !== 1) {
        return;
      }
      const game = GAME_LIBRARY.get(tree.game);
      if (game.compensationFromPoint === undefined || game.compensationToPoint === undefined) {
        return;
      }
      const originalEncoding = tree.positions[tree.currentPositionIdentity];
      const original = game.deserializePosition(originalEncoding.serialization);
      if (original.getColorAndPieceType(...game.compensationFromPoint)[1] !== game.dragon ||
        original.getColorAndPieceType(...game.compensationToPoint)[1] !== undefined) {
        return;
      }
      const positionEncoding = encodeReplacementRootPosition(
        game,
        tree.eternal,
        originalEncoding.ply,
        original
          .modified(...game.compensationFromPoint, undefined, undefined)
          .modified(...game.compensationToPoint, undefined, game.dragon),
        originalEncoding,
        game.prettifyPoint(...game.compensationFromPoint),
        game.prettifyPoint(...game.compensationToPoint),
      );
      tree.positions = {
        [positionEncoding.identity]: positionEncoding,
      };
      tree.rootIdentity = positionEncoding.identity;
      tree.currentPositionIdentity = positionEncoding.identity;
      tree.modificationTime = impure.createTimestamp();
    },
    setAnalysis: (gameTrees, action) => {
      const {
        treeName,
        positionIdentity,
        analysisDepth,
        advantage,
        suggestions,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree === undefined || (positionIdentity !== undefined && positionIdentity !== tree.currentPositionIdentity)) {
        return;
      }
      const positionEncoding = tree.positions[tree.currentPositionIdentity];
      positionEncoding.analysisDepth = analysisDepth;
      positionEncoding.advantage = advantage;
      positionEncoding.suggestions = suggestions;
    },
    makeMove: (gameTrees, action) => {
      const {
        treeName,
        positionIdentity,
        move,
        isNull,
        preferMainLine,
        time,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree === undefined || (positionIdentity !== undefined && positionIdentity !== tree.currentPositionIdentity)) {
        return;
      }
      const game = GAME_LIBRARY.get(tree.game);
      const parentEncoding = tree.positions[tree.currentPositionIdentity];
      if (isNull || Object.getOwnPropertyNames(parentEncoding.children).includes(move)) {
        const newMove = parentEncoding.children[move] === undefined || parentEncoding.children[move] === NO_IDENTITY;
        const positionEncoding = obtainChildEncoding(
          tree,
          game,
          parentEncoding,
          move,
          preferMainLine,
          isNull ? (parent) => parent.nextTurn : undefined,
        );
        if (newMove && !tree.complete) {
          consumeTime(tree, parentEncoding.ply % game.playerCount, time, true);
          tree.runningClockIndex = positionEncoding.ply % game.playerCount;
          if (!positionEncoding.live) {
            tree.runningClockIndex = undefined;
            tree.complete = true;
          }
        }
        tree.positions[positionEncoding.identity] = positionEncoding;
        tree.currentPositionIdentity = positionEncoding.identity;
        tree.modificationTime = impure.createTimestamp();
      }
    },
    makeMainLine: (gameTrees, action) => {
      const {
        treeName,
        positionIdentity,
      } = action.payload;
      const tree = gameTrees[treeName];
      tree.modificationTime = impure.createTimestamp();
      let positionEncoding = tree.positions[positionIdentity];
      for (;;) {
        const parentEncoding = tree.positions[positionEncoding.parentIdentity];
        if (parentEncoding === undefined) {
          break;
        }
        for (const move of Object.getOwnPropertyNames(parentEncoding.children)) {
          if (parentEncoding.children[move] === positionEncoding.identity) {
            parentEncoding.mainLineMove = move;
            break;
          }
        }
        positionEncoding = parentEncoding;
      }
    },
    deleteLine: (gameTrees, action) => {
      const {
        treeName,
        positionIdentity,
      } = action.payload;
      const tree = gameTrees[treeName];
      tree.modificationTime = impure.createTimestamp();
      const parentIdentity = tree.positions[positionIdentity].parentIdentity;
      const currentPositionDeleted = deletePosition(tree, positionIdentity);
      if (currentPositionDeleted) {
        tree.currentPositionIdentity = parentIdentity;
      }
    },
    jumpToFirst: (gameTrees, action) => {
      const {
        treeName,
      } = action.payload;
      jump(gameTrees[treeName], false, true);
    },
    jumpToPrevious: (gameTrees, action) => {
      const {
        treeName,
        playerIndex,
      } = action.payload;
      jump(gameTrees[treeName], false, false, playerIndex);
    },
    jumpToNext: (gameTrees, action) => {
      const {
        treeName,
        playerIndex,
      } = action.payload;
      jump(gameTrees[treeName], true, false, playerIndex);
    },
    jumpToLast: (gameTrees, action) => {
      const {
        treeName,
      } = action.payload;
      jump(gameTrees[treeName], true, true);
    },
    setPosition: (gameTrees, action) => {
      const {
        treeName,
        position,
      } = action.payload;
      const tree = gameTrees[treeName];
      if (tree === undefined) {
        return;
      }
      if (Object.getOwnPropertyNames(tree.positions).includes(String(position))) {
        tree.currentPositionIdentity = position;
      }
    },
  },
});
export default gameTreesSlice;

export const {
  newGame,
  synchronizeNetworkGame,
  touchGame,
  moveGame,
  deleteGame,
  deleteGames,
  retitleGame,
  setPlayer,
  setPlayers,
  setTimeControls,
  takeTime,
  setCompensation,
  rotateBoard,
  rotateBoardFor,
  rerootWithModification,
  rerootWithTeleportation,
  rerootWithCompensation,
  setAnalysis,
  makeMove,
  makeMainLine,
  deleteLine,
  jumpToFirst,
  jumpToPrevious,
  jumpToNext,
  jumpToLast,
  setPosition,
} = gameTreesSlice.actions;

function selectTrees(state) {
  return state.gameTrees;
}

export const selectNetworkCodes = createObjectSelector(
  [selectTrees],
  (tree) => tree.networkCode,
);

export const selectPlayers = createObjectSelector(
  [selectTrees],
  (tree) => tree.players.map((player) => {
    if (player !== null && player.assistance === null) {
      // JSON serializes Infinity as null, so we have to undo that after deserialization
      return {
        ...player,
        assistance: Infinity,
      };
    }
    return player;
  }),
);

export const selectTimeControls = createObjectSelector(
  [selectTrees],
  // JSON serializes Infinity as null, so we have to undo that after deserialization
  (tree) => tree.timeControls === null ? Infinity : tree.timeControls,
);

export const selectTimeUsed = createObjectSelector(
  [selectTrees],
  (tree) => tree.timeUsed,
);

export const selectTimeUsedEditCount = createObjectSelector(
  [selectTrees],
  (tree) => tree.timeUsedEditCount,
);

export const selectRunningClockIndices = createObjectSelector(
  [selectTrees],
  (tree) => tree.runningClockIndex,
);

export const selectCompensations = createObjectSelector(
  [selectTrees],
  (tree) => tree.compensated,
);

export const selectCompletionFlags = createObjectSelector(
  [selectTrees],
  (tree) => tree.complete,
);

export const selectWinnersOnTime = createObjectSelector(
  [selectTrees],
  (tree) => tree.winnerOnTime,
);

export const selectWinnersByResignation = createObjectSelector(
  [selectTrees],
  (tree) => tree.winnerByResignation,
);

export const selectRotations = createObjectSelector(
  [selectTrees],
  (tree) => tree.rotated,
);

export const selectGames = createObjectSelector(
  [selectTrees],
  (tree) => GAME_LIBRARY.get(tree.game),
);

export const selectPreviews = createObjectSelector(
  [selectTrees],
  (tree) => ({
    title: tree.title,
    players: tree.players,
    game: GAME_LIBRARY.get(tree.game),
    modificationTime: new Date(tree.modificationTime),
  }),
);

export const selectRerootSafeties = createObjectSelector(
  [selectTrees],
  (tree) => Object.getOwnPropertyNames(tree.positions).length === 1,
);

export const selectMoveTrees = createObjectSelector(
  [selectTrees],
  (tree) => {
    function createSubtree(positionEncoding) {
      const children = new Map();
      for (const move of Object.getOwnPropertyNames(positionEncoding.children)) {
        const child = tree.positions[positionEncoding.children[move]];
        if (child !== undefined) {
          children.set(move, createSubtree(child));
        }
      }
      return {
        identity: positionEncoding.identity,
        ply: positionEncoding.ply,
        moves: [
          ...[positionEncoding.mainLineMove].filter(
            (move) => move !== undefined,
          ),
          ...[...children.keys()].filter(
            (move) => move !== positionEncoding.mainLineMove,
          ).sort(),
        ],
        children,
      };
    }
    return createSubtree(tree.positions[tree.rootIdentity]);
  },
);

export const selectPreviousAvailabilities = createObjectSelector(
  [selectTrees],
  (tree) => {
    const positionEncoding = tree.positions[tree.currentPositionIdentity];
    return positionEncoding !== undefined && positionEncoding.parentIdentity !== NO_IDENTITY;
  },
);

export const selectNextAvailabilities = createObjectSelector(
  [selectTrees],
  (tree) => {
    const positionEncoding = tree.positions[tree.currentPositionIdentity];
    return positionEncoding !== undefined && positionEncoding.mostRecentMove !== undefined;
  },
);

export const selectRootPositions = createObjectSelector(
  [selectTrees],
  (tree) => tree.positions[tree.rootIdentity],
);

export const selectPositions = createObjectSelector(
  [selectTrees],
  (tree) => tree.positions[tree.currentPositionIdentity],
);

function getLine(tree) {
  const positionEncoding = tree.positions[tree.currentPositionIdentity];
  const results = [];
  for (let ancestor = tree.positions[positionEncoding.parentIdentity];
    ancestor !== undefined;
    ancestor = tree.positions[ancestor.parentIdentity]) {
    results.push(ancestor);
  }
  return results;
}

export const selectTaboos = createObjectSelector(
  [selectTrees],
  (tree) => getLine(tree),
);

export const selectRecentMoves = createObjectSelector(
  [selectTrees],
  (tree) => {
    const positionEncoding = tree.positions[tree.currentPositionIdentity];
    if (positionEncoding !== undefined && positionEncoding.parentIdentity !== NO_IDENTITY) {
      const parent = tree.positions[positionEncoding.parentIdentity];
      for (const move of Object.getOwnPropertyNames(parent.children)) {
        if (parent.children[move] === positionEncoding.identity) {
          return move;
        }
      }
    }
    return undefined;
  },
);

export const selectMoveSets = createObjectSelector(
  [selectTrees],
  (tree) => {
    const positionEncoding = tree.positions[tree.currentPositionIdentity];
    if (positionEncoding === undefined || !positionEncoding.live) {
      return [];
    }
    const game = GAME_LIBRARY.get(tree.game);
    const position = game.deserializePosition(positionEncoding.serialization);
    const player = tree.players[positionEncoding.ply % game.playerCount];
    if (player !== null && player.handicap !== undefined && positionEncoding.ply / game.playerCount < player.handicap) {
      return [game.pass];
    }
    const taboo = new Set(getLine(tree).map((ancestorEncoding) => BigInt(ancestorEncoding.signature)));
    const unfiltered = [];
    const filtered = [];
    for (const child of position.children) {
      unfiltered.push(position.getMoveTo(child));
      if (tree.eternal || !taboo.has(child.nextSignature)) {
        filtered.push(position.getMoveTo(child));
      }
    }
    return filtered.length > 0 ? filtered : unfiltered;
  },
);

export const selectEndsOfContinuations = createObjectSelector(
  [selectTrees],
  (tree) => {
    let result = tree.positions[tree.currentPositionIdentity];
    if (result !== undefined) {
      while (result.mostRecentMove !== undefined) {
        result = tree.positions[result.children[result.mostRecentMove]];
      }
    }
    return result;
  },
);

export const selectAssistanceFlags = createObjectSelector(
  [selectTrees],
  (tree) => {
    const game = GAME_LIBRARY.get(tree.game);
    const positionEncoding = tree.positions[tree.currentPositionIdentity];
    const player = tree.players[positionEncoding.ply % game.playerCount];
    if (player === null) {
      return false;
    }
    const assistanceLimit = (player.handicap || 0) + (player.assistance !== null ? player.assistance : Infinity);
    return positionEncoding.ply / game.playerCount < assistanceLimit;
  },
);

export const internals = {
  encodePiece,
};
