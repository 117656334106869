import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flipper, Flipped } from 'react-flip-toolkit';
import PropTypes from 'prop-types';

import { uninvite, makeMove as makeNetworkMove, resign } from '../../client.js';
import { useOnAbruptShutdown, takeTimeDuringAbruptShutdown } from './abruptShutdownRecovery.js';

import { PiecesMenu } from '../preferences/piecesMenu.js';

import { Modal, createModalOpener } from '../../widgets/modal.js';
import { Menu, MenuText, MenuButton } from '../../widgets/menu.js';
import { ButtonBar, Button } from '../../widgets/buttonBar.js';

import classNames from 'classnames';
import styles from './board.module.css';
import inviteIcon from '../../icons/invite.svg';
import resignIcon from '../../icons/resign.svg';

import {
  selectTreesBySlot,
  loadTree,
} from '../lobby/treeSlotsSlice.js';
import {
  selectNetworkCodes,
  selectPlayers,
  selectTimeControls,
  selectTimeUsed,
  selectTimeUsedEditCount,
  selectRunningClockIndices,
  selectRotations,
  selectGames,
  selectRerootSafeties,
  selectCompensations,
  selectCompletionFlags,
  selectWinnersOnTime,
  selectWinnersByResignation,
  selectPositions,
  selectMoveSets,
  selectAssistanceFlags,
  newGame,
  takeTime,
  rerootWithModification,
  rerootWithTeleportation,
  rerootWithCompensation,
  makeMove,
} from './gameTreesSlice.js';
import {
  selectDragon,
  selectColorSpecifications,
  selectPawns,
  selectKnights,
  selectTowers,
} from '../preferences/piecesSlice.js';

import { HUMAN_PLAYER, REMOTE_PLAYER } from './playerTypes.js';

import { formatDuration, untilNextUserVisibleTick } from './timekeeping.js';

export const GAMEPLAY_MODE = Symbol('GAMEPLAY_MODE');
export const PIECE_REMOVAL_MODE = Symbol('PIECE_REMOVAL_MODE');
export const PIECE_ADDITION_MODE = Symbol('PIECE_ADDITION_MODE');
export const PIECE_TELEPORTATION_MODE = Symbol('PIECE_TELEPORTATION_MODE');

// Because react-flip-toolkit has limited support for animated SVG elements, we
// have to scale everything to pixels for rendering.  The UNSCALED_* constants
// below are measured in points on the board, whereas the SCALED_* constants are
// measured in pixels.

/* eslint-disable no-magic-numbers */
const UNSCALED_LINE_WIDTH = 1 / 32;
const UNSCALED_FONT_HEIGHT = 3 / 8;
const UNSCALED_FONT_WIDTH = (2 / 3) * UNSCALED_FONT_HEIGHT;
const UNSCALED_DOT_RADIUS = 3 / 32;
const UNSCALED_TARGET_RADIUS = 3 / 8;
const UNSCALED_TARGET_THICKNESS = 1 / 16;
const UNSCALED_SUGGESTION_CIRCLE_RADIUS = 5 / 8;
const UNSCALED_SUGGESTION_ARROW_WIDTH = 1 / 4;
const UNSCALED_SUGGESTION_ARROW_RETRACTION = 1 / 8;
/* eslint-enable no-magic-numbers */

const SCALED_BOARD_SHADOW = 5;
const SCALED_BOARD_ROUNDING = 8;

// These, however, are just ratios.
const ADVANTAGE_ANALYSIS_PULSE_MAXIMUM = 0.50;
const ADVANTAGE_ANALYSIS_PULSE_MINIMUM = 0.25;

const FALLBACK_DIMENSION = 1;

const MINIMUM_TIME_DECREMENT = 0.1;

export const impure = {
  createTimestamp() {
    return new Date();
  },
};

function asX(file, game, rotated) {
  return rotated ? game.boardWidth - 1 - file : file;
}

function asY(rank, game, rotated) {
  return rotated ? game.boardHeight - 1 - rank : rank;
}

function asFile(x, game, rotated) {
  return rotated ? game.boardWidth - 1 - x : x;
}

function asRank(y, game, rotated) {
  return rotated ? game.boardHeight - 1 - y : y;
}

function Surface(props) {
  return (
    <>
      <filter id="shadow">
        <feGaussianBlur in="SourceAlpha" stdDeviation={SCALED_BOARD_SHADOW} />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <rect
        x={0}
        y={0}
        width={props.scale * props.width}
        height={props.scale * props.height}
        rx={SCALED_BOARD_ROUNDING}
        filter="url(#shadow)"
        className={styles.surface} />
    </>
  );
}

Surface.propTypes = {
  scale: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

function File(props) {
  const lineClasses = classNames({
    [styles.file]: true,
    [styles.highlight]: props.highlight,
  });
  const textClasses = classNames({
    [styles.label]: true,
    [styles.highlight]: props.highlight,
  });
  return (
    <>
      <line
        x1={props.scale * (props.x + 0.5)}
        y1={props.scale * props.length}
        x2={props.scale * (props.x + 0.5)}
        y2={0}
        strokeWidth={props.scale * UNSCALED_LINE_WIDTH}
        className={lineClasses} />
      <text
        x={props.scale * (props.x + 0.5)}
        y={props.scale * (props.length + UNSCALED_FONT_HEIGHT)}
        textAnchor={'middle'}
        dominantBaseline={'middle'}
        fontSize={props.scale * UNSCALED_FONT_HEIGHT}
        fontWeight={props.highlight ? 'bold' : 'normal'}
        className={textClasses}>{props.name}</text>
    </>
  );
}

File.propTypes = {
  scale: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
};

function Rank(props) {
  const lineClasses = classNames({
    [styles.rank]: true,
    [styles.highlight]: props.highlight,
  });
  const textClasses = classNames({
    [styles.label]: true,
    [styles.highlight]: props.highlight,
  });
  return (
    <>
      <line
        x1={0}
        y1={props.scale * ((props.maxY - props.y) + 0.5)}
        x2={props.scale * props.length}
        y2={props.scale * ((props.maxY - props.y) + 0.5)}
        strokeWidth={props.scale * UNSCALED_LINE_WIDTH}
        className={lineClasses} />
      <text
        x={props.scale * -UNSCALED_FONT_WIDTH}
        y={props.scale * ((props.maxY - props.y) + 0.5)}
        textAnchor={'middle'}
        dominantBaseline={'middle'}
        fontSize={props.scale * UNSCALED_FONT_HEIGHT}
        fontWeight={props.highlight ? 'bold' : 'normal'}
        className={textClasses}>{props.name}</text>
    </>
  );
}

Rank.propTypes = {
  scale: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
};

function Dot(props) {
  const circleClasses = classNames({
    [styles.dot]: true,
    [styles.highlight]: props.highlight,
  });
  return (
    <circle
      cx={props.scale * (props.x + 0.5)}
      cy={props.scale * ((props.maxY - props.y) + 0.5)}
      r={props.scale * UNSCALED_DOT_RADIUS}
      className={circleClasses} />
  );
}

Dot.propTypes = {
  scale: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
  highlight: PropTypes.bool,
};

function Target(props) {
  return (
    <circle
      cx={props.scale * (props.x + 0.5)}
      cy={props.scale * ((props.maxY - props.y) + 0.5)}
      r={props.scale * UNSCALED_TARGET_RADIUS}
      strokeWidth={props.scale * UNSCALED_TARGET_THICKNESS}
      className={styles.target} />
  );
}

Target.propTypes = {
  scale: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
};

function Square(props) {
  const classes = classNames({
    [styles.square]: true,
    [styles.from]: props.from,
    [styles.to]: props.canBeTo,
  });
  const onClick = props.canBeTo ?
    () => {
      props.action();
      props.setSelectedFromPoint(undefined);
    } :
    props.canBeFrom ?
      () => props.setSelectedFromPoint(props.point) :
      () => props.setSelectedFromPoint(undefined);
  return (
    <rect
      data-testid={`${props.x},${props.y}`}
      x={props.scale * props.x}
      y={props.scale * (props.maxY - props.y)}
      width={props.scale}
      height={props.scale}
      rx={props.scale / props.maxX}
      strokeWidth={props.scale * UNSCALED_LINE_WIDTH}
      className={classes}
      onClick={onClick} />
  );
}

Square.propTypes = {
  mode: PropTypes.symbol.isRequired,
  scale: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  maxX: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
  point: PropTypes.string.isRequired,
  setSelectedFromPoint: PropTypes.func.isRequired,
  canBeFrom: PropTypes.bool.isRequired,
  from: PropTypes.bool.isRequired,
  canBeTo: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
};

function BoardMarkings(props) {
  const dispatch = useDispatch();
  const result = [
    <Surface key={'surface'} scale={props.scale} width={props.width} height={props.height} />,
  ];
  for (let x = 0; x < props.width; ++x) {
    const file = asFile(x, props.game, props.rotated);
    if (props.hideFiles !== undefined && props.hideFiles(file)) {
      continue;
    }
    const highlight = props.highlightFiles !== undefined && props.highlightFiles(file);
    result.push(
      <File
        key={`file #${file}`}
        scale={props.scale}
        x={x}
        length={props.height}
        name={props.game.prettifyFile(file)}
        highlight={highlight} />,
    );
  }
  for (let y = 0; y < props.height; ++y) {
    const rank = asRank(y, props.game, props.rotated);
    if (props.hideRanks !== undefined && props.hideRanks(rank)) {
      continue;
    }
    const highlight = props.highlightRanks !== undefined && props.highlightRanks(rank);
    result.push(
      <Rank
        key={`rank #${rank}`}
        scale={props.scale}
        y={y}
        maxY={props.height - 1}
        length={props.width}
        name={props.game.prettifyRank(rank)}
        highlight={highlight} />,
    );
  }
  for (let y = 0; y < props.height; ++y) {
    const rank = asRank(y, props.game, props.rotated);
    for (let x = 0; x < props.width; ++x) {
      const file = asFile(x, props.game, props.rotated);
      if (props.hidePoints !== undefined && props.hidePoints(file, rank)) {
        continue;
      }
      const highlight = props.highlightPoints !== undefined && props.highlightPoints(file, rank);
      const key = props.game.prettifyPoint(file, rank);
      result.push(
        <Dot
          key={`dot at ${key}`}
          scale={props.scale}
          x={x}
          y={y}
          maxY={props.height - 1}
          highlight={highlight} />,
      );
    }
  }
  const maybeCopyGame = () => {
    if (!props.copyOnEdit) {
      return props.treeName;
    }
    const treeName = `${props.slot}/${impure.createTimestamp()}`;
    dispatch(newGame({
      treeName,
      gameIdentifier: props.game.identifier,
      rootPosition: props.position.serialization,
      rootPly: props.position.ply,
      eternal: false,
      compensated: props.compensated && props.position.ply === 0,
      rotated: props.rotated,
      originalEncoding: props.position,
    }));
    dispatch(loadTree({
      slot: props.slot,
      treeName,
    }));
    return treeName;
  };
  for (let y = 0; y < props.height; ++y) {
    const rank = asRank(y, props.game, props.rotated);
    for (let x = 0; x < props.width; ++x) {
      const file = asFile(x, props.game, props.rotated);
      const key = props.game.prettifyPoint(file, rank);
      const piece = props.position.pieces[key];
      const move = props.game.joinPoints(props.fromPoint, key);
      let canBeFromSquare = false;
      let isFromSquare = false;
      let canBeToSquare = false;
      let action = undefined;
      switch (props.mode) {
      case GAMEPLAY_MODE:
        canBeFromSquare = props.moves.some((candidateMove) => {
          const [fromPoint] = props.game.splitMove(candidateMove);
          return fromPoint === key;
        });
        isFromSquare = key === props.fromPoint;
        canBeToSquare = props.moves.includes(move);
        action = () => {
          if (props.networkCode !== undefined) {
            props.setWaitingOnServer(true);
            makeNetworkMove(props.networkCode, move).then((message) => {
              props.setWaitingOnServer(false);
            }).catch(() => {
              props.setWaitingOnServer(false);
            });
          } else {
            dispatch(makeMove({
              treeName: props.treeName,
              move,
              preferMainLine: !props.analysisOnly,
              time: props.getElapsedTime(),
            }));
          }
        };
        break;
      case PIECE_REMOVAL_MODE:
        canBeToSquare = piece !== undefined && piece.type !== props.game.dragon;
        action = () => dispatch(rerootWithModification({
          treeName: maybeCopyGame(),
          point: key,
          playerIndex: undefined,
          pieceType: undefined,
        }));
        break;
      case PIECE_ADDITION_MODE:
        canBeToSquare = piece === undefined;
        action = () => dispatch(rerootWithModification({
          treeName: maybeCopyGame(),
          point: key,
          playerIndex: props.additionPlayerIndex,
          pieceType: props.additionPieceType,
        }));
        break;
      case PIECE_TELEPORTATION_MODE:
        canBeFromSquare = piece !== undefined;
        isFromSquare = key === props.fromPoint;
        canBeToSquare = props.fromPoint !== undefined && piece === undefined;
        action = () => dispatch(rerootWithTeleportation({
          treeName: maybeCopyGame(),
          from: props.fromPoint,
          to: key,
        }));
        break;
      default:
        console.assert(false, `\`${props.mode}' is not a supported board interaction mode.`);
      }
      result.push(
        <Square
          mode={props.mode}
          key={`square ${key}`}
          scale={props.scale}
          x={x}
          y={y}
          maxX={props.width - 1}
          maxY={props.height - 1}
          point={key}
          setSelectedFromPoint={props.setSelectedFromPoint}
          canBeFrom={canBeFromSquare}
          from={isFromSquare}
          canBeTo={canBeToSquare}
          action={action} />,
      );
      if (key === props.target) {
        result.push(
          <Target
            key={`target ${key}`}
            scale={props.scale}
            x={x}
            y={y}
            maxY={props.height - 1} />,
        );
      }
    }
  }
  return result;
}

BoardMarkings.propTypes = {
  mode: PropTypes.symbol.isRequired,
  additionPlayerIndex: PropTypes.number,
  additionPieceType: PropTypes.string,
  copyOnEdit: PropTypes.bool.isRequired,
  slot: PropTypes.string,
  treeName: PropTypes.string.isRequired,
  networkCode: PropTypes.string,
  players: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    assistance: PropTypes.number,
    strength: PropTypes.number,
  })).isRequired,
  game: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    dragon: PropTypes.string.isRequired,
    boardWidth: PropTypes.number.isRequired,
    boardHeight: PropTypes.number.isRequired,
    noPoint: PropTypes.string.isRequired,
    prettifyFile: PropTypes.func.isRequired,
    prettifyRank: PropTypes.func.isRequired,
    prettifyPoint: PropTypes.func.isRequired,
    splitMove: PropTypes.func.isRequired,
    joinPoints: PropTypes.func.isRequired,
  }).isRequired,
  position: PropTypes.shape({
    ply: PropTypes.number.isRequired,
    serialization: PropTypes.string.isRequired,
    pieces: PropTypes.objectOf(PropTypes.shape({
      point: PropTypes.string.isRequired,
      identity: PropTypes.any.isRequired,
    })).isRequired,
  }).isRequired,
  compensated: PropTypes.bool.isRequired,
  scale: PropTypes.number.isRequired,
  rotated: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  hideFiles: PropTypes.func,
  hideRanks: PropTypes.func,
  hidePoints: PropTypes.func,
  highlightFiles: PropTypes.func,
  highlightRanks: PropTypes.func,
  highlightPoints: PropTypes.func,
  target: PropTypes.string,
  moves: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  analysisOnly: PropTypes.bool,
  fromPoint: PropTypes.string,
  setSelectedFromPoint: PropTypes.func.isRequired,
  setWaitingOnServer: PropTypes.func.isRequired,
  getElapsedTime: PropTypes.func.isRequired,
};

function Piece(props) {
  return (
    <Flipped flipId={props.identity}>
      <image
        x={props.scale * props.x}
        y={props.scale * (props.maxY - props.y)}
        width={props.scale}
        height={props.scale}
        href={props.image}
        className={styles.piece} />
    </Flipped>
  );
}

Piece.propTypes = {
  identity: PropTypes.any.isRequired,
  scale: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};

function Pieces(props) {
  const dragon = useSelector(selectDragon);
  const pawns = useSelector(selectPawns);
  const knights = useSelector(selectKnights);
  const towers = useSelector(selectTowers);

  const result = [];
  for (const key of Object.getOwnPropertyNames(props.position.pieces)) {
    const piece = props.position.pieces[key];
    const image =
      piece.type === props.game.dragon ? dragon :
        piece.type === props.game.pawn ? pawns.get(piece.playerIndex) :
          piece.type === props.game.knight ? knights.get(piece.playerIndex) :
            piece.type === props.game.tower ? towers.get(piece.playerIndex) :
              undefined;
    console.assert(image !== undefined, `Cannot find an image for the piece type ${piece.type}.`);
    const [file, rank] = props.game.unprettifyPoint(piece.point);
    result.push(
      <Piece
        key={piece.identity}
        identity={piece.identity}
        scale={props.scale}
        x={asX(file, props.game, props.rotated)}
        y={asY(rank, props.game, props.rotated)}
        maxY={props.height - 1}
        image={image} />,
    );
  }
  return result;
}

Pieces.propTypes = {
  game: PropTypes.shape({
    boardWidth: PropTypes.number.isRequired,
    boardHeight: PropTypes.number.isRequired,
    noPoint: PropTypes.string.isRequired,
    prettifyFile: PropTypes.func.isRequired,
    prettifyRank: PropTypes.func.isRequired,
    prettifyPoint: PropTypes.func.isRequired,
    unprettifyPoint: PropTypes.func.isRequired,
    splitMove: PropTypes.func.isRequired,
    joinPoints: PropTypes.func.isRequired,
  }).isRequired,
  position: PropTypes.shape({
    pieces: PropTypes.objectOf(PropTypes.shape({
      point: PropTypes.string.isRequired,
      identity: PropTypes.any.isRequired,
    })).isRequired,
  }).isRequired,
  scale: PropTypes.number.isRequired,
  rotated: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
};

function Time(props) {
  return (
    <text
      x={props.x}
      y={props.y}
      textAnchor={props.alignment}
      dominantBaseline={'middle'}
      fontSize={props.fontSize}
      transform={`rotate(90, ${props.x}, ${props.y})`}
      className={props.highlight}>{props.time}</text>
  );
}

Time.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  alignment: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

const RESIGNATION_MENU_SUBPATH = 'confirmResignation';

function formatTime(time, invulnerable) {
  if (typeof time !== 'number') {
    return time;
  }
  if (time <= 0 && !invulnerable) {
    return 'Lost on Time';
  }
  return formatDuration(time);
}

function Clock(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const x = props.scale * (props.boardWidth + UNSCALED_FONT_HEIGHT);
  const fontSize = props.scale * UNSCALED_FONT_HEIGHT;
  const timeStates = [useState(), useState()];

  useEffect(() => {
    const timers = [];
    for (const index of [0, 1]) {
      const [time, setTime] = timeStates[index];
      const expiration = props.expirations[index];
      if (time === undefined || typeof props.times[index] !== 'number' || time > props.times[index]) {
        setTime(props.times[index]);
      }
      if (!props.disabled && typeof props.times[index] === 'number' && expiration < Infinity) {
        const delay = untilNextUserVisibleTick(expiration - impure.createTimestamp().getTime());
        if (delay > 0) {
          timers.push(setTimeout(() => setTime(expiration - impure.createTimestamp().getTime()), delay));
        }
      }
    }
    return () => timers.map(clearTimeout);
  });

  const nearTime = timeStates[props.rotated ? 1 : 0][0];
  const farTime = timeStates[props.rotated ? 0 : 1][0];
  const invulnerable = timeStates.some(([time]) => typeof time !== 'number');
  const otherwiseDecided = timeStates.some(([time]) => typeof time !== 'number' || time <= 0);

  const onClick = createModalOpener(location, navigate, RESIGNATION_MENU_SUBPATH);
  const onKeyDown = (event) => event.key === ' ' || event.key === 'Enter' ? onClick() : undefined;

  return (
    <>
      { nearTime !== undefined && nearTime !== Infinity ?
        <Time
          x={x}
          y={props.scale * props.boardHeight}
          fontSize={fontSize}
          alignment={'end'}
          time={formatTime(nearTime, invulnerable)} /> :
        null }
      { farTime !== undefined && farTime !== Infinity ?
        <Time
          x={x}
          y={0}
          fontSize={fontSize}
          alignment={'start'}
          time={formatTime(farTime, invulnerable)} /> :
        null }
      { props.allowResignation && !otherwiseDecided ?
        <image
          x={x - props.scale * UNSCALED_FONT_HEIGHT / 2}
          y={props.scale * (props.boardHeight - UNSCALED_FONT_HEIGHT) / 2}
          width={props.scale * UNSCALED_FONT_HEIGHT}
          height={props.scale * UNSCALED_FONT_HEIGHT}
          href={resignIcon}
          role={'button'}
          aria-label={'Resign'}
          tabIndex={0}
          onClick={onClick}
          onContextMenu={onClick}
          onKeyDown={onKeyDown} /> :
        null }
    </>
  );
}

Clock.propTypes = {
  scale: PropTypes.number.isRequired,
  rotated: PropTypes.bool.isRequired,
  boardWidth: PropTypes.number.isRequired,
  boardHeight: PropTypes.number.isRequired,
  times: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired).isRequired,
  expirations: PropTypes.arrayOf(PropTypes.number).isRequired,
  allowResignation: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

function ResignationSubMenu(props) {
  const navigate = useNavigate();
  const onResign = () => {
    resign(props.networkCode).catch(() => {});
    navigate(-1);
  };
  return (
    <Modal subpath={RESIGNATION_MENU_SUBPATH} altText={'Really resign?'}>
      <Menu>
        <MenuText>
          Really resign?
          <br />
          Even if you cannot win, you might still be able to give your opponent a good fight.
        </MenuText>
        <MenuButton onClick={onResign}>
          Confirm Resignation
        </MenuButton>
        <MenuButton onClick={() => navigate(-1)}>
          Return to Game
        </MenuButton>
      </Menu>
    </Modal>
  );
}

ResignationSubMenu.propTypes = {
  networkCode: PropTypes.string,
};

function Suggestion(props) {
  const [fromFile, fromRank, toFile, toRank] = props.game.unprettifyMove(props.move);
  if (fromFile === undefined || fromRank === undefined || toFile === undefined || toRank === undefined) {
    return null;
  }
  const fromX = asX(fromFile, props.game, props.rotated);
  const fromY = asY(fromRank, props.game, props.rotated);
  const toX = asX(toFile, props.game, props.rotated);
  const toY = asY(toRank, props.game, props.rotated);
  if (fromX === toX && fromY === toY) {
    return (
      <circle
        cx={props.scale * (fromX + 0.5)}
        cy={props.scale * ((props.maxY - fromY) + 0.5)}
        r={props.scale * UNSCALED_SUGGESTION_CIRCLE_RADIUS}
        className={styles.suggestion} />
    );
  }
  const dx = toX - fromX;
  const dy = toY - fromY;
  const shortening = UNSCALED_SUGGESTION_ARROW_RETRACTION / Math.sqrt(dx * dx + dy * dy);
  const shortX = toX - shortening * dx;
  const shortY = toY - shortening * dy;
  const pathBegin = `M ${props.scale * (fromX + 0.5)} ${props.scale * ((props.maxY - fromY) + 0.5)}`;
  const pathEnd = `L ${props.scale * (shortX + 0.5)} ${props.scale * ((props.maxY - shortY) + 0.5)}`;
  return (
    <path
      d={`${pathBegin} ${pathEnd}`}
      strokeWidth={props.scale * UNSCALED_SUGGESTION_ARROW_WIDTH}
      strokeLinejoin="round"
      strokeLinecap="round"
      markerEnd="url(#arrowhead)"
      className={styles.suggestion} />
  );
}

Suggestion.propTypes = {
  game: PropTypes.shape({
    boardWidth: PropTypes.number.isRequired,
    boardHeight: PropTypes.number.isRequired,
    unprettifyMove: PropTypes.func.isRequired,
  }).isRequired,
  move: PropTypes.string.isRequired,
  scale: PropTypes.number.isRequired,
  rotated: PropTypes.bool.isRequired,
  maxY: PropTypes.number.isRequired,
};

function Suggestions(props) {
  const baseRadius = props.scale * Math.min(UNSCALED_FONT_WIDTH, UNSCALED_FONT_HEIGHT);
  const minimumRadius = ADVANTAGE_ANALYSIS_PULSE_MINIMUM * baseRadius;
  const maximumRadius = ADVANTAGE_ANALYSIS_PULSE_MAXIMUM * baseRadius;
  const dotX = props.scale * (props.boardWidth + UNSCALED_FONT_WIDTH);
  const dotY = props.scale * (props.boardHeight + UNSCALED_FONT_HEIGHT);

  return (
    <>
      { props.analyzing ?
        <circle
          cx={dotX}
          cy={dotY}
          r={minimumRadius}
          className={styles['analysis-indicator']}>
          <animate
            attributeName="r"
            values={`${minimumRadius};${maximumRadius};${minimumRadius}`}
            dur="1.5s"
            repeatCount="indefinite" />
        </circle> :
        null }
      <marker
        id="arrowhead"
        viewBox="0 0 5 4"
        refX="3.5"
        refY="2"
        markerUnits="strokeWidth"
        markerWidth="3.75"
        markerHeight="3"
        orient="auto">
        <path
          d="M 1 1 L 4 2 L 1 3 z"
          strokeLinejoin="round"
          strokeLinecap="round"
          className={styles.arrowhead} />
      </marker>
      {props.suggestions.map((move) =>
        <Suggestion
          key={move}
          game={props.game}
          move={move}
          scale={props.scale}
          rotated={props.rotated}
          maxY={props.boardHeight - 1} />,
      )}
    </>
  );
}

Suggestions.propTypes = {
  game: PropTypes.shape({
    boardWidth: PropTypes.number.isRequired,
    boardHeight: PropTypes.number.isRequired,
    unprettifyMove: PropTypes.func.isRequired,
  }).isRequired,
  analyzing: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  scale: PropTypes.number.isRequired,
  rotated: PropTypes.bool.isRequired,
  boardWidth: PropTypes.number.isRequired,
  boardHeight: PropTypes.number.isRequired,
};

const ADVANTAGE_SCALE = 86;
const ADVANTAGE_CLAMP = Math.floor(ADVANTAGE_SCALE * Math.log(Number.MAX_VALUE - 1));

function toProbability(advantage) {
  if (advantage === undefined) {
    return 0.5;
  }
  const clampedAdvantage = Math.min(Math.max(advantage, -ADVANTAGE_CLAMP), ADVANTAGE_CLAMP);
  const exponentiation = Math.exp(clampedAdvantage / ADVANTAGE_SCALE);
  return exponentiation / (exponentiation + 1);
}

function Advantage(props) {
  const colorSpecifications = useSelector(selectColorSpecifications);
  const [lastAdvantage, setLastAdvantage] = useState();

  const advantage = props.advantage !== undefined ? props.advantage : lastAdvantage;
  if (advantage !== undefined && advantage !== lastAdvantage) {
    setLastAdvantage(advantage);
  }

  const x = props.scale * (props.boardWidth + UNSCALED_FONT_WIDTH);
  const lowY = props.scale * props.boardHeight;
  const highY = 0;
  const middleY = Math.max(
    Math.min(
      lowY + toProbability(props.rotated ? -advantage : advantage) * (highY - lowY),
      lowY - 1),
    1,
  );
  const baseRadius = props.scale * Math.min(UNSCALED_FONT_WIDTH, UNSCALED_FONT_HEIGHT);
  const minimumRadius = ADVANTAGE_ANALYSIS_PULSE_MINIMUM * baseRadius;
  const lowColor = colorSpecifications.get(props.rotated ? 1 : 0);
  const highColor = colorSpecifications.get(props.rotated ? 0 : 1);

  return (
    <>
      <circle
        cx={x}
        cy={lowY}
        r={minimumRadius}
        stroke={lowColor}
        fill={lowColor} />
      <circle
        cx={x}
        cy={highY}
        r={minimumRadius}
        stroke={highColor}
        fill={highColor} />
      <rect
        x={x - minimumRadius}
        y={highY}
        width={2 * minimumRadius}
        style={{ height: `${lowY - highY}px` }}
        stroke={lowColor}
        fill={lowColor} />
      <Flipped flipId={'Advantage for Opponent'}>
        <rect
          x={x - minimumRadius}
          y={highY}
          width={2 * minimumRadius}
          style={{ height: `${middleY - highY}px` }}
          stroke={highColor}
          fill={highColor} />
      </Flipped>
    </>
  );
}

Advantage.propTypes = {
  advantage: PropTypes.number,
  scale: PropTypes.number.isRequired,
  rotated: PropTypes.bool.isRequired,
  boardWidth: PropTypes.number.isRequired,
  boardHeight: PropTypes.number.isRequired,
};

const PIECES_MENU_SUBPATH = 'piecesContextMenu';

export function Board(props) {
  const treesBySlot = useSelector(selectTreesBySlot);
  const treeName = props.slot !== undefined ? treesBySlot[props.slot] : props.treeName;
  const networkCode = useSelector(selectNetworkCodes)[treeName];
  const players = useSelector(selectPlayers)[treeName];
  const timeControls = useSelector(selectTimeControls)[treeName];
  const timeUsed = useSelector(selectTimeUsed)[treeName];
  const timeUsedEditCount = useSelector(selectTimeUsedEditCount)[treeName];
  const runningClockIndex = useSelector(selectRunningClockIndices)[treeName];
  const compensated = useSelector(selectCompensations)[treeName];
  const complete = useSelector(selectCompletionFlags)[treeName];
  const winnerOnTime = useSelector(selectWinnersOnTime)[treeName];
  const winnerByResignation = useSelector(selectWinnersByResignation)[treeName];
  const rotated = useSelector(selectRotations)[treeName];
  const game = useSelector(selectGames)[treeName];
  const rerootSafety = useSelector(selectRerootSafeties)[treeName];
  const position = useSelector(selectPositions)[treeName];
  const positionIdentity = position !== undefined ? position.identity : undefined;
  const moves = useSelector(selectMoveSets)[treeName];
  const assistanceFlag = useSelector(selectAssistanceFlags)[treeName];

  const invitationOnly = networkCode !== undefined &&
    players.some((player) => player?.type === REMOTE_PLAYER && player.name === undefined);

  const [drawingWidth, setDrawingWidth] = useState(undefined);
  const [drawingHeight, setDrawingHeight] = useState(undefined);
  const drawingArea = useRef();
  useEffect(() => {
    if (!drawingArea.current) {
      return undefined;
    }
    function updateDrawingRectangle() {
      if (!drawingArea.current) { // An extra safety in case the drawing area is taken away again.
        return;
      }
      const rectangle = drawingArea.current.getBoundingClientRect();
      setDrawingWidth(rectangle.width);
      setDrawingHeight(rectangle.height);
    }
    updateDrawingRectangle();
    const resizeObserver = new ResizeObserver(updateDrawingRectangle);
    resizeObserver.observe(drawingArea.current);
    return () => resizeObserver.disconnect();
  });

  const [waitingOnServer, setWaitingOnServer] = useState(false);

  const ready =
    game !== undefined &&
    position !== undefined &&
    !invitationOnly &&
    drawingWidth !== undefined &&
    drawingHeight !== undefined;
  const availableMoves =
    !props.disabled &&
    !props.locked &&
    winnerByResignation === undefined &&
    !waitingOnServer &&
    ready &&
    position.live &&
    (props.analysisOnly || players[position.ply % game.playerCount].type === HUMAN_PLAYER) ? moves : [];

  const times = ready ? timeUsed.map((used) => Math.max(timeControls - used, 0) * 1000) : [0, 0];
  if (winnerOnTime !== undefined) {
    for (let i = 0; i < times.length; ++i) {
      if (i !== winnerOnTime) {
        times[i] = 'Lost on Time';
      }
    }
  }
  if (winnerByResignation !== undefined) {
    for (let i = 0; i < times.length; ++i) {
      if (i !== winnerByResignation) {
        times[i] = 'Lost by Resignation';
      }
    }
  }
  const expirations = [undefined, undefined];
  if (runningClockIndex !== undefined && typeof times[runningClockIndex] === 'number') {
    expirations[runningClockIndex] = impure.createTimestamp().getTime() + times[runningClockIndex];
  }
  const allowResignation = networkCode !== undefined &&
    !complete &&
    winnerOnTime === undefined &&
    winnerByResignation === undefined;

  const analyzing =
    !props.disabled &&
    ((ready &&
      position.live &&
      (props.analysisOnly ||
       (players[position.ply % game.playerCount].type === HUMAN_PLAYER && assistanceFlag)) &&
      props.analysisDepth !== undefined &&
      (position.analysisDepth === undefined || position.analysisDepth < props.analysisDepth)) ||
     props.forceShowAnalysisIndicator || false);
  const advantage = ready ? position.advantage : undefined;
  const suggestions = ready ? position.suggestions : [];

  const [lastTreeName, setLastTreeName] = useState();
  const [lastPosition, setLastPosition] = useState();
  const [selectedFromPoint, setSelectedFromPoint] = useState();
  const unexpiredFromPoint =
    lastTreeName === treeName && lastPosition === positionIdentity && winnerByResignation === undefined ?
      selectedFromPoint : undefined;
  useEffect(() => {
    if (lastTreeName !== treeName || lastPosition !== positionIdentity) {
      setSelectedFromPoint(undefined);
      setLastTreeName(treeName);
      setLastPosition(positionIdentity);
    }
  }, [lastTreeName, treeName, lastPosition, positionIdentity]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let lastUpdate = impure.createTimestamp().getTime();
  const getElapsedTime = () => {
    const now = impure.createTimestamp().getTime();
    const elapsed = (now - lastUpdate) / 1000;
    lastUpdate = now;
    return elapsed;
  };

  useEffect(() => {
    if (!props.disabled && ready && (props.mode === undefined || props.mode === GAMEPLAY_MODE)) {
      dispatch(rerootWithCompensation({
        treeName,
      }));
    }
  });
  useEffect(() => {
    if (!props.disabled && ready && availableMoves.length === 1 && availableMoves[0] === game.pass &&
      position.mostRecentMove === undefined) {
      dispatch(makeMove({
        treeName,
        positionIdentity,
        move: game.pass,
        isNull: true, // necessary because the pass might only be legal because of a handicap
        preferMainLine: !props.analysisOnly,
        time: 0,
      }));
    }
  });
  useEffect(() => {
    if ((props.forceRunningClock || (!props.disabled && !props.locked && winnerByResignation === undefined)) && ready) {
      return () => {
        const elapsed = getElapsedTime();
        if (elapsed >= MINIMUM_TIME_DECREMENT) {
          dispatch(takeTime({
            treeName,
            time: elapsed,
            fromEditCount: timeUsedEditCount,
          }));
        }
      };
    }
    return undefined;
  });
  useOnAbruptShutdown(() => {
    const elapsed = getElapsedTime();
    if (elapsed >= MINIMUM_TIME_DECREMENT) {
      takeTimeDuringAbruptShutdown({
        treeName,
        time: elapsed,
        fromEditCount: timeUsedEditCount,
      });
    }
  });

  const boardWidth = ready ? game.boardWidth : 1;
  const boardHeight = ready ? game.boardHeight : 1;
  const width = ready ? drawingWidth : FALLBACK_DIMENSION;
  const height = ready ? drawingHeight : FALLBACK_DIMENSION;
  const scale = Math.min(width / boardWidth, height / boardHeight);
  const bleed = scale * (2 * UNSCALED_FONT_HEIGHT) / Math.min(boardWidth, boardHeight);
  const viewBox =
    `${-bleed * boardWidth} ${-bleed * boardHeight} ` +
    `${(scale + 2 * bleed) * boardWidth} ${(scale + 2 * bleed) * boardHeight}`;

  const onCancelInvite = () => {
    uninvite(networkCode).then(() => navigate(props.backTo)).catch(() => {});
  };

  return (
    <>
      <div
        className={styles.board}
        onContextMenu={props.disabled ? () => {} : createModalOpener(location, navigate, PIECES_MENU_SUBPATH)}>
        <Flipper flipKey={`${rotated}/${positionIdentity}/${advantage}`}>
          <svg ref={drawingArea} viewBox={viewBox}>
            { ready ?
              <>
                <BoardMarkings
                  mode={props.mode || GAMEPLAY_MODE}
                  additionPlayerIndex={props.additionPlayerIndex}
                  additionPieceType={props.additionPieceType}
                  copyOnEdit={props.slot !== undefined && !rerootSafety}
                  slot={props.slot}
                  treeName={treeName}
                  networkCode={networkCode}
                  players={players}
                  game={game}
                  position={position}
                  compensated={compensated}
                  scale={scale}
                  rotated={rotated}
                  width={boardWidth}
                  height={boardHeight}
                  hideFiles={props.hideFiles}
                  hideRanks={props.hideRanks}
                  hidePoints={props.hidePoints}
                  highlightFiles={props.highlightFiles}
                  highlightRanks={props.highlightRanks}
                  highlightPoints={props.highlightPoints}
                  target={props.target}
                  moves={availableMoves}
                  analysisOnly={props.analysisOnly}
                  fromPoint={unexpiredFromPoint}
                  setSelectedFromPoint={setSelectedFromPoint}
                  setWaitingOnServer={setWaitingOnServer}
                  getElapsedTime={getElapsedTime} />
                <Pieces
                  game={game}
                  position={position}
                  scale={scale}
                  rotated={rotated}
                  height={boardHeight} />
                { !props.analysisOnly ?
                  <Clock
                    scale={scale}
                    rotated={rotated}
                    boardWidth={boardWidth}
                    boardHeight={boardHeight}
                    times={times}
                    expirations={expirations}
                    allowResignation={allowResignation}
                    disabled={!props.forceRunningClock && props.disabled} /> :
                  null }
                { analyzing || suggestions.length > 0 ?
                  <>
                    <Suggestions
                      game={game}
                      scale={scale}
                      rotated={rotated}
                      analyzing={analyzing}
                      suggestions={suggestions}
                      boardWidth={boardWidth}
                      boardHeight={boardHeight} />
                  </> :
                  null }
                { props.analysisOnly && props.analysisDepth !== undefined ?
                  <>
                    <Advantage
                      advantage={advantage}
                      scale={scale}
                      rotated={rotated}
                      boardWidth={boardWidth}
                      boardHeight={boardHeight} />
                  </> :
                  null }
              </> :
              null }
          </svg>
        </Flipper>
        { invitationOnly ?
          <div className={styles.instructions}>
            <p><strong>Open Invitation</strong></p>
            <p>Have an opponent join this game by tapping either the "Accept an Invitation" button on their home screen
              or the <img src={inviteIcon} alt="Accept an Invitation" /> button below their list of network games and
              then entering this invitation code:</p>
            <p><strong>{networkCode}</strong></p>
            { !props.disabled ?
              <>
                <p>Or, if you want to cancel the invitation, tap below:</p>
                <ButtonBar>
                  <Button text={'Cancel Invitation'} onClick={onCancelInvite} />
                </ButtonBar>
              </> : null }
          </div> : null }
      </div>
      { invitationOnly ? <div className={styles.filler} /> : null }
      { props.disabled || !ready ?
        null :
        <PiecesMenu subpath={PIECES_MENU_SUBPATH} playerCount={game.playerCount} /> }
      { networkCode !== undefined ? <ResignationSubMenu networkCode={networkCode} /> : null }
    </>
  );
}

Board.propTypes = {
  mode: PropTypes.symbol,
  additionPlayerIndex: PropTypes.number,
  additionPieceType: PropTypes.string,
  // Either slot or treeName is required, but we do not check that here.
  slot: PropTypes.string,
  treeName: PropTypes.string,
  analysisOnly: PropTypes.bool.isRequired,
  analysisDepth: PropTypes.number,
  forceShowAnalysisIndicator: PropTypes.bool,
  forceRunningClock: PropTypes.bool,
  disabled: PropTypes.bool,
  locked: PropTypes.bool,
  hideFiles: PropTypes.func,
  hideRanks: PropTypes.func,
  hidePoints: PropTypes.func,
  highlightFiles: PropTypes.func,
  highlightRanks: PropTypes.func,
  highlightPoints: PropTypes.func,
  target: PropTypes.string,
  backTo: PropTypes.string,
};
